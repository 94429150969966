import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { USStatesAbbr } from 'helpers/constants/us-states-abbr';
import { TMyRewardsBannerProps } from 'helpers/types/organisms/banners/my-rewards-banner';
import { getMenuFiltersLink } from 'helpers/utils/common';
import { useAppSelector } from 'redux/hooks';
import {
  MyRewardsBox,
  MyRewardsContainer,
  MyRewardsInnerContainer,
  MyRewardsInnerContainerDesktop,
  MyRewardsPointsRow,
  MyRewardsTitleRow,
  RewardsIllustrationContainer,
  RewardsIllustrationDesktop,
  RewardsIllustrationMobile,
  RiseRewardsLogoContainer,
  RiseRewardsLogoDesktop,
  RiseRewardsLogoMobile,
} from './styles';

const getRewardsInformationElements = (title, points, buttonLabel, isMobile, homeState) => {
  return (
    <>
      <MyRewardsTitleRow>
        <Typography variant="h5" data-testid={`my_rewards_title_${isMobile}`}>
          {title}
        </Typography>
      </MyRewardsTitleRow>
      <MyRewardsPointsRow>
        <Typography variant="h1" data-testid={`my_rewards_points_${isMobile}`}>
          {points.toLocaleString()}
        </Typography>
      </MyRewardsPointsRow>
      <Typography
        variant="body-small"
        data-testid={`my_rewards_last_updated_${isMobile}`}
        className="max-w-[220px] self-stretch capitalize opacity-80 md:max-w-none xl:max-w-[400px]"
      >
        Points displayed are from your {USStatesAbbr[homeState]?.toLowerCase()} RISE Rewards account
      </Typography>
      <div className="pt-[28px] md:pt-[48px]">
        <CommonButton
          as="a"
          href={getMenuFiltersLink()}
          button-type="primary"
          color="green"
          data-testid={`my_rewards_button_${isMobile}`}
        >
          {buttonLabel}
        </CommonButton>
      </div>
    </>
  );
};

export const MyRewardsBannerComponent = ({
  title,
  points = 0,
  buttonLabel,
  backgroundImageSrc,
  backgroundImageDesktopSrc,
  backgroundImageAltText = '',
  backgroundImageAriaLabel = '',
  riseRewardsImageSrc,
  riseRewardsImageDesktopSrc,
  riseRewardsImageAltText = '',
  riseRewardsImageAriaLabel = '',
  illustrationImageSrc,
  illustrationImageDesktopSrc,
  illustrationImageAltText = '',
  illustrationImageAriaLabel = '',
}: TMyRewardsBannerProps) => {
  const user = useAppSelector((store) => store?.accountReducer?.user);
  return (
    <MyRewardsContainer id="rewards-section">
      <MyRewardsBox>
        <MyRewardsInnerContainer
          data-testid="my_rewards_banner_bg"
          style={{ backgroundImage: backgroundImageSrc ? `url('${backgroundImageSrc}')` : 'unset' }}
          role="img"
          aria-label={backgroundImageAriaLabel}
          arial-title={backgroundImageAltText}
        >
          <RiseRewardsLogoContainer>
            <RiseRewardsLogoMobile data-testid="my_rewards_rr_logo">
              <Image
                src={riseRewardsImageSrc}
                width={36}
                height={24}
                alt={riseRewardsImageAltText}
                aria-label={riseRewardsImageAriaLabel}
                loader={(options) => options.src}
              />
            </RiseRewardsLogoMobile>
          </RiseRewardsLogoContainer>
          <RewardsIllustrationContainer>
            <RewardsIllustrationMobile data-testid="my_rewards_illustration">
              <Image
                src={illustrationImageSrc}
                width={163}
                height={172}
                alt={riseRewardsImageAltText}
                aria-label={riseRewardsImageAriaLabel}
                loader={(options) => options.src}
              />
            </RewardsIllustrationMobile>
          </RewardsIllustrationContainer>
          {getRewardsInformationElements(title, points, buttonLabel, true, user?.attributes['custom:home_state'])}
        </MyRewardsInnerContainer>

        <MyRewardsInnerContainerDesktop
          style={{ backgroundImage: backgroundImageDesktopSrc ? `url('${backgroundImageDesktopSrc}')` : 'unset' }}
          role="img"
          aria-label={backgroundImageAriaLabel}
          arial-title={backgroundImageAltText}
        >
          <RiseRewardsLogoContainer>
            <RiseRewardsLogoDesktop>
              <Image
                src={riseRewardsImageDesktopSrc}
                width={48}
                height={32}
                alt={riseRewardsImageAltText}
                aria-label={riseRewardsImageAriaLabel}
                loader={(options) => options.src}
              />
            </RiseRewardsLogoDesktop>
          </RiseRewardsLogoContainer>
          <RewardsIllustrationContainer>
            <RewardsIllustrationDesktop>
              <Image
                src={illustrationImageDesktopSrc}
                width={626}
                height={336}
                alt={illustrationImageAltText}
                aria-label={illustrationImageAriaLabel}
                loader={(options) => options.src}
              />
            </RewardsIllustrationDesktop>
          </RewardsIllustrationContainer>
          {getRewardsInformationElements(title, points, buttonLabel, false, user?.attributes['custom:home_state'])}
        </MyRewardsInnerContainerDesktop>
      </MyRewardsBox>
    </MyRewardsContainer>
  );
};
