'use client';

import React from 'react';
import AboutUsDonationInfo from 'components/organisms/about/donation-info';

const AboutUsDonationTastic = (props: any) => {
  const { data } = props;
  const donationInfo = data?.donationInfo?.dataSource?.data?.page?.items[0];
  const donatedThroughGreenThumb = donationInfo?.donatedThroughGreenThumb;
  const donatedThroughGreenThumbDesc = donationInfo?.donatedThroughGreenThumbDesc;

  const donatedToNonProfit = donationInfo?.donatedToNonProfit;
  const donatedToNonProfitDesc = donationInfo?.donatedToNonProfitDesc;
  const donationHeading = donationInfo?.donationHeading;
  const donationParagraph = donationInfo?.donationParagraph;

  return (
    <AboutUsDonationInfo
      donatedThroughGreenThumb={donatedThroughGreenThumb}
      donatedThroughGreenThumbDesc={donatedThroughGreenThumbDesc}
      donatedToNonProfit={donatedToNonProfit}
      donatedToNonProfitDesc={donatedToNonProfitDesc}
      donationHeading={donationHeading}
      donationParagraph={donationParagraph}
    />
  );
};

export default AboutUsDonationTastic;
