'use client';

import Image from 'next/image';
import { BLOCKS } from '@contentful/rich-text-types';
import { formatDate } from 'date-fns';
import PublishNewLabel from 'components/atoms/labels/publish-new';
import Label from 'components/atoms/labels/text-and-icon';
import Breadcrumb from 'components/atoms/navigation/breadcrumb/breadcrumb';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IRichTextData, TBreadCrumbItem } from 'helpers/types';
import { mediumDesktop, tablet } from 'helpers/utils/screensizes';
import {
  Empty,
  EmptySmall,
  HeroArticleDetailBannerImage,
  HeroArticleDetailContainer,
  HeroArticleDetailContentContainer,
  HeroArticleDetailFlexNone,
  HeroArticleDetailIntroContainer,
  HeroArticleDetailIntroContentSection,
  HeroArticleDetailIntroInnerContentSection,
  HeroArticleDetailIntroParagphtSection,
  HeroArticleDetailRecipeDetail,
  HeroArticleDetailSubtitleSection,
  HeroArticleDetailTimeIcon,
  HeroArticleDetailTimeStampContent,
  HeroArticleDetailTitleSection,
  ParagraphSpacerLeft,
  ParagraphSpacerRight,
  Spacer,
  TitleStyling,
} from './styles';

const HeroArticleTimeStamp = ({ date }: { date: string | number }) => {
  return (
    <HeroArticleDetailTimeStampContent>
      <HeroArticleDetailFlexNone>
        <HeroArticleDetailTimeIcon />
      </HeroArticleDetailFlexNone>
      <HeroArticleDetailFlexNone>{date} min</HeroArticleDetailFlexNone>
    </HeroArticleDetailTimeStampContent>
  );
};

interface HeroArticleRecipeSectionProps {
  recipeLevel: string;
  recipeTime: string;
}

const HeroArticleRecipeSection = ({ recipeLevel, recipeTime }: HeroArticleRecipeSectionProps) => {
  if (!recipeLevel || !recipeTime) return <></>;
  return (
    <HeroArticleDetailRecipeDetail>
      <Label variant="white" className="border border-grey-300 bg-white" data-testid="recipe-level">
        {recipeLevel}
      </Label>
      <Label variant="white" className="border border-grey-300 bg-white" data-testid="recipe-time">
        {`Cooking time: ${recipeTime}`}
      </Label>
    </HeroArticleDetailRecipeDetail>
  );
};

interface HeroArticleDetailProps {
  title: string;
  subTitle: string;
  url: string;
  alt: string;
  date: string;
  category: string;
  content?: IRichTextData;
  ariaLabel: string;
  estimateTime?: string | number;
  breadcrumbItems: TBreadCrumbItem[];
  recipeLevel?: string;
  recipeTime?: string;
  height: number;
  width: number;
}

const HeroArticleDetail = ({
  title = '',
  subTitle = '',
  url = '',
  date,
  category = '',
  content,
  alt = '',
  ariaLabel = '',
  estimateTime,
  breadcrumbItems = [],
  recipeLevel,
  recipeTime,
}: HeroArticleDetailProps) => {
  const dateFormatted = date ? formatDate(date, 'MM.dd.yyyy') : undefined;
  const [width] = useMediaQuery();

  return (
    <HeroArticleDetailContainer>
      <HeroArticleDetailBannerImage>
        {url && (
          <Image
            className=" relative z-[1]  w-full self-stretch"
            src={url}
            alt={alt}
            width={width > mediumDesktop ? 1920 : width > tablet ? 1280 : 767}
            height={width > mediumDesktop ? 523 : width > tablet ? 443 : 613.6}
            loader={(options) => options.src}
            aria-labelledby={ariaLabel}
          />
        )}
      </HeroArticleDetailBannerImage>
      <HeroArticleDetailContentContainer>
        <HeroArticleDetailIntroContainer>
          <Empty />
          <HeroArticleDetailIntroContentSection>
            <Spacer />
            <HeroArticleDetailIntroInnerContentSection>
              <div> {breadcrumbItems?.length > 0 && <Breadcrumb items={breadcrumbItems} />}</div>
              <div>
                <PublishNewLabel date={date} />
                <Label variant="grey-200">{category}</Label>
                {category !== 'Resources' && dateFormatted && (
                  <Label variant="white" className="bg-white">
                    {dateFormatted}
                  </Label>
                )}
                {category !== 'Resources' && estimateTime && <HeroArticleTimeStamp date={estimateTime} />}
              </div>
              <HeroArticleDetailTitleSection>
                <div className="h-auto">
                  <Typography variant="h2" className={TitleStyling}>
                    {title}
                  </Typography>
                </div>
                <HeroArticleDetailSubtitleSection>
                  <Typography variant="body-large" className="!text-grey-400">
                    {subTitle}
                  </Typography>
                </HeroArticleDetailSubtitleSection>
                <HeroArticleRecipeSection recipeLevel={recipeLevel as string} recipeTime={recipeTime as string} />
              </HeroArticleDetailTitleSection>
            </HeroArticleDetailIntroInnerContentSection>
            <Spacer />
          </HeroArticleDetailIntroContentSection>
          <EmptySmall />
        </HeroArticleDetailIntroContainer>
        {content && (
          <HeroArticleDetailIntroParagphtSection>
            <ParagraphSpacerLeft />
            <ContentfulRichText
              data={content}
              renderNodeOptions={{
                [BLOCKS.PARAGRAPH]: (node, children) => (
                  <Typography
                    variant="body-large"
                    className="mb-4 !text-grey-400 xl:w-[742px] 2xl:w-[735px]"
                    data-testid="content"
                  >
                    {children}
                  </Typography>
                ),
              }}
            />
            <ParagraphSpacerRight />
          </HeroArticleDetailIntroParagphtSection>
        )}
      </HeroArticleDetailContentContainer>
    </HeroArticleDetailContainer>
  );
};

export default HeroArticleDetail;
