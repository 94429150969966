import { Typography } from 'components/atoms/typography';
import { IContentfulImage } from 'helpers/types/common.type';
import { BrandCardContainer, BrandCardImg, BrandCardLabel } from './styles';

export interface BrandCardProps {
  title: string;
  image: IContentfulImage;
  url: string;
  host?: string;
  className?: string;
}

const BrandCard = ({ image, url = '/', title = '', host, className = '' }: BrandCardProps) => {
  return (
    <BrandCardContainer className={className}>
      <BrandCardImg src={image?.url || ''} alt={image?.alt || ''} ariaLabel={image?.title || ''} />
      <BrandCardLabel>
        <Typography
          as={'a'}
          variant="h5"
          href={url}
          target={!url?.includes?.(host as string) ? '_blank' : '_self'}
          rel={!url?.includes?.(host as string) ? 'nofollow' : ''}
        >
          {title}
        </Typography>
      </BrandCardLabel>
    </BrandCardContainer>
  );
};

export default BrandCard;
