'use client';

import { useState } from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { Typography } from 'components/atoms/typography';
import { TContenfulImageProp } from 'helpers/types';
import { ReplaceUrlWithHost, getLinkTarget } from 'helpers/utils/common';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import { RootState } from 'redux/store';
import PopUpModal from './pop-up-modal';
import { LinkCardsContainer, LinkCardsImageBox, LinkCardsTitleBox } from './styles';

interface ILink {
  popupType?: string;
  title: string;
  image: TContenfulImageProp;
  longUrl: string;
  buttonText?: string;
  buttonImage?: TContenfulImageProp;
  popupIFrameUrl?: string;
  openInNewTab?: boolean;
}

interface LinkCardProps {
  index: number;
  link: ILink;
  titleClickable?: boolean;
}

const LinkCard = ({ index, link, titleClickable = false }: LinkCardProps) => {
  const [openModal, setOpenModal] = useState(false);
  const { host } = useSelector((state: RootState) => state.hostReducer);
  const replaceWithHost = ReplaceUrlWithHost(link?.longUrl, host);
  const handleClick = (e: any) => {
    e.preventDefault();
    setOpenModal(true);
  };
  return (
    <>
      <LinkCardsContainer key={index}>
        {(link?.image || link?.buttonImage) && (
          <LinkCardsImageBox>
            <Image
              src={link?.image?.url || link?.buttonImage?.url || ''}
              width={link?.image?.width}
              height={link?.image?.height}
              title={link?.image?.title}
              alt={link?.image?.alt || ''}
              loader={(options) => options.src}
              className="size-full self-stretch overflow-hidden object-cover"
            />
          </LinkCardsImageBox>
        )}

        <LinkCardsTitleBox>
          {!link?.title ? (
            link?.buttonText && (
              <Typography variant="h5" as="button" onClick={handleClick}>
                {link?.buttonText}
              </Typography>
            )
          ) : titleClickable ? (
            <Typography
              variant="h5"
              as="a"
              href={replaceWithHost}
              target={getLinkTarget(replaceWithHost, link?.openInNewTab)}
            >
              {link?.title}
            </Typography>
          ) : (
            <Typography variant="h5">{link?.title}</Typography>
          )}
          <IconButton
            data-testid="icon-button"
            className="!bg-kief !text-leaf"
            size="sm"
            color="green"
            button-type="primary"
            as={link?.popupType === 'Scheduling' ? 'button' : 'a'}
            href={replaceWithHost}
            target={getLinkTarget(replaceWithHost, link?.openInNewTab)}
            rel={replaceWithHost?.includes(host) ? 'nofollow' : ''}
            onClick={link?.popupType === 'Scheduling' ? handleClick : () => {}}
          >
            <ChevronRightIcon />
          </IconButton>
        </LinkCardsTitleBox>
      </LinkCardsContainer>
      {openModal && link?.popupIFrameUrl && (
        <PopUpModal
          iframeUrl={link?.popupIFrameUrl}
          iframeTitle={link?.buttonText as string}
          title={link?.buttonText || ''}
          handleClose={() => setOpenModal(false)}
        />
      )}
    </>
  );
};

export default LinkCard;
