import tw from 'tailwind-styled-components';

export const UserReviewContainer = tw.div`
    md:py-[64px]
    py-[32px]
    md:px-[60px]
    px-[30px]
    bg-grey-100
    block
    md:flex
    max-w-[330px]
    md:max-w-[450px]
    lg:max-w-[600px]
    xl:max-w-[886px]
    rounded-md
`;

export const RatingContainer = tw.div`
    md:p-[10px]
    p-0
    pr-[20px]
    md:border-r-[1px]
    md:border-r-solid
    md:border-r-grey-300
    md:border-b-[0px]
    pt-2
    pb-8
    border-r-[0px]
    border-b-[1px]
    border-b-solid
    border-b-grey-300
    relative
    min-w-[180px]
    mb-10
    md:mb-0
    flex
    md:block
`;

export const CommentContainer = tw.div`
    md:ml-10
    ml-0
`;

export const StarContainer = tw.div`
    py-[10px]
`;
