import tw from 'tailwind-styled-components';

export const OcpMainContainer: any = tw.div`
  relative
  bg-light 
  lg:my-[120px]
  my-12
  max-w-[1920px]
  mx-auto
`;

export const OcpItemsContainer: any = tw.div`
  flex
  flex-col
  md:justify-center
  md:items-center
`;

export const OcpItems: any = tw.div`
  [&_button]:hover:inline-flex
  w-fit
`;

export const ButtonContainer = tw.div`
  flex
  flex-col
  md:justify-center
  md:items-center
  mt-12
  md:mt-0
`;

export const OcpContainer = tw.div`
  flex
`;

export const OcpLeftSideImageContainer = tw.div`
  flex
  flex-col
  items-start
`;

export const OcpListItemsContainer = tw.div`
  w-full
  mx-5
  my-15
  lg:mx-0
  lg:my-0
`;

export const OcpRightSideContainer = tw.div`
  flex
  flex-col
  items-end
`;
