import React from 'react';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import { communicationInitiated } from 'helpers/analytics/communication-initiated-track';
import { SegmentEvents } from 'helpers/constants/forms';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import ExternalLinkIcon from 'public/icons/figma/external-link.svg';
import LocationIcon from 'public/icons/figma/location.svg';
import PhoneIcon from 'public/icons/figma/phone.svg';
import TimeIcon from 'public/icons/figma/time.svg';
import {
  DispensaryInformationCardAddressBox,
  DispensaryInformationCardBox,
  DispensaryInformationCardWapper,
} from './styles';

type TProps = {
  openHours: string;
  phoneNumber: string;
  phoneLabel?: string;
  address: string;
  addressLabel?: string;
  addressUrl: string;
  handleSegmentEvent?: (type?: string, linkName?: string) => void;
};

const DispensaryInformationCard = ({
  openHours,
  phoneNumber,
  phoneLabel,
  address,
  addressLabel,
  addressUrl,
  handleSegmentEvent,
}: TProps) => {
  const handleSegmentEvents = () => {
    handleSegmentEvent && handleSegmentEvent(SegmentEvents.BANNER_CLICKED, phoneLabel);
    const data = {
      method: 'call',
      link_name: 'call-now',
      link_type: 'hyperlink',
      communication_trigger: 'hero-banner',
    };
    communicationInitiated(data);
  };

  return (
    <DispensaryInformationCardWapper>
      {openHours && (
        <DispensaryInformationCardBox>
          <TimeIcon size={24} className="text-grey-400" />
          <Typography variant="body-small" className="!text-grey-400">
            {openHours}
          </Typography>
        </DispensaryInformationCardBox>
      )}
      {phoneNumber && (
        <DispensaryInformationCardBox>
          <PhoneIcon className="text-grey-400" height={24} width={24} />
          <Typography variant="body-small" className="!text-grey-400">
            {phoneNumber}
          </Typography>
          <IconWithTextButton
            as="a"
            button-type={'tertiary'}
            color={'green'}
            className={'!p-0 '}
            href={`tel:${phoneNumber}`}
            onClick={handleSegmentEvents}
            iconPosition="after"
            icon={<ChevronRightIcon height={24} width={24} className="text-leaf" />}
          >
            {phoneLabel}
          </IconWithTextButton>
        </DispensaryInformationCardBox>
      )}
      {address && (
        <DispensaryInformationCardBox className="!items-start">
          <LocationIcon className="text-grey-400" height={24} width={24} />
          <DispensaryInformationCardAddressBox>
            <Typography variant="body-small" className="!text-grey-400">
              {address}
            </Typography>
            <IconWithTextButton
              as="a"
              button-type={'tertiary'}
              color={'green'}
              className={'!p-0'}
              href={addressUrl}
              target="_blank"
              iconPosition="after"
              icon={<ExternalLinkIcon height={24} width={24} className="text-leaf" />}
              onClick={() => handleSegmentEvent && handleSegmentEvent(SegmentEvents.BANNER_CLICKED, addressLabel)}
              rel="noopener noreferrer"
            >
              {addressLabel}
            </IconWithTextButton>
          </DispensaryInformationCardAddressBox>
        </DispensaryInformationCardBox>
      )}
    </DispensaryInformationCardWapper>
  );
};

export default DispensaryInformationCard;
