import { ReactNode } from 'react';
import { BaseTypographyProps, Typography, colors } from 'components/atoms/typography';
import { AnchorLinkTextEffects, LineElement } from './styles';

export interface TProps extends BaseTypographyProps {
  children: ReactNode;
  isActive?: boolean;
  color?: colors;
}

const AnchorLink = ({ children, isActive, href, ...rest }: TProps) => {
  return (
    <div className="mb-8">
      <LineElement className={` ${isActive ? 'bg-resin ' : 'bg-grey-200'}`} />
      <Typography
        as={'a'}
        variant="body-small-bold"
        className={!isActive ? AnchorLinkTextEffects : ''}
        data-testid="anchor-link"
        href={href || ''}
        {...rest}
      >
        {children}
      </Typography>
    </div>
  );
};

export default AnchorLink;
