import tw from 'tailwind-styled-components';
import { ModalCenteredBodyCloseIcon } from '../styles';

export const GalleryContainer = tw.div`
  relative
`;

export const GalleryBodyContainer = tw.div`
  h-[390px]
  w-full
  lg:h-[582px]
  lg:w-[588px]
  2xl:h-[881px]
  2xl:w-[890px]
`;

export const GalleryBodyInnerContainer = tw.div`
  flex
  flex-col
  gap-[13px]
`;

export const GalleryCloseIcon = tw(ModalCenteredBodyCloseIcon)`
  md:text-white
  md:fixed
  md:top-10
  md:right-10
`;

export const PaginationMobileContainer = tw.div`
  flex
  w-full
  justify-center
  md:hidden
`;

export const PaginationDesktopContainer = tw.div`
  pt-5
  xl:bottom-0
  2xl:pt-6
  hidden
  md:absolute
  md:flex
  lg:block
  lg:w-[588px]
  xl:[&>div]:!h-[54px]
  xl:[&>div:has(svg)]:!h-[54px]
  2xl:w-[890px]
  2xl:[&>div]:!h-16
  2xl:[&>div:has(svg)]:!h-16
`;
