type TList = {
  title: string;
};

export const sortListByTitle = (dispensaries: TList[] = []) => {
  return dispensaries?.sort((a, b) => {
    const title1 = a?.title?.toLowerCase()?.includes('dispensary')
      ? a?.title?.toLowerCase()?.split('dispensary ')[1]
      : a?.title?.toLowerCase()?.includes('rise')
      ? a?.title?.toLowerCase()?.split('rise ')[1]
      : a?.title;

    const title2 = b?.title?.toLowerCase()?.includes('dispensary')
      ? b?.title?.toLowerCase()?.split('dispensary ')[1]
      : b?.title?.toLowerCase()?.includes('rise')
      ? b?.title?.toLowerCase()?.split('rise ')[1]
      : b?.title;

    return title1?.localeCompare(title2);
  });
};
