import tw from 'tailwind-styled-components';
import Polygon from 'public/icons/figma/Polygon.svg';

export const MainContainer: any = tw.div`
    flex 
    gap-4
    lg:gap-6
    w-full
`;

export const IconContainer: any = tw.div`
    relative 
`;

export const ComponentDeatilsContainer: any = tw.div`
    w-full
    flex
    items-center
    lg:items-start
    justify-between
    lg:flex-col-reverse
    lg:justify-center
`;

export const TitleContainer = tw.div`
    flex
    gap-[6px] 
    lg:gap-[8px]
    items-start
`;

export const ImageContainer = tw.div`
    absolute 
    inset-0 
    flex 
    items-center 
    justify-center
`;

export const TitleDiv = tw.div`
    flex 
    flex-col
`;

export const PercentageContainer = tw.div`
    lg:mb-4
`;

export const HexaBox = tw(Polygon)`
    relative 
    [&_svg]:h-[48px] 
    [&_svg]:w-[48px] 
    !text-kief
    [&_svg]:lg:h-[128px] 
    [&_svg]:lg:w-[128px]`;
