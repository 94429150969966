import tw from 'tailwind-styled-components';
import DiscountIcon from 'public/icons/figma/discount.svg';
import StarFilledIcon from 'public/icons/figma/rating-star-filled.svg';

export const ProductDetailsContainer = tw.div`
    grid
    grid-cols-1
    md:grid-cols-2
`;

export const ProductDetailsCarouselContainer = tw.div`
    w-full
`;

export const PaginationContainer = tw.div`
    flex
    absolute
    right-0
    md:left-0
    bottom-0
    px-5
    py-4
    md:p-10
    xl:px-[60px]
    xl:py-12
`;

export const ProductDetailsMobileCarouselContainer = tw.div`
    contents
    md:hidden
`;

export const ProductDetailsCarouselContainerBox = tw.div`
    contents
    relative
`;

export const ProductDetailsInfoContainer = tw.div`
    lg:!overflow-y-scroll
    lg:h-screen
    px-5
    pt-4
    pb-16
    md:px-10
    xl:px-[60px]
    md:pt-9
    md:pb-[120px]
    xl:pt-14
    xl:pb-40
`;

export const ProductDetailsHeadingContainer = tw.div`
    flex
    flex-col
    gap-[8px]
    md:gap-[12px]
`;

export const ProductDetailsRatingRow = tw.div`
    flex
    gap-[8px]
    md:pb-[48px]
    md:justify-start
`;

export const ProductDetailsBrand = tw.div`
    flex
    self-start
    md:hidden
`;

export const ProductDetailsBrandDesktop = tw.div`
    hidden
    md:contents
    text-grey-400
`;

export const ProductDetailsRatingAndSavedContainer = tw.span`
    flex
    gap-[8px]
    w-full
    justify-end
    items-center
    md:justify-start
    leading-4
    tracking-[0.14px]
    lg:leading-5
    lg:tracking-[0.16px]
`;

export const ProductDetailsStar = tw(StarFilledIcon)`
    text-resin
    w-[24px]
    h-[24px]
`;

export const ProductDetailsTagsContainer = tw.div`
    flex
    gap-[8px]
    md:gap-[12px]
`;

export const ProductDetailsCartSection = tw.div`
    pt-[24px]
    md:pt-[72px]
    pb-[56px]
    md:pb-[72px]
`;
interface ProductDetailsPriceContainerProps {
  $outOfStock?: boolean;
}
export const ProductDetailsPriceContainer = tw.div<ProductDetailsPriceContainerProps>`
    flex
    ${(p) => (p.$outOfStock ? 'pt-6 md:pt-5' : 'pt-6 md:pt-8')}
    items-end
`;

export const ProductDetailsOriginalPrice = tw.div`
    font-arial
    text-grey-400
    text-base
    line-through
    leading-[16px]
    tracking-[0.14px]
    mb-0
    pl-[8px]
`;

export const ProductDetailsDiscountContainer = tw.div`
    flex
    items-center
    pt-[4px]
    pb-[24px]
    md:pt-[8px]
    md:pb-[32px]
`;

export const ProductDetailsDiscountIcon = tw(DiscountIcon)`
    [&_g]:!fill-resin
`;

export const ProductDetailsCartContainer = tw.div`
    flex
    w-full
    gap-[6px]
`;

export const ProductDetailsQuantitySelector = tw.div`
    w-[136px]
    lg:w-[168px]
`;

export const ProductDetailsGalleryContainer = tw.div`
    absolute
    right-0
    top-0
    px-5
    py-4
    md:p-10
    xl:px-[60px]
    xl:py-12
`;
