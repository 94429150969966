'use client';

import { useEffect, useState } from 'react';
import { usePathname, useSearchParams, useRouter } from 'next/navigation';
import { sitePageIndex } from 'helpers/algolia/clients';
import { cardListFilteredTrack } from 'helpers/analytics/card-list-filtered-track';
import { parseCategoryUrl } from 'helpers/utils/category-url-parse';

const useBlogRecipeFilter = ({ showFilter }: { showFilter: boolean }) => {
  const router = useRouter();
  const path = usePathname();
  const params = useSearchParams();

  const [{ searchFilterPill, searchFilterData }, setState] = useState({
    searchFilterData: [],
    searchFilterPill: [{ name: 'All', value: true }],
    filtersApplied: [],
  });

  const getUrlQueryFilterData = (data: any[] = searchFilterData) => {
    const filterQuery: any = params.get('filter-category');
    if (!filterQuery) return searchFilterPill;
    if (typeof filterQuery === 'string') {
      const index = data?.findIndex(({ title }) => parseCategoryUrl(title) === filterQuery);
      return data?.[index] ? [{ name: data?.[index]?.title, value: true }] : searchFilterPill;
    }
    const queryParams = filterQuery?.reduce((array: any[], category) => {
      const index = data?.findIndex(({ title }) => parseCategoryUrl(title) === category);
      if (data?.[index]) array?.push({ name: data?.[index]?.title, value: true });
      return array;
    }, []);
    return queryParams.length === 0 ? searchFilterPill : queryParams;
  };

  const setQueryParams = (searchFilterPill: any) => {
    const isAllFilter = searchFilterPill?.[0]?.name === 'All';
    const pathname = path.split('?')[0];

    if (isAllFilter) {
      router.push(path, undefined);
      return null;
    }
    const query = {
      'filter-category': searchFilterPill?.map(({ name }) => parseCategoryUrl(name)),
    };

    router.push(`${pathname}${query}`);
  };

  const updateState = (data: any) => {
    setState((state) => {
      const updatedSearch = { ...state, ...data };
      setQueryParams(updatedSearch.searchFilterPill);
      return updatedSearch;
    });
  };

  const handleSearchFilterPill = (data: any) => {
    const allFilter = { name: 'All', value: true };
    let filtes: {
      name: string;
      value: boolean;
    }[] = [];

    const hasAllFilter = searchFilterPill.some(
      (dataInfo) => dataInfo?.name?.replace(/\s*\(\d+\)\s*/g, '').toLowerCase() === allFilter.name.toLowerCase(),
    );

    if (data?.name?.replace(/\s*\(\d+\)\s*/g, '')?.toLowerCase() === allFilter.name.toLowerCase()) {
      filtes = [allFilter];
      updateState({ searchFilterPill: filtes });
    } else {
      if (hasAllFilter) {
        filtes = [data];
        updateState({ searchFilterPill: filtes });
      } else {
        if (searchFilterPill.some((dataInfo) => dataInfo?.name?.toLowerCase() === data?.name?.toLowerCase())) {
          filtes =
            searchFilterPill?.length < 2
              ? [allFilter]
              : searchFilterPill?.filter((dataInfo) => dataInfo?.name?.toLowerCase() !== data?.name?.toLowerCase());
          updateState({
            searchFilterPill: filtes,
          });
        } else {
          filtes = [...searchFilterPill, data];
          updateState({ searchFilterPill: filtes });
        }
      }
    }

    let dataInfo = {
      card_list_name: 'Blog Index Page',
      card_list_title: '',
      card_list_type: 'Grid',
      card_list_facet_name: '',
      card_list_facet_value: '',
      placement: 1,
      filters_applied: [],
      no_results: true,
    };

    if (filtes.length > 0) {
      const filtersAppliedList = [
        {
          filter_type: 'Blog - Filters',
          filter_values: filtes?.map((item) => String(item?.name.replace(/\(\d+\)/g, '').trim()) || ''),
        },
      ];

      updateState({ filtersApplied: filtersAppliedList });

      dataInfo = {
        ...dataInfo,
        // @ts-ignore
        filters_applied: filtersAppliedList,
        no_results: false,
      };
    }
    cardListFilteredTrack(dataInfo);
  };

  const getFilterData = async () => {
    await sitePageIndex
      .search('', {
        facets: ['*'],
        filters: '(page_type: Blog OR page_type: Recipe) AND showOnBlogListing:true',
      })
      .then((res) => {
        if (res?.facets?.category) {
          let totalItems = 0;
          const filterPills = [{ title: 'All (0)' }];
          Object.entries(res?.facets?.category).forEach((e) => {
            filterPills.push({ title: `${e[0]} (${e[1]})` });
            totalItems = totalItems + e[1];
          });
          filterPills[0] = { title: `All (${totalItems})` };
          updateState({
            searchFilterData: filterPills,
            searchFilterPill: getUrlQueryFilterData(filterPills),
          });
        }
      })
      .catch((e) => {
        console.log(e);
        updateState({
          searchFilterData: [{ title: 'All (0)' }],
        });
      });
  };

  const generateCategoryFilter = (data: any) => {
    const hasAllFilter = data.some((dataInfo) => dataInfo?.name?.replace(/\s*\(\d+\)\s*/g, '').toLowerCase() === 'all');
    const output = data?.map((item) => `category: "${item?.name?.replace(/\s*\(\d+\)\s*/g, '')}"`).join(' OR ');
    return hasAllFilter ? '' : `(${output})`;
  };

  useEffect(() => {
    if (showFilter) {
      getFilterData();
    }
  }, []);

  return {
    handleSearchFilterPill,
    searchFilterPill,
    searchFilterData,
    generateCategoryFilter,
  };
};

export default useBlogRecipeFilter;
