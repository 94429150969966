import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { InnerAddressContainer } from 'components/organisms/listings/about-this-dispensary/styles';
import { IDispensaryTiming } from 'helpers/types';
import { getLinkTarget } from 'helpers/utils/common';
import { removeOhioHost } from 'helpers/utils/remove-oh-link';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import ExternalLinkIcon from 'public/icons/figma/external-link.svg';
import LocationIcon from 'public/icons/figma/location.svg';
import MailIcon from 'public/icons/figma/mail.svg';
import PhoneIcon from 'public/icons/figma/phone.svg';
import { store } from 'redux/store';
import StoreHours from '../accordions/dispensary-accordion/store-hours';
import {
  AddressContainer,
  ContactContainer,
  ContactUsContainer,
  IconsStyle,
  aboutDispensaryButtonClasses,
  addressClasses,
  contactClasses,
} from './styles';

interface DispensaryInfoSectionProps {
  contact?: string;
  timing?: IDispensaryTiming[];
  address?: string;
  mapCtaText?: string;
  mapURL?: string;
  callCtaText?: string;
  contactCtaText?: string;
  shopDispensaryCtaText?: string;
  shopDispensaryCtaUrl?: string;
}

const DispensaryInfoSection = ({
  timing,
  contact,
  mapCtaText,
  mapURL,
  address,
  callCtaText,
  contactCtaText,
  shopDispensaryCtaText,
  shopDispensaryCtaUrl,
}: DispensaryInfoSectionProps) => {
  const isOH = store.getState().hostReducer.isCurrentHostOH;
  const shopUrl =
    isOH && !shopDispensaryCtaUrl?.includes('ohio')
      ? removeOhioHost(shopDispensaryCtaUrl as string)
      : shopDispensaryCtaUrl ?? '';

  return (
    <>
      {timing && timing.length > 0 && <StoreHours storeHours={timing} isHomeDispensary={true} />}
      {contact && (
        <ContactContainer>
          <PhoneIcon />
          <Typography variant="body-small" className={contactClasses} data-testid="contact-text">
            {contact}
          </Typography>
          <CommonButton
            button-type="tertiary"
            color="green"
            size="sm"
            className={aboutDispensaryButtonClasses + '!my-0 !p-0'}
            as="a"
            href={`tel:${contact}`}
          >
            {callCtaText}
            <ChevronRightIcon className={IconsStyle} />
          </CommonButton>
        </ContactContainer>
      )}
      {address && (
        <AddressContainer>
          <LocationIcon />
          <InnerAddressContainer>
            <Typography variant="body-small" className={addressClasses} data-testid="address-text">
              {address}
            </Typography>
            <CommonButton
              button-type="tertiary"
              color="green"
              size="sm"
              className={aboutDispensaryButtonClasses + 'ml-0 flex !px-0 !py-0 sm:mt-1 md:!my-0'}
              as="a"
              target={'_blank'}
              href={mapURL || ''}
            >
              {mapCtaText}
              <ExternalLinkIcon className={`${IconsStyle} h-6 w-6`} />
            </CommonButton>
          </InnerAddressContainer>
        </AddressContainer>
      )}
      <ContactUsContainer>
        <MailIcon />
        <CommonButton
          button-type="tertiary"
          color="green"
          size="sm"
          className={aboutDispensaryButtonClasses + 'mt-0 !px-0 !py-0 md:!my-0'}
          href="/contact"
          as="a"
        >
          {contactCtaText}
          <ChevronRightIcon className={IconsStyle} />
        </CommonButton>
      </ContactUsContainer>
      <CommonButton
        button-type="secondary"
        color="green"
        size="sm"
        target={getLinkTarget(shopUrl)}
        className="viewDispensary mt-8 inline-flex whitespace-nowrap !px-[26px] !py-[16px] md:!px-[38px] md:!py-[22px]"
        as="a"
        href={shopUrl || '/'}
      >
        {shopDispensaryCtaText}
      </CommonButton>
    </>
  );
};

export default DispensaryInfoSection;
