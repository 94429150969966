'use client';

import React, { useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import Slider from 'react-slick';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import QuantitySelector from 'components/atoms/buttons/quantity-selector';
import Label from 'components/atoms/labels/text-and-icon';
import Pagination from 'components/atoms/pagination';
import { Typography } from 'components/atoms/typography';
import { ProductDetailsAccordionComponent } from 'components/molecules/accordions/product-details-accordion';
import { SingleSelectWeightSelectorGroup } from 'components/molecules/shopping/single-select-weight-selector-group';
import { ModalImageGalleryComponent } from 'components/organisms/modal/centered/modal-image-gallery';
import { SegementAddToCartTriggerSource } from 'helpers/analytics/cart-product-added';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TProductDetails } from 'helpers/types/organisms/listing/product-details';
import { useCartState } from 'helpers/utils/cart-funationaltiy';
import { tablet } from 'helpers/utils/screensizes';
import AlertIcon from 'public/icons/figma/alert.svg';
import GoFullScreenIcon from 'public/icons/figma/go-full-screen.svg';
import {
  PaginationContainer,
  ProductDetailsBrand,
  ProductDetailsBrandDesktop,
  ProductDetailsCarouselContainer,
  ProductDetailsCarouselContainerBox,
  ProductDetailsCartContainer,
  ProductDetailsCartSection,
  ProductDetailsContainer,
  ProductDetailsDiscountContainer,
  ProductDetailsDiscountIcon,
  ProductDetailsGalleryContainer,
  ProductDetailsHeadingContainer,
  ProductDetailsInfoContainer,
  ProductDetailsOriginalPrice,
  ProductDetailsPriceContainer,
  ProductDetailsQuantitySelector,
  ProductDetailsRatingAndSavedContainer,
  ProductDetailsRatingRow,
  ProductDetailsStar,
  ProductDetailsTagsContainer,
} from './styles';

export const openGalleryButton = ({ onClick }: any) => {
  return (
    <IconButton
      color="white"
      button-type="primary"
      className={`
        z-10
        h-16
        w-16
        items-center
        justify-center
        !rounded-[40px]
        shadow-md
        md:left-[520px]
        md:top-[40px]
        xl:left-[940px]
        xl:top-[48px]
      `}
      onClick={onClick}
      data-testid="open-image-gallery-btn"
    >
      <GoFullScreenIcon className="h-6 w-6" />
    </IconButton>
  );
};

const ProductDetailsComponent = ({
  images,
  brand,
  kind,
  strain,
  productType,
  price,
  originalPrice = null,
  averageRating,
  reviews,
  tags,
  productSizes,
  productDescription,
  maxCartLimit,
  isShowWeightSelector = false,
  defaultWeightSelect,
  outOfStock,
  productId,
  storeId,
  offerText,
}: TProductDetails) => {
  const [isTablet] = useMediaQuery(tablet);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const slickRef = useRef(null);
  const pageChange = (pageNumber: number) => {
    //@ts-ignore
    slickRef.current.slickGoTo(pageNumber - 1);
  };

  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 100,
    variableWidth: false,
    afterChange: (currentSlide: number) => {
      setActiveImageIndex(currentSlide);
    },
  };

  const cartProps = {
    tags: tags,
    placement: 2,
    defaultWeightSelect: defaultWeightSelect,
    productId: productId,
    storeId: storeId,
    offerText: offerText,
    price: originalPrice,
    brand: brand,
    strain: strain,
    kind: kind,
    originalPrice: price,
  };

  const triggeredFrom = SegementAddToCartTriggerSource.PDP;
  const {
    selectedQuantity,
    selectedWeight,
    setSelectedWeight,
    isSelectedWeightInCart,
    setSelectedQuantity,
    handleCartClick,
    existingItem,
    // @ts-ignore
  } = useCartState(cartProps, triggeredFrom);

  const totalPrice = price * selectedQuantity || null;
  const totalOriginalProductPrice = (originalPrice || 0) * selectedQuantity || null;
  const handleReview = () => {
    const element = document?.getElementById('customerreview');
    if (element) {
      const offset = 150;
      const y = element.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };
  const onChangeHandler = () => {
    setActiveImageIndex(0);
    setSelectedQuantity(1);
    setTimeout(() => {
      pageChange(0);
      setActiveImageIndex(0);
    }, 100);
  };
  return (
    <ProductDetailsContainer>
      <ProductDetailsCarouselContainer>
        <ProductDetailsCarouselContainerBox>
          <div className="relative">
            <Slider {...settings} ref={slickRef}>
              {useMemo(() => {
                return (
                  Array.isArray(images) &&
                  images?.map((image, index) => {
                    return (
                      <Image
                        key={index}
                        src={image.url || ''}
                        width={image.width || 1064}
                        height={image.height || 1064}
                        alt={image.alt || ''}
                        loader={(options) => options.src}
                        className="h-full w-full"
                      />
                    );
                  })
                );
              }, [images])}
            </Slider>
            <ProductDetailsGalleryContainer>
              <ModalImageGalleryComponent
                images={images || []}
                defaultOpen={false}
                openGalleryButton={openGalleryButton}
              />
            </ProductDetailsGalleryContainer>
            {images && images?.length > 1 && (
              <PaginationContainer>
                <Pagination
                  isVisible={true}
                  count={images.length}
                  color="resin"
                  variant={isTablet ? 'pill-desktop' : 'pill-mobile'}
                  onPageChange={({ page }) => pageChange(page)}
                  slickCurrentIndex={activeImageIndex}
                />
              </PaginationContainer>
            )}
          </div>
        </ProductDetailsCarouselContainerBox>
      </ProductDetailsCarouselContainer>
      <ProductDetailsInfoContainer>
        <ProductDetailsHeadingContainer>
          <ProductDetailsRatingRow>
            <ProductDetailsBrand>
              <Typography variant="body-small" className="!text-grey-400" data-testid="product_details_brand">
                {brand}
              </Typography>
            </ProductDetailsBrand>
            <ProductDetailsRatingAndSavedContainer onClick={handleReview}>
              {averageRating && (
                <React.Fragment>
                  <Typography
                    variant="body-small"
                    className="!font-arial !font-bold !text-grey-500"
                    data-testid="product_details_rating"
                  >
                    {averageRating}
                  </Typography>
                  <ProductDetailsStar data-testid="product_details_star" />
                </React.Fragment>
              )}
              {reviews && (
                <Typography
                  variant="body-small"
                  className="hidden cursor-pointer !font-arial !text-grey-400 hover:underline md:flex"
                  data-testid="product_details_reviews"
                >
                  {reviews} reviews
                </Typography>
              )}
            </ProductDetailsRatingAndSavedContainer>
          </ProductDetailsRatingRow>
          <ProductDetailsBrandDesktop>
            <Typography variant="body-small" className="!text-grey-400">
              {brand}
            </Typography>
          </ProductDetailsBrandDesktop>
          <Typography as="h1" variant="h2" className="py-[8px] md:py-3" data-testid="product_details_strain">
            {strain}
          </Typography>
          <Typography
            variant="body-small"
            className="py-[8px] !text-grey-400 md:py-3"
            data-testid="product_details_product_type"
          >
            {productType}
          </Typography>
          <ProductDetailsTagsContainer>
            {useMemo(() => {
              return tags
                ?.filter((tag) => tag)
                ?.map((tag, index) => {
                  return (
                    <Label key={index} variant="white-border" data-testid={`product_details_tag_${index}`}>
                      {tag}
                    </Label>
                  );
                });
            }, [tags])}
          </ProductDetailsTagsContainer>
        </ProductDetailsHeadingContainer>
        <ProductDetailsCartSection>
          {outOfStock ? (
            <div className="mb-5 flex md:mb-6">
              <AlertIcon className="mr-[6px] h-6 w-6 text-resin md:mr-1" />
              <Typography
                variant="body-small"
                className="py-[5px] !font-bold !text-resin"
                data-testid="outOfStockLabel"
              >
                Out of Stock
              </Typography>
            </div>
          ) : (
            isShowWeightSelector && (
              <SingleSelectWeightSelectorGroup
                weights={productSizes || []}
                setSelectedWeight={setSelectedWeight}
                selectedWeight={selectedWeight}
                onChange={onChangeHandler}
              />
            )
          )}
          {!outOfStock && (
            <>
              <ProductDetailsPriceContainer $outOfStock={outOfStock}>
                {totalPrice && (
                  <Typography variant="h3" data-testid="product_details_price">
                    ${totalPrice.toFixed(2)}
                  </Typography>
                )}
                {totalOriginalProductPrice && (
                  <ProductDetailsOriginalPrice data-testid="product_details_original_price">
                    ${totalOriginalProductPrice.toFixed(2)}
                  </ProductDetailsOriginalPrice>
                )}
              </ProductDetailsPriceContainer>
              <ProductDetailsDiscountContainer>
                {offerText && (
                  <React.Fragment>
                    <ProductDetailsDiscountIcon />
                    <Typography
                      variant="body-small-bold"
                      className="!text-resin"
                      data-testid="product_details_discount_percentage"
                    >
                      {offerText}
                    </Typography>
                  </React.Fragment>
                )}
              </ProductDetailsDiscountContainer>
            </>
          )}
          <ProductDetailsCartContainer>
            {!outOfStock && (
              <ProductDetailsQuantitySelector data-testid="quantitySelectorContainer">
                <QuantitySelector
                  onChange={(value: number) => setSelectedQuantity(value)}
                  maxLimit={maxCartLimit}
                  initialCount={selectedQuantity}
                  existingItemQuantity={existingItem.quantity}
                />
              </ProductDetailsQuantitySelector>
            )}
            <CommonButton
              color={outOfStock ? 'white' : 'green'}
              button-type={outOfStock ? 'secondary' : 'primary'}
              size="lg"
              onClick={handleCartClick}
              className="!w-[198px] lg:!w-[264px]"
              data-testid="addToCartButton"
              disabled={outOfStock}
            >
              {!existingItem.quantity || (isSelectedWeightInCart && existingItem.quantity === selectedQuantity)
                ? 'Add to Cart'
                : selectedQuantity === 0
                ? 'Remove Item'
                : 'Update Cart'}
            </CommonButton>
          </ProductDetailsCartContainer>
        </ProductDetailsCartSection>
        <ProductDetailsAccordionComponent description={productDescription as string} index={0} />
      </ProductDetailsInfoContainer>
    </ProductDetailsContainer>
  );
};

export default ProductDetailsComponent;
