'use client';

import { useState } from 'react';
import Image from 'next/image';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { StoreHours } from 'components/atoms/listings/store-hours/store-hours';
import { Typography } from 'components/atoms/typography';
import {
  AboutContainer,
  AddressContainer,
  ClockContainer,
  ContactContainer,
  ContactUsContainer,
  InfoContainer,
  InnerAddressContainer,
  TextContainer,
  TextWrapper,
  contactClasses,
  descriptionClasses,
} from 'components/organisms/listings/about-this-dispensary/styles';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { communicationInitiated } from 'helpers/analytics/communication-initiated-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IDispensaryTiming } from 'helpers/types/common.type';
import { tablet } from 'helpers/utils/screensizes';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import ExternalLinkIcon from 'public/icons/figma/external-link.svg';
import LocationIcon from 'public/icons/figma/location.svg';
import MailIcon from 'public/icons/figma/mail.svg';
import MinusIcon from 'public/icons/figma/minus.svg';
import PhoneIcon from 'public/icons/figma/phone.svg';
import PlusIcon from 'public/icons/figma/plus.svg';

interface AboutThisDispensaryProps {
  tasticPlacementNumber?: number;
  heading: string;
  description: string;
  timing: Record<string, any>;
  contact: string;
  address: string;
  mapCtaText: string;
  mapURL: string;
  medicalHeading: string;
  medicalDescription: string;
  alarmClock: Record<string, any>;
  alarmClockMobile: Record<string, any>;
}

export const AboutThisDispensary = ({
  tasticPlacementNumber,
  heading,
  description,
  timing,
  contact,
  address,
  mapCtaText,
  mapURL,
  medicalHeading,
  medicalDescription,
  alarmClock,
  alarmClockMobile,
}: AboutThisDispensaryProps) => {
  const [isTablet] = useMediaQuery(tablet);

  const descriptionSplit = description.split(/\r?\n/).filter((paragraph) => paragraph.length !== 0);
  const [showMoreParagraphs, setShowMoreParagraphs] = useState(false);
  const clockImage = isTablet ? alarmClock : alarmClockMobile;

  const targetRef = useIntersectionObserver(() => {
    const bannerClickedData = {
      placement: tasticPlacementNumber ? tasticPlacementNumber + 1 : 1,
      banner_position: 1,
      banner_internal_name: heading,
      banner_title: heading,
      banner_image_main: '',
      banner_type: 'Other',
      link_name: '',
      link_type: '',
    };
    bannerTrack(SegmentEvents.BANNER_VIEWED, bannerClickedData);
  });

  const handleCallNow = () => {
    const data = {
      method: 'call',
      link_name: `tel:${contact}`,
      link_type: 'hyperlink',
      communication_trigger: 'dispensary-loc-info',
    };
    communicationInitiated(data);
  };
  const handleContactUs = () => {
    const data = {
      method: 'Contact us',
      link_name: '/contact',
      link_type: 'hyperlink',
      communication_trigger: 'dispensary-loc-info',
    };
    communicationInitiated(data);
  };
  return (
    <AboutContainer ref={targetRef}>
      <TextWrapper>
        <TextContainer>
          <Typography variant="h3">{heading}</Typography>
          <Typography
            variant="body-large"
            className={
              descriptionClasses +
              `${!showMoreParagraphs && !isTablet ? '[&_p]:!mb-0' : ''} ${isTablet ? 'whitespace-pre-wrap' : ''}`
            }
            data-testid="description"
          >
            {isTablet ? (
              description
            ) : (
              <>
                {descriptionSplit.map((paragraph, index) => (
                  <Typography
                    className={`${descriptionClasses}${!showMoreParagraphs && index !== 0 ? ' hidden' : ''}`}
                    key={index}
                    variant="body-large"
                  >
                    {paragraph}
                  </Typography>
                ))}
              </>
            )}
          </Typography>
          {!isTablet && (
            <IconWithTextButton
              // TODO: Update tests with test-id instead of the class
              className="readButton flex !p-0"
              button-type="tertiary"
              color="green"
              icon={showMoreParagraphs ? <MinusIcon /> : <PlusIcon />}
              onClick={() => setShowMoreParagraphs(!showMoreParagraphs)}
              iconPosition="after"
            >
              {showMoreParagraphs ? 'Read Less' : 'Read More'}
            </IconWithTextButton>
          )}
        </TextContainer>
      </TextWrapper>
      <InfoContainer>
        {clockImage && (
          <ClockContainer>
            <Image
              loader={(options) => options.src}
              src={clockImage.url}
              alt={clockImage.title}
              objectFit="contain"
              width={clockImage.width}
              height={clockImage.height}
            />
          </ClockContainer>
        )}
        {timing && timing.length > 0 && (
          <StoreHours
            storeHours={(timing as IDispensaryTiming[]) || []}
            medicalHeading={medicalHeading || ''}
            medicalDescription={medicalDescription || ''}
          />
        )}
        {contact && (
          <ContactContainer>
            <PhoneIcon className="!text-grey-400" />
            <Typography variant="body-small" className={contactClasses}>
              {contact}
            </Typography>
            <IconWithTextButton
              href={`tel:${contact}`}
              as="a"
              className="!p-0"
              button-type="tertiary"
              color="green"
              icon={<ChevronRightIcon />}
              onClick={handleCallNow}
              iconPosition="after"
            >
              Call now
            </IconWithTextButton>
          </ContactContainer>
        )}
        {address && (
          <AddressContainer>
            <div className="flex gap-3 md:!mt-4 md:items-center">
              <LocationIcon className="!text-grey-400" />
              <InnerAddressContainer>
                <Typography variant="body-small" className="!text-grey-400">
                  {address}
                </Typography>
                <IconWithTextButton
                  as="a"
                  target={'_blank'}
                  href={mapURL}
                  data-testid="view-map-button"
                  className="!p-0 md:hidden"
                  button-type="tertiary"
                  color="green"
                  icon={<ExternalLinkIcon />}
                  iconPosition="after"
                >
                  {mapCtaText}
                </IconWithTextButton>
              </InnerAddressContainer>
            </div>
            <IconWithTextButton
              as="a"
              target={'_blank'}
              href={mapURL}
              className="mt-[10px] hidden !p-0 md:flex"
              button-type="tertiary"
              color="green"
              icon={<ExternalLinkIcon />}
              iconPosition="after"
            >
              {mapCtaText}
            </IconWithTextButton>
          </AddressContainer>
        )}
        <ContactUsContainer>
          <MailIcon className="!text-grey-400" />
          <IconWithTextButton
            href="/contact"
            as="a"
            className="!p-0"
            button-type="tertiary"
            color="green"
            icon={<ChevronRightIcon />}
            onClick={handleContactUs}
            iconPosition="after"
          >
            Contact us
          </IconWithTextButton>
        </ContactUsContainer>
      </InfoContainer>
    </AboutContainer>
  );
};
