import { ImageBg } from 'components/atoms/image';
import Label from 'components/atoms/labels/text-and-icon';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { IContentfulImage, IRichTextData } from 'helpers/types/common.type';
import { DealCardContainer, DealCardsContentSection } from './styles';

const vertical = 'vertical';
interface DealCardProps {
  image: IContentfulImage;
  specialText?: string;
  discountText?: string;
  extraText?: IRichTextData;
  percentText?: string;
  size?: 'sm' | 'md';
  variant?: 'horizontal' | 'vertical';
  className?: string;
  handleAnalytics?: () => void;
}

const imgClass = {
  horizontal: '!w-[170px] !h-[192px] lg:!w-[170px] lg:!h-[192px]',
  vertical: 'grow  basis-0 md:!w-full lg:!w-full',
};

const textClass = {
  horizontal: 'w-[144px]',
  vertical: '', // to prevent null populating in class
};

const DealCard = ({
  image,
  specialText,
  discountText = '',
  percentText,
  extraText,
  size = 'md',
  variant = vertical,
  className = '',
  handleAnalytics,
}: DealCardProps) => {
  return (
    <DealCardContainer
      $size={size}
      $variant={variant}
      className={className}
      onClick={() => handleAnalytics && handleAnalytics()}
    >
      <ImageBg
        src={image?.url || ''}
        alt={image?.alt || ''}
        ariaLabel={image?.description || ''}
        className={'w-full shrink-0 bg-center ' + imgClass[variant]}
      />
      {percentText && (
        <Label
          variant="resin"
          className={`!absolute left-4 top-4 ${variant === vertical ? 'md:left-auto md:right-8 md:top-8' : ''}`}
        >
          {percentText}
        </Label>
      )}
      <DealCardsContentSection $size={size} $variant={variant}>
        <Typography variant="body-small" className={`self-stretch ${textClass[variant]}`}>
          {specialText}
        </Typography>
        <Typography variant="h4" as={'p'} className={`self-stretch ${textClass[variant]}`}>
          {discountText}
        </Typography>
        {extraText && (
          <Typography variant="body-small" className={`self-stretch [&_div_p]:!pb-0 ${textClass[variant]}`}>
            <ContentfulRichText data={extraText} />
          </Typography>
        )}
      </DealCardsContentSection>
    </DealCardContainer>
  );
};

export default DealCard;
