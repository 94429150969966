import tw from 'tailwind-styled-components';

export const RewardsMatrixPointsCardSection = tw.div`
    flex 
    flex-row 
    justify-between 
    gap-2.5 
    border-b 
    border-grey-250 
    pb-4 
    pr-0 
    pt-0 
    last:border-b-0
    md:flex-col 
    md:gap-16 
    md:border-b-0 
    md:border-r 
    md:py-14 
    md:pr-8 
    last:md:border-r-0 
    xl:pr-0
`;

export const RewardsMatrixPointsRewardsSection = tw.div`
    flex 
    flex-col 
    gap-5
`;

export const RewardsMatrixPointsRewardsTitleSection = tw.div`
    flex 
    flex-row 
    items-end
`;

export const RewardsMatrixPointsTitleSection = tw.div`
    flex 
    flex-col 
    items-start 
    gap-3  
    md:flex-row
`;
