import { generateHashId } from 'helpers/utils/common';
import { ContentfulRichText } from './rich-text-react-render';
import { ContentfulRichTextWrapper, RichTextComponentWrapper, RichTextTitle } from './styles';

const RichTextItems = (data) => {
  return (
    <RichTextComponentWrapper className={data?.className || ''}>
      {data.data?.items?.map((item, index) => {
        const PaddingClass = index < data.data?.items.length - 1;
        return (
          <>
            {item.useInToC && item.title && (
              <RichTextTitle variant="h4" as="h2" key={index} id={generateHashId(item.title)}>
                {item.title}
              </RichTextTitle>
            )}

            <ContentfulRichTextWrapper $PaddingClass={PaddingClass}>
              <ContentfulRichText key={index} data={item.body} />
            </ContentfulRichTextWrapper>
          </>
        );
      })}
    </RichTextComponentWrapper>
  );
};

export default RichTextItems;
