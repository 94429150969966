'use client';

import React, { useState } from 'react';
import Image from 'next/image';
import { Typography } from 'components/atoms/typography';
import FaqAccordion from 'components/molecules/accordions/faq-accordion';
import TableOfContent from 'components/organisms/media/summary-anchor-links';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TFAQItem } from 'helpers/types';
import { generateHashId } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import { EmptyDiv, FaqBodyContainer, FaqCategoriesContainer, FaqDataContainer, FlexList } from '../faq-styles';

type TFAQGroup = {
  faqsCollection: TFAQItem[];
  group: {
    title: string;
    icon: {
      title: string;
      url: string;
    };
  };
};
interface IFaqSection {
  tocHeading: string;
  faqSectionCollections: TFAQGroup[];
}

const FAQCategories = ({ tocHeading, faqSectionCollections }: IFaqSection) => {
  const [openFAQ, setOpenFAQ] = useState<null | string>(null);
  const [isTablet] = useMediaQuery(tablet);

  const handleOpen = (value: string) => {
    setOpenFAQ((prevOpen) => (prevOpen === value ? null : value));
  };

  return (
    <FaqCategoriesContainer>
      <FaqBodyContainer>
        <FlexList>
          <TableOfContent
            tocMargin={true}
            isDrawer={isTablet ? false : true}
            titles={faqSectionCollections.map((section: TFAQGroup) => section?.group?.title || '')}
            tocHeading={tocHeading || ''}
          />
          <EmptyDiv />
          <div>
            {faqSectionCollections.map((section: TFAQGroup, groupIndex: number) => (
              <FaqDataContainer
                className={groupIndex > 0 ? 'scroll-headings mt-16' : 'scroll-headings'}
                key={groupIndex}
                id={generateHashId(section?.group?.title)}
              >
                <div className="flex pb-6 lg:pb-10 2xl:pb-0">
                  <Image
                    src={section?.group?.icon?.url || ''}
                    width={isTablet ? 40 : 24}
                    height={isTablet ? 40 : 24}
                    loader={({ src }) => src}
                    alt="Icons"
                    unoptimized
                    role="img"
                    aria-label="Icon"
                  />
                  <Typography
                    variant="h4"
                    className="leading-2 !h-6 pb-[2px] pl-2 font-normal tracking-wide lg:pb-1 2xl:font-medium"
                  >
                    {section?.group?.title || ''}
                  </Typography>
                </div>
                <ul>
                  {section?.faqsCollection?.map((faq: TFAQItem, faqIndex: number) => (
                    <FaqAccordion
                      key={faqIndex}
                      data={faq}
                      index={faqIndex}
                      faqWidth={true}
                      shouldOpen={openFAQ === `section-${groupIndex}-faq-${faqIndex}`}
                      handleOpen={() => handleOpen(`section-${groupIndex}-faq-${faqIndex}`)}
                    />
                  ))}
                </ul>
              </FaqDataContainer>
            ))}
          </div>
        </FlexList>
      </FaqBodyContainer>
    </FaqCategoriesContainer>
  );
};

export default FAQCategories;
