'use client';

import React, { useMemo, useState } from 'react';
import Image from 'next/image';
import { Formik } from 'formik';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import FilePicker from 'components/atoms/file-picker/file-picker';
import { RadioCheck } from 'components/atoms/inputs/radio/radio-card';
import { TextArea } from 'components/atoms/inputs/text-area';
import Pagination from 'components/atoms/pagination';
import ReCaptcha from 'components/atoms/recaptcha/recaptcha';
import { Typography } from 'components/atoms/typography';
import PhoneNumber from 'components/molecules/form-fields/phone-number';
import { SelectDispensaryDropdown, SelectStateDropdown } from 'components/molecules/form-fields/select';
import { EmailInput, FirstNameInput, LastNameInput } from 'components/molecules/form-fields/text-field';
import Loading from 'components/organisms/global/loader';
import { ContactUsValidationSchemaObj } from 'helpers/constants/validation-schema';
import { contactUsFormSubmit } from 'helpers/forms/contact-us-form-submit';
import useDispensary from 'helpers/hooks/use-dispensary';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { s3UploadV2 } from 'helpers/s3-helper';
import { DISP_TAGS } from 'helpers/types/cookies';
import { IContactUsProps } from 'helpers/types/forms';
import * as screenSizes from 'helpers/utils/screensizes';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import {
  BannerContainer,
  BannerContent,
  BannerSubTitle,
  Center,
  CenterContainer,
  CheckIcon,
  CloseIcon,
  Content,
  FileDeleteSuccess,
  FooterButton,
  FooterContainer,
  FooterContent,
  FooterRightButton,
  ListContainer,
  LogoContainer,
  MainContainer,
  RecaptchaContainer,
  RightContainer,
  StepThreeFormContainer,
  StepTwoFormContainer,
  UpperContainer,
} from '../common/style';
import { SuccessBanner } from '../common/success-banner';

const ContactUsForm = (props: IContactUsProps) => {
  const { currentDispensary } = useDispensary();
  const { stateCode } = currentDispensary?.state?.state || {};
  //@ts-ignore
  const { heroSection, radioOptionsCollection, step1Heading, step2Heading, step3Heading, successMessage } = props?.data;
  const initialValues = {
    help_you_with: '',
    description: '',
    contact_us_file: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    state: stateCode || '',
    dispensary: '',
    reCaptchaToken: '',
  };
  const [stepCount, setStepCount] = useState(1);
  const [enableLoader, setLoader] = useState(false);
  const [showDeleteSuccess, setDeleteSuccess] = useState(false);
  const [showSuccessBanner, setShowsuccessBanner] = useState(false);
  const [isBiggerThanMobile] = useMediaQuery(screenSizes.mobile);
  const dispensariesStateData = props?.stateList;
  const onContinue = () => {
    if (stepCount <= 2) setStepCount(stepCount + 1);
  };
  const IsOhio = props?.host?.includes('oh.risecannabis') || false;
  const { dispensaries, states } = useMemo(
    () =>
      dispensariesStateData?.reduce(
        (obj, { dispensaries, state }) => {
          const stateName = state?.state?.stateName || '';
          const stateCode = state?.state?.stateCode || '';
          const data = dispensaries?.reduce((obj, dispensary) => {
            if (!obj[stateCode]) obj[stateCode] = [];
            const label = dispensary?.dispensaryInformation?.title;
            if (!dispensary?.dispensaryTags?.includes(DISP_TAGS.COMING_SOON)) {
              obj[stateCode].push({ label, value: label });
            }
            return obj;
          }, {});
          obj.dispensaries = { ...obj.dispensaries, ...data };
          //@ts-ignore
          obj.states.push({ label: stateName, value: stateCode });
          return obj;
        },
        { dispensaries: {}, states: [] },
      ),
    [dispensariesStateData, IsOhio],
  );

  const validateSteps = (values) => {
    if (stepCount === 1) {
      return !values.help_you_with;
    } else if (stepCount === 3) {
      return (
        !values.email ||
        !values.reCaptchaToken ||
        !values.phoneNumber ||
        !values.firstName ||
        !values.lastName ||
        !values.dispensary ||
        !values.state
      );
    } else return false;
  };

  const onSubmit = async (values) => {
    try {
      setLoader(true);
      if (values.contact_us_file) {
        const fileAttachments = await s3UploadV2([values.contact_us_file]);
        values.user_attachments = fileAttachments?.data;
        delete values.contact_us_file;
      }
      const res = await contactUsFormSubmit(values);
      if (res) setShowsuccessBanner(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  const onFileDelete = () => {
    setDeleteSuccess(true);
    setTimeout(() => {
      setDeleteSuccess(false);
    }, 5000);
  };
  const { image, mobileImage, overlayImage, subTitle, title } = heroSection;
  const bannerImage = isBiggerThanMobile ? image : mobileImage;
  const logo = isBiggerThanMobile ? overlayImage?.desktopImage : overlayImage?.mobileImage;

  return (
    <>
      {showDeleteSuccess && (
        <FileDeleteSuccess>
          <Center>
            <CheckIcon />
            <span>File was deleted successfully</span>
          </Center>

          <button onClick={() => setDeleteSuccess(false)}>
            <CloseIcon />
          </button>
        </FileDeleteSuccess>
      )}

      <MainContainer>
        {enableLoader && <Loading showbackdrop={true} />}
        <BannerContainer
          style={{
            background: `url(${bannerImage.url})`,
          }}
        >
          <LogoContainer>
            <Image
              alt="contactus-logo"
              loading="lazy"
              src={logo.url}
              loader={(options) => options.src}
              height={isBiggerThanMobile ? 147 : 66}
              width={isBiggerThanMobile ? 128 : 65}
            />
          </LogoContainer>
          <BannerContent>
            <Typography variant="body-large" className="mb-2 md:mb-6" data-testid="contactus_banner_title">
              {title}
            </Typography>

            <BannerSubTitle>
              <Typography variant="h4" data-testid="contactus_banner_subtitle">
                {subTitle}
              </Typography>
            </BannerSubTitle>
          </BannerContent>
        </BannerContainer>
        <RightContainer>
          {!showSuccessBanner && (
            <Formik
              initialValues={initialValues}
              validationSchema={ContactUsValidationSchemaObj}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={onSubmit}
              // enableReinitialize={true}
            >
              {(formikProps) => {
                const { setFieldValue, values, setTouched } = formikProps;
                return (
                  <>
                    <UpperContainer>
                      <CenterContainer $footer={true}>
                        <div>
                          <Pagination
                            isVisible={true}
                            variant="center-carousel"
                            count={3}
                            color="resin"
                            additionalText="Step"
                            currentPage={stepCount}
                          />
                        </div>
                        <Content>
                          <div>
                            <Typography variant="h3" data-testid="contact_us_heading">
                              {stepCount === 1 && step1Heading}
                              {stepCount === 2 && step2Heading}
                              {stepCount === 3 && step3Heading}
                            </Typography>
                          </div>
                          {stepCount === 1 && (
                            <ListContainer data-testid="contact_us_form1">
                              {radioOptionsCollection.items.map((item, idx) => (
                                <RadioCheck
                                  name="help_you_with"
                                  value={values.help_you_with}
                                  checked={item.title === values.help_you_with}
                                  key={idx}
                                  label={item.title}
                                  onClick={() => setFieldValue('help_you_with', item.title)}
                                  iconUrl={item?.icon?.url}
                                />
                              ))}
                            </ListContainer>
                          )}

                          {stepCount === 2 && (
                            <StepTwoFormContainer data-testid="contact_us_form2">
                              <TextArea name="description" rows={10} />
                              <FilePicker
                                label="Upload File"
                                fieldName="contact_us_file"
                                onChange={(file) => setFieldValue('contact_us_file', file)}
                                values={values.contact_us_file}
                                onDelete={() => onFileDelete()}
                              />
                            </StepTwoFormContainer>
                          )}
                          {stepCount === 3 && (
                            <StepThreeFormContainer data-testid="contact_us_form3">
                              <FirstNameInput {...formikProps} required={true} />
                              <LastNameInput {...formikProps} required={true} />
                              <PhoneNumber
                                fieldName="phoneNumber"
                                required={true}
                                customOnChange={(e) => setFieldValue('phoneNumber', e.target.value)}
                              />
                              <EmailInput {...formikProps} required={true} />
                              <SelectStateDropdown
                                {...formikProps}
                                items={states}
                                required={true}
                                onChange={(value) => {
                                  if (value !== values.state) {
                                    setFieldValue('dispensary', '');
                                    setTouched({ ...formikProps.touched, dispensary: false });
                                  }
                                  setFieldValue('state', value);
                                }}
                              />
                              <SelectDispensaryDropdown
                                {...formikProps}
                                items={dispensaries[values.state]}
                                required={true}
                              />
                              {stepCount === 3 && !isBiggerThanMobile && <ReCaptcha fieldName="reCaptchaToken" />}
                            </StepThreeFormContainer>
                          )}
                        </Content>
                      </CenterContainer>
                    </UpperContainer>
                    <FooterContainer>
                      <CenterContainer $footer={true}>
                        <FooterContent>
                          {stepCount > 1 && (
                            <FooterButton $marginLeft={false}>
                              <IconWithTextButton
                                data-testid="contact_us_back_button"
                                size="sm"
                                button-type="tertiary"
                                color="green"
                                icon={<ChevronLeftIcon />}
                                iconPosition="before"
                                onClick={() => setStepCount(stepCount - 1)}
                              >
                                Back
                              </IconWithTextButton>
                            </FooterButton>
                          )}

                          {stepCount === 3 && isBiggerThanMobile && (
                            <RecaptchaContainer>
                              <ReCaptcha fieldName="reCaptchaToken" />
                            </RecaptchaContainer>
                          )}

                          <FooterRightButton $marginLeft={stepCount !== 3}>
                            <IconWithTextButton
                              data-testid="contact_us_continue_button"
                              size="sm"
                              button-type="primary"
                              color="green"
                              icon={<ChevronRightIcon />}
                              iconPosition="after"
                              className={`${stepCount === 1 ? 'w-full md:w-auto' : ''} md:ml-5 md:w-min`}
                              onClick={() => (stepCount === 3 ? formikProps.submitForm() : onContinue())}
                              disabled={validateSteps(values)}
                            >
                              {stepCount === 3 ? 'Submit' : 'Continue'}
                            </IconWithTextButton>
                          </FooterRightButton>
                        </FooterContent>
                      </CenterContainer>
                    </FooterContainer>
                  </>
                );
              }}
            </Formik>
          )}
          {showSuccessBanner && <SuccessBanner {...successMessage} />}
        </RightContainer>
      </MainContainer>
    </>
  );
};

export default ContactUsForm;
