'use client';

import CarouselBrands from 'components/organisms/carousel/brands';
import { getMenuFiltersLink } from 'helpers/utils/common';

interface TProps {
  data: any;
  pageFolder: Record<string, any>;
}

const CarouselBrandsTastic = ({ pageFolder, data }: TProps) => {
  if (!data?.brandsCarousal || data?.brandsCarousal?.dataSource?.isError) {
    return <></>;
  }

  const pageAboutUsCollection = data?.brandsCarousal?.dataSource?.data?.pageAboutUsCollection?.items[0];
  const items = pageAboutUsCollection?.brandsCollection?.items;
  const brandItems = items?.map((item) => {
    console.log('item', item);
    return {
      image: item?.image?.desktopImage,
      title: item?.title,
      url: getMenuFiltersLink(item?.algoliaId, item?.algoliaValue),
    };
  });

  const title = pageAboutUsCollection?.brandsSectionHeading;
  const content = pageAboutUsCollection?.brandsSectionDescription?.json?.content[0]?.content[0]?.value;

  return <CarouselBrands host={pageFolder?.host} title={title} content={content} items={brandItems} />;
};

export default CarouselBrandsTastic;
