import { SegmentEvents } from '../constants/forms';
import { SegementTrackEvent } from './segment-helper';
interface IPropsSignInSignout {
  email: string;
  dispensary_name?: string;
}

export const trackSignedOut = (data: IPropsSignInSignout) => {
  return SegementTrackEvent(SegmentEvents.SIGN_OUT, data, { sendGeoData: true });
};

export const trackSignedIn = async (data: IPropsSignInSignout) => {
  return await SegementTrackEvent(SegmentEvents.SIGN_IN, data, { sendGeoData: true });
};

interface IPropsSignupError {
  error_type: string; // user | system
  error_message: string; // As configured and displayed on FE
  error_trigger: string; // springbig | annex-cloud | cognito | jane | account-service
}

export const trackSignupError = async (data: IPropsSignupError) => {
  return await SegementTrackEvent(SegmentEvents.SIGNUP_ERROR, data, { sendGeoData: true });
};

interface IPropsSignupInitiated {
  signup_source: string;
}
export const trackSignupInitiated = (data: IPropsSignupInitiated) => {
  return SegementTrackEvent(SegmentEvents.SIGUNP_INITIATED, data, { sendGeoData: true });
};

interface IPropsAccountCreated {
  state: string;
  dispensary: string;
  signupSource: string;
}

export const trackAccountCreated = async ({ state, dispensary, signupSource }: IPropsAccountCreated) => {
  const newData = {
    form_state_name: state,
    form_dispensary_id: dispensary,
    signup_source: signupSource,
  };
  return await SegementTrackEvent(SegmentEvents.ACCOUNT_CREATED, newData, { sendGeoData: true });
};
