import React, { useState } from 'react';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import Label from 'components/atoms/labels/text-and-icon';
import RatingReview from 'components/atoms/rating/review';
import { Typography } from 'components/atoms/typography';
import useDispensary from 'helpers/hooks/use-dispensary';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TProductSize } from 'helpers/types/organisms/listing/product-details';
import { getLinkTarget } from 'helpers/utils/common';
import { desktop } from 'helpers/utils/screensizes';
import AlertIcon from 'public/icons/figma/alert.svg';
import DiscountIcon from 'public/icons/figma/discount.svg';
import { useAppSelector } from 'redux/hooks';
import { RootState } from 'redux/store';
import ProductCardTypography from '../product-card-typography';
import { EditQuantityCountStyle } from '../product-card-vertical/styles';
import ProductCardConfirmHorizontal from './product-card-confirm-horizontal';
import {
  CardHorizontalBodyBox,
  CardHorizontalBodyContainers,
  CardHorizontalBox,
  CardHorizontalContainers,
  CardHorizontalFavoriteBox,
  CardHorizontalFooterBox,
  CardHorizontalImageBox,
  CardHorizontalImageWrapper,
  CardHorizontalLableBox,
  CardHorizontalPriceBox,
  CardHorizontalPriceButtonBox,
  CardHorizontalSummaryBottomDetail,
  CardHorizontalSummarySection,
  CardHorizontalSummarySectionInner,
  CardHorizontalSummaryTopDetail,
} from './styles';

type TProps = {
  showFavorite?: boolean;
  containersStyle?: string;
  image: string;
  horizontalImagePosition?: 'left' | 'right';
  horizontalShowBorder?: boolean;
  horizontalFooterSection?: boolean;
  horizontalImageStyle?: string;
  name: string;
  brandName: string;
  price?: string;
  discountPrice?: string;
  saleLabel?: string;
  newLabel?: string;
  strainsLabel?: string;
  type?: string;
  order?: number;
  kind?: string;
  thc?: string;
  gram?: string;
  showSalePriceFooter?: boolean;
  rewardPoints?: string | number;
  salePrice?: string;
  position?: number;
  hideGram?: boolean;
  showOutOfStock?: boolean;
  hidePrice?: boolean;
  hideAddToCart?: boolean;
  disableAddToCartBtn?: boolean;
  buttonText?: string;
  defaultWeightSelect?: string;
  handleSelectedWeight?: (data: any) => void;
  isShowWeightSelector?: boolean;
  weights?: TProductSize[];
  url?: string;
  maxCartLimit?: number;
  productId?: number;
  averageRating?: number;
  reviews?: number;
};
const ProductCardHorizontal = ({
  showFavorite = false,
  containersStyle,
  image,
  horizontalImagePosition = 'right',
  name,
  brandName,
  horizontalShowBorder = true,
  horizontalFooterSection = true,
  price,
  discountPrice,
  strainsLabel,
  saleLabel,
  newLabel,
  thc,
  order,
  kind,
  type,
  gram,
  horizontalImageStyle,
  showSalePriceFooter = false,
  position,
  rewardPoints,
  salePrice,
  hideGram = false,
  showOutOfStock = false,
  hidePrice = false,
  hideAddToCart = false,
  disableAddToCartBtn = false,
  buttonText = 'Add to cart',
  weights,
  defaultWeightSelect,
  productId,
  handleSelectedWeight,
  isShowWeightSelector,
  url,
  maxCartLimit = 10,
  averageRating,
  reviews,
}: TProps) => {
  const [showConfirmSection, setConfirmSection] = useState(false);
  const [isDesktop] = useMediaQuery(desktop);
  const cart = useAppSelector((state: RootState) => state?.cart);
  const isOH = useAppSelector((state: RootState) => state.hostReducer.isCurrentHostOH);
  const pvidsArray = weights?.map((weightItem) => {
    const weight = weightItem?.name;
    return `${productId}+${weight || 'each'}`;
  });
  const { currentDispensary } = useDispensary();
  const storeId = currentDispensary?.dispensaries?.storeId;
  const existingCart = cart && cart.carts && cart.carts[`${storeId}`];
  const existingItems = existingCart?.items || {};
  const existingProducts = pvidsArray?.map((items) => existingItems[items]);
  const totalQuantity = existingProducts?.reduce((acc, item) => acc + (item ? item?.quantity : 0), 0);

  return (
    <React.Fragment>
      <CardHorizontalContainers
        data-testid="horizontal-containers"
        className={showConfirmSection ? `!hidden ${containersStyle}` : containersStyle}
        href={!showOutOfStock ? url : '/'}
        target={getLinkTarget(url as string)}
      >
        <CardHorizontalBox data-testid="horizontal-box-desktop" $position={horizontalImagePosition}>
          <CardHorizontalBodyContainers data-testid="horizontal-border-box" $showBorder={horizontalShowBorder}>
            <CardHorizontalBodyBox $position={horizontalImagePosition}>
              <CardHorizontalLableBox>
                {strainsLabel && <Label variant="white-border">{strainsLabel}</Label>}
                {saleLabel && <Label variant="resin">{saleLabel}</Label>}
                {newLabel && <Label variant="grey-200">{newLabel}</Label>}
              </CardHorizontalLableBox>
              <div className="grid">
                <ProductCardTypography variant="brand" text={brandName ?? ''} />
                <ProductCardTypography variant="name" text={name ?? ''} />
                <ProductCardTypography variant="type" text={type ?? ''} />
                <ProductCardTypography variant="thc" text={thc ?? ''} />
                <div className="flex flex-row items-center gap-2 pt-1">
                  <RatingReview score={averageRating} text={reviews ? `${reviews} reviews` : undefined} />
                </div>
              </div>
            </CardHorizontalBodyBox>
            {!isDesktop && (
              <CardHorizontalImageWrapper $position={horizontalImagePosition}>
                <CardHorizontalImageBox className={`flex lg:hidden ${horizontalImageStyle}`}>
                  <Image
                    layout="fill"
                    alt="product card"
                    loader={(options) => options.src}
                    src={image}
                    className="size-full object-fit rounded-md"
                    priority
                  />
                  {showFavorite && (
                    <CardHorizontalFavoriteBox $position={horizontalImagePosition}>
                      <IconButton button-type="primary" className="!bg-grey-200 p-2" color="white">
                        {/* <MdOutlineFavorite size={'1.5em'} /> */}
                      </IconButton>
                    </CardHorizontalFavoriteBox>
                  )}
                </CardHorizontalImageBox>
              </CardHorizontalImageWrapper>
            )}
          </CardHorizontalBodyContainers>
          {horizontalFooterSection && (
            <CardHorizontalFooterBox className="product-card-footer" data-testid="horizontal-footer-section">
              {!hideGram && <ProductCardTypography variant="gram" text={gram as string} />}
              {showOutOfStock && (
                <IconWithTextButton
                  button-type="tertiary"
                  className="[&_svg]:size-6 !pointer-events-none cursor-not-allowed !text-resin"
                  icon={<AlertIcon />}
                  iconPosition="before"
                  color="green"
                >
                  Out of stock
                </IconWithTextButton>
              )}
              <CardHorizontalPriceButtonBox>
                <CardHorizontalPriceBox>
                  {!hidePrice && (
                    <ProductCardTypography variant="price" text={price ?? Number(price)?.toFixed(2)} className="pr-2" />
                  )}
                  <ProductCardTypography
                    variant="discount-price"
                    text={discountPrice ?? Number(discountPrice)?.toFixed(2)}
                  />
                </CardHorizontalPriceBox>
                {!hideAddToCart && (
                  <CommonButton
                    onClick={(e) => {
                      e.preventDefault();
                      if (!isOH && url?.includes('oh')) {
                        window?.open(url, '_blank');
                      } else {
                        setConfirmSection(true);
                      }
                    }}
                    size="lg"
                    button-type="secondary"
                    color="green"
                    className="!px-5 !py-3 lg:!px-6 lg:!py-3.5"
                    disabled={disableAddToCartBtn || showOutOfStock}
                  >
                    <Typography variant="body-small-bold" className={`${showOutOfStock && '!text-grey-300'}`}>
                      {existingProducts && totalQuantity !== 0 ? (
                        <div className="flex justify-center text-center">
                          Edit Quantity <EditQuantityCountStyle>{totalQuantity}</EditQuantityCountStyle>
                        </div>
                      ) : (
                        buttonText
                      )}
                    </Typography>
                  </CommonButton>
                )}
              </CardHorizontalPriceButtonBox>
            </CardHorizontalFooterBox>
          )}
          {showSalePriceFooter && (
            <CardHorizontalSummarySection>
              <CardHorizontalSummarySectionInner>
                <CardHorizontalSummaryTopDetail>
                  <Typography variant="body-small">{gram}</Typography>
                  <Typography variant="body-small-bold" className="!text-leaf">
                    {rewardPoints}
                  </Typography>
                </CardHorizontalSummaryTopDetail>
                {salePrice && (
                  <CardHorizontalSummaryBottomDetail>
                    <DiscountIcon className="text-leaf" />
                    <Typography variant="body-small-bold">{salePrice}</Typography>
                  </CardHorizontalSummaryBottomDetail>
                )}
              </CardHorizontalSummarySectionInner>
            </CardHorizontalSummarySection>
          )}
        </CardHorizontalBox>
        {isDesktop && (
          <CardHorizontalImageWrapper $position={horizontalImagePosition}>
            <CardHorizontalImageBox className={`hidden lg:flex ${horizontalImageStyle}`}>
              <Image
                layout="fill"
                alt="product card"
                loader={(options) => options.src}
                src={image}
                className="size-full rounded-md object-cover"
                priority
              />
            </CardHorizontalImageBox>
          </CardHorizontalImageWrapper>
        )}
      </CardHorizontalContainers>
      {showConfirmSection && (
        <ProductCardConfirmHorizontal
          name={name}
          brandName={brandName}
          price={price as string}
          onClose={() => setConfirmSection(false)}
          containersStyle={containersStyle}
          weights={weights}
          position={position}
          defaultWeightSelect={defaultWeightSelect as string}
          handleSelectedWeight={handleSelectedWeight}
          isShowWeightSelector={isShowWeightSelector}
          maxCartLimit={maxCartLimit}
          productId={productId}
          thc={thc as string}
          order={order}
          kind={kind}
          discountPrice={discountPrice}
          category={type as string}
          offer={saleLabel as string}
        />
      )}
    </React.Fragment>
  );
};

export default ProductCardHorizontal;
