import tw from 'tailwind-styled-components';

export const MainContainer = tw.div`
  bg-light
  h-auto
  w-full
  relative
  `;

export const ButtonContainer = tw.div`
  flex
  flex-col
  justify-center
  items-center
  lg:py-[120px]
  py-[56px]
  `;

export const LeftImageContainer = tw.div`
  flex
  flex-col
  items-start
  `;

export const RightImageContainer = tw.div`
  flex 
  flex-col 
  items-end 
  lg:col-span-2
  `;
