import tw from 'tailwind-styled-components';

export const TransactionHistoryBox = tw.div`
    flex 
    flex-col 
    gap-12 
    bg-white 
    px-5 
    py-6 
    lg:px-[60px] 
    lg:py-12
    md:flex-1
`;

export const TransactionHistoryTitleBox = tw.div`
    flex 
    flex-col 
    items-start  
    justify-between 
    gap-6  
    md:flex-row 
    md:gap-2
`;
