'use client';

import { Typography } from 'components/atoms/typography';
import GroupDiscountsCard from 'components/molecules/cards/group-discounts-card';
import {
  GroupDiscountsCardContainer,
  GroupDiscountsContainer,
} from 'components/organisms/discounts/group-discounts/styles';
import { dealClicked } from 'helpers/analytics/deal-clicked';
import { SegmentEvents } from 'helpers/constants/forms';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';

interface GroupDiscountsProps {
  title: string;
  collection: Record<string, any>[];
  host: string;
}

const GroupDiscounts = ({ title, collection, host }: GroupDiscountsProps) => {
  const [isTablet] = useMediaQuery(tablet);

  return (
    <>
      {collection && collection.length > 0 && (
        <GroupDiscountsContainer>
          {title && (
            <Typography
              variant="h2"
              className="mb-10 md:mb-[60px] min1440:mb-[120px]"
              data-testid="groupDiscountsTitle"
            >
              {title}
            </Typography>
          )}

          <GroupDiscountsCardContainer data-testid="groupDiscountsContainer">
            {collection.map((discount, index) => {
              return (
                discount.title && (
                  <GroupDiscountsCard
                    key={index}
                    host={host}
                    cta={discount.cta}
                    title={discount.title}
                    description={discount.dealDescription}
                    image={discount.image}
                    mobileImage={discount.mobileImage}
                    onClick={() => {
                      dealClicked(SegmentEvents.DEAL_CLICKED, {
                        deal_position: index + 1,
                        deal_name: discount?.internalName || '',
                        deal_title: discount?.title || '',
                        deal_image: isTablet
                          ? discount?.image
                            ? discount?.image?.url
                            : discount?.mobileImage?.url
                          : discount.mobileImage
                          ? discount.mobileImage?.url
                          : discount.image?.url,
                        deal_type: 'group discount',
                        link_name: discount?.cta?.title,
                        link_type: 'hyperlink',
                        placement: discount?.placement,
                      });
                    }}
                  />
                )
              );
            })}
          </GroupDiscountsCardContainer>
        </GroupDiscountsContainer>
      )}
    </>
  );
};

export default GroupDiscounts;
