'use client';

import React, { useEffect, useState } from 'react';
import { MyRewardsBannerComponent } from 'components/organisms/banners/my-rewards';
import SideBar from 'components/organisms/drawers/sidebar';
import { accountOverviewGetUserInfoApi } from 'helpers/account-api';
import { TSelectedSubItem } from 'helpers/types';
import { useAppSelector } from 'redux/hooks';

type TTasticsProps = {
  data: any;
};

const MyRewardsBannerTastic: React.FC<TTasticsProps> = ({ data }) => {
  const myRewardsBannerData =
    data.sectionData?.dataSource?.data?.pageAccountCollection?.items[0]?.bodyCollection?.items[0] || {};
  const { title, ctaCollection, image, mobileImage, additionalImage, overlayImage } = myRewardsBannerData;
  const [userInfo, setUserInfo] = useState({ balance: 0 });

  const accountUser = useAppSelector((state) => state?.accountReducer?.user?.attributes);
  const stateCode = accountUser?.['custom:current_state'];
  const phoneNumber = accountUser?.['phone_number'];
  const email = accountUser?.['email'];

  useEffect(() => {
    if (stateCode && phoneNumber && email) {
      (async () => {
        const users = await accountOverviewGetUserInfoApi({ stateCode, phoneNumber, email });
        users?.data?.member && setUserInfo(users?.data?.member);
      })();
    }
  }, [stateCode, phoneNumber]);
  if (!myRewardsBannerData) return <></>;
  return (
    <div className="md:flex">
      <SideBar selectedItem={TSelectedSubItem.REWARDS} />
      <MyRewardsBannerComponent
        title={title}
        points={userInfo?.balance}
        buttonLabel={ctaCollection?.items[0]?.title}
        backgroundImageSrc={mobileImage?.url}
        backgroundImageDesktopSrc={image?.url}
        backgroundImageAltText={mobileImage?.title}
        backgroundImageAriaLabel={mobileImage?.title}
        riseRewardsImageSrc={overlayImage?.mobileImage?.url}
        riseRewardsImageDesktopSrc={overlayImage?.desktopImage?.url}
        illustrationImageSrc={additionalImage?.mobileImage?.url}
        illustrationImageDesktopSrc={additionalImage?.desktopImage?.url}
      />
    </div>
  );
};
export default MyRewardsBannerTastic;
