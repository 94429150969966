import tw from 'tailwind-styled-components';

export const LinkCardsContainer = tw.div`
    flex
    w-full
    md:w-[270px]
    2xl:w-[426px]
    flex-col
    justify-center
    items-center
    rounded-md
    bg-white
    overflow-hidden
`;

export const LinkCardsImageBox = tw.div`
    relative
    h-[184px]
    md:h-[193px]
    xl:h-[224px]
    w-full
`;

export const LinkCardsTitleBox = tw.div`
    flex
    py-[18px]
    px-5
    md:py-8
    md:px-5
    2xl:p-8
    gap-20
    md:gap-4
    items-center
    justify-between
    w-full
    self-stretch
`;
