import tw from 'tailwind-styled-components';

export const DispensaryLocationInformationContainer = tw.div`
    md:px-[60px]
    px-5
    md:py-[112px]
    py-10
    bg-grey-100
    md:flex
    block
`;

export const HeadingContainer = tw.div`
    md:pr-5
    pr-0
`;

export const DispensaryContainer = tw.div`
    rounded-md
    bg-white
    md:p-10
    py-6
    px-5
    ml-auto
    flex
`;

export const DataContainer = tw.div`
    flex
    items-center
`;

export const ButtonClasses = `
    ml-3
    sm:p-0
    md:p-0
    mt-4
    flex
`;

export const InnerDataContainer = tw.div`
    sm:flex
    md:block
    xl:flex
    items-center
`;

export const addressClasses = `
    ml-3
    !text-grey-400
`;

export const InfoContainer = tw.div`
    md:mr-10
    sm:mr-0
`;

export const ImageContainer = tw.div`
    [&_img]:!rounded-md
    relative
    max-w-[584px]
    sm:mb-5
    md:mb-0
`;
