'use client';

import { useEffect, useState } from 'react';
import RichTextItems from 'components/organisms/media/rich-text-component';
import TableOfContent from 'components/organisms/media/summary-anchor-links';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import { HideTocContainer, LegalBodyWrapper, ShowTocContainer } from './styles';

type TProps = {
  data: Record<string, any>;
};

const LegalBodyTastic = ({ data }: TProps) => {
  const dataCollection = data?.data?.dataSource?.data?.pageBlogResourceCollection?.items?.[0];
  const sectionData = dataCollection?.blogSectionsCollection;
  const [isDrawer, setIsDrawer] = useState(false);
  const [isTablet] = useMediaQuery(tablet);

  useEffect(() => {
    if (isTablet) {
      return;
    }
    let timer = null;

    const handleScroll = () => {
      // @ts-ignore
      clearTimeout(timer);

      // @ts-ignore
      timer = setTimeout(() => {
        const tocEndElement = document.getElementById('tocEnd');
        if (tocEndElement) {
          const rect = tocEndElement.getBoundingClientRect();
          if (rect.top >= 0) {
            setIsDrawer(false);
          } else {
            setIsDrawer(true);
          }
        } else {
          setIsDrawer(true);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isTablet]);

  if (!dataCollection) return <></>;

  return (
    <>
      {dataCollection?.showToC === true ? (
        <LegalBodyWrapper>
          <TableOfContent
            tocMargin={true}
            bottomScroll={true}
            isDrawer={isDrawer}
            titles={sectionData.items.filter((item) => item.useInToC && item.title !== null).map((item) => item.title)}
            tocHeading={'Content'}
          />
          <ShowTocContainer id="tocEnd">
            <RichTextItems data={sectionData} />
          </ShowTocContainer>
        </LegalBodyWrapper>
      ) : (
        <HideTocContainer>
          <RichTextItems data={sectionData} />
        </HideTocContainer>
      )}
    </>
  );
};

export default LegalBodyTastic;
