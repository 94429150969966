import { useState } from 'react';
import tw from 'tailwind-styled-components';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';

const ToggleButtonContainer = tw.div`
    inline-flex
    p-1
    items-center
    rounded-[32px]
    bg-grey-200
    md:p-2
    md:rounded-[48px]
`;

const buttonDefault = `
    flex
    h-[48px]
    p-[16px_26px]
    items-center
    gap-1
    round-[32px]
    shadow-none

    md:h-[64px]
    md:p-[22px_38px]
    md:gap-3

    font-arial
    text-[14px]
    not-italic
    font-bold
    leading-4
    tracking-[0.14px]

    md:text-[16px]
    md:leading-5
    md:tracking-[0.16px]
`;

const activeButtonStyle = `
    ${buttonDefault}
    bg-leaf
    hover:!text-white
    hover:bg-leaf
`;
const defaultButtonStyle = `
    ${buttonDefault}
    !text-leaf
    bg-transparent
    hover:!text-white
    hover:bg-leaf
`;

interface IToggleButtonProps {
  leftLabel: string;
  rightLabel: string;
  onClick: (label: string) => void;
  selectedFromProps?: boolean;
}

export const ToggleButton = ({
  leftLabel = 'Lorem Ipsum',
  rightLabel = 'Lorem Ipsum',
  onClick,
  selectedFromProps = false,
}: IToggleButtonProps) => {
  const [isSelected, setIsSelect] = useState(selectedFromProps);
  const [hoverButton, setHoverButton] = useState(false);
  const onMouseOver1 = () => !isSelected && setHoverButton(true);
  const onMouseOver2 = () => isSelected && setHoverButton(true);
  const hoverStyle = hoverButton
    ? ` bg-transparent !text-leaf no-underline md:underline md:decoration-2 md:underline-offset-[3px]`
    : '';

  const onClickLeft = () => {
    if (onClick) onClick(leftLabel);
    setIsSelect(true);
  };
  const onClickRight = () => {
    if (onClick) onClick(rightLabel);
    setIsSelect(false);
  };

  return (
    <ToggleButtonContainer>
      <CommonButton
        color="green"
        button-type={'primary'}
        className={`${isSelected ? activeButtonStyle + hoverStyle : defaultButtonStyle} !rounded-[32px]`}
        onClick={onClickLeft}
        onMouseOver={onMouseOver1}
        onMouseOut={() => setHoverButton(false)}
      >
        {leftLabel}
      </CommonButton>
      <CommonButton
        color="green"
        button-type={'primary'}
        className={`${isSelected ? defaultButtonStyle : activeButtonStyle + hoverStyle} !rounded-[32px]`}
        onClick={onClickRight}
        onMouseOver={onMouseOver2}
        onMouseOut={() => setHoverButton(false)}
      >
        {rightLabel}
      </CommonButton>
    </ToggleButtonContainer>
  );
};
