import React from 'react';
import { Drawer } from '@material-tailwind/react/components/Drawer';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import CloseIcon from 'public/icons/figma/close-small.svg';
import ListIcon from 'public/icons/figma/list-icon.svg';
import { BottomDrawerButton, TopicsContainer } from './styles';
import TopicsListContainer from './topics-container';

type BottomdrawerProps = {
  titles: string[];
  tocHeading: string;
  isDrawer?: boolean;
  tocMargin?: boolean;
  bottomScroll?: boolean;
};

const TableOfContent = ({ titles, tocHeading, isDrawer, bottomScroll, tocMargin }: BottomdrawerProps) => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const closeDrawer = () => setOpenDrawer(false);

  return (
    <>
      {!isDrawer ? (
        <TopicsContainer $isDrawer={isDrawer} $bottomScroll={bottomScroll} $tocMargin={tocMargin}>
          <TopicsListContainer titles={titles} tocHeading={tocHeading} closeDrawer={closeDrawer} />
        </TopicsContainer>
      ) : (
        isDrawer && (
          <>
            <TopicsContainer $isDrawer={isDrawer} $bottomScroll={bottomScroll} $tocMargin={tocMargin}>
              <TopicsListContainer titles={titles} tocHeading={tocHeading} closeDrawer={closeDrawer} />
            </TopicsContainer>
            <BottomDrawerButton>
              <IconWithTextButton
                icon={<ListIcon className="h-6 w-6" />}
                iconPosition="after"
                color="green"
                button-type="primary"
                onClick={() => setOpenDrawer(true)}
                className="flex h-12 w-[127px] items-center gap-0.5 py-4 pr-6 text-sm"
                data-testid="drawer-button"
                aria-label="Table of Contents"
              >
                {tocHeading}
              </IconWithTextButton>
            </BottomDrawerButton>
            <Drawer
              open={openDrawer}
              onClose={closeDrawer}
              className={openDrawer ? '!max-h-[508px] overflow-y-auto p-5 pb-10' : '!max-h-[300px]'}
              placement="bottom"
              overlayProps={{ className: 'bg-overlay !backdrop-blur-none fixed' }}
            >
              <div className="mb-6 flex justify-end">
                <CloseIcon className="cursor-pointer text-grey-400" onClick={closeDrawer} />
              </div>
              <TopicsListContainer
                isDrawer={isDrawer}
                titles={titles}
                tocHeading={tocHeading}
                closeDrawer={closeDrawer}
              />
            </Drawer>
          </>
        )
      )}
    </>
  );
};

export default TableOfContent;
