import React from 'react';
import Label from 'components/atoms/labels/text-and-icon';
import { Typography } from 'components/atoms/typography';
import { TRewardsMatrixRewardDataProps } from 'helpers/types/organisms/rewards/rewards-matrix';
import RewardsMatrixPointsCard from './rewards-matrix-points-card';
import {
  RewardsMatrixPointsCardSection,
  RewardsMatrixPointsMessageSection,
  RewardsMatrixPointsSection,
} from './styles';

const RewardsMatrixPointsComponent = ({ data, message }: TRewardsMatrixRewardDataProps) => {
  return (
    <RewardsMatrixPointsCardSection>
      <RewardsMatrixPointsSection>
        {data?.map((item, index) => (
          <RewardsMatrixPointsCard {...item} key={index} />
        ))}
      </RewardsMatrixPointsSection>
      <RewardsMatrixPointsMessageSection>
        <Label variant="resin" data-testid="rewards_matrix_message_text">
          {message?.title}
        </Label>
        <Typography variant="body-small" className="!text-grey-400" data-testid="rewards_matrix_message_description">
          {message?.description}
        </Typography>
      </RewardsMatrixPointsMessageSection>
    </RewardsMatrixPointsCardSection>
  );
};

export default RewardsMatrixPointsComponent;
