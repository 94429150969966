'use client';

import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import AccountEmptyState from 'components/organisms/accounts/empty-state';
import TransactionHistory from 'components/organisms/accounts/transactions-history';
import SideBar from 'components/organisms/drawers/sidebar';
import Loading from 'components/organisms/global/loader';
import { SnackBarComponent } from 'components/organisms/global/snack-bar';
import ModalCentered from 'components/organisms/modal/centered';
import { TSelectedSubItem } from 'helpers/types';
import Auth from 'helpers/utils/cognito';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setUser } from 'redux/reducers/account';
import { RootState } from 'redux/store';

const AccountOrdersTastic = (data) => {
  const tasticPlacementNumber = data?.tasticPlacementNumber;
  const { bodyCollection, errorMessageCollection } = data?.data?.accountOrders?.dataSource?.data?.page?.items[0];
  const user = useAppSelector((store) => store.accountReducer.user);
  const [emailVerificationStatus, setemailVerificationStatus] = useState(user?.attributes['email_verified']);
  const [openCenteredModal, setOpenCenteredModal] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [sendEmailStatus, setSendEmailStatus] = useState({
    loading: false,
    err: '',
  });
  const router = useRouter();
  const dispatch = useAppDispatch();
  const host = useSelector((state: RootState) => state.hostReducer.host);

  useEffect(() => {
    const queryParams = router.query;
    if (
      user &&
      queryParams?.email_verification === 'true' &&
      queryParams?.code &&
      !user?.attributes['email_verified']
    ) {
      // Verify Email
      const code: any = queryParams?.code;
      const sendNewEmailVerificationLink = async () => {
        try {
          await Auth.verifyEmail('link');
          setOpenCenteredModal(true);
        } catch (error) {
          console.log('Failed Sending EMail verification link:-', error);
        }
      };
      const verifyEmail = async () => {
        try {
          setSendEmailStatus({ loading: true, err: '' });
          await Auth.verifyEmailSubmit(code);
          setemailVerificationStatus(true);
          setOpenCenteredModal(true);
          // const currentUser = await Auth.currentAuthenticatedUser();
          const updatedUser = await Auth.getUpdatedUser();
          dispatch(setUser(updatedUser));
          setSendEmailStatus({ loading: false, err: '' });
        } catch (error) {
          setSendEmailStatus({ loading: false, err: error });
          if (error?.code === 'ExpiredCodeException' || error?.code === 'CodeMismatchException') {
            // Email code expired. generate new one and send it
            sendNewEmailVerificationLink();
          }
        }
      };
      verifyEmail();
    }
  }, [user]);

  return (
    <>
      {showSnackBar && (
        <SnackBarComponent
          backgroundColor="leafGreen"
          title="We have successfully sent verification link to your email. Please check your email"
          showSnackBar={showSnackBar}
          handleCloseAction={() => {
            setShowSnackBar(false);
          }}
          host={host}
        />
      )}
      <div className="md:flex">
        {sendEmailStatus.loading && <Loading />}
        <SideBar selectedItem={TSelectedSubItem.ORDERS} />
        {!user ? (
          <></>
        ) : user?.attributes['email_verified'] ? (
          <TransactionHistory emptyStateData={bodyCollection} tasticPlacementNumber={tasticPlacementNumber} />
        ) : (
          <AccountEmptyState
            firstName={user?.attributes?.given_name}
            message={errorMessageCollection?.items[0]?.title}
            subMessage={errorMessageCollection?.items[0]?.description}
            buttonLabel={errorMessageCollection?.items[0]?.ctaCollection?.items[0]?.title}
            src={errorMessageCollection?.items[0]?.image?.url}
            alt={errorMessageCollection?.items[0]?.image?.title}
            ariaLabel={errorMessageCollection?.items[0]?.image?.title}
            ctaAsButton
            handlectaButtonClick={async () => {
              try {
                setSendEmailStatus({ loading: true, err: '' });
                await Auth.verifyEmail('link');
                setShowSnackBar(true);
                setSendEmailStatus({ loading: false, err: '' });
              } catch (error) {
                setSendEmailStatus({ loading: false, err: error });
              }
            }}
          />
        )}
      </div>
      {openCenteredModal && (
        <ModalCentered
          title={
            emailVerificationStatus
              ? 'Your Email is verified successfully'
              : 'Your verification link has expired. We have sent a new email verification link. Kindly use the same'
          }
          outsideControl={true}
          openFromParent={openCenteredModal}
          handleOpenFromParent={(x) => {
            setOpenCenteredModal(x);
          }}
          hideModalCta
          buttonCloseLabel="Close"
        />
      )}
    </>
  );
};

export default AccountOrdersTastic;
