import tw from 'tailwind-styled-components';
import { MapType } from 'helpers/types';

export const ListingMapContainer = tw.div`
    md:max-w-[2200px]
    w-full
    block
    md:flex
    md:p-[60px]
    mx-auto
    overflow-x-hidden
`;

export const ListContainer = tw.div`
    md:pt-[60px]
    py-6
    min1440:h-[821px]
`;

export const MapContainer = tw.div`
    w-full
`;
interface AccordionContainerProps {
  $mapType: MapType;
}
export const AccordionContainer = tw.div<AccordionContainerProps>`
    ${(p) =>
      p.$mapType === MapType.dispensary ? 'md:min-h-[680px] md:max-h-[680px]' : 'md:min-h-[480px] md:max-h-[480px]'}
    overflow-y-auto
    px-5
    md:px-0
    relative
    scrollbar
`;

export const LegendContainer = tw.div`
    rounded-md
    border-[1px]
    border-solid
    border-grey-480
    shadow-[0px_40px_80px_0px_rgba(0,14,6,0.10)]
    flex
    bg-white
    py-4
    md:py-6
    px-5
    md:px-8
    !top-5
    md:!top-6
    !left-5
    !right-auto
    md:!right-6
    md:!left-auto
`;

export const LegendIndicator = tw.div`
    bg-leaf-shadow
    w-[60px]
    h-5
`;

export const FadeBox = tw.div`
    pointer-events-none
    h-[120px]
    absolute
    bottom-0
    w-full
    bg-[linear-gradient(180deg,_rgba(255,_255,_255,_0.00)_0%,_rgba(255,_255,_255,_0.90)_100%)]
    z-[1]
`;
