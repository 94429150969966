export const removeOhioHost = (link: string) => {
  const parts = link?.split('risecannabis') || [];
  const hostBasedOnSubdomian =
    (parts[0]?.includes('-oh') ? parts[0]?.replace('-oh.', '.') : parts[0]?.replace('oh.', '')) +
    'risecannabis' +
    parts[1];

  const newLink = parts?.length > 1 ? hostBasedOnSubdomian : link;

  return newLink?.replace('{{url}}', '')?.replace('{{url-oh}}', '');
};
