import Image from 'next/image';

interface ItemsImageProps {
  alt: string;
  src: string;
  width: number;
  height: number;
}

const ImageContainer = ({ alt, src, width, height }: ItemsImageProps) => {
  return (
    <Image
      objectFit="contain"
      src={src}
      alt={alt}
      width={width}
      height={height}
      loader={(options) => options.src}
      className=" absolute"
    />
  );
};

export default ImageContainer;
