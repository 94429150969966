import tw from 'tailwind-styled-components';

export const DispensaryInformationCardWapper = tw.div`
    flex 
    flex-wrap 
    flex-col 
    md:flex-row 
    items-start 
    md:items-center 
    gap-4 
    md:gap-5
`;

export const DispensaryInformationCardBox = tw.div`
    flex
    flex-row
    items-center
    gap-3
`;

export const DispensaryInformationCardAddressBox = tw.div`
    flex 
    flex-col 
    gap-1
    items-start 
    md:items-center 
    md:flex-wrap 
    md:flex-row 
    md:gap-3
`;
