'use client';

import { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { TStickyBarProps } from 'helpers/types/molecules/rewards/sticky-bar';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setOpenLoginDrawer, setSignUpSource } from 'redux/reducers/account';
import {
  StickyBarContainer,
  StickyBarDesktopContainer,
  StickyBarInnerDesktopContainer,
  StickyBarMobileContainer,
} from './styles';

const renderCtaButton = (isMobile: boolean, buttonCta: string, openLogin: React.MouseEventHandler) => {
  return (
    <CommonButton
      button-type="primary"
      color="green"
      onClick={openLogin}
      data-testid={`rewards_hero_button${isMobile ? '' : '_desktop'}`}
      className={'shadow-[0_24px_40px_rgba(0,14,6,0.15)] md:shadow-none'}
    >
      {buttonCta}
    </CommonButton>
  );
};

export const StickyBarComponent = ({ stickyImage, stickyText, buttonCta }: TStickyBarProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountReducer.user);
  const openLogin = () => {
    dispatch(setOpenLoginDrawer(true));
    dispatch(setSignUpSource('website-join-now'));
  };
  const [isStickyVisible, setStickyVisibility] = useState(false);
  const [stickyBarTop, setStickyBarTop] = useState(undefined);
  const ref = useRef(null);

  useEffect(() => {
    //@ts-ignore
    const stickyBarEl = ref?.current?.getBoundingClientRect();
    if (stickyBarEl?.top !== undefined) {
      setStickyBarTop(stickyBarEl?.top);
    }
  }, []);

  useEffect(() => {
    if (stickyBarTop === null || stickyBarTop === undefined) return;
    window.addEventListener('scroll', listenToScroll);
    return () => {
      window.removeEventListener('scroll', listenToScroll);
    };
  }, [stickyBarTop]);

  const listenToScroll = () => {
    const windowScrollHeight = window.scrollY;
    if (stickyBarTop && windowScrollHeight > stickyBarTop - 150) {
      setStickyVisibility(true);
    } else {
      setStickyVisibility(false);
    }
  };

  return (
    !user && (
      <StickyBarContainer $isSticky={isStickyVisible} ref={ref} data-testid="sticky_bar_outter_container">
        <StickyBarMobileContainer $isSticky={isStickyVisible}>
          {isStickyVisible && renderCtaButton(true, buttonCta, openLogin)}
        </StickyBarMobileContainer>
        {isStickyVisible && (
          <StickyBarDesktopContainer data-testid="sticky_bar_desktop_container">
            <StickyBarInnerDesktopContainer>
              <Image
                src={stickyImage?.url || ''}
                alt={stickyImage?.alt || ''}
                title={stickyImage?.title}
                width={stickyImage?.width || 38}
                height={stickyImage?.height || 32}
                loader={(options) => options?.src}
              />
              <Typography variant="h5" className="pl-[32px] md:min-w-[516px]">
                {stickyText}
              </Typography>
              <div className="flex w-full justify-end">
                {isStickyVisible && renderCtaButton(false, buttonCta, openLogin)}
              </div>
            </StickyBarInnerDesktopContainer>
          </StickyBarDesktopContainer>
        )}
      </StickyBarContainer>
    )
  );
};
