'use client';

import { bannerTrack } from 'helpers/analytics/banner-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import { TRewardsMatrixProps } from 'helpers/types/organisms/rewards/rewards-matrix';
import RewardsMatrixJoinComponent from './rewards-matrix-join';
import RewardsMatrixPointsComponent from './rewards-matrix-points';
import { RewardsMatrixBannerImageContainer, RewardsMatrixContainer } from './styles';

const RewardsMatrixComponent = ({
  title,
  subtitle,
  buttonCta,
  rewardData,
  internalName,
  tasticPlacementNumber,
}: TRewardsMatrixProps) => {
  const handleSegmentEvent = (type?: string) => {
    const bannerClickedData = {
      placement: tasticPlacementNumber + 1,
      banner_position: 1,
      banner_internal_name: internalName,
      banner_title: title,
      banner_image_main: '',
      banner_type: 'Other',
      link_name: buttonCta.items[0].title,
      link_type: 'Hyperlink',
    };
    bannerTrack(type, bannerClickedData);
  };

  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });

  return (
    <RewardsMatrixContainer ref={targetRef}>
      <RewardsMatrixJoinComponent
        handleSegmentEvent={handleSegmentEvent}
        title={title}
        subtitle={subtitle}
        buttonCta={buttonCta}
      />
      <RewardsMatrixBannerImageContainer>
        <RewardsMatrixPointsComponent {...rewardData} />
      </RewardsMatrixBannerImageContainer>
    </RewardsMatrixContainer>
  );
};

export default RewardsMatrixComponent;
