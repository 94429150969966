import tw from 'tailwind-styled-components';

export const RewardsMatrixPointsCardSection = tw.div`
    flex 
    h-full 
    w-full 
    flex-col 
    items-start 
    gap-6 
    bg-grey-100 
    px-5 
    pb-12 
    pt-5 
    md:gap-16
    md:px-[60px] 
    md:pb-[104px] 
    md:pt-16
`;

export const RewardsMatrixPointsSection = tw.div`
    gird-cols-1 
    grid 
    w-full 
    gap-5 
    md:grid-cols-3 
    md:gap-8 
    xl:gap-20
`;

export const RewardsMatrixPointsMessageSection = tw.div`
    flex 
    flex-row 
    items-center 
    gap-4
`;
