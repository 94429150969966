import tw from 'tailwind-styled-components';
import TimeIcon from 'public/icons/figma/time.svg';

export const HeroArticleDetailContainer = tw.div`
    relative 
    mb-[80px] 
    h-auto 
    lg:mb-[120px] 
    2xl:mb-[200px]
`;

export const HeroArticleDetailContentContainer = tw.div`
   relative 
   z-[2] 
   flex 
   flex-col 
   items-center 
   gap-10 
   self-stretch 
   lg:gap-[60px] 
   2xl:gap-[88px]
`;

export const HeroArticleDetailIntroContainer = tw.div`
    mt-[-82px] 
    flex 
    px-5 
    lg:mt-[-299px] 
    lg:px-10 
    2xl:mt-[-379px] 
    2xl:px-[60px]
`;

export const HeroArticleDetailTitleSection = tw.div`
   flex 
   w-[100%] 
   flex-col 
   gap-4 
   lg:gap-6 
`;

export const HeroArticleDetailSubtitleSection = tw.div`
    w-full 
    2xl:max-w-[735px]
`;

export const HeroArticleDetailIntroInnerContentSection = tw.div`
    flex 
    h-auto 
    w-[100%] 
    flex-col 
    gap-y-6 
    px-6  
    lg:gap-y-10 
    lg:px-10 
    xl:px-[60px] 
    2xl:px-0
`;
export const HeroArticleDetailIntroContentSection = tw.div`
    flex 
    min-w-[360px] 
    md:min-w-[700px] 
    lg:min-w-[800px] 
    xl:min-w-[950px] 
    bg-grey-100 
    py-[32px] 
    lg:py-[60px] 
    2xl:py-[88px]
`;

export const HeroArticleDetailRecipeDetail = tw.div`
    flex
    flex-col
    items-start
    content-start
    self-stretch
    flex-wrap
    gap-2

    md:flex-row
    md:gap-2.5
`;

export const HeroArticleDetailBannerImage = tw.div`
    md:max-h-unset 
    !max-h-[450px] 
    overflow-hidden
`;

export const TitleStyling = `
    overflow-wrap-everywhere 
    lg:!text-[54px] 
    lg:leading-[50px] 
    2xl:!text-[104px] 
    2xl:!leading-[128px]
`;

export const HeroArticleDetailIntroParagphtSection = tw.div`
   flex 
   w-[100%] 
   px-5 
   md:px-[40px] 
   2xl:px-[60px]
`;

export const HeroArticleDetailTimeStampContent = tw.div`
    flex
    flex-row
    items-center
    gap-1
    !text-grey-400
`;

export const HeroArticleDetailTimeIcon = tw(TimeIcon)`
    text-grey-400
`;

export const HeroArticleDetailFlexNone = tw.div`
    flex-none
`;

export const Empty = tw.div`
    hidden
    md:block
    2xl:w-[304px]
    2xl:max-h-[788px]
    xl:w-[153px]
    xl:max-h-[602px]
`;
export const EmptySmall = tw.div`
    hidden
    2xl:block
    xl:w-[152px]
    2xl:max-h-[788px]
 `;

export const Spacer = tw.div`
    hidden
    2xl:block
    2xl:w-[152px]
    2xl:max-h-[612px]
    xl:w-[153px]
    xl:max-h-[482px]
`;

export const ParagraphSpacerLeft = tw.div`
    2xl:w-[456px]
    2xl:h-[192px]
    xl:w-[306px]
    xl:h-[140px]
`;

export const ParagraphSpacerRight = tw.div`
    xl:w-[152px]
    2xl:h-[192px]
    xl:h-[140px]
`;
