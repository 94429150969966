import tw from 'tailwind-styled-components';

export const GroupDiscountsCardContainer = tw.div`
    block
    md:flex
    max-w-[350px]
    md:max-w-[888px]
`;

export const TextContainer = tw.div`
    md:p-[30px]
    py-8
    px-5
    w-[350px]
    md:w-[456px]
    bg-grey-200
`;

export const ImageContainer = tw.div`
    relative
    w-[350px]
    md:w-[294px]
    min1440:w-[280px]
    h-[200px]
    md:h-full
`;
