import tw from 'tailwind-styled-components';

export const MainContainer = tw.div`
flex 
flex-col
px-5
pb-[56px]
lg:px-[60px]
lg:pb-[160px]
lg:grid 
lg:grid-rows-12 
lg:grid-cols-3
`;
export const ProductListContainer = tw.div`
mt-8
lg:mt-0
lg:col-start-2 
lg:col-span-2
lg:row-span-3
`;

export const ProductList = tw.div`
mb-4
border-b-2 
border-grey-200`;

export const ButtonContainer = tw.div`
flex 
flex-col 
items-center`;

// export const LinkContainer = tw.div`
// mt-8
// mb-[56px]
// lg:row-start-2
// lg:col-start-1
// `;
export const TitleContainer = tw.div`
lg:col-span-1 
lg:row-span-1 
lg:row-start-1
`;
export const ProductListItems = tw.div`
lg:grid 
lg:grid-flow-row 
lg:grid-cols-2
xl:grid-cols-3
`;
