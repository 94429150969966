'use client';

import { FunctionComponent, useEffect, useState } from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import LegalMap from 'components/molecules/legal-map';
import HeroArticleDetail from 'components/organisms/heros/hero-article-detail';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { RichTextTitle } from 'components/organisms/media/rich-text-component/styles';
import TableOfContent from 'components/organisms/media/summary-anchor-links';
import { USStatesFull } from 'helpers/constants/us-states-full';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TBreadCrumbItem } from 'helpers/types';
import { generateHashId } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import { ArticleBodyWrapper, ShowTocContainer } from 'frontastic/tastics/resources/article-body/styles';
import {
  LegalDataContianerDesktop,
  LegalDataContianerMobile,
  LegalDataWrapperMobile,
  TOCFixedStyles,
  TopMarginContent,
  WhereIsCannabisLegalBodyContainer,
  WhereIsCannabisLegalBodyWrapper,
} from './styles';

const WhereIsCannabisLegalTastics: FunctionComponent = (props: any) => {
  const sectionData =
    props?.data?.whereiscannabislegaldatasource?.dataSource?.data?.pageBlogResourceCollection?.items?.[0]
      ?.blogSectionsCollection?.items;
  const heroSectionData =
    props?.data?.whereiscannabislegaldatasource?.dataSource?.data?.pageBlogResourceCollection?.items?.[0];
  const breadcrumb: TBreadCrumbItem[] = props?.data?.breadcrumbs?.map((breadcrumb) => {
    return {
      label: breadcrumb?.breadcrumbTitle,
      path: breadcrumb?.slug || '/',
    };
  });

  const [isDrawer, setIsDrawer] = useState(false);
  const [isTablet] = useMediaQuery(tablet);

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isTablet) {
      return;
    }
    let timer = null;

    const handleScroll = () => {
      // @ts-ignore
      clearTimeout(timer);

      // @ts-ignore
      timer = setTimeout(() => {
        const tocEndElement = document.getElementById('tocEnd');
        if (tocEndElement) {
          const rect = tocEndElement.getBoundingClientRect();
          if (rect.top >= 0) {
            setIsDrawer(false);
          } else {
            setIsDrawer(true);
          }
        } else {
          setIsDrawer(true);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isTablet]);

  return (
    <>
      <HeroArticleDetail
        title={heroSectionData?.heroSection?.title}
        subTitle={heroSectionData?.heroSection?.subTitle}
        url={
          isTablet
            ? heroSectionData?.heroSection.image?.url
            : heroSectionData?.heroSection.mobileImage?.url || heroSectionData?.heroSection?.image?.url
        }
        width={
          isTablet
            ? heroSectionData?.heroSection.image?.width
            : heroSectionData?.heroSection.mobileImage?.width || heroSectionData?.heroSection?.image?.width
        }
        height={
          isTablet
            ? heroSectionData?.heroSection.image?.height
            : heroSectionData?.heroSection.mobileImage?.height || heroSectionData?.heroSection?.image?.height
        }
        content={heroSectionData?.heroSection?.description}
        date={heroSectionData?.publishDate}
        category={heroSectionData?.category || 'Resources'}
        estimateTime={heroSectionData?.readTime}
        alt={heroSectionData?.heroSection?.image.title}
        ariaLabel={heroSectionData?.heroSection?.image.description}
        breadcrumbItems={breadcrumb}
      />
      <ArticleBodyWrapper>
        <div>
          <LegalDataWrapperMobile>
            <LegalDataContianerMobile>
              {sectionData.map((item, index) => (
                <div key={item.title} className="first:pb-12">
                  <ContentfulRichText
                    renderNodeOptions={{
                      [BLOCKS.HEADING_2]: (node, children) => (
                        <RichTextTitle variant="h4" as="h2" id={item.title}>
                          {index === 0 && children}
                        </RichTextTitle>
                      ),
                    }}
                    data={index === 0 && item.body}
                  />
                </div>
              ))}
              <LegalMap />
            </LegalDataContianerMobile>
            <div className="px-5">
              {sectionData.map(
                (item, index) => index === 1 && <ContentfulRichText key={item.title} data={item.body} />,
              )}
            </div>
          </LegalDataWrapperMobile>
          <div className={scrollPosition < 0 ? 'fixed' : TOCFixedStyles}>
            {heroSectionData?.showToC && (
              <TableOfContent
                tocMargin={true}
                bottomScroll={true}
                isDrawer={isDrawer}
                titles={sectionData
                  .filter((tocItem) => tocItem.useInToC && tocItem.title !== null)
                  .map((tocItem) => tocItem.title)}
                tocHeading={'Content'}
              />
            )}
          </div>
        </div>
        <WhereIsCannabisLegalBodyWrapper $margin={heroSectionData?.showToC}>
          <LegalDataContianerDesktop>
            {sectionData.map((item, index) => (
              <div key={item.title} className={index === 0 ? TopMarginContent : ''}>
                <ContentfulRichText
                  key={item.title}
                  renderNodeOptions={{
                    [BLOCKS.HEADING_2]: (node, children) => (
                      <RichTextTitle variant="h4" as="h2" id={item.title}>
                        {index === 0 && children}
                      </RichTextTitle>
                    ),
                  }}
                  data={index === 0 && item.body}
                />
              </div>
            ))}
            <LegalMap />
          </LegalDataContianerDesktop>
          {sectionData.map((item, index) => (
            <div key={item.title} id={item.title}>
              {!isTablet && index === 1 ? null : (
                <div key={item.title} className={index === 1 || index === 2 ? WhereIsCannabisLegalBodyContainer : ''}>
                  <ShowTocContainer id="tocEnd">
                    <div id={generateHashId(item.title && USStatesFull[item.title.toLocaleUpperCase()])}>
                      {item.useInToC && (
                        <RichTextTitle variant="h4" as="h2" id={generateHashId(item.title)}>
                          {item.title}
                        </RichTextTitle>
                      )}
                    </div>
                    <ContentfulRichText
                      renderNodeOptions={{
                        [BLOCKS.HEADING_2]: (node, children) => (
                          <RichTextTitle variant="h4" as="h2" id={item.title}>
                            {index != 0 && children}
                          </RichTextTitle>
                        ),
                      }}
                      data={index != 0 && item.body}
                    />
                  </ShowTocContainer>
                </div>
              )}
            </div>
          ))}
        </WhereIsCannabisLegalBodyWrapper>
      </ArticleBodyWrapper>
    </>
  );
};
export default WhereIsCannabisLegalTastics;
