import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import {
  GroupDiscountsCardContainer,
  ImageContainer,
  TextContainer,
} from 'components/molecules/cards/group-discounts-card/styles';
import ResponsiveImageOrVideo from 'components/molecules/image-or-video/responsive-image-or-video';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { IContentfulImage, IRichTextData } from 'helpers/types';
import { ReplaceUrlWithHost, getPathMenuFiltersLink } from 'helpers/utils/common';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';

interface GroupDiscountsCardProps {
  description: IRichTextData;
  image: IContentfulImage;
  mobileImage: IContentfulImage;
  title: string;
  cta: {
    title: string;
    url: string;
    longUrl: string;
    openInNewTab: boolean;
  };
  host: string;
  onClick?: () => void;
}

const GroupDiscountsCard = ({
  description,
  image,
  mobileImage,
  title,
  cta,
  host,
  onClick,
}: GroupDiscountsCardProps) => {
  const link = ReplaceUrlWithHost(cta?.longUrl ?? cta?.url, host);

  return (
    <GroupDiscountsCardContainer>
      {(image || mobileImage) && (
        <ImageContainer>
          <ResponsiveImageOrVideo
            desktop={image}
            mobile={mobileImage}
            imageProps={{
              width: 432,
              height: 292,
              className: 'h-full w-full object-cover',
              'data-testid': 'groupDiscountsCardImage',
              alt: image?.title || '',
            }}
          />
        </ImageContainer>
      )}
      <TextContainer>
        {title && (
          <Typography variant="h4" as="h3" data-testid="groupDiscountsCardTitle">
            {title}
          </Typography>
        )}
        {description && (
          <Typography variant="body-small" className="my-4 !text-grey-400" data-testid="groupDiscountsCardDescription">
            <ContentfulRichText data={description} />
          </Typography>
        )}
        {cta && (
          <IconWithTextButton
            data-testid="groupDiscountsCardCTA"
            as="a"
            color="green"
            button-type="tertiary"
            iconPosition="after"
            icon={<ChevronRightIcon />}
            href={getPathMenuFiltersLink(link)}
            host={host}
            target={!getPathMenuFiltersLink(link).includes(host) || cta.openInNewTab ? '_blank' : '_self'}
            className="!p-0"
            onClick={onClick}
          >
            {cta.title}
          </IconWithTextButton>
        )}
      </TextContainer>
    </GroupDiscountsCardContainer>
  );
};

export default GroupDiscountsCard;
