'use client';

import { BLOCKS } from '@contentful/rich-text-types';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import Breadcrumb from 'components/atoms/navigation/breadcrumb/breadcrumb';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import { TRewardsHeroProps } from 'helpers/types/organisms/hero/rewards-hero';
import { ReplaceUrlWithHost } from 'helpers/utils/common';
import { isVideoUrl } from 'helpers/utils/is-video-file';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setOpenLoginDrawer, setSignUpSource } from 'redux/reducers/account';
import {
  RewardHeroBgImageMobileContainer,
  RewardHeroLogoMobileContainer,
  RewardsHeroContainer,
  RewardsHeroDescriptionContainer,
  RewardsHeroDesktopBannerContainer,
  RewardsHeroDesktopImageContainer,
  RewardsHeroImageContainer,
  RewardsHeroImageMobileContainer,
  RewardsHeroInnerTextContainer,
  RewardsHeroRewardsLogoContainer,
  RewardsHeroRewardsLogoImage,
  RewardsHeroRewardsLogoImageContainer,
  RewardsHeroTextContainer,
} from './styles';

export const RewardsHeroComponent = ({
  title,
  description,
  buttonCta,
  heroImageSrc,
  heroImageDesktopSrc,
  rewardsLogoSrc,
  rewardsLogoDesktopSrc,
  breadcrumbs,
  internalName,
  placement,
}: TRewardsHeroProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.accountReducer.user);
  const host = useAppSelector((state) => state.hostReducer.host);

  const handleSegmentEvent = (type?: string) => {
    const bannerClickedData = {
      placement: placement ? placement + 1 : 1,
      banner_position: 1,
      banner_internal_name: internalName,
      banner_title: title,
      banner_image_main: heroImageDesktopSrc,
      banner_type: 'other',
      link_name: buttonCta?.items?.[0]?.title || '',
      link_type: 'hyperlink',
    };
    bannerTrack(type, bannerClickedData);
  };
  const openLogin = () => {
    dispatch(setOpenLoginDrawer(true));
    dispatch(setSignUpSource('website-join-now'));
    handleSegmentEvent(SegmentEvents.BANNER_CLICKED);
  };
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });

  return (
    <>
      <RewardsHeroContainer ref={targetRef}>
        <RewardsHeroTextContainer>
          <div>
            {breadcrumbs && breadcrumbs.length > 0 && <Breadcrumb items={breadcrumbs} />}
            <RewardsHeroInnerTextContainer>
              <Typography variant="h1" data-testid="rewards_hero_title">
                {title}
              </Typography>
              <RewardsHeroDescriptionContainer>
                {description && (
                  <ContentfulRichText
                    data={description}
                    renderNodeOptions={{
                      [BLOCKS.PARAGRAPH]: (node, children) => (
                        <Typography
                          variant="body-large"
                          data-testid="rewards_hero_description"
                          className="!text-grey-400"
                        >
                          {children}
                        </Typography>
                      ),
                    }}
                  />
                )}
              </RewardsHeroDescriptionContainer>
              <CommonButton
                button-type="primary"
                color="green"
                as={!user ? 'button' : 'a'}
                href={user && (ReplaceUrlWithHost(buttonCta?.items?.[1]?.longUrl as string, host) || '/')}
                data-testid="rewards_hero_button"
                onClick={!user && buttonCta.items?.[0]?.longUrl === '#rise-join-now' ? () => openLogin() : () => {}}
              >
                {!user ? buttonCta?.items?.[0]?.title : buttonCta?.items?.[1]?.title}
              </CommonButton>
            </RewardsHeroInnerTextContainer>
          </div>
        </RewardsHeroTextContainer>
        <RewardsHeroImageMobileContainer>
          <RewardHeroBgImageMobileContainer>
            {/* TODO: HC-9791 update this RewardsHeroImageContainer to nextjs image or ResponsiveImageOrVideo  */}
            <RewardsHeroImageContainer data-testid="rewards_hero_bg_img" src={heroImageSrc} />
          </RewardHeroBgImageMobileContainer>
          <RewardHeroLogoMobileContainer>
            <RewardsHeroRewardsLogoContainer>
              <RewardsHeroRewardsLogoImageContainer>
                <RewardsHeroRewardsLogoImage src={rewardsLogoSrc} data-testid="rewards_hero_rr_logo_img" />
              </RewardsHeroRewardsLogoImageContainer>
            </RewardsHeroRewardsLogoContainer>
          </RewardHeroLogoMobileContainer>
        </RewardsHeroImageMobileContainer>
        <RewardsHeroDesktopBannerContainer>
          <RewardsHeroRewardsLogoContainer>
            <RewardsHeroRewardsLogoImageContainer>
              <RewardsHeroRewardsLogoImage src={rewardsLogoDesktopSrc} data-testid="rewards_hero_rr_logo_desktop_img" />
            </RewardsHeroRewardsLogoImageContainer>
          </RewardsHeroRewardsLogoContainer>
          {!isVideoUrl(heroImageDesktopSrc) ? (
            <RewardsHeroDesktopImageContainer
              style={{
                backgroundImage: `${heroImageDesktopSrc ? `url(${heroImageDesktopSrc})` : 'unset'}`,
              }}
              data-testid="rewards_hero_bg_desktop_img"
            />
          ) : (
            <video className="h-full w-full" controls autoPlay loop muted>
              <source src={heroImageDesktopSrc} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          )}
        </RewardsHeroDesktopBannerContainer>
      </RewardsHeroContainer>
    </>
  );
};
