import { Typography } from 'components/atoms/typography';
import { useAppSelector } from 'redux/hooks';
import { AccountOverviewChevronIcon, AccountOverviewFlexRow, ImageBackground } from './styles';

interface AccountOverviewBannerProps {
  src: string;
  ariaLabel: string;
  alt: string;
  bgGradient?: string;
  className?: string;
  breadcrumbTitle: string;
  breadcrumbClassName?: string;
  description: string;
  descriptionClassName?: string;
  href: string;
  descriptionHeader?: 'h1' | 'h3';
  type: 'HOME_DISPENSARY' | 'LOYALTY_POINTS';
}

const AccountOverviewBanner = ({
  type,
  src = '',
  ariaLabel = '',
  alt = '',
  bgGradient,
  className = '',
  breadcrumbTitle = '',
  breadcrumbClassName = '',
  description = '',
  descriptionClassName = '',
  href = '/',
  descriptionHeader = 'h3',
}: AccountOverviewBannerProps) => {
  const host = useAppSelector((store) => store.hostReducer.host);
  const url = `${host}${href}`;
  return (
    <ImageBackground
      role="img"
      aria-label={ariaLabel}
      className={className}
      style={{ backgroundImage: `${bgGradient ? `${bgGradient}, url('${src}')` : `url('${src}')`}` }}
      arial-title={alt}
      $type={type}
    >
      <AccountOverviewFlexRow>
        <div className="w-full">
          <Typography variant="h5" as="a" href={url} className={breadcrumbClassName}>
            {breadcrumbTitle}
          </Typography>
        </div>
        <a href={url}>
          <AccountOverviewChevronIcon className={breadcrumbClassName} />
        </a>
      </AccountOverviewFlexRow>
      <Typography variant={descriptionHeader} href={url} as="a" className={'mt-auto ' + descriptionClassName}>
        {description}
      </Typography>
    </ImageBackground>
  );
};

export default AccountOverviewBanner;
