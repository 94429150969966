'use client';

import React from 'react';
import { usePathname, useRouter } from 'next/navigation';
import { ToggleButton } from 'components/atoms/inputs/toggle-button';
import Breadcrumb from 'components/atoms/navigation/breadcrumb/breadcrumb';
import { Typography } from 'components/atoms/typography';
import { BannerBreadcumbContainer } from 'components/organisms/heros/hero-content/hero-content-styles';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { menuChangeToggle } from 'helpers/analytics/menu-change-toggle';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import { TBreadCrumbItem } from 'helpers/types';
import { formatMenuTitle } from 'helpers/utils/format-menu-title';
import { useAppSelector } from 'redux/hooks';
import DispensaryInformation from './dispensary-information';
import { HeroProductListingBox, HeroProductListingTitleBox, HeroProductListingWapper } from './styles';

type TToggleButton = {
  linkedFrom: {
    pageMenuCollection: any;
  };
  title: string;
};
type TProps = {
  title: string;
  openHours: string;
  phoneNumber: string;
  phoneLabel?: string;
  address: string;
  addressLabel?: string;
  dispensaryInformationLabel: string;
  addressUrl: string;
  toggleButton: TToggleButton[];
  internalName?: string;
  tasticPlacementNumber?: number;
  breadcrumbs?: TBreadCrumbItem[];
};
const HeroProductListing = ({
  title,
  openHours,
  phoneNumber,
  phoneLabel,
  address,
  addressLabel,
  addressUrl,
  dispensaryInformationLabel,
  toggleButton,
  internalName,
  tasticPlacementNumber,
  breadcrumbs,
}: TProps) => {
  const { push } = useRouter();
  const path = usePathname();

  const host = useAppSelector((store) => store.hostReducer.host);

  const handleToggleButton = (values) => {
    if (!toggleButton) return;

    const toggleButtonTitles = toggleButton?.map((button) => button?.title?.toLowerCase());
    const index = toggleButtonTitles?.indexOf(values?.toLowerCase());

    if (index !== -1) {
      const currentIndex = index === 0 ? 1 : 0;
      const currentButton = toggleButton[currentIndex];
      const targetButton = toggleButton[index];
      const linkedPageSlug = targetButton?.linkedFrom?.pageMenuCollection?.items?.[0]?.slug;
      menuChangeToggle({
        menu: formatMenuTitle(targetButton?.title),
        page_url: `${host}${currentButton?.linkedFrom?.pageMenuCollection?.items?.[0]?.slug}`,
      });
      push(linkedPageSlug);
    }
  };
  const handleAnalyticSegmentEvent = (type?: string, linkName?: string) => {
    const bannerClickedData = {
      placement: tasticPlacementNumber ? tasticPlacementNumber + 1 : 1,
      banner_position: 1,
      banner_internal_name: internalName,
      banner_title: title,
      banner_image_main: '',
      banner_type: 'other',
      link_name: linkName || '',
      link_type: 'hyperlink',
    };
    bannerTrack(type, bannerClickedData);
  };
  const targetRef = useIntersectionObserver(() => {
    handleAnalyticSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });

  const buttonIndex: number = toggleButton?.findIndex(
    ({ linkedFrom }) => linkedFrom?.pageMenuCollection?.items?.[0]?.slug?.includes(path?.split('?')?.[0]) || 0,
  );
  const selectedButton: boolean = buttonIndex > 0 ? false : true;

  return (
    <HeroProductListingWapper ref={targetRef}>
      {breadcrumbs && breadcrumbs.length > 0 && (
        <BannerBreadcumbContainer
          className="!pt-0 pb-6 md:pb-10 xl:pb-[60px]"
          role="navigation"
          aria-label="Breadcrumb"
        >
          <Breadcrumb items={breadcrumbs} />
        </BannerBreadcumbContainer>
      )}
      <HeroProductListingBox>
        <HeroProductListingTitleBox>
          {title && <Typography variant="h2">{title}</Typography>}
          {(openHours || phoneNumber || address) && (
            <DispensaryInformation
              openHours={openHours}
              phoneNumber={phoneNumber}
              phoneLabel={phoneLabel}
              address={address}
              addressUrl={addressUrl}
              addressLabel={addressLabel}
              title={dispensaryInformationLabel}
              handleSegmentEvent={handleAnalyticSegmentEvent}
            />
          )}
        </HeroProductListingTitleBox>
        {toggleButton && toggleButton?.length > 1 && (
          <div>
            <ToggleButton
              leftLabel={toggleButton[0]?.title}
              onClick={handleToggleButton}
              selectedFromProps={selectedButton}
              rightLabel={toggleButton[1]?.title}
            />
          </div>
        )}
      </HeroProductListingBox>
    </HeroProductListingWapper>
  );
};

export default HeroProductListing;
