'use client';

import { BannerSection } from 'components/organisms/media/rich-text-component/rich-text-react-render/contentful-render-component';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
type TProps = {
  data: any;
};
// Content Feed Section
const ContentFeedSection = ({ data }: TProps) => {
  const [isTablet] = useMediaQuery(tablet);
  return (
    Array.isArray(data) &&
    data &&
    data?.map((item) => {
      return BannerSection(item, 'xl:py-16 xl:px-[60px] md:p-10 py-6 px-5', isTablet);
    })
  );
};

export default ContentFeedSection;
