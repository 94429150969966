import { useMemo, useRef, useState } from 'react';
import Image from 'next/image';
import { Dialog, DialogBody } from '@material-tailwind/react/components/Dialog';
import Slider from 'react-slick';
import Pagination from 'components/atoms/pagination';
import { IModalImageGallery } from 'helpers/types';
import { GalleryThumbnailImages } from './gallery-thumbnail-images';
import {
  GalleryBodyContainer,
  GalleryBodyInnerContainer,
  GalleryCloseIcon,
  GalleryContainer,
  PaginationDesktopContainer,
  PaginationMobileContainer,
} from './styles';

export const ModalImageGalleryComponent = ({ images, defaultOpen = false, openGalleryButton }: IModalImageGallery) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const handleOpen = () => {
    setIsOpen(!isOpen);
    setActiveImageIndex(0);
  };
  const onModalClose = () => {
    setIsOpen(false);
    setActiveImageIndex(0);
  };
  const ref = useRef<Slider>(null);
  const [activeImageIndex, setActiveImageIndex] = useState(0);

  const pageChange = (pageNumber: number) => {
    ref?.current?.slickGoTo(pageNumber - 1);
    setActiveImageIndex(pageNumber - 1);
  };

  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 100,
    variableWidth: false,
    afterChange: (currentSlide: number) => {
      setActiveImageIndex(currentSlide);
    },
  };

  const memoizedImages = useMemo(() => {
    return images?.map((image, index) => {
      return (
        <Image
          key={index}
          src={image?.url || ''}
          alt={image?.alt || ''}
          title={image?.title}
          width={0}
          height={0}
          className="md:rounded-md"
          layout="responsive"
          aria-description={image?.description}
          loader={(options) => options.src}
          onMouseEnter={() => {
            /* TODO: HC-3564 implement zoom functionality */ return;
          }}
          onMouseLeave={() => {
            return;
          }}
        />
      );
    });
  }, [images]);
  return (
    <GalleryContainer data-testid="modal-gallery-drawer-component">
      {openGalleryButton({ onClick: handleOpen })}
      <Dialog
        open={isOpen}
        handler={handleOpen}
        className={`
          absolute
          !mx-0
          h-full
          w-full
          max-w-full
          rounded-none
          bg-white
          !px-0
          md:bg-transparent
          md:shadow-transparent
          lg:min-w-[700px]
          xl:h-[656px]
          xl:w-[700px]
          2xl:h-[969px]
          2xl:w-[1041px]
          2xl:max-w-full
        `}
        data-testid="modal-gallery-dialog-component"
      >
        <GalleryCloseIcon onClick={onModalClose} data-testid="modal-gallery-close-button" />
        <DialogBody className="top-[15%] flex h-full flex-col !p-0 xl:top-0">
          <GalleryBodyContainer>
            <GalleryBodyInnerContainer>
              <Slider {...settings} ref={ref}>
                {memoizedImages}
              </Slider>
              <div className="md:absolute md:right-0 md:top-[0]">
                <GalleryThumbnailImages images={images} activeImageIndex={activeImageIndex} pageChange={pageChange} />
              </div>
              {images?.length > 1 && (
                <PaginationMobileContainer>
                  <Pagination
                    count={images?.length}
                    variant={'pill-mobile'}
                    isVisible={true}
                    onPageChange={({ page }) => pageChange(page)}
                    color="resin"
                    slickCurrentIndex={activeImageIndex}
                  />
                </PaginationMobileContainer>
              )}
            </GalleryBodyInnerContainer>
          </GalleryBodyContainer>
          {images?.length > 1 && (
            <PaginationDesktopContainer id="debugPaginationDesktopCont">
              <Pagination
                count={images?.length}
                variant={'pill-desktop'}
                isVisible={true}
                onPageChange={({ page }) => pageChange(page)}
                color="resin"
                slickCurrentIndex={activeImageIndex}
              />
            </PaginationDesktopContainer>
          )}
        </DialogBody>
      </Dialog>
    </GalleryContainer>
  );
};
