import orderBy from 'lodash/orderBy';
import { IGeoLoc } from 'helpers/types';

type TParams = {
  origin: IGeoLoc;
  locationList: any[];
};
export const getSortedDispensaries = async ({ origin, locationList }: TParams) => {
  const locationListCopy = [...locationList];
  const locationListCopyList = [];
  while (locationListCopy.length) {
    //@ts-ignore
    locationListCopyList.push(locationListCopy.splice(0, 25));
  }

  return processInputInSeries(origin, locationListCopyList).then((distanceList) => {
    return orderBy(
      locationList.map((d, i) => {
        return { ...d, distanceForOrderBy: parseFloat(distanceList[i]), customDistance: distanceList[i] };
      }),
      ['distanceForOrderBy'],
    );
  });
};
const getDistanceMatrix = async (origin, destinationsList) => {
  const service = new google.maps.DistanceMatrixService();

  const matrixOptions = {
    origins: [origin],
    destinations: destinationsList.map((dis) => {
      return {
        lat: parseFloat(dis._geoloc.lat.toString()),
        lng: parseFloat(dis._geoloc.lng.toString()),
      };
    }),
    travelMode: google.maps.TravelMode.DRIVING,
    unitSystem: google.maps.UnitSystem.IMPERIAL,
  };

  return new Promise((resolve) => {
    service.getDistanceMatrix(matrixOptions, (response, status) => {
      if (status !== 'OK') {
        return [];
      }
      //@ts-ignore
      const distanceResponse: string[] = response.rows[0].elements.map((row) => {
        if (row.status === 'ZERO_RESULTS') {
          return 'Out of Range';
        }
        const distanceInKm = parseFloat(row?.distance?.text?.split(' ')[0].replace(/,/g, ''));
        return (+distanceInKm * 0.622).toFixed(1);
      });
      resolve(distanceResponse);
    });
  });
};
const processInputInSeries = async (origin, locationListCopyList) => {
  const processNext = (index, locationListCopyList, results) => {
    if (index >= locationListCopyList.length) return results;
    return getDistanceMatrix(origin, locationListCopyList[index]).then((result: string[]) => {
      return processNext(index + 1, locationListCopyList, [...results, ...result]);
    });
  };
  return processNext(0, locationListCopyList, []);
};
