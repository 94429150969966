import tw from 'tailwind-styled-components';

export const AboutContainer = tw.div`
    px-5
    md:px-[60px]
    md:py-16
    pt-8
    pb-[96px]
    md:flex
    sm:block
`;
export const TextWrapper = tw.div`
    sm:mb-6
    md:mb-0
    sm:w-full
    md:w-[50%]
    md:pr-5
`;
export const TextContainer = tw.div`
    mb-6
    md:mb-0
    w-full
    md:max-w-[584px]
`;

export const InfoContainer = tw.div`
    bg-grey-100
    w-full
    md:max-w-[584px]
    py-6
    md:pt-[58px]
    px-5
    md:px-[56px]
    rounded-md
    relative
    h-full
`;

export const descriptionClasses = `
    !text-grey-400
    my-5
    md:my-6
    [&_p]:mb-4
`;

export const contactClasses = `
    !text-grey-400
    md:py-4
    py-0
`;

export const addressClasses = `
    ml-3
    !text-grey-400
    my-0
    md:my-6
`;

export const aboutDispensaryButtonClasses = `
    ml-3
    p-0
    md:p-0
    flex
`;

export const ContactContainer = tw.div`
    flex
    md:border-t-solid
    md:border-t-[1px]
    md:border-t-grey-200
    items-center
    mb-6
    md:my-0
    gap-3
`;

export const AddressContainer = tw.div`
    md:border-y-solid
    md:border-y-[1px]
    md:border-y-grey-200
    items-center
    mb-6
`;

export const InnerAddressContainer = tw.div`
    block
    md:flex
    md:flex-wrap
`;
export const ContactUsContainer = tw.div`
    flex
    items-center
    gap-3
`;

export const IconStyle = `
    ml-2
    mt-[2px]
`;

export const ClockContainer = tw.div`
    absolute
    md:top-0
    right-[-5%]
    md:right-[-25%]
    bottom-[-35%]
    max-w-[280px]
    max-h-[200px]

`;
