import React from 'react';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import DispensaryInformationCard from './dispensary-information-card';
import DispensaryInformationMobile from './dispensary-information-mobile';
type TProps = {
  openHours: string;
  phoneNumber: string;
  phoneLabel?: string;
  address: string;
  addressLabel?: string;
  addressUrl: string;
  title?: string;
  handleSegmentEvent?: (type?: string, linkName?: string) => void;
};
const DispensaryInformation = (props: TProps) => {
  const [isTablet] = useMediaQuery(tablet);
  return isTablet ? <DispensaryInformationCard {...props} /> : <DispensaryInformationMobile {...props} />;
};

export default DispensaryInformation;
