import tw from 'tailwind-styled-components';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';

export const AccountOverviewFlexRow = tw.div`
    flex
    w-full
`;

export const AccountOverviewContainer = tw.div`
    flex w-[390px] min-w-[320px] max-w-[767px] flex-col items-start gap-6 px-5 py-6 w-full 
    md:min-w-full #md:max-w-[958px] md:max-w-full
    xl:min-w-[860px] xl:min-h-[968px] xl:flex-row xl:max-w-none
`;

export const AccountOverviewContent = tw.div`
    xl:px-[60px] xl:py-12 w-full
`;

export const AccountOverviewUserSection = tw(AccountOverviewFlexRow)`
    flex items-center gap-4 self-stretch px-4 py-3 rounded-md bg-grey-100 mt-5 mb-6
    md:justify-between  md:items-start  md:content-start  md:gap-y-6  md:self-stretch  md:flex-wrap  md:p-5 md:my-10
    xl:px-8 xl:py-6 xl:my-12
`;

export const AccountOverviewUserInfoSection = tw.div`
    flex
    flex-col
    w-full
    md:w-auto
    gap-1
    md:gap-0.5 
    xl:gap-1
`;

export const AccountOverviewUserEditButtonWrapper = tw.span`
    flex
    w-10
    h-10
    p-2
    md:w-auto
    justify-center
    items-center
    shrink-0

    xl:!h-12
    xl:!px-3.5
    xl:!pr-6
    xl:!pl-[18px]
    xl:!justify-end
    xl:!items-center
    xl:!gap-2
`;

export const AccountOverviewSavedProductSection = tw.div`
    flex
    flex-col
    justify-center
    items-start
    gap-3
    xl:items-start
    hidden
    xl:block
`;

export const AccountOverviewBannerSection = tw.div`
flex items-start content-start gap-2.5 self-stretch flex-wrap
md:self-stretch  md:pt-0 md:pb-10 md:px-6 md:gap:0 md:flex-nowrap
xl:flex-col xl:gap-0 xl:p-0
`;

export const AccountOverviewChevronIcon = tw(ChevronRightIcon)<{ className?: string }>`
    text-white
`;

export const ImageBackground = tw.div<{ $type: 'HOME_DISPENSARY' | 'LOYALTY_POINTS' }>`
  flex h-[200px] min-w-[170px] flex-col justify-between items-start flex-[1_0_0] p-4
  bg-cover bg-center
  self-stretch
  md:min-w-[356px] md:gap-[105px] md:px-6 md:py-10 md:h-auto
  xl:self-stretch
  xl:px-[60px]
  xl:py-14
  2xl:min-w-[608px]
  ${(p) => (p.$type === 'LOYALTY_POINTS' ? 'xl:max-h-[369px]' : '')}
`;
