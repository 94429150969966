import tw from 'tailwind-styled-components';

export const DiscountEventContainer = tw.div`
    relative
    flex
    flex-col
    items-start

    md:py-10
    md:w-full
    md:items-center
    md:gap-[60px]

    2xl:py-[60px]
    2xl:min-w-[1280px]
    2xl:gap-[120px]
`;

export const DiscountEventHeaderSection = tw.div`
    flex
    flex-col
    items-center
    self-stretch
    py-10
    px-5
    gap-4

    md:items-start
    md:py-0
    md:px-10
    md:gap-6

    2xl:px-[60px]
`;

export const DiscountEventFlexDirectionContainer = tw.div`
    flex
    flex-col
    items-start
    bg-grey-100

    md:flex-row-reverse
    md:items-center
    md:self-stretch
    md:justify-between
    md:h-[400px]
    xl:h-[529px]
`;

export const DiscountEventContentSection = tw.div`
    flex
    flex-col
    items-start
    gap-6
    py-10
    px-5
    h-[348px]
    md:p-10
    md:h-full
    md:min-w-[360px]
    md:max-w-[475px]
    md:justify-between
    lg:min-w-[475px]
    xl:justify-center
    xl:gap-14
    xl:min-w-[637px]
    xl:px-[60px]
    xl:py-16
`;

export const DiscountEventInnerContent = tw.div`
    flex
    flex-col
    items-start
    gap-4
    self-stretch
    lg:gap-6
    xl:pr-[60px]
`;

export const DiscountEventButtonSection = tw.div`
    flex
    flex-col
    items-start
    w-full
    gap-6

    lg:gap-5
    lg:self-stretch
    lg:justify-between

    xl:flex-row
    xl:items-end
    xl:gap-y-[42px]
`;

export const DiscountEventContainerSlider = tw.div`
    w-full
    [&_div.slick-track]:flex
`;
