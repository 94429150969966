'use client';

import React, { useEffect, useRef, useState } from 'react';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import FaqAccordion from 'components/molecules/accordions/faq-accordion';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { generateHashId } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import { FaqBodyContainer, FaqDataContainer, FaqImageContainer, FaqsContainer } from '../faq-styles';

type FAQImage = {
  desktopImage: {
    url: string;
    width: number;
    height: number;
    alt: string;
  };
  mobileImage: {
    url: string;
    width: number;
    height: number;
    alt: string;
  };
};

type FAQItem = {
  question: string;
  answer: any;
};

interface IFAQSection {
  title: string;
  subTitle: string;
  faqsCollection: FAQItem[];
  faqImage: FAQImage;
}

const FAQSectionVariantA = ({ title, subTitle, faqsCollection, faqImage }: IFAQSection) => {
  const totalFAQs = faqsCollection.length;
  const [displayCount, setDisplayCount] = useState(totalFAQs <= 8 ? totalFAQs : 4);
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(null);
  const accordionDataToShow = faqsCollection.slice(0, displayCount);
  const topRef = useRef(null);

  const handleAccordionClick = (index: number) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  useEffect(() => {
    const hash = window.location.hash;
    const element = document.getElementById(hash);

    if (element) {
      const y = element.getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({ top: y, behavior: 'instant' });
    }
  }, []);

  const [isTablet] = useMediaQuery(tablet);

  const showButtonText =
    totalFAQs > 8 ? (displayCount === totalFAQs ? `Show Less` : `Show More (${totalFAQs - displayCount})`) : '';
  return (
    <FaqsContainer id={`#${generateHashId('state_faq_section/')}`} ref={topRef}>
      {faqImage && (
        <FaqImageContainer>
          <Image
            className="z-0"
            src={isTablet ? faqImage.desktopImage.url : faqImage.mobileImage.url}
            width={isTablet ? faqImage.desktopImage.width : faqImage.mobileImage.width}
            height={isTablet ? faqImage.desktopImage.height : faqImage.mobileImage.height}
            loader={({ src }) => src}
            alt={isTablet ? faqImage.desktopImage.alt : faqImage.mobileImage.alt}
            unoptimized
          />
        </FaqImageContainer>
      )}
      <FaqBodyContainer>
        {title && (
          <Typography variant="body-small" className="pb-2">
            {title}
          </Typography>
        )}
        {subTitle && (
          <Typography variant="h3" className="mb-6 lg:mb-10">
            {subTitle}
          </Typography>
        )}
        <FaqDataContainer $isGap={true}>
          {totalFAQs > 0 &&
            accordionDataToShow.map((item: FAQItem, index: number) => (
              <FaqAccordion
                key={index}
                data={item}
                faqWidth={true}
                index={index}
                shouldOpen={openAccordionIndex === index}
                handleOpen={handleAccordionClick}
              />
            ))}
        </FaqDataContainer>
        {totalFAQs > 8 && (
          <div className="pt-6">
            <Typography variant="small" className="pb-4">{`Showing ${displayCount} of ${totalFAQs}`}</Typography>
            <CommonButton
              color="green"
              button-type="secondary"
              className="px-5 py-3 text-sm lg:px-6 lg:py-[14px] lg:text-base"
              onClick={() => {
                setDisplayCount(displayCount === totalFAQs ? 4 : totalFAQs);
                if (accordionDataToShow.length === totalFAQs) {
                  //@ts-ignore
                  topRef.current.scrollIntoView();
                }
              }}
            >
              {showButtonText}
            </CommonButton>
          </div>
        )}
      </FaqBodyContainer>
    </FaqsContainer>
  );
};

export default FAQSectionVariantA;
