import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import Link from 'next/link';
import { formatDate } from 'date-fns';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import Label from 'components/atoms/labels/text-and-icon';
import { Typography } from 'components/atoms/typography';
import ProductCard from 'components/molecules/cards/product-card';
import { ORDER_STATUS_MAPPING } from 'helpers/types';
import { getDefaultProductImageUrl } from 'helpers/utils/product-details';
import AlertIcon from 'public/icons/figma/alert.svg';
import BagMediumIcon from 'public/icons/figma/bag-medium.svg';
import CheckIcon from 'public/icons/figma/check.svg';
import ChevronDownIcon from 'public/icons/figma/chevron-down.svg';
import ChevronUpIcon from 'public/icons/figma/chevron-up.svg';
import LocationIcon from 'public/icons/figma/location.svg';
import TimeIcon from 'public/icons/figma/time.svg';
import { useAppSelector } from 'redux/hooks';
import {
  AccordionBodyBox,
  AccordionBodyCard,
  AccordionBodyOrderBox,
  AccordionBodyOrderCard,
  AccordionCard,
  AccordionHeaderCard,
  AccordionHeaderTitleCard,
  FadeBox,
  OrderItemBox,
  ProductCardBox,
} from './styles';

type TransactionHistoryAccordionType = {
  open: boolean;
  data: any;
  handleOpen: (index: number) => void;
  index: number;
  showProductCardNumber?: number;
  ctaName?: string;
  ctaLink?: string;
};
const Icon = ({ open }: { open: boolean }) => {
  return open ? <ChevronUpIcon size={24} /> : <ChevronDownIcon size={24} />;
};
const OrderItem = ({ title, icon }: { title: string; icon: JSX.Element }) => {
  return (
    <OrderItemBox>
      {icon}
      <Typography variant="body-small" className="!text-grey-400">
        {title}
      </Typography>
    </OrderItemBox>
  );
};

const TransactionHistoryAccordion: ForwardRefRenderFunction<HTMLDivElement, TransactionHistoryAccordionType> = (
  { open, data, handleOpen, index, showProductCardNumber = 4, ctaName = 'View order' },
  ref,
) => {
  const host = useAppSelector((store) => store.hostReducer.host);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { checked_out_time, status, id, store_name, products, uuid, productsCount } = data;

  const showGreenButton =
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.delivery_started ||
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.delivery_finished ||
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.ready_for_pickup ||
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.finished ||
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.with_review ||
    ORDER_STATUS_MAPPING[status] === ORDER_STATUS_MAPPING.finished_without_review;

  return (
    <AccordionCard data-testid="order-history-accordion" open={open} icon={<Icon open={open} />} ref={ref}>
      <AccordionHeaderCard onClick={() => handleOpen(index)}>
        <AccordionHeaderTitleCard>
          {checked_out_time && (
            <Typography variant="h4">{`${formatDate(new Date(checked_out_time), 'EEEE MM.dd.yy')}`}</Typography>
          )}
          <div>
            {showGreenButton ? (
              <Label variant="grey-200" className="cursor-pointer" icon={<CheckIcon className="font-bold" size={16} />}>
                {ORDER_STATUS_MAPPING[status]}
              </Label>
            ) : (
              <Label variant="white-border" className="cursor-pointer" icon={<TimeIcon size={16} />}>
                {ORDER_STATUS_MAPPING[status]}
              </Label>
            )}
          </div>
        </AccordionHeaderTitleCard>
      </AccordionHeaderCard>
      <AccordionBodyBox $open={open}>
        <AccordionBodyCard>
          <AccordionBodyOrderCard>
            <AccordionBodyOrderBox>
              <OrderItem icon={<AlertIcon size={16} className="!text-gray-400" />} title={`Order #${id}`} />
              <OrderItem icon={<LocationIcon size={16} className="!text-gray-400" />} title={store_name} />
              <OrderItem
                icon={<BagMediumIcon size={16} className="!text-gray-400" />}
                title={`${productsCount} items`}
              />
            </AccordionBodyOrderBox>

            <div>
              <Link href={`${host}/account/orders/${uuid}`}>
                <CommonButton
                  as="a"
                  href={`${host}/account/orders/${uuid}`}
                  size="sm"
                  color="green"
                  button-type="secondary"
                >
                  {ctaName}
                </CommonButton>
              </Link>
            </div>
          </AccordionBodyOrderCard>
          <ProductCardBox>
            {products?.slice(0, showProductCardNumber)?.map((item, number) => (
              <React.Fragment key={number}>
                <ProductCard
                  position={number}
                  variant="horizontal"
                  horizontalFooterSection={false}
                  horizontalShowBorder={false}
                  horizontalImageStyle="w-[104px] h-[94px]"
                  containersStyle={number === Number(showProductCardNumber - 1) ? 'h-[80px] overflow-hidden' : ''}
                  brandName={item?.product?.brand}
                  name={item?.product?.name}
                  type={item?.product?.brand_subtype}
                  image={
                    item?.photos?.[0]?.urls?.original ||
                    item?.image_urls?.[0] ||
                    getDefaultProductImageUrl(
                      item?.product?.kind === 'flower' ? item?.product?.kind : item?.product?.type,
                      item?.product?.category,
                      item?.product?.root_subtype,
                    )
                  }
                />
                {open && number === Number(showProductCardNumber - 1) && <FadeBox />}
              </React.Fragment>
            ))}
          </ProductCardBox>
        </AccordionBodyCard>
      </AccordionBodyBox>
    </AccordionCard>
  );
};

export default forwardRef(TransactionHistoryAccordion);
