import tw from 'tailwind-styled-components';
import BrandCard, { BrandCardProps } from 'components/molecules/cards/brand-card';

export const CarouselBrandsContainer = tw.div`
    flex
    flex-col
    bg-white
    py-6
    px-5
    items-center
    justify-center
    gap-2.5
    w-full
    md:py-10
    md:px-0
`;

export const CarouselBrandsInnerContainer = tw.div`
    flex
    flex-col
    justify-center
    items-center
    py-10
    gap-2.5
    w-full
    grow
    shrink-0
    basis-0
    bg-grey-250
    rounded-md

    md:max-w-[2200px]
    md:py-0
    md:px-10
    md:self-stretch
    md:bg-white
`;

export const CarouselBrandsFlexDirection = tw.div`
    flex
    flex-col
    items-start
    self-stretch
    gap-6

    md:bg-grey-250
    md:rounded-md
    md:py-[60px]
    md:px-0
    md:items-center
    md:justify-center
    md:gap-10

    2xl:py-[88px]
`;

export const CarouselBrandsContent = tw.div`
    flex
    flex-col
    items-start
    self-stretch
    px-5
    gap-6

    md:gap-6
    md:px-0
    md:min-w-[395px]
    md:items-start
    md:content-end
    md:grow
    md:shrink-0
    md:basis-0

    2xl:min-w-[650px]
`;

export const CarouselBrandsDesktopFlexContainer = tw.div`
    md:flex
    md:px-0
    md:min-w-[600px]
    md:items-end
    md:content-end
    md:gap-x-[60px]
    md:gap-y-5
    md:grow
    md:shrink-0
    md:basis-0
    md:flex-wrap

    2xl:min-w-[1048px]
    2xl:justify-between

`;

export const CarouselBrandsDesktopFlexContainerSpacer = tw.div`
    hidden
    md:flex
    md:min-w-10
    md:max-w-[152px]
    md:grow
    md:shrink-0
    md:basis-0
    md:self-stretch
`;

export const CarouselBrandsContentContainer = tw.div`
    md:flex
    md:items-end
    md:self-stretch
    md:justify-between
`;

export const CarouselBrandsSliderContainer = tw.div`
    w-full
    [&_div.slick-track]:flex
    [&_div.slick-track]:items-start
    [&_div.slick-track]:justify-start
    [&_div.slick-track]:gap-2.5
    [&_div.slick-list]:!pr-0

    [&_div[aria-hidden="true"]]:opacity-40

    md:[&_div.slick-track]:gap-5

    [&_div.slick-list]:pl-5
    lg:[&_div.slick-list]:pl-[152px]

    2xl:[&_div.slick-track]:gap-6
`;

export const CarouselBrandsMobileHidden = tw.div`
    hidden
    md:flex
`;

export const CarouselBrandsDesktopeHidden = tw.div`
    flex
    flex-col
    items-start
    justify-center
    px-5
    self-stretch
    gap-2.5
    [&>div]:min-w-[240px]
    [&>div]:max-w-[318px]
    [&_div[data-testid="progressbar-indicator"]]:!-ml-2
    [&>div_div[data-testid="progressbar-indicator"]]:!mr-0
    [@media_screen_and_(min-device-width:370px)]:[&_div[data-testid="progressbar-indicator"]]:!mx-9
    md:hidden
`;

export const CarouselBrandsCard = tw(BrandCard)<BrandCardProps>`
    !w-[29.69vw]
    [@media(min-width:1281px)]:max-w-[280px]
    [@media(min-width:385px)]:!w-[30vw]
    [@media(min-width:390px)]:!w-[33.335vw]
    [@media(min-width:390px)]:max-w-[130px]
    [@media(min-width:391px)]:max-w-[318px]
    [@media(min-width:468px)]:!w-[calc(40vw_+_11.703px)]
    [@media(min-width:768px)]:!w-[17.84vw]
    xl:!w-[calc((14.833vw)_+_0.39999px)]
    xl:h-[352px]
    [@media(min-width:2033px)]:max-w-[336px]
    md:[&_div[role="img"]]:!min-w-[65px]
    md:[&_div[role="img"]]:!w-[65px]

`;
