import tw from 'tailwind-styled-components';
type TCardHorizontalPositionProps = {
  $position: 'left' | 'right';
};
type TCardHorizontalBodyContainers = {
  $showBorder: boolean;
};
export const CardHorizontalContainers = tw.a`
    relative
    grid
    w-full
    grid-cols-3
    gap-6
    rounded-md
    border
    border-solid
    border-grey-300
    bg-white
    p-4
    lg:w-[732px]
    lg:p-6
`;

export const CardHorizontalBox = tw.div<TCardHorizontalPositionProps>`
    col-span-3
    lg:col-span-2
    grid
    items-center
    ${(props) => (props.$position === 'left' ? 'order-last' : 'order-first')}
`;

export const CardHorizontalImageBox = tw.div`
    relative
    overflow-hidden
    w-full
    h-full
`;

export const CardHorizontalBodyContainers = tw.div<TCardHorizontalBodyContainers>`
    grid
    grid-cols-3
    gap-4
    ${(props) => (props.$showBorder ? 'border-b border-solid border-grey-200 mb-3 pb-3 lg:pb-5' : '')}
`;
export const CardHorizontalBodyBox = tw.div<TCardHorizontalPositionProps>`
    ${(props) => (props.$position === 'left' ? 'order-last' : 'order-first')}
    col-span-2
    lg:col-span-3
    gap-3
    lg:gap-4
    items-center
    grid
`;

export const CardHorizontalFavoriteBox = tw.div<TCardHorizontalPositionProps>`
    absolute
    top-0
    flex
    m-0
    gap-[6px]
    ${(props) => (props.$position === 'left' ? 'left-0' : 'right-0 ')}
`;

export const CardHorizontalFooterBox = tw.div`
    flex
    flex-row
    items-center
    justify-end
`;

export const CardHorizontalPriceButtonBox = tw.div`
    flex
    flex-row
    items-center
    gap-4
    lg:gap-6
`;

export const CardHorizontalPriceBox = tw.div`
    flex
    flex-row
    items-center
`;

export const CardHorizontalImageWrapper = tw.div<TCardHorizontalPositionProps>`
   w-full
    flex
    ${(props) => (props.$position === 'left' ? 'justify-start' : 'justify-end')}
`;

export const CardHorizontalLableBox = tw.div`
    flex
    flex-row
    items-center
    gap-2
`;

export const CardHorizontalSummarySection = tw.div`
    md:flex
    md:flex-col
    md:items-end
    md:gap-[30px]
    md:self-stretch
    lg:gap-7
`;

export const CardHorizontalSummarySectionInner = tw.div`
    flex
    flex-col
    items-start
    gap-1
    self-stretch
`;

export const CardHorizontalSummaryTopDetail = tw.div`
    flex
    items-center
    justify-between
    self-stretch
`;

export const CardHorizontalSummaryBottomDetail = tw.div`
    flex
    items-center
    gap-1
    self-stretch
    md:gap-1.5
    lg:gap-2
`;

export const CardConfirmHorizontalBox = tw.div`
    flex 
    flex-col 
    justify-between 
    h-full
`;
export const CardConfirmHorizontalButtonBox = tw.div`
    flex 
    flex-row 
    gap-6
`;

export const CardConfirmHorizontalWeightsBox = tw.div`
    grid 
    grid-flow-row-dense 
    grid-cols-2 
    gap-2 
    pb-3 
    lg:pt-4 
    pt-[7px] 
    lg:pb-6
`;
