'use client';

import { FunctionComponent } from 'react';
import HeroArticleDetail from 'components/organisms/heros/hero-article-detail';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TBreadCrumbItem } from 'helpers/types';
import { tablet } from 'helpers/utils/screensizes';
interface HeroArticleDetailTasticsProps {
  pageFolder: {
    host: string;
    dataSourceConfigurations: any[];
  };
  data: Record<string, any>;
}

const HeroArticleDetailTastics: FunctionComponent = ({ data, pageFolder }: HeroArticleDetailTasticsProps) => {
  const dataObject = pageFolder?.dataSourceConfigurations.filter((config) => config.streamId === '__master');
  const dataParsed = dataObject[0].preloadedValue.data.pageBlogResourceCollection.items[0] || {};
  const hero = dataParsed?.heroSection || {};
  const img = hero?.image;
  const recipeLevel = dataParsed?.preparationDifficulty?.join('/');
  const receipeTime = dataParsed?.preparationTime;
  const [isTablet] = useMediaQuery(tablet);

  const breadcrumb: TBreadCrumbItem[] = data?.breadcrumbs?.map((breadcrumb) => {
    return {
      label: breadcrumb?.breadcrumbTitle,
      path: breadcrumb?.slug || '/',
    };
  });

  return (
    <HeroArticleDetail
      title={hero?.title}
      subTitle={hero?.subTitle}
      url={isTablet ? img?.url : hero?.mobileImage?.url || img?.url}
      height={img?.height}
      width={img?.width}
      date={dataParsed?.publishDate}
      category={dataParsed?.category || 'Resources'}
      estimateTime={dataParsed?.readTime}
      content={hero?.description}
      alt={isTablet ? img?.title : hero?.mobileImage?.title || img?.title}
      ariaLabel={isTablet ? img?.description : hero?.mobileImage?.description || img?.description}
      recipeTime={receipeTime}
      recipeLevel={recipeLevel}
      breadcrumbItems={breadcrumb}
    />
  );
};

export default HeroArticleDetailTastics;
