import axios from 'axios';
import { fetchApiHub } from 'frontastic';

interface accountOverviewGetUserInfoApiProps {
  stateCode: string;
  phoneNumber: string | number;
  email: string;
}

export const accountOverviewGetUserInfoApi = async ({
  stateCode,
  phoneNumber,
  email,
}: accountOverviewGetUserInfoApiProps) => {
  try {
    const url = process.env.NEXT_PUBLIC_LMS_HOST;
    return await axios.post(`${url}/get-profile`, {
      state: stateCode,
      phoneNumber: phoneNumber.toString().split('+1')[1],
      email,
    });
  } catch (e) {
    console.error(e);
    return { error: 400, data: {} };
  }
};

interface ICreateUserInJaneData {
  sub: string;
  birthdate: string;
  given_name: string;
  phone_number: string;
  family_name: string;
  email: string;
  'custom:current_dispensary': string;
  'custom:current_state': string;
}

export const createUserInJane = async (data: ICreateUserInJaneData, idToken) => {
  return await axios.post(
    process.env.NEXT_PUBLIC_AMS_HOST + '/jane',
    {
      data,
    },
    {
      headers: {
        'Content-Type': 'application/json',
        authorization: idToken,
      },
    },
  );
};

interface ICreateProfile {
  phoneNumber: string;
  firstName: string;
  dateOfBirth: string;
  lastName: string;
  email: string;
  state: string;
  dispensarySlug: string;
  recaptchaToken;
  dispensaryName: string;
  allowedEmail: boolean;
  allowedSms: boolean;
  hipaaAcknowledged: boolean;
  sub: string;
  anonymousId: string;
  createSource: any;
  stateName: string;
  geoDispensary: string;
  geoState: string;
}

export const createProfile = async (data: ICreateProfile, idToken: string) => {
  return await axios.post(
    process.env.NEXT_PUBLIC_AMS_HOST + '/create-profile',
    { data },
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: idToken,
      },
    },
  );
};

interface IGetHomeDispensaryDataFromCmsReq {
  dispensarySlug: string;
  state: string;
  host: string;
}
export const getHomeDispensaryDataFromCms = async (reqObj: IGetHomeDispensaryDataFromCmsReq) => {
  try {
    const res = await fetchApiHub(`/action/account/homeDispensary`, { method: 'POST', body: JSON.stringify(reqObj) });
    return res?.dataSourcePayload?.data?.page?.items[0];
  } catch (err) {
    return { error: 400, data: {} };
  }
};
