import tw from 'tailwind-styled-components';

const DealCardContainerVerticalMdStyles = `
  h-[412px]
  w-[350px]
  md:w-[400px]
  md:h-[564px]
  md:max-w-[568px]
  md:items-start
  md:justify-between

  lg:w-[323px]
  lg:min-w-[432px]
  lg:max-w-[496px]
  lg:items-center
`;

interface DealCardStylesProps {
  $size: 'sm' | 'md';
  $variant: 'horizontal' | 'vertical';
}

export const DealCardContainer = tw.div<DealCardStylesProps>`
    flex
    items-center
    relative
    border
    rounded-md
    shrink-0
    bg-kief
    ${(p) =>
      p.$variant === 'vertical'
        ? // vertical styles
          `flex-col ` + (p.$size === 'sm' ? 'h-[400px] w-[260px]' : DealCardContainerVerticalMdStyles)
        : // horizontal styles
          'flex-row-reverse w-[350px] h-[192px]'}
`;

const DealCardContentVerticalMdStyles = `
      md:h-[184px]
      md:py-10
      md:px-[30px]
`;

export const DealCardsContentSection = tw.div<DealCardStylesProps>`
    flex
    flex-col
    justify-center
    items-start
    bg-kief
    shrink-0
    self-stretch

    py-6
    px-4
    gap-2.5
    ${(p) => (p.$variant === 'vertical' ? (p.$size === 'sm' ? '' : DealCardContentVerticalMdStyles) : '')}
`;
