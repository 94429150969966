import tw from 'tailwind-styled-components';

export const LegalDataContianerMobile = tw.div`
    pb-8
    scroll-heading
    px-5
`;

type TTocProps = {
  $margin?: boolean;
};

export const WhereIsCannabisLegalBodyWrapper = tw.div<TTocProps>`
    px-5 
    pb-[80px] 
    lg:px-0
    ${(p) => (p.$margin ? '  xl:pl-[177px]  2xl:pl-[176px]' : 'ml-[340px] mx-auto max-w-[1920px]')}
`;

export const TOCStickyClass = tw.div`
    lg:top-[180px] 
`;

export const TOCFixedStyles = `
    md:sticky 
    lg:mt-[-700px] 
    md:top-[170px] 
    2xl:top-40 
`;

export const TopMarginContent = `
    xl:pb-5 
    2xl:pb-6
`;

export const LegalDataContianerDesktop = tw.div`
    xl:pb-[120px] 
    2xl:pb-[200px]
    hidden
    lg:block
    scroll-heading
`;

export const WhereIsCannabisLegalBodyContainer = `
    [&_p]:pb-12 
    [&_p]:2xl:pb-[80px]
`;

export const LegalDataWrapperMobile = tw.div`
    block
    lg:hidden
`;
