'use client';

import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import Breadcrumb from 'components/atoms/navigation/breadcrumb/breadcrumb';
import { Typography } from 'components/atoms/typography';
import { CenteredCarousel } from 'components/molecules/carousels/centered-carousel';
import {
  BannerBox,
  BreadCrumbContainer,
  ButtonContainer,
  CarouselContainer,
  ContentContainer,
  TextContainer,
} from 'components/organisms/heros/hero-dispensary/styles';
import EventsReservationModal from 'components/organisms/popups/reservation-modal/events-reservation-modal';
import MakeReservationModal from 'components/organisms/popups/reservation-modal/make-reservation-modal';
import SmokeasyModal from 'components/organisms/popups/reservation-modal/reservation-modal';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { PopUpType, TBreadCrumbItem } from 'helpers/types';
import { getMundeleinMenuBtns } from 'helpers/utils/convert-to-dispensary-page-type';
import { tablet } from 'helpers/utils/screensizes';
import { setOpenLoungeModal } from 'redux/reducers/lounge-popup-slice';
import { RootState } from 'redux/store';

type TProps = {
  buttons: Record<string, any>[];
  images: Record<string, any>[];
  heading: string;
  description: string;
  host: string;
  breadcrumbs: any[];
  tasticPlacementNumber: number;
  internalName: string;
  popUpCta: any[];
  slug: string;
};

export const HeroDispensary = ({
  buttons,
  images,
  heading,
  description,
  host,
  breadcrumbs,
  tasticPlacementNumber,
  internalName,
  popUpCta,
  slug,
}: TProps) => {
  const dispatch = useDispatch();
  const openLoungeModalFromRedux = useSelector((state: RootState) => state.loungePopup.loungePopup);
  const handleBackClick = (e) => {
    e.preventDefault();
    dispatch(
      setOpenLoungeModal({
        smokeasy: true,
        eventCalender: false,
        reservation: false,
      }),
    );
  };
  const openLoungePopUp = (popUpType: PopUpType) => {
    dispatch(
      setOpenLoungeModal({
        eventCalender: popUpType === PopUpType.EVENT_LIST ? true : false,
        reservation: popUpType === PopUpType.RESERVATION ? true : false,
        smokeasy: popUpType === PopUpType.SMOKEY ? true : false,
      }),
    );
  };

  const handleClose = () => {
    dispatch(
      setOpenLoungeModal({
        reservation: false,
        eventCalender: false,
      }),
    );
  };

  const clickHandler = (text) => {
    const type = text.toLowerCase().includes('reservation')
      ? PopUpType.RESERVATION
      : text.toLowerCase().includes('event')
      ? PopUpType.EVENT_LIST
      : null;
    openLoungePopUp(type as PopUpType);
  };

  const [isTablet] = useMediaQuery(tablet);
  const [currentImageSlider, setCurrentImageSlider] = useState(0);
  const breadcrumb: TBreadCrumbItem[] = breadcrumbs?.map((breadcrumb) => {
    return {
      label: breadcrumb.breadcrumbTitle,
      path: breadcrumb.slug,
    };
  });
  const handleButtonClick = (e, popUpType: PopUpType) => {
    if (!popUpType) return;
    e?.preventDefault();
    openLoungePopUp(popUpType);
  };

  const imageArray =
    Array.isArray(images) &&
    images?.map((image) => {
      return !isTablet && image.mobileImage ? image.mobileImage : image.desktopImage ? image.desktopImage : '';
    });

  const handleSegmentEvent = ({ type, buttonText, index, options }: any) => {
    const bannerClickedData = {
      placement: tasticPlacementNumber + 1,
      banner_position: index + 1,
      banner_internal_name: internalName,
      banner_title: heading,
      banner_image_main: imageArray[index]?.url,
      banner_type: 'other',
      link_name: buttonText || '',
      link_type: 'hyperlink',
      ...options,
    };
    bannerTrack(type, bannerClickedData);
  };
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent({ type: SegmentEvents.BANNER_VIEWED, index: currentImageSlider });
  });
  const handleImageSlider = (page: number) => {
    handleSegmentEvent({
      type: SegmentEvents.BANNER_SCROLLED,
      index: page,
      options: { banner_scroll_direction: currentImageSlider > page ? 'left' : 'right' },
    });
    setCurrentImageSlider(page);
  };
  if (slug?.includes('mundelein-lounge')) {
    popUpCta[2].btns = getMundeleinMenuBtns(popUpCta);
  }

  return (
    <>
      <>
        {openLoungeModalFromRedux?.smokeasy && popUpCta?.[2] && (
          <SmokeasyModal
            smokeEasyData={popUpCta?.[2]}
            handleClose={handleClose}
            handleButtonClick={handleButtonClick}
          />
        )}
        {openLoungeModalFromRedux?.reservation && popUpCta?.[0] && (
          <MakeReservationModal
            reservationModalData={popUpCta[0]}
            handleClose={handleClose}
            handleBackClick={handleBackClick}
          />
        )}
        {openLoungeModalFromRedux?.eventCalender && popUpCta?.[1] && (
          <EventsReservationModal
            eventCalendarModalData={popUpCta[1]}
            handleClose={handleClose}
            handleBackClick={handleBackClick}
          />
        )}
      </>
      <BannerBox ref={targetRef}>
        <BreadCrumbContainer>
          <Breadcrumb items={breadcrumb || []} />
        </BreadCrumbContainer>
        <ContentContainer>
          {isTablet && (
            <CarouselContainer>
              <CenteredCarousel
                images={imageArray || []}
                paginationColor="resin"
                containerHeight={`${Math.floor((608 * imageArray?.[0]?.height) / imageArray?.[0]?.width)}px`}
                mobilePaginationVariant="center-carousel"
                handleCurrentPage={handleImageSlider}
              />
            </CarouselContainer>
          )}
          <TextContainer>
            <Typography variant="h2" className="sm:mb-4 sm:text-center md:mb-8 md:text-left" as={'h1'}>
              {heading}
            </Typography>
            <Typography
              data-testid="description"
              variant="body-large"
              className="!text-grey-400 sm:text-center md:text-left"
            >
              {description}
            </Typography>
            <ButtonContainer>
              {buttons.map((button, index) => {
                return (
                  <CommonButton
                    key={index}
                    href={`${button?.linkedFrom?.pageMenuCollection?.items[0] ? host : ''}${
                      button?.linkedFrom?.pageMenuCollection?.items[0]?.slug || button?.menuSourceUrl
                    }`}
                    color="green"
                    button-type="primary"
                    size="lg"
                    target={button.menuSource === 'external' ? '_blank' : '_self'}
                    className={`${index === 0 && buttons.length > 1 ? 'mr-3' : null} whitespace-nowrap`}
                    as={
                      button?.linkedFrom?.pageMenuCollection?.items[0]?.slug ||
                      !button?.menuSourceUrl?.includes('popup')
                        ? 'a'
                        : 'button'
                    }
                    data-testid="menuButton"
                    onClick={() => {
                      button?.linkedFrom?.pageMenuCollection?.items[0]?.slug
                        ? handleSegmentEvent({
                            type: SegmentEvents.BANNER_CLICKED,
                            buttonText: button.title,
                            index: currentImageSlider,
                          })
                        : clickHandler(button?.title);
                    }}
                  >
                    {button?.title}
                  </CommonButton>
                );
              })}
            </ButtonContainer>
          </TextContainer>
          {!isTablet && (
            <CarouselContainer>
              <CenteredCarousel
                images={imageArray || []}
                paginationColor="resin"
                containerHeight={`${Math.floor((294 * imageArray?.[0]?.height) / imageArray?.[0]?.width)}px`}
                mobilePaginationVariant="center-carousel"
                handleCurrentPage={handleImageSlider}
              />
            </CarouselContainer>
          )}
        </ContentContainer>
      </BannerBox>
    </>
  );
};
