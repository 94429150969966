import React, { useState } from 'react';
import { Accordion, AccordionBody, AccordionHeader } from '@material-tailwind/react/components/Accordion';
import { Typography } from 'components/atoms/typography';
import AlertIcon from 'public/icons/figma/alert.svg';
import ChevronDownIcon from 'public/icons/figma/chevron-down.svg';
import ChevronUpIcon from 'public/icons/figma/chevron-up.svg';
import DispensaryInformationCard from '../dispensary-information-card';
import { DispensaryInformationMobileHeaderBox, DispensaryInformationMobileHeaderWapper } from './styles';

type TProps = {
  title?: string;
  openHours: string;
  phoneNumber: string;
  phoneLabel?: string;
  address: string;
  addressLabel?: string;
  addressUrl: string;
  handleSegmentEvent?: (type?: string, linkName?: string) => void;
};

const DispensaryInformationMobile = (props: TProps) => {
  const [open, setOpen] = useState(false);
  return (
    <Accordion open={open}>
      <AccordionHeader onClick={() => setOpen(!open)} className={`${open ? 'pb-3' : 'pb-6'} border-0 pt-0`}>
        <DispensaryInformationMobileHeaderWapper>
          <AlertIcon className="!text-leaf" />
          <DispensaryInformationMobileHeaderBox>
            <Typography variant="body-small">{props?.title}</Typography>
            {open ? (
              <ChevronUpIcon data-testid="internal-icon" size={'0.8em'} className="!text-leaf" />
            ) : (
              <ChevronDownIcon data-testid="external-icon" size={'0.8em'} className="!text-leaf" />
            )}
          </DispensaryInformationMobileHeaderBox>
        </DispensaryInformationMobileHeaderWapper>
      </AccordionHeader>
      <AccordionBody className={`${open ? 'border-t border-grey-250 pb-6 pt-3' : ''}`}>
        <DispensaryInformationCard {...props} />
      </AccordionBody>
    </Accordion>
  );
};

export default DispensaryInformationMobile;
