import tw from 'tailwind-styled-components';

export const RewardsMatrixContainer = tw.div`
    flex
    flex-col
    md:flex-row
    w-full
    h-full
`;

export const RewardsMatrixBannerImageContainer = tw.div`
    h-2/3
    md:h-auto
    md:w-2/3
`;
