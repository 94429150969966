import tw from 'tailwind-styled-components';
export const MapCardContainer = tw.div`
    pt-6
    px-8
    pb-8
`;

export const Item = tw.div`
  flex
  items-center
  gap-3
  md:gap-5
  lg:gap-6
  [&_p]:!text-grey-400
  [&_svg]:!text-grey-400
  [&_svg]:w-6
  [&_svg]:h-6
  mb-3
`;

export const ContactUsItem = tw.div`
  flex
  items-center
  [&_svg]:mr-3
  [&_svg]:lg:mr-6
  [&_p]:!text-leaf
  [&_svg]:!text-grey-400
  [&_svg]:w-6
  [&_svg]:h-6
`;

export const HeadingContainer = tw.div`
    flex
`;
