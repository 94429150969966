import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionProps,
} from '@material-tailwind/react/components/Accordion';
import tw from 'tailwind-styled-components';
import { IAccordionHeaderComponent } from 'helpers/types/molecules/accordions/accordions';
import ChevronDownIcon from 'public/icons/figma/chevron-down.svg';
import ChevronUpIcon from 'public/icons/figma/chevron-up.svg';

export const AccordionComponent: React.FC<AccordionProps> = tw(Accordion)`
  !w-auto
  lg:w-[676px]
  border-b
  border-b-grey-300
  hover:border-b-grey-480
  pt-[30px]
  lg:pt-10
  pb-[30px]
  lg:pb-10
  lg:mr-[38px]
`;

export const SelectedIconBox = tw.div`
  flex
  justify-center
  items-center
  w-6
  lg:w-10
  h-6
  lg:h-10
  lg:p-2
  rounded-[20px]
  mr-3
  lg:mr-4
  bg-kief
`;

export const AccordionHeaderComponent: React.FC<IAccordionHeaderComponent> = tw(AccordionHeader)`
  border-none
  !py-0
  [&_span_svg]:w-6
  [&_span_svg]:h-6
  group
  ${(p: IAccordionHeaderComponent) => (p.$active ? '[&_h5]:text-leaf' : '[&_h5]:text-grey-400')}

`;

export const AccordionHeaderContainer = tw.div`
  flex
  items-center
`;

export const AccordionIcons = tw.div`
  h-6
  w-6
  transition-transform
`;

export const AccordionBodyComponent = tw(AccordionBody)`
  !py-0
`;

export const AccordionBodyContainer = tw.div`
  lg:flex
  lg:gap-6
  pt-5
  lg:pt-6
`;

export const DispensaryDetailContainer = tw.div`
  flex
  flex-col
  gap-5
  lg:gap-6
  lg:w-[464px]
`;

export const TagsContainer = tw.div`
  flex
  items-center
  gap-3
`;

export const Tag = tw.div`
  py-1.5
  lg:py-2
  px-2
  lg:px-3
  rounded-md
  bg-grey-200
`;

export const DetailContainer = tw.div`
  flex
  flex-col
  gap-3
  lg:ml-2
  lg:gap-4
`;

export const Item = tw.div`
  flex
  items-center
  gap-3
  lg:gap-6
  [&_p]:!text-grey-400
  [&_svg]:!text-grey-400
  [&_svg]:w-6
  [&_svg]:h-6
`;

export const ContactUsItem = tw.div`
  flex
  items-center
  gap-3
  lg:gap-6
  [&_p]:!text-leaf
  [&_svg]:w-6
  [&_svg]:h-6
`;

export const ContactUsIconContainer = tw.span`
  ml-1.5
  lg:ml-2
  [&_svg]:!text-leaf
`;

export const ImageContainer = tw.div`
  sm:hidden
  lg:block
  relative
  w-[188px]
  h-[128px]
  shrink-0
`;

export const CaretDownIcon = tw(ChevronDownIcon)<{ $verticalAlignBottom: boolean }>`
  text-secondary-100
  sm:text-[20px]
  md:text-[27px]
  ${(props) => (props.$verticalAlignBottom ? 'self-end	md:mb-[-3px]' : '')}
`;

export const CaretUpIcon = tw(ChevronUpIcon)<{ $verticalAlignBottom: boolean }>`
  text-secondary-100
  sm:text-[20px]
  md:text-[27px]
  ${(props) => (props.$verticalAlignBottom ? 'self-end	md:mb-[-3px]' : '')}
`;

export const MetaContent = tw.div`
  ml-9
  lg:ml-12
`;

export const TimingItem = tw.div`
  flex
  mb-4
`;

export const StoreTimingRow = tw.div<IHomeDispensaryProps>`
  flex
  [&_p]:!text-grey-400
  [&_svg]:!text-grey-400
   ${(props) =>
     props.$isHomeDispensary ? '[&_svg]:!extrabold max-h-4 md:max-h-6' : 'items-center [&_svg]:w-6 [&_svg]:h-6'}
    
`;

export const TimingHourContainer = tw.div<{ $color?: string }>`
  flex
  flex-col
  ${(props) => props.$color && props.$color}
  pl-6
`;

export const TimingDay = tw.div`
  w-24
`;

interface IHomeDispensaryProps {
  $isHomeDispensary: boolean;
  $isDispensaryTiming?: boolean;
}
export const StoreTimingForToday = tw.span<IHomeDispensaryProps>`
	 ${(props) => (props.$isHomeDispensary ? 'md:-ml-3 h-4 md:h-6' : 'whitespace-pre')}
   
`;
