import React, { useState } from 'react';
import { Typography } from 'components/atoms/typography';
import { TProductDescriptionAccordion } from 'helpers/types/molecules/accordions/product-description-accordion';
import MinusIcon from 'public/icons/figma/minus.svg';
import PlusIcon from 'public/icons/figma/plus.svg';
import { AccordionBodyComponent, AccordionComponent, AccordionHeaderComponent } from './styles';

export const ProductDetailsAccordionComponent = ({ description, index }: TProductDescriptionAccordion) => {
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(0);
  const handleAccordionClick = (index: number) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const AccordionToggleIcon = ({ open }: { open: boolean }) => {
    return open ? <MinusIcon /> : <PlusIcon />;
  };

  return (
    <AccordionComponent
      open={openAccordionIndex === index}
      icon={<AccordionToggleIcon open={openAccordionIndex === index} />}
    >
      <AccordionHeaderComponent $active={openAccordionIndex === index} onClick={() => handleAccordionClick(index)}>
        <Typography variant="h5" className="!text-grey-500">
          Description
        </Typography>
      </AccordionHeaderComponent>
      <AccordionBodyComponent>
        <Typography variant="body-large" className="!text-grey-400" data-testid="product-details-accordion-description">
          {description &&
            description?.split(/\n/).map((line, index) => (
              <React.Fragment key={index}>
                {line}
                {index !== description.split(/\n/).length - 1 && <br />}
              </React.Fragment>
            ))}
        </Typography>
      </AccordionBodyComponent>
    </AccordionComponent>
  );
};
