'use client';

import React from 'react';
import BannerInformation from 'components/organisms/banners/banner-information';
import { bannerTrack } from 'helpers/analytics/banner-track';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { CaseVariants, VariantType } from 'helpers/types';
import { tablet } from 'helpers/utils/screensizes';

type TTasticsProps = {
  pageFolder: any;
  data: any;
};

const PrimaryDealTastics = ({ data, pageFolder }: TTasticsProps) => {
  let variantContent;
  const [isTablet] = useMediaQuery(tablet);
  const parsed =
    pageFolder?.dataSourceConfigurations.find((item) => item?.streamId === '__master')?.preloadedValue?.data
      ?.pageStateDealsCollection?.items[0] || {};

  if (!parsed) {
    return <React.Fragment />;
  }

  const bannerData = parsed?.primaryDealsCollection?.items?.filter((d) => Boolean(d))[0];

  if (!bannerData) return <></>;

  const handleSegmentEvent = (type?: string) => {
    const bannerClickedData = {
      placement: data?.tasticPlacementNumber + 1,
      banner_position: 1,
      banner_internal_name: bannerData?.internalName,
      banner_title: bannerData?.title,
      banner_image_main: isTablet ? bannerData?.image?.url : bannerData?.mobileImage?.url || bannerData?.image?.url,
      banner_image_additional: isTablet
        ? bannerData?.additionalImage?.desktopImage?.url
        : bannerData?.additionalImage?.mobileImage?.url || bannerData?.additionalImage?.desktopImage?.url,
      banner_image_overlay: isTablet
        ? bannerData?.additionalImage?.desktopImage?.url
        : bannerData?.additionalImage?.mobileImage?.url || bannerData?.additionalImage?.desktopImage?.url,
      banner_type: bannerData?.bannerType,
      link_name: bannerData?.ctaCollection?.items[0]?.title || '',
      link_type: 'hyperlink',
    };
    bannerTrack(type, bannerClickedData);
  };

  const commonBannerData = {
    title: bannerData.title,
    description: bannerData.description,
    subTitle: bannerData?.subTitle,
    image: {
      desktopImage: {
        ...bannerData.image,
      },
      mobileImage: { ...bannerData?.mobileImage },
    },
    additionalImage: bannerData.additionalImage,
    overlayImage: bannerData.overlayImage,
    ctaCollection: bannerData.ctaCollection.items[0],
    host: pageFolder?.host,
    video: bannerData?.videoEmbed,
    handleSegmentEvent: (type?: string) => handleSegmentEvent(type),
  };

  switch (bannerData?.bannerType) {
    case CaseVariants.BANNER_C11_A_DEALS:
      variantContent = <BannerInformation variant={VariantType.DEALS} {...commonBannerData} />;
      break;
    case CaseVariants.BANNER_C11_B_SPECIAL_OFFER:
      variantContent = <BannerInformation variant={VariantType.SPECIAL_OFFER} {...commonBannerData} />;
      break;
    case CaseVariants.BANNER_C11_C_REWARDS:
      variantContent = <BannerInformation variant={VariantType.REWARDS} {...commonBannerData} />;
      break;
    case CaseVariants.BANNER_C11_D_TEXT_AREA:
      variantContent = <BannerInformation variant={VariantType.TEXT_AREA} {...commonBannerData} />;
      break;
    case CaseVariants.BANNER_C11_E_VIDEO:
      variantContent = <BannerInformation variant={VariantType.VIDEO} {...commonBannerData} />;
      break;
    case 'Other':
      variantContent = <></>;
  }

  return <React.Fragment>{variantContent}</React.Fragment>;
};

export default PrimaryDealTastics;
