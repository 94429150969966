'use client';

import { ReplaceUrlWithHost } from 'helpers/utils/common';
import { SiteMapContainer, SiteMapHtmlContent } from './styles';

interface SiteMapInnerHtmlProps {
  content: string;
  host: string;
}

const SiteMapContent = ({ content = '', host }: SiteMapInnerHtmlProps) => {
  const __html = host ? ReplaceUrlWithHost(content, host, true) : content;
  return (
    <SiteMapContainer>
      <SiteMapHtmlContent data-testid="site-map-content" dangerouslySetInnerHTML={{ __html }} />
    </SiteMapContainer>
  );
};

export default SiteMapContent;
