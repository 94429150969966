'use client';

import Image from 'next/image';
import Tooltip from 'components/atoms/overlay/tooltip/tooltip';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { THowItWorksProps } from 'helpers/types/organisms/rewards/how-it-works';
import { desktop } from 'helpers/utils/screensizes';
import {
  HowItWorksContainer,
  HowItWorksDescriptionContainer,
  HowItWorksImageContainer,
  HowItWorksInnerContainer,
  HowItWorksStepContainer,
  HowItWorksStepTextContainer,
  HowItWorksStepTitleContainer,
  HowItWorksStepsContainer,
} from './styles';

export const HowItWorksComponent = ({ title, entries }: THowItWorksProps) => {
  const [isDesktop] = useMediaQuery(desktop);
  return (
    <HowItWorksContainer>
      <HowItWorksInnerContainer>
        <Typography variant="h3" as="h2" data-testid="how_it_works_title">
          {title}
        </Typography>
        <HowItWorksStepsContainer>
          {entries.map(({ image, tooltip, description, heading }, index) => {
            return (
              <HowItWorksStepContainer key={index} data-testid={`how_it_works_step_${index}_container`}>
                <HowItWorksImageContainer data-testid={`how_it_works_step_${index}_image`}>
                  <Image
                    src={isDesktop ? image.desktopImage.url : image.mobileImage.url}
                    width={150}
                    height={160}
                    alt={isDesktop ? image.desktopImage.title : image.mobileImage.title}
                    loader={(options) => options.src}
                  />
                </HowItWorksImageContainer>
                <HowItWorksStepTextContainer>
                  <HowItWorksStepTitleContainer>
                    <Typography variant="h4" as="h3" data-testid={`how_it_works_step_${index}_heading`}>
                      {heading}
                    </Typography>
                    {tooltip && (
                      <Tooltip
                        data={{
                          heading: !isDesktop ? (tooltip.title as string) : undefined,
                          hideCloseIcon: isDesktop,
                          description: tooltip.description,
                        }}
                        placement={'top'}
                      />
                    )}
                  </HowItWorksStepTitleContainer>
                  <HowItWorksDescriptionContainer>
                    <ContentfulRichText data={description} />
                  </HowItWorksDescriptionContainer>
                </HowItWorksStepTextContainer>
              </HowItWorksStepContainer>
            );
          })}
        </HowItWorksStepsContainer>
      </HowItWorksInnerContainer>
    </HowItWorksContainer>
  );
};
