import tw from 'tailwind-styled-components';

export const DispensaryInformationMobileHeaderWapper = tw.div`
    flex 
    items-center 
    flex-row 
    gap-3
`;

export const DispensaryInformationMobileHeaderBox = tw.div`
    flex 
    items-center 
    gap-1
`;
