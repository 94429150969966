import { IDealClicked, SegementTrackEvent } from './segment-helper';

export const dealClicked = (type, value) => {
  try {
    const data: IDealClicked = {
      page_location: typeof window !== 'undefined' && window.location.href,
      ...value,
    };
    return SegementTrackEvent(type, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
