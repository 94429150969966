'use client';

import { FunctionComponent } from 'react';
import HeroArticle from 'components/organisms/heros/hero-article';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TBreadCrumbItem } from 'helpers/types';
import { tablet } from 'helpers/utils/screensizes';

const HeroArticleTastics: FunctionComponent = ({ data }: any) => {
  const { heroBannerCollection, heroSection } = data?.heroArticle?.dataSource?.data?.page?.items?.[0] || {};
  const [isTablet] = useMediaQuery(tablet);

  const { listItemDescription, listItemImages, listItemTitle, publishDate, slug, category } =
    heroBannerCollection?.items?.[0] || {};

  const image = isTablet ? listItemImages.desktopImage : listItemImages.mobileImage || listItemImages.desktopImage;

  const breadcrumbs: TBreadCrumbItem[] = data?.breadcrumbs?.map((breadcrumb) => {
    return {
      label: breadcrumb?.breadcrumbTitle,
      path: breadcrumb?.slug,
    };
  });
  return (
    <HeroArticle
      title={heroSection?.title}
      subTitle={heroSection?.description}
      url={image?.url}
      date={publishDate}
      category={category}
      contentTitle={listItemTitle}
      content={listItemDescription}
      alt={image?.title}
      ctaLink={slug}
      breadcrumbItems={breadcrumbs}
      tasticPlacementNumber={data?.tasticPlacementNumber}
      internalName={heroSection?.internalName}
    />
  );
};

export default HeroArticleTastics;
