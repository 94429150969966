'use client';

import React, { useEffect, useRef, useState } from 'react';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import Terpenes from 'components/molecules/terpenes';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage, IRichTextData } from 'helpers/types';
import { tablet } from 'helpers/utils/screensizes';
import {
  ButtonContainer,
  MainContainer,
  ProductList,
  ProductListContainer,
  ProductListItems,
  TitleContainer,
} from './styles';

interface IProductItems {
  title: string;
  subTitle: {
    json: any;
  };
  percentage: string;
  logo: IContentfulImage;
  tooltip: {
    title: string;
    description: IRichTextData;
    image: IContentfulImage;
    ctaCollection: {
      items: {
        title: string;
        url: string;
        longUrl: string;
      }[];
    };
  };
}

interface ProductListProps {
  productItems: IProductItems[];
  tasticPlacementNumber: number;
  title?: string;
  description?: any;
}

const TerpenesList = ({ productItems, tasticPlacementNumber, title, description }: ProductListProps) => {
  const totalItems = productItems.length;
  const [displayCount, setDisplayCount] = useState(totalItems);
  const [isDesktop] = useMediaQuery(tablet);
  const [openTooltip, setOpenTooltip] = useState<number | null>(null);
  const topRef = useRef(null);
  const productListToShow = productItems.slice(0, displayCount);

  useEffect(() => {
    setDisplayCount(isDesktop ? totalItems : 6);
  }, [isDesktop]);

  const handleTooltipClick = (index: number) => {
    setOpenTooltip(index);
  };

  const targetRef = useIntersectionObserver(() => {
    const bannerClickedData = {
      placement: tasticPlacementNumber + 1,
      banner_position: 1,
      banner_internal_name: 'Terpenes profile',
      banner_title: 'Terpenes profile',
      banner_image_main: '',
      banner_type: 'Other',
      link_name: '',
      link_type: '',
    };
    bannerTrack(SegmentEvents.BANNER_VIEWED, bannerClickedData);
  });

  const showButtonText =
    totalItems > 6 ? (displayCount === totalItems ? `Show Less` : `Show More (${totalItems - displayCount})`) : '';

  if (totalItems < 0) return null;

  return (
    <MainContainer ref={targetRef}>
      <TitleContainer ref={topRef}>
        {title && (
          <Typography variant="h3" className="lg:w-[280px]">
            {title}
          </Typography>
        )}
        {description && (
          <div className="mt-4 lg:w-[280px]">
            <ContentfulRichText data={description} />
          </div>
        )}
      </TitleContainer>
      <ProductListContainer>
        <ProductListItems>
          {totalItems > 0 &&
            productListToShow.map((item, index) => {
              return (
                <ProductList key={index} data-testid="terpene-item">
                  <Terpenes
                    name={item.title || ''}
                    title={item?.subTitle || null}
                    className="mb-4"
                    percentage={item.percentage || ''}
                    logo={item.logo || {}}
                    tooltip={item.tooltip}
                    terpenIndex={index}
                    openTooltip={openTooltip as number}
                    handleOpen={handleTooltipClick}
                  />
                </ProductList>
              );
            })}
        </ProductListItems>
      </ProductListContainer>
      {!isDesktop && totalItems > 6 && (
        <ButtonContainer>
          <Typography
            variant="small"
            className="pb-4 !text-grey-400"
          >{`Showing ${displayCount} of ${totalItems}`}</Typography>
          <CommonButton
            color="green"
            button-type="secondary"
            className="w-fit"
            onClick={() => {
              setDisplayCount(displayCount === totalItems ? 6 : totalItems);
              if (displayCount === totalItems) {
                // @ts-ignore
                topRef.current.scrollIntoView();
              }
            }}
          >
            {showButtonText}
          </CommonButton>
        </ButtonContainer>
      )}
      {/* <LinkContainer>
        <IconWithTextButton
          className="!items-start !p-0"
          button-type="tertiary"
          icon={<DownloadIcon />}
          iconPosition="before"
          color="green"
        >
          Certification of analysis(PDF, 1.5MB)
        </IconWithTextButton>
      </LinkContainer> */}
    </MainContainer>
  );
};

export default TerpenesList;
