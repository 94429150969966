'use client';

interface DeliveryZoneMapAddPolygonProps {
  map: any;
  geoJsons: any[];
  matches: string;
  bounds: any;
}

export const deliveryZoneMapAddPolygon = ({ map, geoJsons, matches, bounds }: DeliveryZoneMapAddPolygonProps) => {
  geoJsons.forEach((geolocation) => {
    map.data.addGeoJson(geolocation);
  });

  const deliveryZoneStyle = {
    fillColor: '#00461e',
    strokeWeight: 1,
    strokeColor: '#C4D600',
    fillOpacity: 0.5,
  };

  map.data.setStyle(deliveryZoneStyle);
  map.data.forEach((feature) => {
    const dispensaryName = feature?.getProperty('storeName').toLowerCase();
    if (dispensaryName?.includes(matches)) {
      feature.getGeometry().forEachLatLng((latlng) => {
        bounds.extend(latlng);
      });
    }
  });

  map.fitBounds(bounds);
  map.data.addListener('click', (event) => {
    // TODO: Implement routing
    // router.push(event.feature?.getProperty('link'));
  });

  const hoverStyle = {
    fillColor: '#C4D600',
    strokeWeight: 1,
    strokeColor: '#CCCC00',
    fillOpacity: 0.5,
  };

  map.data.addListener('mouseover', (event) => {
    const currentId = event?.feature?.getId?.();
    const currentStoreName = event?.feature?.getProperty('storeName');
    const currentZone = currentStoreName + currentId;
    map.data.setStyle((options) => {
      const zone = options?.h?.storeName + options?.getId?.();
      return zone === currentZone ? hoverStyle : deliveryZoneStyle;
    });
  });

  map.data.addListener('mouseout', () => map.data.setStyle(deliveryZoneStyle));
};

export const deliveryZoneNormalizeName = (url = '') => url?.toLowerCase()?.replace('/', '');

export const findMenuLinkStoreName = (link = '') => link.split('/')?.[3] || '';

export const getMainDeliveryZone = (data: any[] = [], uniqueObj: any) => {
  data?.forEach((deliveryZone: any) => {
    const geoJson = deliveryZone?.geoJsons?.items.reduce((obj, { geoJson }: any) => {
      if (obj?.features?.[0]?.geometry?.coordinates?.[0]?.length) {
        const coordinates = geoJson?.features?.[0]?.geometry?.coordinates?.[0] || [];
        obj.features[0].geometry.coordinates?.push?.(coordinates);
      } else {
        obj = geoJson;
      }
      return obj;
    }, {});
    const id = deliveryZone?.sys?.id;
    const link = deliveryZone?.defaultMenu?.linkedFrom?.pageMenuCollection?.items?.[0]?.slug;
    const storeName = findMenuLinkStoreName(link);
    if (link) {
      if (geoJson?.features?.[0]?.properties) {
        geoJson.features[0].properties = {
          ...geoJson.features[0].properties,
          link,
          storeName,
        };
        uniqueObj[id] = geoJson;
      }
    }
  });
};
