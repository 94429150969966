import tw from 'tailwind-styled-components';

export const DispensaryReviewsContainer = tw.div`
    px-5
    md:px-[60px]
    md:py-[160px]
    py-[42px]
    block
    md:flex
    gap-x-[106px]
    md:mr-6
    mr-[10px]
    overflow-x-hidden
`;

export const TitlePaginationContainer = tw.div`
    max-w-[350px]
`;

export const CarouselContainer = tw.div`
    flex
    overflow-hidden
    mb-6
    md:mb-0
`;
