'use client';

import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'next/navigation';
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { Typography } from 'components/atoms/typography';
import MapCard from 'components/molecules/cards/map-card';
import { LegendContainer, LegendIndicator } from 'components/organisms/listings/map-listing/styles';
import { googleMapStyle } from 'helpers/constants/google-map/google-map-style';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IGeoLoc, MapType, TDetailForMap } from 'helpers/types';
import { deliveryZoneMapAddPolygon, deliveryZoneNormalizeName } from 'helpers/utils/delivery-zone';
import { tablet } from 'helpers/utils/screensizes';
import ProviderIcon from 'public/icons/rise-icons/Provider.png';
import ProviderHighlightedIcon from 'public/icons/rise-icons/ProviderHighlighted.png';
import ProviderHoverIcon from 'public/icons/rise-icons/ProviderHover.png';
import StoreIcon from 'public/icons/rise-icons/Store.png';
import StoreHighlightedIcon from 'public/icons/rise-icons/StoreHighlighted.png';
import StoreHoverIcon from 'public/icons/rise-icons/StoreHover.png';
import Loading from '../../global/loader';

type TProps = {
  data: TDetailForMap[];
  mapStyle: any;
  selectedListingFromParent?: TDetailForMap;
  handleSetSelectedListingFromParent?(listing: TDetailForMap | null, fromMap: boolean): void;
  geoLoc?: ReactElement;
  center?: IGeoLoc;
  host: string;
  geoJsons?: any[];
  setOpen?: (index) => void;
  open: any;
  showAllDispensaries?: boolean;
  page: string;
  showLegend?: boolean;
  handleSetMapLoaded?: (x: boolean) => void;
};

const MapComponent = ({
  data = [],
  mapStyle,
  selectedListingFromParent,
  handleSetSelectedListingFromParent,
  geoLoc,
  center,
  host,
  geoJsons,
  open,
  setOpen,
  showAllDispensaries = false,
  page,
  showLegend = true,
  handleSetMapLoaded,
}: TProps) => {
  const isDispensary = page === MapType.dispensary;
  const icon = isDispensary ? StoreIcon : ProviderIcon;
  const hoverIcon = isDispensary ? StoreHoverIcon : ProviderHoverIcon;
  const highlightedIcon = isDispensary ? StoreHighlightedIcon : ProviderHighlightedIcon;
  const [selectedListing, setSelectedListing] = React.useState<TDetailForMap | null>();
  const [map, setMap] = React.useState<any>(null);
  const [openIcon, setOpenIcon] = useState(false);
  const [isDeliveryZoneSet, setIsDeliveryZoneSet] = React.useState<boolean>(false);

  const params = useSearchParams();

  const mapRef = useRef(null);
  const legendRef = useRef(null);
  const [hoveredIcon, setHoveredIcon] = useState<number | null>(null);
  const { isLoaded: isMapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_MAP_APIKEY || '',
    version: 'weekly',
    libraries: ['places'],
  });

  if (isMapLoaded && handleSetMapLoaded) {
    handleSetMapLoaded(true);
  }
  const [isTablet, width] = useMediaQuery(tablet);

  const onGoogleReady = (map) => {
    isTablet
      ? map.controls[google.maps.ControlPosition.RIGHT_TOP].push(document.getElementById('legend'))
      : map.controls[google.maps.ControlPosition.LEFT_TOP].push(document.getElementById('legend'));
  };
  const onMapLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      data?.forEach((item: TDetailForMap) => {
        if (item?.lat && item?.lng) {
          bounds.extend({ lat: +item?.lat, lng: +item?.lng });
        }
      });
      if (data.length === 1) {
        const latLng = new window.google.maps.LatLng(+data[0]?.lat, +data[0]?.lng);
        map.setCenter(latLng);
        map.setZoom(15);
      } else {
        map.fitBounds(bounds);
      }
      setMap(map);
      onGoogleReady(map);
      if (legendRef && legendRef.current) {
        // @ts-ignore
        legendRef.current.style.display = 'flex';
      }
    },
    [data],
  );
  useEffect(() => {
    if (mapRef?.current && params.get('delivery-zone')) {
      //@ts-ignore
      mapRef.current.mapRef.scrollIntoView({ behavior: 'smooth', inline: 'nearest', block: 'center' });
    }
  }, [mapRef?.current, params.get('delivery-zone')]);

  useEffect(() => {
    if (geoJsons?.length && map && !isDeliveryZoneSet) {
      const url: any = params.get('delivery-zone');
      const matches = deliveryZoneNormalizeName(url);
      const bounds = new window.google.maps.LatLngBounds();
      deliveryZoneMapAddPolygon({
        map,
        geoJsons,
        matches,
        bounds,
      });
      setIsDeliveryZoneSet(true);
    }
  }, [map, geoJsons?.length, isDeliveryZoneSet]);

  useEffect(() => {
    let timer;
    if (map) {
      const url: any = params.get('delivery-zone');
      const matches = deliveryZoneNormalizeName(url);
      if (matches === '' && showAllDispensaries) {
        const bounds = new window.google.maps.LatLngBounds();
        data?.forEach((item: TDetailForMap) => {
          if (item?.lat && item?.lng) {
            bounds.extend({ lat: +item?.lat, lng: +item?.lng });
          }
        });
        map.fitBounds(bounds);
        const zoomLevel = width < 1014 ? 3 : 4;
        timer = setTimeout(() => map.setZoom(zoomLevel), 500);
      } else if (center) {
        const latLng = new window.google.maps.LatLng(center.lat, center.lng);
        map.panTo(latLng);
      }
    }
    () => clearInterval(timer);
  }, [map, data, width]); // eslint-disable-line react-hooks/exhaustive-deps

  const onMapUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const openInfoModel = (listing: TDetailForMap) => {
    setOpen && setOpen(data.findIndex((item) => item.title === listing.title));
    if (handleSetSelectedListingFromParent) {
      handleSetSelectedListingFromParent(listing, true);
    } else {
      setSelectedListing(listing);
      setOpenIcon(true);
    }
  };

  const closeInfoModel = () => {
    if (handleSetSelectedListingFromParent) {
      handleSetSelectedListingFromParent(null, true);
    } else {
      setSelectedListing(null);
      setOpenIcon(false);
    }
  };

  if (!isMapLoaded) {
    return (
      <div data-testid="loadingContainer">
        <Loading />
      </div>
    );
  }

  return (
    <div className="relative min1440:h-[821px]" data-testid="mapContainer">
      <GoogleMap
        ref={mapRef}
        id="google-map"
        mapContainerStyle={mapStyle}
        zoom={10}
        onLoad={onMapLoad}
        onUnmount={onMapUnmount}
        options={{ fullscreenControl: false, mapTypeControl: false, streetViewControl: false, styles: googleMapStyle }}
      >
        {geoLoc}
        {data?.map((item: TDetailForMap, index) => {
          return (
            <Marker
              data-testid="marker"
              key={item?.id || item?.title}
              icon={{
                url:
                  index === open && openIcon === true
                    ? highlightedIcon?.src
                    : hoveredIcon === index
                    ? hoverIcon?.src
                    : icon?.src,
                anchor: new window.google.maps.Point(icon?.width / 2, icon?.height / 2),
              }}
              position={{
                lat: +item?.lat,
                lng: +item?.lng,
              }}
              title={item?.title + ' marker'}
              onClick={() => openInfoModel(item)}
              onMouseOver={() => setHoveredIcon(index)}
              onMouseOut={() => setHoveredIcon(null)}
              zIndex={index === open ? 9999 : 1}
            >
              {(page === MapType.provider
                ? (selectedListingFromParent?.id || selectedListing?.id) === item?.id
                : (selectedListingFromParent?.title || selectedListing?.title) === item?.title) && (
                <InfoWindow onCloseClick={closeInfoModel} data-testid="markerWindow">
                  <MapCard
                    host={host}
                    title={selectedListingFromParent?.title ? (selectedListing?.title as string) : ''}
                    address={selectedListingFromParent?.address || selectedListing?.address}
                    phoneNumber={selectedListingFromParent?.phoneNumber || selectedListing?.phoneNumber}
                    onCloseClick={closeInfoModel}
                    menuBtns={selectedListingFromParent?.menuBtns || selectedListing?.menuBtns}
                    page={page}
                    email={selectedListingFromParent?.email || selectedListing?.email}
                    fax={selectedListingFromParent?.fax || selectedListing?.fax}
                  />
                </InfoWindow>
              )}
            </Marker>
          );
        })}
      </GoogleMap>
      {showLegend && geoJsons && geoJsons?.length > 0 && <Legend legendRef={legendRef} />}
      {/* deliveryZoneSet div is used for testing purposes to indicate there was a geoJson to render and it has been set */}
      {isDeliveryZoneSet && <div className="hidden" data-testid="deliveryZoneSet" />}
    </div>
  );
};

export default React.memo(MapComponent);

const Legend = ({ legendRef }: { legendRef: React.MutableRefObject<any> }) => {
  return (
    <LegendContainer id="legend" data-testid="legendContainer" ref={legendRef} style={{ display: 'none' }}>
      <LegendIndicator />
      <Typography variant="body-small-bold" className="ml-4 !text-grey-500">
        Delivery Zone
      </Typography>
    </LegendContainer>
  );
};
