import tw from 'tailwind-styled-components';

export const HowItWorksContainer = tw.div`
    bg-white
    px-[20px]
    py-[56px]
`;

export const HowItWorksInnerContainer = tw.div`
    flex
    flex-col
    gap-y-[24px]
    md:flex-row
    md:gap-y-0
    md:gap-x-[176px]
    md:py-[200px]
    md:px-[60px]
`;

export const HowItWorksStepsContainer = tw.div`
    flex
    flex-col
    gap-y-[24px]
    md:flex-row
    md:gap-y-0
    md:gap-x-[24px]
`;

export const HowItWorksStepContainer = tw.div`
    flex
    flex-col-2
    items-center
    md:flex-col
    md:items-start
    md:w-[432px]
`;

export const HowItWorksImageContainer = tw.div`
    md:[&_img]:!w-[432px]
    md:[&_img]:!h-[320px]
`;

export const HowItWorksStepTextContainer = tw.div`
    flex
    flex-col
    gap-y-[16px]
    mt-[52px]
    mb-[20px]
`;

export const HowItWorksStepTitleContainer = tw.div`
    flex
    flex-col-2
    gap-x-[12px]
`;

export const HowItWorksDescriptionContainer = tw.div`
    w-[200px]
    h-auto
    md:w-[280px]
   [&_p]:text-grey-400
   [&_p]:xl:text-base 
   [&_p]:xl:font-normal 
   [&_p]:xl:leading-5 
   [&_p]:xl:tracking-[0.16px]
   [&_p]:md:text-[15px] 
   [&_p]:md:font-normal 
   [&_p]:md:leading-[18px] 
   [&_p]:md:tracking-[0.15px]
   [&_p]:text-sm 
   [&_p]:font-normal 
   [&_p]:leading-4 
   [&_p]:tracking-[0.14px]
`;
