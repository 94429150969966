import React from 'react';
import { Typography } from 'components/atoms/typography';
import { TRewardDataProps } from 'helpers/types/organisms/rewards/rewards-matrix';
import PointsIcon from 'public/icons/figma/points.svg';
import {
  RewardsMatrixPointsCardSection,
  RewardsMatrixPointsRewardsSection,
  RewardsMatrixPointsRewardsTitleSection,
  RewardsMatrixPointsTitleSection,
} from './styles';

const RewardsMatrixPointsCard = ({ pointTitle, count, rewardTitle }: TRewardDataProps) => {
  return (
    <RewardsMatrixPointsCardSection>
      <RewardsMatrixPointsTitleSection>
        <PointsIcon className="text-kief" />
        <Typography variant="h5" className="!text-grey-400">
          {pointTitle}
        </Typography>
      </RewardsMatrixPointsTitleSection>
      <RewardsMatrixPointsRewardsSection>
        <RewardsMatrixPointsRewardsTitleSection>
          <Typography variant="h2">$</Typography>
          <Typography variant="h1">{count}</Typography>
        </RewardsMatrixPointsRewardsTitleSection>
        <Typography variant="body-small" className="hidden !text-grey-400 md:block">
          {rewardTitle}
        </Typography>
      </RewardsMatrixPointsRewardsSection>
    </RewardsMatrixPointsCardSection>
  );
};

export default RewardsMatrixPointsCard;
