import React from 'react';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import RewardsAboutCard from './donation-points';
import { DataContainer, MainContainer, MessageContainer } from './styles';

interface IRewardsAboutData {
  donatedThroughGreenThumb: string;
  donatedThroughGreenThumbDesc: any;
  donatedToNonProfit: string;
  donatedToNonProfitDesc: any;
  donationHeading: string;
  donationParagraph: any;
}

const AboutUsDonationInfo = ({
  donatedThroughGreenThumb,
  donatedThroughGreenThumbDesc,
  donatedToNonProfit,
  donatedToNonProfitDesc,
  donationHeading,
  donationParagraph,
}: IRewardsAboutData) => {
  return (
    <MainContainer>
      <MessageContainer>
        <Typography variant="h3" as="p" className="pb-4 !text-light lg:pb-5 2xl:pb-6">
          {donationHeading}
        </Typography>
        <Typography
          variant="body-small"
          as="div"
          className="[&_a]:text-light [&_p]:!max-w-full  [&_p]:!text-light"
          data-testid="donation"
        >
          <ContentfulRichText data={donationParagraph} />
        </Typography>
      </MessageContainer>
      <DataContainer>
        <RewardsAboutCard
          donatedToNonProfit={donatedToNonProfit}
          donatedToNonProfitDesc={donatedToNonProfitDesc}
          donatedThroughGreenThumb={donatedThroughGreenThumb}
          donatedThroughGreenThumbDesc={donatedThroughGreenThumbDesc}
        />
      </DataContainer>
    </MainContainer>
  );
};

export default AboutUsDonationInfo;
