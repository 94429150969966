import Link from 'next/link';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import { AccountUrl, IContentfulImage } from 'helpers/types/common.type';
import PencilIcon from 'public/icons/figma/pencil.svg';
import { useAppSelector } from 'redux/hooks';
import AccountEmptyState from '../empty-state';
import AccountOverviewBanner from './account-overview-banner';
import {
  AccountOverviewBannerSection,
  AccountOverviewContainer,
  AccountOverviewContent,
  AccountOverviewSavedProductSection,
  AccountOverviewUserEditButtonWrapper,
  AccountOverviewUserInfoSection,
  AccountOverviewUserSection,
} from './styles';

interface AccountOverviewProps {
  firstName: string;
  fullName: string;
  email: string;
  message?: string;
  subMessage?: string;
  topImg: IContentfulImage;
  topLink?: string;
  topTitle: string;
  topDescription: string;
  bottomLink?: string;
  buttonLabel?: string;
  bottomImg: IContentfulImage;
  bottomTitle: string;
  bottomDescription: string;
  emptyStateImg?: IContentfulImage;
  emptyStateLink?: string;
}

// TODO: Need to refractor parameters. Unknown data such as First name and full might need to be parse. Will fix it during integration.
const AccountOverview = ({
  firstName,
  fullName,
  email,
  message,
  subMessage,
  topImg,
  topLink,
  topTitle,
  buttonLabel,
  topDescription,
  emptyStateImg,
  bottomImg,
  bottomLink,
  bottomTitle,
  bottomDescription,
  emptyStateLink,
}: AccountOverviewProps) => {
  const host = useAppSelector((store) => store.hostReducer.host);
  return (
    <AccountOverviewContainer>
      <AccountOverviewContent>
        <Typography variant="h2">Hey, {firstName}!</Typography>
        <AccountOverviewUserSection>
          <AccountOverviewUserInfoSection>
            <Typography variant="body-small-bold">{fullName}</Typography>
            <Typography variant="body-small" className="pt-1 !text-grey-400">
              {email}
            </Typography>
          </AccountOverviewUserInfoSection>
          <Link href={`${host}${AccountUrl.PROFILE}`} passHref>
            <AccountOverviewUserEditButtonWrapper>
              <IconButton color="green" button-type="secondary" className="md:hidden">
                <PencilIcon />
              </IconButton>
              <IconWithTextButton
                iconPosition="before"
                icon={<PencilIcon />}
                color="green"
                button-type="secondary"
                className="hidden md:!flex md:!h-12 md:!shrink-0 md:!items-center md:!justify-end md:!gap-2 md:!px-3.5 md:!pl-[18px] md:!pr-6"
              >
                View profile
              </IconWithTextButton>
            </AccountOverviewUserEditButtonWrapper>
          </Link>
        </AccountOverviewUserSection>
        <AccountOverviewSavedProductSection>
          <AccountEmptyState
            message={message as string}
            headerVariant="h4"
            headerAs="h3"
            subMessageHeaderVariant="body-small"
            buttonLabel={buttonLabel}
            subMessage={subMessage as string}
            src={emptyStateImg?.url || ''}
            alt={emptyStateImg?.description || ''}
            ariaLabel={emptyStateImg?.alt || ''}
            ctaLink={emptyStateLink}
            className="h-[204px] w-full rounded-md bg-grey-100 !px-[20px] !py-10 lg:!h-[544px] lg:gap-0 lg:!px-[60px] lg:!pb-[55px] lg:pt-14 lg:[&_div]:m-0 "
            imageContainerClassName="hidden lg:flex lg:!pr-0 lg:!mt-auto"
            introMessageClassName="gap-2 lg:gap-4"
            imagebgClassName="lg:!h-[244px] lg:!w-[244px] lg:!bg-grey-100"
            messageContainerClassName="gap-5 lg:gap-4 lg:!w-[320px]"
          />
        </AccountOverviewSavedProductSection>
      </AccountOverviewContent>
      <AccountOverviewBannerSection>
        <AccountOverviewBanner
          type="HOME_DISPENSARY"
          src={topImg?.url || ''}
          ariaLabel={topImg?.description || ''}
          alt={topImg?.alt || ''}
          bgGradient="linear-gradient(180deg, #000E061A 0%, #000E06B2 100%)"
          // className="lg:!h-[62%]"
          breadcrumbTitle={topTitle}
          breadcrumbClassName={'!text-white'}
          description={topDescription}
          descriptionClassName={' !text-white'}
          href={topLink || ''}
        />
        <AccountOverviewBanner
          type="LOYALTY_POINTS"
          src={bottomImg?.url || ''}
          ariaLabel={bottomImg?.description || ''}
          alt={bottomImg?.alt || ''}
          // className="lg:!h-[38.12%]"
          breadcrumbTitle={bottomTitle}
          description={bottomDescription}
          breadcrumbClassName="!text-leaf"
          descriptionHeader="h1"
          href={bottomLink || ''}
        />
      </AccountOverviewBannerSection>
    </AccountOverviewContainer>
  );
};

export default AccountOverview;
