import { ElementType } from 'react';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { ImageBg } from 'components/atoms/image';
import { TTypographyVariant, Typography } from 'components/atoms/typography';
import {
  AccountEmptyStateContainer,
  AccountEmptyStateImageSection,
  AccountEmptyStateMessageSection,
  AccountEmptyStateMessageSubSection,
  AccountEmptyStateMessageWrapper,
} from './styles';

interface AccountEmptyStateProps {
  firstName?: string;
  message: string;
  subMessage: string;
  src: string;
  alt: string;
  ariaLabel: string;
  buttonLabel?: string;
  ctaLink?: string;
  className?: string;
  imageContainerClassName?: string;
  imagebgClassName?: string;
  introMessageClassName?: string;
  messageContainerClassName?: string;
  headerVariant?: TTypographyVariant;
  headerAs?: ElementType;
  subMessageHeaderVariant?: TTypographyVariant;
  subMessageHeaderAs?: ElementType;
  ctaAsButton?: boolean;
  handlectaButtonClick?(): void;
}

const AccountEmptyState = ({
  firstName = '',
  message = '',
  subMessage = '',
  buttonLabel = 'Shop now',
  src = '',
  alt = '',
  ariaLabel = '',
  ctaLink = '/',
  className = '',
  imageContainerClassName = '',
  introMessageClassName = '',
  messageContainerClassName = '',
  imagebgClassName = '',
  headerVariant = 'h2',
  headerAs = 'h2',
  subMessageHeaderVariant = 'body-large',
  subMessageHeaderAs = 'p',
  ctaAsButton = false,
  handlectaButtonClick,
}: AccountEmptyStateProps) => {
  return (
    <AccountEmptyStateContainer $styles={className}>
      <AccountEmptyStateMessageSection $styles={messageContainerClassName}>
        <AccountEmptyStateMessageWrapper className={introMessageClassName}>
          <Typography variant={headerVariant} as={headerAs} className="text-center lg:text-start">
            {firstName !== '' ? (
              <>
                Hi {firstName}, <br />
              </>
            ) : (
              ''
            )}
            {message}
          </Typography>
          <AccountEmptyStateMessageSubSection>
            <Typography
              variant={subMessageHeaderVariant}
              as={subMessageHeaderAs}
              className="text-center !text-grey-400 lg:text-start"
            >
              {subMessage}
            </Typography>
          </AccountEmptyStateMessageSubSection>
        </AccountEmptyStateMessageWrapper>
        {ctaAsButton ? (
          <CommonButton onClick={handlectaButtonClick} color="green" button-type="primary">
            {buttonLabel}
          </CommonButton>
        ) : (
          <CommonButton as="a" href={ctaLink} color="green" button-type="primary">
            {buttonLabel}
          </CommonButton>
        )}
      </AccountEmptyStateMessageSection>
      <AccountEmptyStateImageSection $styles={imageContainerClassName}>
        <ImageBg
          src={src}
          alt={alt}
          ariaLabel={ariaLabel}
          className={
            'h-[184px] w-[302px] bg-white !bg-contain !bg-center lg:!h-[360px] lg:!w-[512px] lg:!bg-right ' +
            imagebgClassName
          }
        />
      </AccountEmptyStateImageSection>
    </AccountEmptyStateContainer>
  );
};

export default AccountEmptyState;
