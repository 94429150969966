import { ICategoriesTrackEvent, SegementTrackEvent } from './segment-helper';
type TProps = {
  category: string | Array<string>;
  type: string;
};
export const categoriesTrack = (data: TProps) => {
  const { category, type } = data;
  const values: ICategoriesTrackEvent =
    typeof category === 'string' ? { category_name: category } : { categories: category };
  return SegementTrackEvent(type, values, { sendGeoData: true });
};
