'use client';

import React from 'react';
import { BLOCKS } from '@contentful/rich-text-types';
import { useDispatch, useSelector } from 'react-redux';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { SegmentEvents } from 'helpers/constants/forms';
import { TRewardsMatrixJoinContainerProps } from 'helpers/types/organisms/rewards/rewards-matrix';
import { ReplaceUrlWithHost } from 'helpers/utils/common';
import { useAppSelector } from 'redux/hooks';
import { setOpenLoginDrawer } from 'redux/reducers/account';
import { RootState } from 'redux/store';
import { RewardsMatrixJoinContainer, RewardsMatrixJoinInnerContainer, RewardsMatrixSubtitleContainer } from './styles';

const RewardsMatrixJoinComponent = ({
  title,
  subtitle,
  buttonCta,
  handleSegmentEvent,
}: TRewardsMatrixJoinContainerProps) => {
  const dispatch = useDispatch();
  const user = useAppSelector((state) => state.accountReducer.user);
  const openLogin = () => {
    dispatch(setOpenLoginDrawer(true));
    handleSegmentEvent && handleSegmentEvent(SegmentEvents.BANNER_CLICKED);
  };
  const { host } = useSelector((state: RootState) => state.hostReducer);
  const replaceWithHost = ReplaceUrlWithHost(buttonCta?.items?.[1]?.longUrl || '/', host);
  return (
    <RewardsMatrixJoinContainer>
      <RewardsMatrixJoinInnerContainer>
        <Typography variant="h3" as="h2" className="text-white" data-testid="rewards_matrix_title">
          {title}
        </Typography>
        <RewardsMatrixSubtitleContainer>
          {subtitle?.json && (
            <ContentfulRichText
              data={subtitle}
              renderNodeOptions={{
                [BLOCKS.PARAGRAPH]: (node, children) => (
                  <Typography variant="body-small" data-testid="rewards_matrix_subtitle" className="text-white">
                    {children}
                  </Typography>
                ),
              }}
            />
          )}
        </RewardsMatrixSubtitleContainer>
        <CommonButton
          color="white"
          as={!user ? 'button' : 'a'}
          href={user && replaceWithHost}
          button-type="primary"
          data-testid="rewards_matrix_button_cta"
          onClick={!user && buttonCta?.items?.[0]?.longUrl === '#rise-join-now' ? openLogin : () => {}}
        >
          {!user ? buttonCta?.items?.[0]?.title : buttonCta?.items?.[1]?.title}
        </CommonButton>
      </RewardsMatrixJoinInnerContainer>
    </RewardsMatrixJoinContainer>
  );
};

export default RewardsMatrixJoinComponent;
