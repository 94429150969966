import { ForwardRefRenderFunction, forwardRef } from 'react';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { getHomeDispensaryDataFromCms } from 'helpers/account-api';
import { communicationInitiated } from 'helpers/analytics/communication-initiated-track';
import { IDispensaryInfoProps, MapType } from 'helpers/types';
import Auth from 'helpers/utils/cognito';
import CheckIcon from 'public/icons/figma/check.svg';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import FaxIcon from 'public/icons/figma/fax.svg';
import LocationIcon from 'public/icons/figma/location.svg';
import MailIcon from 'public/icons/figma/mail.svg';
import MinusIcon from 'public/icons/figma/minus.svg';
import PhoneIcon from 'public/icons/figma/phone.svg';
import PlusIcon from 'public/icons/figma/plus.svg';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setUser, setUserHomeDispensary } from 'redux/reducers/account';
import { RootState } from 'redux/store';
import { Typography } from '../../../atoms/typography';
import StoreHoursDropdown from './store-hours';
import {
  AccordionBodyComponent,
  AccordionBodyContainer,
  AccordionComponent,
  AccordionHeaderComponent,
  AccordionHeaderContainer,
  ContactUsItem,
  DetailContainer,
  DispensaryDetailContainer,
  ImageContainer,
  Item,
  SelectedIconBox,
  Tag,
  TagsContainer,
} from './styles';

export interface IDispensaryAccordionProps extends IDispensaryInfoProps {
  tags?: Array<string>;
  isSelected?: boolean;
  cms_tags?: Array<string>;
}

export type TDispensaryAccordion = {
  open: boolean;
  handleOpen(index: number): void;
  data: IDispensaryAccordionProps;
  index: number;
  type?: string;
  handleConfirmDispensary?(data?: any): void;
  isButton?: boolean;
};

const AccordionToggleIcon = ({ open }: { open: boolean }) => {
  return open ? <MinusIcon className="text-grey-500" /> : <PlusIcon className="text-grey-400" />;
};

const DispensaryAccordion: ForwardRefRenderFunction<HTMLDivElement, TDispensaryAccordion> = (
  { open, data, handleOpen, index, handleConfirmDispensary, type = MapType.dispensary },
  ref,
) => {
  const dispatch = useAppDispatch();
  const host = useAppSelector((store) => store.hostReducer.host);
  const drawerStartedBy = useAppSelector((store) => store?.dispensaryListDrawerReducer?.drawerStartedBy);

  const {
    isSelected = false,
    title,
    tags,
    timing,
    phoneNumber,
    address,
    contactUs,
    cta,
    image,
    state,
    email,
    fax,
  } = data || {};

  const changeUserHomeDispensary = async () => {
    try {
      dispatch(
        setUserHomeDispensary({
          loading: true,
        }),
      );
      const updatedUser = await Auth.updateUserAttributes([
        {
          Name: 'custom:current_dispensary',
          Value: data?.slug as string,
        },
        {
          Name: 'custom:current_state',
          Value: data?.state as string,
        },
      ]);
      dispatch(setUser(updatedUser));
      const dispensaryData = await getHomeDispensaryDataFromCms({
        dispensarySlug: data?.slug as string,
        host,
        state: state as string,
      });

      dispatch(
        setUserHomeDispensary({
          loading: false,
          data: dispensaryData,
        }),
      );
      handleConfirmDispensary && handleConfirmDispensary(data);
    } catch (err) {
      dispatch(
        setUserHomeDispensary({
          loading: false,
          data: null,
          error: err,
        }),
      );
    }
  };
  const handleClick = (e) => {
    if (handleConfirmDispensary && drawerStartedBy !== 'HOME_DISPENSARY') {
      handleConfirmDispensary(data);
    }
    if (drawerStartedBy === 'HOME_DISPENSARY') {
      e.preventDefault();
      changeUserHomeDispensary();
    }
  };

  const handleContactUs = () => {
    const data = {
      method: contactUs.text || 'Contact us',
      link_name: contactUs.url || '/contact',
      link_type: 'hyperlink',
      communication_trigger: 'dispensary-list',
    };
    communicationInitiated(data);
  };

  const user = useSelector((state: RootState) => state?.accountReducer?.user);
  const userHomeDispensary = user?.attributes['custom:current_dispensary'];
  if (userHomeDispensary === data?.slug) {
    tags?.push('Home dispensary');
  }

  return (
    <AccordionComponent
      data-testid="dispensary-accordion"
      ref={ref}
      open={open}
      icon={<AccordionToggleIcon open={open} />}
      id={`accordion-${index}`}
    >
      <AccordionHeaderComponent $active={open} onClick={() => handleOpen(index)}>
        <AccordionHeaderContainer>
          {isSelected && open && (
            <SelectedIconBox>
              <CheckIcon className="h-6 w-6" />
            </SelectedIconBox>
          )}
          <Typography
            className={`${
              !open ? 'text-grey-400 group-hover:text-grey-500' : MapType.provider === type ? '!text-grey-500' : ''
            }`}
            variant="h5"
          >
            {title?.toUpperCase()}
          </Typography>
        </AccordionHeaderContainer>
      </AccordionHeaderComponent>
      <AccordionBodyComponent>
        <AccordionBodyContainer>
          <DispensaryDetailContainer>
            <TagsContainer>
              {tags &&
                tags?.length > 0 &&
                tags?.map((tag) => (
                  <Tag key={tag}>
                    <Typography variant="body-small-bold">{tag}</Typography>
                  </Tag>
                ))}
            </TagsContainer>
            <DetailContainer>
              {timing && Array.isArray(timing) && timing?.length > 0 && <StoreHoursDropdown storeHours={timing} />}
              {phoneNumber && type === MapType.dispensary && (
                <Item>
                  <PhoneIcon />
                  <Typography variant="body-small">{phoneNumber}</Typography>
                </Item>
              )}
              {address?.text && address?.url && (
                <Item>
                  <LocationIcon />
                  <Typography variant="body-small">{address.text}</Typography>
                </Item>
              )}
              {phoneNumber && type !== MapType.dispensary && (
                <Item>
                  <PhoneIcon />
                  <Typography variant="body-small">{phoneNumber}</Typography>
                </Item>
              )}
              {email && type !== MapType.dispensary && (
                <Item>
                  <MailIcon className="h-6 w-6" />
                  <Typography variant="body-small" data-testid="mapCardEmail">
                    {email}
                  </Typography>
                </Item>
              )}
              {fax && type !== MapType.dispensary && (
                <Item>
                  <FaxIcon className="h-6 w-6" />
                  <Typography variant="body-small" data-testid="mapCardFax">
                    {fax}
                  </Typography>
                </Item>
              )}
              {contactUs && type === MapType.dispensary && (
                <ContactUsItem>
                  <MailIcon />
                  <IconWithTextButton
                    color="green"
                    button-type="tertiary"
                    iconPosition="after"
                    icon={<ChevronRightIcon />}
                    className="!p-0"
                    as="a"
                    href={contactUs.url}
                    onClick={handleContactUs}
                  >
                    <Typography variant="body-small-bold">{contactUs.text || 'Contact us'}</Typography>
                  </IconWithTextButton>
                </ContactUsItem>
              )}
            </DetailContainer>
            {cta?.text && cta?.url && (
              <CommonButton
                button-type="secondary"
                color="green"
                size="sm"
                className="self-start"
                as={handleConfirmDispensary ? 'button' : 'a'}
                href={cta.url}
                onClick={handleClick}
                target={cta?.url?.includes('http') ? '_blank' : '_self'}
              >
                {cta.text}
              </CommonButton>
            )}
          </DispensaryDetailContainer>
          <ImageContainer>
            {image?.url && (
              <Image
                src={image.url}
                layout="fill"
                objectFit="contain"
                loader={({ src }) => src}
                alt={image.title || ''}
                priority
              />
            )}
          </ImageContainer>
        </AccordionBodyContainer>
      </AccordionBodyComponent>
    </AccordionComponent>
  );
};

export default forwardRef(DispensaryAccordion);
