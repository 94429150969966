import { Typography } from 'components/atoms/typography';
import { IContentfulImage, IRichTextData } from 'helpers/types/common.type';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import DealCardListShowMore from './deal-cards-list-show-more';
import DealCardListSlider from './deal-cards-list-slider';
import { DealCardsListContainer, DealCardsListHeaderSection } from './styles';
export interface DealCardItemsProps {
  image: IContentfulImage;
  specialText?: string;
  discountText: string;
  extraText?: IRichTextData;
  percentText?: string;
  link?: string;
  tasticPlacement?: number;
  internalName?: string;
}
interface DealsCardsProps {
  host: string;
  dealText?: string;
  items: DealCardItemsProps[];
  mobileSize?: 'sm' | 'md';
  tabletSize?: 'sm' | 'md';
  tableItemstLimit?: number;
  desktopItemsLimit?: number;
  mobileVariant?: 'vertical' | 'horizontal';
  tabeltVariant?: 'vertical' | 'horizontal';
  maxItems?: number;
}

const DealCardsList = ({
  dealText = 'Deals',
  items = [],
  mobileSize = 'sm',
  tabletSize = 'md',
  mobileVariant,
  tabeltVariant,
  tableItemstLimit,
  desktopItemsLimit,
  maxItems = 20,
  host,
}: DealsCardsProps) => {
  if (items?.length === 0) return <></>;
  const truncateItems = items.slice(0, maxItems);
  return (
    <DealCardsListContainer>
      <DealCardsListHeaderSection>
        <Typography variant="h3">{dealText}</Typography>
      </DealCardsListHeaderSection>
      <DealCardListSlider host={host} items={truncateItems} size={mobileSize} variant={mobileVariant} />
      <DealCardListShowMore
        host={host}
        items={truncateItems}
        size={tabletSize}
        variant={tabeltVariant}
        tabletItemsLimit={tableItemstLimit}
        desktopItemsLimit={desktopItemsLimit}
      />
    </DealCardsListContainer>
  );
};

export default DealCardsList;
