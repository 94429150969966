'use client';

import React, { useEffect, useState } from 'react';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import FaqAccordion from 'components/molecules/accordions/faq-accordion';
import { FaqBodyContainer, FaqDataContainer, FaqImageContainer, FaqsContainer } from '../faq-styles';

type FAQItem = {
  question: string;
  answer: any;
};

interface IFAQSection {
  title: string;
  faqsCollection: FAQItem[];
}

const FAQSectionVariantB = ({ faqsCollection, title }: IFAQSection) => {
  const totalFAQs = faqsCollection.length;
  const [displayCount, setDisplayCount] = useState(totalFAQs <= 8 ? totalFAQs : 4);
  const [openAccordionIndex, setOpenAccordionIndex] = useState<number | null>(null);
  const accordionDataToShow = faqsCollection.slice(0, displayCount);
  const handleAccordionClick = (index: number) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const remainingFAQCount = totalFAQs - displayCount;

  const showButtonText =
    totalFAQs > 8 ? (displayCount === totalFAQs ? `Show Less` : `Show More (${remainingFAQCount})`) : '';

  useEffect(() => {
    const rewardSection = document.getElementById('rewards-section');
    if (rewardSection) {
      const el = document.getElementById('faq-section-variant-b') as HTMLElement;
      rewardSection.appendChild(el);
    }
  }, []);

  return (
    <FaqsContainer id="faq-section-variant-b">
      <FaqImageContainer className="lg:pr-[176px] lg:pt-[104px]">
        <Typography variant="h3" className="lg:w-[280px]">
          {title}
        </Typography>
      </FaqImageContainer>
      <FaqBodyContainer className="lg:pr-[60px] lg:pt-[104px]">
        <FaqDataContainer $isGap={true}>
          {totalFAQs > 0 &&
            accordionDataToShow.map((item: FAQItem, index: number) => (
              <FaqAccordion
                key={index}
                faqWidth={true}
                data={item}
                index={index}
                shouldOpen={openAccordionIndex === index}
                handleOpen={handleAccordionClick}
              />
            ))}
        </FaqDataContainer>
        {totalFAQs > 8 && (
          <div className="pt-6">
            <Typography variant="small" className="pb-4">{`Showing ${displayCount} of ${totalFAQs}`}</Typography>
            <CommonButton
              color="green"
              button-type="secondary"
              className="px-5 py-3 text-sm lg:px-6 lg:py-[14px] lg:text-base"
              onClick={() => {
                setDisplayCount(displayCount === totalFAQs ? 4 : totalFAQs);
              }}
            >
              {showButtonText}
            </CommonButton>
          </div>
        )}
      </FaqBodyContainer>
    </FaqsContainer>
  );
};

export default FAQSectionVariantB;
