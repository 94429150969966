import React from 'react';
import Image from 'next/image';
import Tooltip from 'components/atoms/overlay/tooltip/tooltip';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage, IRichTextData } from 'helpers/types';
import { tablet } from 'helpers/utils/screensizes';
import {
  ComponentDeatilsContainer,
  HexaBox,
  IconContainer,
  ImageContainer,
  MainContainer,
  PercentageContainer,
  TitleContainer,
  TitleDiv,
} from './styles';

export interface TerpenesProps {
  name?: string;
  percentage?: string;
  title?: {
    json: Record<string, unknown>;
  };
  className?: string;
  logo?: IContentfulImage;
  tooltip?: {
    title: string;
    description: IRichTextData;
    image: IContentfulImage;
    ctaCollection: {
      items: {
        title: string;
        url: string;
        longUrl: string;
      }[];
    };
  };
  openTooltip?: number;
  handleOpen: (index: number) => void;
  terpenIndex?: number;
}

const Terpenes = ({
  name,
  percentage,
  title,
  className = '',
  logo,
  tooltip,
  openTooltip,
  handleOpen,
  terpenIndex,
}: TerpenesProps) => {
  const [isTablet] = useMediaQuery(tablet);

  return (
    <MainContainer className={className}>
      <IconContainer>
        <HexaBox />
        {logo?.url && (
          <ImageContainer>
            <Image
              src={logo?.url}
              alt={logo?.description || ''}
              width={isTablet ? logo.width : 20}
              height={isTablet ? logo.height : 20}
              loader={(options) => options.src}
            />
          </ImageContainer>
        )}
      </IconContainer>
      <ComponentDeatilsContainer>
        <TitleContainer>
          <TitleDiv>
            {name && <Typography variant="body-small-bold">{name}</Typography>}
            {title && (
              <Typography variant="body-small" as={'div'} className="[&_div_p]:pb-0 [&_div_p]:text-[15px]">
                <ContentfulRichText data={title} />
              </Typography>
            )}
          </TitleDiv>
          {(tooltip?.title || tooltip?.description) && (
            <Tooltip
              iconSize={20}
              open={openTooltip === terpenIndex}
              data={{
                heading: tooltip?.title,
                description: tooltip?.description,
                image: tooltip?.image,
                showReadMore: true,
                readMoreLink: tooltip?.ctaCollection?.items[0]?.longUrl
                  ? tooltip?.ctaCollection.items[0]?.longUrl
                  : tooltip?.ctaCollection.items[0]?.url,
              }}
              index={terpenIndex}
              handleOpen={handleOpen}
            />
          )}
        </TitleContainer>
        <PercentageContainer>
          {percentage && (
            <Typography variant="h4">
              {percentage}
              <Typography variant="h5" as="span">
                %
              </Typography>
            </Typography>
          )}
        </PercentageContainer>
      </ComponentDeatilsContainer>
    </MainContainer>
  );
};

export default Terpenes;
