import { useState } from 'react';
import { DayListing } from 'components/atoms/listings/store-hours/day-listing';
import {
  CaretDownIcon,
  CaretUpIcon,
  DropdownButton,
  HasMedicalContainer,
  HoursContainer,
  StoreHoursContainer,
  TopHoursContainer,
} from 'components/atoms/listings/store-hours/styles';
import { Typography } from 'components/atoms/typography';
import { DayMap } from 'helpers/constants/misc';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IDispensaryTiming } from 'helpers/types';
import { getStoreHoursForToday } from 'helpers/utils/get-dispensary-time';
import { tablet } from 'helpers/utils/screensizes';
import AlertIcon from 'public/icons/figma/alert.svg';
import TimeIcon from 'public/icons/figma/time.svg';

interface StoreHoursProps {
  storeHours: IDispensaryTiming[];
  medicalHeading: string;
  medicalDescription: string;
}
export const StoreHours = ({ storeHours, medicalHeading, medicalDescription }: StoreHoursProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const storeHourstoDisplay = storeHours?.map((day: IDispensaryTiming) => {
    const hours = day?.hours;
    return {
      fromMedical: day?.fromMedical,
      toMedical: day?.toMedical,
      day: day?.day,
      dayToDisplay: DayMap[day?.day.toLowerCase()],
      hours,
    };
  });
  const storeHoursForToday = getStoreHoursForToday(storeHourstoDisplay);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const shouldShow = isTablet || (!isTablet && dropdownOpen);

  return (
    <>
      <StoreHoursContainer>
        <TopHoursContainer>
          <TimeIcon className="mr-3 !text-grey-400" />
          {shouldShow ? (
            <Typography variant="body-small" className="!text-grey-400">
              Opening hours
            </Typography>
          ) : (
            <DayListing timing={storeHoursForToday as IDispensaryTiming} marginTiming={false} isLast={true} />
          )}
          {!isTablet && (
            <DropdownButton onClick={() => setDropdownOpen(!dropdownOpen)} className="dropdownButton">
              {dropdownOpen ? <CaretUpIcon /> : <CaretDownIcon />}
            </DropdownButton>
          )}
        </TopHoursContainer>
        {shouldShow && (
          <HoursContainer className="dayContainer">
            {storeHourstoDisplay.map((timing, index) => {
              const highlightToday = timing?.day === (storeHoursForToday as IDispensaryTiming)?.day;
              return (
                <DayListing
                  key={index}
                  timing={timing}
                  shouldHighlight={highlightToday}
                  isLast={index === storeHourstoDisplay?.length}
                />
              );
            })}
          </HoursContainer>
        )}
      </StoreHoursContainer>
      {(storeHoursForToday as IDispensaryTiming)?.fromMedical && shouldShow && medicalHeading && medicalDescription && (
        <HasMedicalContainer>
          <div className="w-6">
            <AlertIcon className="!text-error" />
          </div>
          <div>
            <Typography variant="body-small-bold" className="!text-grey-500">
              {medicalHeading}
            </Typography>
            <Typography variant="body-small" className="mt-1 !text-grey-400">
              {medicalDescription
                .replace('{fromMedical}', (storeHoursForToday as IDispensaryTiming)?.fromMedical || '')
                .replace('{toMedical}', (storeHoursForToday as IDispensaryTiming)?.toMedical || '')}
            </Typography>
          </div>
        </HasMedicalContainer>
      )}
    </>
  );
};
