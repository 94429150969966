import tw from 'tailwind-styled-components';

export const GroupDiscountsContainer = tw.div`
    max-w-[2200px]
    mx-auto
    py-10
    md:py-[80px]
    px-4
    md:px-[60px]
`;

export const GroupDiscountsCardContainer = tw.div`
    gap-x-6
    gap-y-6
    grid
    grid-cols-1
    xl:grid-cols-2
    min1440:grid-cols-3
`;
