'use client';

import { ReactElement, useCallback, useEffect, useState } from 'react';
import { ToggleButton } from 'components/atoms/inputs/toggle-button';
import { Typography } from 'components/atoms/typography';
import DispensaryAccordion from 'components/molecules/accordions/dispensary-accordion';
import {
  AccordionContainer,
  FadeBox,
  ListContainer,
  ListingMapContainer,
  MapContainer,
} from 'components/organisms/listings/map-listing/styles';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IAllTabs, IGeoLoc, IListingDetail, MapType, TDetailForMap } from 'helpers/types';
import { getMainDeliveryZone } from 'helpers/utils/delivery-zone';
import { desktop, tablet } from 'helpers/utils/screensizes';
import MapComponent from './google-map';
interface MapProps {
  data: Record<string, any>[];
  host: string;
  center?: IGeoLoc;
  geoLoc?: ReactElement;
  geoJsons?: any[];
  type: MapType;
  title: string;
  autocompleteComponent?: ReactElement;
}
const containerStyle = {
  width: '100%',
  height: '100%',
  minHeight: '810px',
};
enum TabType {
  listView = 'LIST VIEW',
  mapView = 'MAP VIEW',
}
export const MapListing = ({ data, host, center, geoLoc, geoJsons, type, title, autocompleteComponent }: MapProps) => {
  const [open, setOpen] = useState(null);
  const [selectedListingFromParent, setSelectedListingFromParent] = useState<TDetailForMap>();
  const [mapCenter, setMapCenter] = useState(center);
  const [isDesktop] = useMediaQuery(desktop);
  const ListingDetailForMapArray: TDetailForMap[] = data?.map((list: IListingDetail) => {
    return (({
      id,
      title,
      lat,
      lng,
      icon,
      uri,
      address,
      website,
      email,
      phoneNumber,
      customDistance,
      menuBtns,
      openingTime,
      heroImage,
      mobileHeroImage,
      phone,
      fax,
    }) => ({
      id,
      title,
      lat,
      lng,
      icon,
      uri,
      address,
      website,
      email,
      phoneNumber: phoneNumber || phone,
      customDistance,
      menuBtns,
      openingTime,
      heroImage,
      mobileHeroImage,
      fax,
    }))(list);
  });
  const [accordionElement, setAccordionElement] = useState<Element | null>(null);

  const handleSetSelectedListingFromParent = useCallback(
    (list: TDetailForMap, fromMap = false) => {
      const listing = ListingDetailForMapArray.findIndex((item) => {
        return item?.id === list?.id;
      });
      const accordion = document.getElementById(`accordion-${listing}`);
      // @ts-ignore
      accordion && fromMap ? (accordionElement.scrollTop = accordion.offsetTop) : null;
      setSelectedListingFromParent(list);
      list ? setMapCenter({ lat: +list?.lat, lng: +list?.lng }) : setMapCenter(undefined);
    },
    [selectedListingFromParent, accordionElement], // eslint-disable-line react-hooks/exhaustive-deps
  );
  const handleOpen = (index) => {
    const listing = ListingDetailForMapArray[index];
    if (
      listing.title === (selectedListingFromParent && selectedListingFromParent.title) &&
      listing?.id === (selectedListingFromParent && selectedListingFromParent.id)
    ) {
      handleSetSelectedListingFromParent && handleSetSelectedListingFromParent({} as TDetailForMap, false);
    } else {
      if (open !== index) {
        handleSetSelectedListingFromParent && handleSetSelectedListingFromParent(listing, false);
      }
    }
    open === index ? setOpen(null) : setOpen(index);
  };
  const tabContent: IAllTabs[] = [
    {
      index: 0,
      tab: TabType.listView,
    },
    {
      index: 1,
      tab: TabType.mapView,
    },
  ];

  const [currentItem, setCurrentItem] = useState(0);
  const [isMapLoaded, setIsMapLoaded] = useState(false);
  const handleSetMapLoaded = (x: boolean) => setIsMapLoaded(x);
  const [isTablet, screenSize] = useMediaQuery(tablet);

  const hubGeojson = {};
  data?.forEach(({ deliveryZones }) => {
    getMainDeliveryZone(deliveryZones?.items, hubGeojson);
  });
  const handleToggle = (label) => {
    const clickedTab = tabContent.find((tab) => tab.tab === label);
    clickedTab && setCurrentItem(clickedTab.index);
  };
  const [showFadeBox, setShowFadeBox] = useState(true);

  useEffect(() => {
    if (!accordionElement) return;
    accordionElement.addEventListener('scroll', () => {
      accordionElement.scrollTop + accordionElement.clientHeight >= accordionElement.scrollHeight
        ? setShowFadeBox(false)
        : setShowFadeBox(true);
    });
  }, [accordionElement, open]);

  return (
    <ListingMapContainer>
      <ListContainer>
        <Typography
          data-testid="mapListingTitle"
          variant="h3"
          as={'h2'}
          className={`mb-6 md:mb-2 ${type === MapType.provider ? 'lg:max-w-[433px] min1440:max-w-[606px]' : null}`}
        >
          {title}
        </Typography>
        {autocompleteComponent && (isMapLoaded || !isTablet) && (
          <div data-testid="autocompleteContainer">{autocompleteComponent}</div>
        )}
        {screenSize !== 0 && !isTablet && (
          <div data-testid="mapListingToggle">
            <ToggleButton
              selectedFromProps={true}
              leftLabel={tabContent[0].tab}
              rightLabel={tabContent[1].tab}
              onClick={handleToggle}
            />
          </div>
        )}
        {((screenSize !== 0 && isTablet) || currentItem === 0) && (
          <div className="relative">
            <AccordionContainer data-testid="mapListingAccordionContainer" ref={setAccordionElement} $mapType={type}>
              {ListingDetailForMapArray.map((listing, key) => {
                const defaultMenu =
                  listing?.menuBtns?.find((menu) => menu.isDefault === 'yes') || listing?.menuBtns?.[0];
                const props = {
                  title: listing.title,
                  phoneNumber: listing.phoneNumber,
                  address: { text: listing.address, url: '/' },
                  contactUs: { text: 'Contact us', url: '/contact' },
                  timing: listing.openingTime,
                  image: isDesktop ? listing?.heroImage : listing?.mobileHeroImage,
                  cta: {
                    text: type === MapType.dispensary ? 'Shop this dispensary' : 'Visit website',
                    url: defaultMenu?.url,
                  },
                  email: listing.email,
                  fax: listing.fax,
                };
                return (
                  <DispensaryAccordion
                    open={open === key}
                    handleOpen={handleOpen}
                    // @ts-ignore
                    data={props}
                    index={key}
                    key={key}
                    type={type}
                  />
                );
              })}
            </AccordionContainer>
            <FadeBox
              style={{
                display: `${showFadeBox && isTablet ? 'block' : 'none'}`,
              }}
            />
          </div>
        )}
      </ListContainer>
      {((screenSize !== 0 && isTablet) || currentItem === 1) && (
        <MapContainer data-testid="mapListingMapContainer">
          <MapComponent
            geoJsons={geoJsons}
            host={host}
            data={ListingDetailForMapArray}
            mapStyle={containerStyle}
            selectedListingFromParent={selectedListingFromParent}
            handleSetSelectedListingFromParent={handleSetSelectedListingFromParent}
            geoLoc={geoLoc}
            center={mapCenter}
            open={open}
            setOpen={setOpen}
            page={type}
            handleSetMapLoaded={handleSetMapLoaded}
          />
        </MapContainer>
      )}
    </ListingMapContainer>
  );
};
