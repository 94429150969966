import tw from 'tailwind-styled-components';

interface ITOCProps {
  $isDrawer?: boolean;
  $bottomScroll?: boolean;
  $tocMargin?: boolean;
}

export const TopicsContainer = tw.div<ITOCProps>`
items-start
${(props) =>
  props.$isDrawer
    ? 'hidden md:block lg:ml-[60px] lg:w-[432px]'
    : 'block mx-10 xl:mx-0 xl:ml-10 2xl:mx-[60px] pb-[80px]'}
${(props) =>
  props.$bottomScroll ? 'lg:mb-[400px] lg:max-w-[129px] 2xl:min-w-[220px] 2xl:max-w-[220px]' : 'lg:w-[492px]'}
  ${(props) => (props.$tocMargin ? 'lg:pb-[398px]' : 'lg:py-[88px]')}
`;

export const DrawerListItems = tw.div`
font-arial
text-[14px]
text-grey-400
hover:text-leaf
mb-8`;

export const BottomDrawerButton = tw.div`
fixed
bottom-5
right-5
z-[999]
block
md:hidden`;

export const TopicsContainerList = tw.div<{ $isSticky: boolean }>`
  ${(p) => (p.$isSticky ? 'md:sticky md:top-[180px]' : '')}
  2xl:flex
  2xl:flex-col
  2xl:gap-14
`;
