import tw from 'tailwind-styled-components';

export const MainContainer = tw.div`
   flex
   flex-col
   md:flex-col
   xl:flex-row-reverse
   w-full
   h-auto`;

export const MessageContainer = tw.div`
   flex 
   flex-col
   bg-leaf
   w-full
   pt-14
   px-5
   md:pt-15
   md:px-10
  xl:pt-30
  xl:pb-14
  xl:px-15
  xl:w-[25%]
  min1440:w-[30%]`;

export const DataContainer = tw.div`
    flex
    flex-col
    items-center
    px-5
    pt-5 
    pb-12
    md:flex-row
    md:gap-20
    lg:gap-6
    min1440:gap-20
    bg-grey-100
   xl:px-15
   xl:py-30
    md:px-10
    md:pt-10
    md:pb-15
   xl:w-[75%]
   min1440:w-[70%]`;

export const RewardsAboutCountSection = tw.div`
    flex 
    flex-row 
    items-end
    mb-6`;
export const RewardsGrennContainer = tw.div`
    pt-5 
    md:pt-0 
   xl:pt-0`;

export const RewardMessage = tw.div`
    border-b 
    border-grey-250 
    md:border-0 
    md:[&_p]:!pb-0
    [&_p]:!font-antarctican
    [&_p]:!font-bold
   xl:[&_p]:!text-2xl
    md:[&_p]:!text-xl
   xl:[&_p]:leading-6
    md:[&_p]:!leading-5
   xl:[&_p]:tracking-[0.24px]
    md:[&_p]:!tracking-[0.20px]`;
