import tw from 'tailwind-styled-components';
import CommonModal from 'components/organisms/popups/common-modal';

interface PopUpModalProps {
  iframeUrl: string;
  iframeTitle: string;
  title: string;
  handleClose(): void;
}
const PopUpModal = ({ iframeUrl, title, handleClose, iframeTitle }: PopUpModalProps) => {
  const divID = 'scheduling_modal';

  const SchedulingContainer = tw.div`
    w-full
    overflow-hidden
    border-none
    h-[532px]
  `;
  const HeaderDiv = tw.div`
    bg-[#121314]
    py-3
    px-[15px]
`;

  const HeaderTitle = tw.p`
    text-white
    font-semibold
    text-sm
    leading-[17px]
    mb-0
    uppercase
`;

  return (
    <>
      <CommonModal
        handleClose={handleClose}
        divID={divID}
        closeButtonColor="text-white"
        noBorder={true}
        noPadding={true}
        customModalStyle={'!top-[50%] md:!w-[500px] translate-y-[-50%] h-[532px] overflow-hidden '}
        overlayStyle={'bg-grey-700 bg-opacity-50'}
      >
        <HeaderDiv>
          <HeaderTitle role="heading">{title}</HeaderTitle>
        </HeaderDiv>
        <SchedulingContainer id="reservation">
          <iframe src={iframeUrl} title={iframeTitle} width={'100%'} height={'100%'} />
        </SchedulingContainer>
      </CommonModal>
    </>
  );
};

export default PopUpModal;
