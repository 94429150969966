'use client';

import Image from 'next/image';
import Breadcrumb from 'components/atoms/navigation/breadcrumb/breadcrumb';
import { Typography } from 'components/atoms/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage, TBreadCrumbItem } from 'helpers/types/common.type';
import { tablet } from 'helpers/utils/screensizes';
import { BannerBreadcumbContainer, HeroContainer, MainContainer, OverlayContainer, TitleWrapper } from './styles';

export enum ThemeVariantType {
  dark = 'Dark',
  white = 'White',
}

type THeroProps = {
  title?: string;
  image?: IContentfulImage;
  mobileImage?: IContentfulImage;
  theme?: ThemeVariantType;
  isAboutUs?: boolean;
};

type TCarousalData = {
  homeCarousalData: THeroProps;
  breadcrumb?: TBreadCrumbItem[];
};

const AboutUsHero = ({ homeCarousalData, breadcrumb }: TCarousalData) => {
  const [isTablet] = useMediaQuery(tablet);
  return (
    <>
      <HeroContainer>
        {homeCarousalData?.image && homeCarousalData?.mobileImage && (
          <Image
            loader={(item) => item.src}
            src={isTablet ? homeCarousalData?.image?.url || '' : homeCarousalData?.mobileImage?.url || ''}
            alt={isTablet ? homeCarousalData?.image?.title || '' : homeCarousalData?.mobileImage?.title || ''}
            fill
            style={{ objectFit: 'cover' }}
            priority
          />
        )}
        <OverlayContainer $chooseDarkTemplate={homeCarousalData?.theme === ThemeVariantType?.dark} />

        <MainContainer>
          {breadcrumb && (
            <BannerBreadcumbContainer role="navigation" aria-label="Breadcrumb">
              <Breadcrumb items={breadcrumb} />
            </BannerBreadcumbContainer>
          )}

          <TitleWrapper>
            {homeCarousalData?.title && (
              <Typography
                variant="h1"
                as="p"
                className={`text-center sm:flex sm:justify-center md:text-start lg:col-span-10 xl:col-span-4 ${
                  homeCarousalData?.theme === 'Dark' ? '!text-[#FFFFFF]' : '!text-leaf'
                }`}
              >
                {homeCarousalData?.title}
              </Typography>
            )}
          </TitleWrapper>
        </MainContainer>
      </HeroContainer>
    </>
  );
};

export default AboutUsHero;
