import {
  Accordion,
  AccordionBody,
  AccordionBodyProps,
  AccordionHeader,
  AccordionHeaderProps,
  AccordionProps,
} from '@material-tailwind/react/components/Accordion';
import tw from 'tailwind-styled-components';
interface AccordionBodyBoxType extends AccordionBodyProps {
  $open: boolean;
}
export const AccordionCard: React.FC<AccordionProps> = tw(Accordion)`
    border-y 
    border-gray-300
`;

export const AccordionHeaderCard: React.FC<AccordionHeaderProps> = tw(AccordionHeader)`
    md:items-cetner 
    items-start 
    border-b-0 
    py-[22px] 
    md:py-[30px]  
    lg:py-10
`;
export const AccordionHeaderTitleCard = tw.div`
    md:items-cetner 
    flex 
    flex-row
    items-start 
    gap-4 
    md:gap-8
    justify-between
    w-full
    md:justify-start
`;

export const AccordionBodyBox: React.FC<AccordionBodyBoxType> = tw(AccordionBody)`
    py-0 
    ${(p: AccordionBodyBoxType) => (p.$open ? 'pb-10' : '')}
`;

export const AccordionBodyCard = tw.div`
    items-cetner 
    flex 
    flex-col 
    justify-between 
    gap-6 
    md:flex-row 
    md:gap-[10px]
`;

export const AccordionBodyOrderCard = tw.div`
    flex-start 
    flex 
    flex-col 
    gap-6 
    md:gap-8
`;

export const AccordionBodyOrderBox = tw.div`
    flex 
    flex-col 
    gap-3
`;
export const ProductCardBox = tw.div`
    flex 
    flex-col 
    gap-3
`;

export const FadeBox = tw.div`
    absolute 
    bottom-8 
    z-[1] 
    h-[80px] 
    w-full 
    lg:w-[732px]
    [background:linear-gradient(180deg,rgba(255,255,255,0.00)_0%,#FFF_100%)] 
`;

export const OrderItemBox = tw.div`
    items-cetner 
    flex 
    flex-row 
    gap-2
    text-grey-400
    [&_svg]:!text-grey-400
`;
