import tw from 'tailwind-styled-components';

export const StateDispensaryListWrapper = tw.div`
    bg-grey-100
`;

export const StateDispensaryListContainer = tw.div`
    my-0
    md:my-16
    py-12
    px-5
    md:px-[60px]
    max-w-[1920px]
    mx-auto
    block
    md:flex
`;

export const TitleContainer = tw.div`
    xl:w-[342px]
    min1440:w-[456px]
`;

export const LinkCardContainer = tw.div`
    grid
    gap-6
    min1440:gap-8
    grid-cols-1
    md:grid-cols-3
`;
