'use client';

import React, { useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Typography } from 'components/atoms/typography';
import { dealClicked } from 'helpers/analytics/deal-clicked';
import { SegmentEvents } from 'helpers/constants/forms';
import DiscountEventHeroBanner, { itemsProps } from './discount-event-hero-banner';
import { DiscountEventContainer, DiscountEventContainerSlider, DiscountEventHeaderSection } from './styles';
interface DiscountEventComponentProps {
  host: string;
  title?: string;
  maxItems?: number;
  items: itemsProps[];
}

const DiscountEventCarousel = ({
  host = '',
  title = 'Event Discounts',
  items = [],
  maxItems = 10,
}: DiscountEventComponentProps) => {
  const slickRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 100,
    variableWidth: false,
  };

  const handleSegmentEvent = (type: string, item: any, index: number, options?: object) => {
    dealClicked(type, {
      deal_position: index + 1,
      deal_name: item?.internalName || '',
      deal_title: item?.title || '',
      deal_image: item?.image?.url,
      deal_type: 'group discount',
      link_name: item?.ctaCollection?.items?.[0]?.title || 'Read more',
      link_type: 'button',
      placement: item?.placement,
      ...options,
    });
  };

  const data = items?.filter((item) => item.image && item.title).slice(0, maxItems) || [];

  const onChange = (p: any) => {
    const item = data[p?.startIndex || 0];
    handleSegmentEvent(SegmentEvents.BANNER_SCROLLED, item, p?.startIndex || 0, {
      banner_position: currentIndex,
      banner_type: 'Deals Event Banner',
      banner_title: item?.title,
      banner_internal_name: item?.internalName,
      banner_scroll_direction: currentIndex > p?.page ? 'left' : 'right',
    });
    //@ts-ignore
    slickRef?.current?.slickGoTo?.(p.page - 1);
  };

  const truncateItems = useMemo(
    () =>
      data?.map((item, i: number) => {
        const button = item?.ctaCollection?.items?.[0];
        const buttonLinkWithHost = `${host}${button?.longUrl}`;
        return (
          <DiscountEventHeroBanner
            key={i}
            host={host}
            title={item?.title}
            description={item?.description}
            link={button?.openInNewTab ? button?.longUrl : buttonLinkWithHost}
            image={item?.image}
            buttonLabel={button?.title}
            currentIndex={currentIndex}
            count={data?.length}
            onPageChange={onChange}
            onClick={() => {
              handleSegmentEvent(SegmentEvents.DEAL_CLICKED, item, i);
            }}
          />
        );
      }),
    [data],
  );

  return (
    <>
      {truncateItems?.length > 0 && (
        <DiscountEventContainer>
          <DiscountEventHeaderSection>
            <Typography variant="h2">{title}</Typography>
          </DiscountEventHeaderSection>
          <DiscountEventContainerSlider>
            <Slider
              {...settings}
              afterChange={(newIndex) => {
                setCurrentIndex(newIndex);
              }}
              ref={slickRef}
            >
              {truncateItems}
            </Slider>
          </DiscountEventContainerSlider>
        </DiscountEventContainer>
      )}
    </>
  );
};

export default DiscountEventCarousel;
