import { useEffect, useMemo } from 'react';
import { formatDate } from 'date-fns';
import { useInfiniteHits, usePagination, useSortBy } from 'react-instantsearch';
import { AutoSizer, CellMeasurer, CellMeasurerCache, ColumnSizer, Grid, WindowScroller } from 'react-virtualized';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import ArticleCardVertical from 'components/molecules/cards/article-card-vertical';
import { getGTIIndexName } from 'helpers/algolia/get-index-name';
import { cardListClickedTrack } from 'helpers/analytics/card-list-clicked-track';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import RecommendedResources, { TRecommendedResourcesProps } from './recommended-resources';
import { GridArticleCardsBox, GridArticleCardsWrapper, GridArticleContainer, GridArticlePagination } from './styles';

type TProps = {
  title: string;
  recommendedResourcesData: TRecommendedResourcesProps;
  tasticPlacementNumber?: number;
  sortValue: string;
};

const GridArticleAlgolia = (prop) => {
  const items: any = useInfiniteHits(prop);
  const [isTablet] = useMediaQuery(tablet);
  const windowWitdh = window === undefined ? 0 : window?.outerWidth;
  const gap = (col: number) =>
    tablet < windowWitdh
      ? 1920 > windowWitdh
        ? { paddingBottom: 60, paddingLeft: col == 1 ? 40 : 0 }
        : { paddingBottom: 96, paddingLeft: col == 1 ? 96 : 0 }
      : { paddingBottom: 24 };
  const columnCount = windowWitdh > tablet ? 2 : 1;
  const blogItems = items?.hits;
  const cache = useMemo(() => {
    return new CellMeasurerCache({
      minWidth: {
        height: 356,
        width: 350,
      },
      fixedWidth: true,
    });
  }, []);

  const rowCount = Math.round(blogItems.length / columnCount);
  const cellRenderer = ({ columnIndex, key, rowIndex, style, parent }) => {
    const id = rowIndex * columnCount + columnIndex;
    const data = blogItems[id];
    if (!data || blogItems?.length === 0 || id >= blogItems?.length) return null;

    return (
      <CellMeasurer cache={cache} columnIndex={columnIndex} key={key} parent={parent} rowIndex={rowIndex}>
        {({ registerChild, measure }) => (
          <div ref={registerChild} style={{ ...style, ...gap(columnIndex) }} role="row">
            <ArticleCardVertical
              onLoad={measure}
              ctaCollection={{ title: 'Read More', url: data?.slug }}
              title={data?.name}
              description={data?.description}
              key={rowIndex}
              articleCardLabel={{
                category: data?.category,
                time:
                  typeof data?.published_date === 'number'
                    ? formatDate(new Date(Number(data?.published_date) * 1000), 'MM.dd.yyyy')
                    : data?.published_date,
              }}
              image={{
                url: isTablet ? data?.image?.desktopImage?.url : data?.image?.mobileImage?.url,
                title: isTablet ? data?.image?.desktopImage?.title : data?.image?.mobileImage?.title,
              }}
              recipeLabel={{
                time: data?.other_display_info?.preparationTime,
                level: data?.other_display_info?.preparationDifficulty?.join(' | '),
              }}
            />
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <GridArticleCardsWrapper>
      <WindowScroller>
        {({ height, isScrolling, onChildScroll, scrollTop }) => (
          <AutoSizer disableHeight onResize={() => cache.clearAll()}>
            {({ width }) => (
              <ColumnSizer columnCount={columnCount} width={width}>
                {({ adjustedWidth, columnWidth, registerChild }) => {
                  return (
                    <Grid
                      ref={registerChild}
                      scrollTop={scrollTop}
                      isScrolling={isScrolling}
                      onScroll={onChildScroll}
                      cellRenderer={cellRenderer}
                      columnCount={columnCount}
                      autoHeight
                      height={height}
                      width={adjustedWidth}
                      columnWidth={columnWidth}
                      rowCount={rowCount}
                      rowHeight={cache.rowHeight}
                    />
                  );
                }}
              </ColumnSizer>
            )}
          </AutoSizer>
        )}
      </WindowScroller>
    </GridArticleCardsWrapper>
  );
};

const GridArticlePaginations = (props: any) => {
  const items = useInfiniteHits(props);
  const page = usePagination(props);
  const remainingCards = page?.nbHits - items?.hits?.length;
  const buttonText = remainingCards > 6 ? `Show more (6)` : `Show more (${remainingCards})`;

  const { options, refine } = useSortBy({
    ...props,
    items: [
      { label: 'Oldest', value: getGTIIndexName('site-pages') },
      { label: 'Newest', value: getGTIIndexName('site-pages-by-pub-date-desc') },
    ],
  });

  const handleSegmentEvent = () => {
    const cardListData = {
      card_list_name: 'Blog Grid Article',
      card_list_title: props?.title || '',
      card_list_type: '',
      card_list_facet_name: '',
      card_list_facet_value: '',
      card_list_url: '',
      link_name: buttonText,
      placement: props?.tasticPlacementNumber || 0,
      link_type: 'hyperlink',
    };
    cardListClickedTrack(cardListData);
  };

  useEffect(() => {
    const selectedItem = options?.find((item) => item?.label === props?.sortValue);
    refine(selectedItem?.value as string);
  }, [props?.sortValue]);

  return !page?.isLastPage ? (
    <GridArticlePagination>
      <Typography variant="body-small">{`Showing ${items?.hits?.length || 0} of ${page?.nbHits}`}</Typography>
      <CommonButton
        onClick={() => {
          page.refine(page.currentRefinement + 1), handleSegmentEvent();
        }}
        button-type="secondary"
        color="green"
        size="sm"
      >
        {buttonText}
      </CommonButton>
    </GridArticlePagination>
  ) : (
    <GridArticlePagination>
      <Typography variant="body-small">{`Showing ${page?.nbHits} of ${page?.nbHits}`}</Typography>
    </GridArticlePagination>
  );
};

const GridArticles = ({ title, recommendedResourcesData, tasticPlacementNumber, sortValue }: TProps) => {
  return (
    <GridArticleContainer>
      <GridArticleCardsBox>
        <Typography variant="h4">{title}</Typography>
        <GridArticleAlgolia />
        <GridArticlePaginations title={title} tasticPlacementNumber={tasticPlacementNumber} sortValue={sortValue} />
      </GridArticleCardsBox>
      <RecommendedResources {...recommendedResourcesData} />
    </GridArticleContainer>
  );
};

export default GridArticles;
