'use client';

import { useEffect } from 'react';
import HomeDispensary from 'components/molecules/home-dispensary';
import SideBar from 'components/organisms/drawers/sidebar';
import Loading from 'components/organisms/global/loader';
import { TSelectedSubItem } from 'helpers/types';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setUserHomeDispensary } from 'redux/reducers/account';

const HomeDispensaryTastic = (data: any) => {
  const homeDispensaryLoading = useAppSelector((store) => store.accountReducer.homeDispensary.loading);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setUserHomeDispensary({
        data: data?.data?.dispensaryInfo?.dataSource?.data?.page?.items[0],
      }),
    );
  }, []);

  return (
    <div className="md:flex">
      {homeDispensaryLoading && <Loading showbackdrop />}
      <SideBar selectedItem={TSelectedSubItem.HOME_DISPENSARY} />
      <HomeDispensary />
    </div>
  );
};

export default HomeDispensaryTastic;
