import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import { ContactUsItem, HeadingContainer, Item, MapCardContainer } from 'components/molecules/cards/map-card/style';
import { communicationInitiated } from 'helpers/analytics/communication-initiated-track';
import { MapType } from 'helpers/types';
import { ReplaceUrlWithHost, getLinkTarget } from 'helpers/utils/common';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import CloseIcon from 'public/icons/figma/close-small.svg';
import FaxIcon from 'public/icons/figma/fax.svg';
import LocationIcon from 'public/icons/figma/location.svg';
import MailIcon from 'public/icons/figma/mail.svg';
import PhoneIcon from 'public/icons/figma/phone.svg';

type TProps = {
  title: string;
  address?: string;
  host: string;
  phoneNumber?: string;
  onCloseClick?: () => void;
  menuBtns?: Record<string, any>[];
  page: string;
  email?: string;
  fax?: string;
};

const MapCard = ({ title, address, host, phoneNumber, onCloseClick, menuBtns, page, email, fax }: TProps) => {
  const copyMenuBtns = menuBtns ? [...menuBtns] : [];
  let deliveryButton;

  const isDispensaryMap = page === MapType.dispensary;
  if (isDispensaryMap) {
    copyMenuBtns.forEach((button, index) => {
      if (copyMenuBtns[index].name.toLowerCase().includes('delivery')) {
        //@ts-ignore
        deliveryButton = button;
        copyMenuBtns.splice(index, 1);
      }
    });
  }

  const handleClickedEvent = () => {
    const data = {
      method: 'call',
      link_name: '/contact',
      link_type: 'hyperlink',
      communication_trigger: 'dispensary-map',
    };
    communicationInitiated(data);
  };

  const replaceWithHost = ReplaceUrlWithHost('{{url}}' + copyMenuBtns?.[0]?.url, host);

  return (
    <MapCardContainer>
      <HeadingContainer>
        <Typography variant="h5" className="mb-4 !text-grey-500" data-testid="mapCardTitle">
          {title}
        </Typography>
        <CloseIcon className="absolute right-8 top-6 h-5 w-5 cursor-pointer" onClick={onCloseClick} />
      </HeadingContainer>
      {phoneNumber && isDispensaryMap && (
        <Item>
          <div>
            <PhoneIcon className="h-6 w-6" />
          </div>

          <Typography variant="body-small" data-testid="mapCardPhone">
            {phoneNumber}
          </Typography>
        </Item>
      )}
      {address && (
        <Item>
          <div>
            <LocationIcon className="h-6 w-6" />
          </div>

          <Typography variant="body-small" data-testid="mapCardAddress">
            {address}
          </Typography>
        </Item>
      )}
      {phoneNumber && !isDispensaryMap && (
        <Item>
          <div>
            <PhoneIcon className="h-6 w-6" />
          </div>

          <Typography variant="body-small" data-testid="mapCardPhone">
            {phoneNumber}
          </Typography>
        </Item>
      )}
      {email && !isDispensaryMap && (
        <Item>
          <div>
            <MailIcon className="h-6 w-6" />
          </div>

          <Typography variant="body-small" data-testid="mapCardEmail">
            {email}
          </Typography>
        </Item>
      )}
      {fax && !isDispensaryMap && (
        <Item>
          <div>
            <FaxIcon className="h-6 w-6" />
          </div>

          <Typography variant="body-small" data-testid="mapCardFax">
            {fax}
          </Typography>
        </Item>
      )}
      {isDispensaryMap && (
        <ContactUsItem>
          <MailIcon className="h-6 w-6" />
          <IconWithTextButton
            color="green"
            button-type="tertiary"
            iconPosition="after"
            icon={<ChevronRightIcon className="!mr-0" />}
            className="!p-0"
            as="a"
            href={`${host}/contact/`}
            onClick={handleClickedEvent}
            data-testid="mapCardContactButton"
          >
            {'Contact us'}
          </IconWithTextButton>
        </ContactUsItem>
      )}
      {copyMenuBtns && copyMenuBtns.length > 0 && copyMenuBtns?.[0]?.url && (
        <div>
          <CommonButton
            data-testid="mapCardButton"
            size="sm"
            className="mt-5 inline-block whitespace-nowrap"
            color="green"
            button-type="secondary"
            as="a"
            target={getLinkTarget(replaceWithHost)}
            href={replaceWithHost}
          >
            {isDispensaryMap ? 'Shop this dispensary' : 'Visit website'}
          </CommonButton>
        </div>
      )}
      {deliveryButton && deliveryButton.url && (
        <div>
          <CommonButton
            data-testid="mapCardButtonDelivery"
            size="sm"
            className="mt-3 inline-block whitespace-nowrap"
            color="green"
            button-type="secondary"
            as="a"
            href={`${host}${deliveryButton.url}`}
          >
            Shop delivery
          </CommonButton>
        </div>
      )}
    </MapCardContainer>
  );
};

export default MapCard;
