import tw from 'tailwind-styled-components';

export const SiteMapContainer = tw.div`
    flex
    flex-col
    items-start
    self-stretch
    py-14
    px-5

    xl:py-20
    xl:px-10
    xl:gap-[60px]

    2xl:max-w-[2200px]
    2xl:py-[120px]
    2xl:px-[60px]
    2xl:gap-[60px]
`;

export const SiteMapHtmlContent = tw.div`
    flex
    flex-col
    flex-start
    gap-2.5
    self-stretch

    [&_li]:flex
    [&_li]:flex-col
    [&_li]:flex-start
    [&_li]:gap-2.5
    [&_li]:self-stretch
    [&_li]:w-full

    [&_ul]:flex
    [&_ul]:flex-col
    [&_ul]:flex-start
    [&_ul]:gap-2.5
    [&_ul]:self-stretch
    [&_ul]:w-full

    [&_a]:w-full

    xl:[&_a]:w-[736px]

    [&>ul>li]:pl-6

    [&>ul>li>ul>li]:pl-6

    [&>ul>li>ul>li>ul>li]:pl-6

    text-leaf
    font-arial
    text-sm
    leading-4
    not-italic
    font-bold
    tracking-[0.14px]
    [&_a]:underline

    hover:[&_a]:text-kief

    xl:text-[15px]
    xl:tracking-[0.15px]

    2xl:text-base
    2xl:leading-5
    2xl:tracking-[0.16px]
`;
