'use client';

import TerpenesList from 'components/organisms/listings/terpenes-information';
import useProductDetails from 'helpers/hooks/use-product-details';

const TerpenesListTastic = ({ pageFolder, data }: any) => {
  const { labResults, productDetailsData, cms } = useProductDetails({ pageFolder });
  const newData = labResults?.lab_results
    ?.filter((item) => item?.value > 0)
    .map((item) => ({
      title: item?.cfData?.heading || item.unit_id,
      subTitle: item?.cfData?.description,
      percentage: String(item?.value),
      logo: item?.cfData?.image?.desktopImage || {
        url: 'https://images.ctfassets.net/w0o6opvxrq0m/65PD0btqBj228KB2YKDgfx/120d90778be66768821ea6c34182f296/Generic-Terpene.png',
        height: 64,
        width: 64,
        title: item?.cfData?.heading || item.unit_id,
      },
      tooltip: item?.cfData?.tooltip,
    }));
  return (
    !productDetailsData?.outOfStock &&
    labResults?.lab_results?.length > 0 && (
      <TerpenesList
        title={cms?.terpeneSectionHeading}
        description={cms?.terpeneSectionDescription}
        tasticPlacementNumber={data?.tasticPlacementNumber}
        productItems={newData || []}
      />
    )
  );
};

export default TerpenesListTastic;
