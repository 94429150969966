'use client';

import { MapListing } from 'components/organisms/listings/map-listing';
import { MapType } from 'helpers/types';
import { convertToDispensaryType } from 'helpers/utils/convert-to-dispensary-type';
import { getMainDeliveryZone } from 'helpers/utils/delivery-zone';
import { sortListByTitle } from 'helpers/utils/sort-list-by-title';

interface TProps {
  pageFolder: Record<string, any>;
}
const DispensaryMapTastic = ({ pageFolder }: TProps) => {
  const { preloadedValue } = pageFolder?.dataSourceConfigurations?.find((item) => item?.streamId === '__master') || {};
  const statePageDispensariesCollection = preloadedValue?.data?.pageDispensaryCollection?.items || [];
  const hubGeojson = {};
  statePageDispensariesCollection &&
    statePageDispensariesCollection?.forEach(({ deliveryZones }) => {
      getMainDeliveryZone(deliveryZones?.items, hubGeojson);
    });
  const state = preloadedValue?.data?.pageStateCollection?.items?.[0];
  const title = `Dispensaries in ${state?.breadcrumbTitle}`;
  const list = convertToDispensaryType(
    sortListByTitle(
      preloadedValue?.data.pageDispensaryCollection?.items?.map((item) => ({
        ...item,
        title: item?.dispensaryInformation?.title,
      })),
    ),
  );
  return (
    <MapListing
      title={title}
      geoJsons={Object.values(hubGeojson)}
      // @ts-ignore
      data={list}
      host={pageFolder?.host}
      type={MapType.dispensary}
    />
  );
};

export default DispensaryMapTastic;
