import tw from 'tailwind-styled-components';

export const RewardsMatrixJoinContainer = tw.div`
    bg-leaf
    h-1/3
    order-0
    md:h-auto
    md:w-1/3
    md:order-2
`;

export const RewardsMatrixJoinInnerContainer = tw.div`
    pt-[56px]
    pb-[32px]
    px-5
    md:pt-[120px]
    md:pb-[220px]
    md:pr-[60px]
    md:pl-[176px]
`;

export const RewardsMatrixSubtitleContainer = tw.div`
    pt-4
    pb-8
    2xl:w-[304px]
    md:pt-6
    md:pb-10
`;
