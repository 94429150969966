'use client';

import { useEffect, useState } from 'react';
import SideBar from 'components/organisms/drawers/sidebar';
import FormPersonalInformation from 'components/organisms/forms/personal-information';
import { USStatesAbbr } from 'helpers/constants/us-states-abbr';
import { TSelectedSubItem } from 'helpers/types';
import { useAppSelector } from 'redux/hooks';
import JaneAPI from '../../../../helpers/jane-api';

const ProfileTastic = () => {
  const user = useAppSelector((state) => state.accountReducer.user);
  const [userAddess, setUserAddress] = useState<string>('');
  const { given_name, family_name, birthdate, phone_number, email } = user?.attributes || {};

  useEffect(() => {
    if (user) {
      JaneAPI.getUser().then((res) => {
        setUserAddress(res?.data?.data?.delivery_address);
      });
    }
  }, [user]);

  const stateInCapital = USStatesAbbr[user?.attributes['custom:current_state']]?.toLowerCase() || [];
  const stateToShow = stateInCapital[0]?.toUpperCase() + stateInCapital?.slice(1);

  return (
    <div className="md:flex">
      <SideBar selectedItem={TSelectedSubItem.PROFILE} />
      <FormPersonalInformation
        firstName={given_name}
        lastName={family_name}
        dob={birthdate}
        phone={phone_number?.split('+1')}
        email={email}
        state={stateToShow}
        address={userAddess}
      />
    </div>
  );
};

export default ProfileTastic;
