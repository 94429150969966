'use client';

import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AccountOverview from 'components/organisms/accounts/overview';
import SideBar from 'components/organisms/drawers/sidebar';
import { accountOverviewGetUserInfoApi } from 'helpers/account-api';
import { AccountUrl, TSelectedSubItem } from 'helpers/types';
import { getPathMenuFiltersLink } from 'helpers/utils/common';
import bottomImg from 'public/images/rewards-hero.png';
import { RootState } from 'redux/store';
interface userInfoResponseProps {
  balance: number;
}
interface AccountOverviewTasticProps {
  data: {
    accountOverview: { dataSource: any };
    dispensaryInfo: { dataSource: any };
  };
}

const AccountOverviewTastic = ({ data }: AccountOverviewTasticProps) => {
  console.log('AccountOverviewTastic', data);

  const [userInfo, setUserInfo] = useState<userInfoResponseProps>({ balance: 0 });

  const accountOverview =
    data?.accountOverview?.dataSource?.data?.pageAccountCollection.items?.[0]?.bodyCollection?.items?.[0];
  const dispensary = data?.dispensaryInfo?.dataSource?.data?.page.items?.[0];
  const homeDispensaryImage =
    dispensary?.homeDispensary?.desktopImage ||
    dispensary?.homeDispensary?.mobileImage ||
    dispensary?.heroImage ||
    dispensary?.mobileHeroImage;
  const slug = AccountUrl.HOME_DISPENSARY;
  const homeTitle = dispensary?.dispensaryInformation?.title;
  const description = accountOverview?.description;
  const image = accountOverview?.image;
  const title = accountOverview?.title;
  const cta = accountOverview?.ctaCollection?.items?.[0];

  const accountUser = useSelector((state: RootState) => state?.accountReducer?.user?.attributes) || {};
  const stateCode = accountUser?.['custom:home_state'];
  const phoneNumber = accountUser?.['phone_number'];
  const email = accountUser?.['email'];

  useEffect(() => {
    if (stateCode && phoneNumber) {
      (async () => {
        const users = await accountOverviewGetUserInfoApi({ stateCode, phoneNumber, email });
        users?.data?.member && setUserInfo(users.data.member);
      })();
    }
  }, [stateCode, phoneNumber]);

  return (
    <div className="md:flex">
      <SideBar selectedItem={TSelectedSubItem.OVERVIEW} />
      <AccountOverview
        firstName={accountUser.given_name}
        fullName={`${accountUser.given_name} ${accountUser.family_name}`}
        email={accountUser.email}
        message={title}
        subMessage={description}
        buttonLabel={cta?.title}
        emptyStateLink={getPathMenuFiltersLink(cta?.longUrl)}
        topImg={homeDispensaryImage}
        topLink={slug}
        topTitle="HOME DISPENSARY"
        topDescription={homeTitle}
        bottomImg={{
          url: bottomImg.src,
        }}
        bottomLink={AccountUrl.REWARDS}
        bottomTitle="Accumulated points"
        bottomDescription={`${userInfo.balance}`}
        emptyStateImg={image}
      />
    </div>
  );
};

export default AccountOverviewTastic;
