import tw from 'tailwind-styled-components';

export const HeroBanner = tw.div`
    xl:min-h-[412px]
    xl:h-[584px]
    min-h-[360px]
    md:min-h-[300px]
    lg:min-h-[360px]
    bg-grey-100
    md:flex
    max-w-[1920px]
    mx-auto
    overflow-hidden
`;

export const BannerImageContainer = tw.div`
relative
md:order-last
`;

export const MainBannerImageContainer = tw.div`
xl:ml-[120px]
md:text-right
xl:my-[88px]
lg:min-w-[400px]
xl:min-w-[668px]
xl:max-w-[707px]
lg:my-[70px]
md:my-[50px]
`;

export const OverlayBannerImageContainer = tw.div`
absolute
xl:top-[152px]
min-w-[130px]
md:min-w-[150px]
left-[240px]
top-[88px]
lg:top-[150px]
md:-left-[100px]
xl:left-0
xl:min-w-[280px]
xl:mr-[588px]
z-[1]
`;

export const BannerTextContainer = tw.div`
xl:w-[884px]
xl:pl-[60px]
px-5
w-full
`;

export const BannerBreadcumbContainer = tw.div`
 pt-6
 xl:py-[60px]
 md:py-10

`;
