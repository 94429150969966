import ReactStars from 'react-rating-stars-component';
import { Typography } from 'components/atoms/typography';
import StarEmptyIcon from 'public/icons/figma/rating-star-empty.svg';
import StarFilledIcon from 'public/icons/figma/rating-star-filled.svg';
import StarHalfFilledIcon from 'public/icons/figma/rating-star-half.svg';
import ReviewQuotes from 'public/icons/figma/review-qoutes.svg';
import { CommentContainer, RatingContainer, StarContainer, UserReviewContainer } from './styles';

interface UserReviewProps {
  author: string;
  comment: string;
  rating: string;
}
export const UserReview = ({ author, comment, rating }: UserReviewProps) => {
  return (
    <UserReviewContainer>
      <RatingContainer>
        <Typography variant="h2" as={'p'} className="rating mb-0 mr-5 !text-grey-500 md:mr-0">
          {rating}
        </Typography>
        <StarContainer>
          <ReactStars
            count={5}
            value={parseFloat(rating)}
            size={24}
            color={'#FFF'}
            activeColor={'#CF4520'}
            edit={false}
            halfIcon={<StarHalfFilledIcon />}
            filledIcon={<StarFilledIcon />}
            emptyIcon={<StarEmptyIcon color="#CF4520" />}
            isHalf={true}
          />
        </StarContainer>
      </RatingContainer>
      <CommentContainer>
        <ReviewQuotes className="h-[30px] w-8 text-resin" />
        <Typography variant="body-large" className="comment my-[18px] !text-grey-400 md:my-8">
          {comment}
        </Typography>
        <Typography variant="body-large-bold" className="author !text-grey-500">
          {author}
        </Typography>
      </CommentContainer>
    </UserReviewContainer>
  );
};
