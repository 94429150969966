'use client';

import React from 'react';
import BannerStatementLong from 'components/organisms/banners/banner-statement/banner-content-long';

const AboutUsQuotes = (props: any) => {
  const { data } = props;
  if (!data?.quotesData || data?.quotesData?.dataSource?.isError) {
    return <></>;
  }
  const quotesData = data?.quotesData?.dataSource?.data?.page?.items[0] || {};
  const cannabisQuotesCollection = quotesData?.cannabisQuotesCollection;
  const leftSideImage = quotesData?.leftSideImage;
  const rightSideImage = quotesData?.rightSideImage;
  const overlayImage = quotesData?.overlayImage;

  return (
    <BannerStatementLong
      leftSideImage={leftSideImage}
      rightSideImage={rightSideImage}
      overlayImage={overlayImage}
      cannabisQuotesCollection={cannabisQuotesCollection}
    />
  );
};

export default AboutUsQuotes;
