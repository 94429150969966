import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionProps,
} from '@material-tailwind/react/components/Accordion';
import tw from 'tailwind-styled-components';
import { IAccordionHeaderComponent } from 'helpers/types/molecules/accordions/accordions';

export const AccordionComponent: React.FC<AccordionProps> = tw(Accordion)`
  flex
  flex-col
  border-b
  border-b-grey-300
  hover:border-b-grey-480
  border-t
  border-t-grey-300
  hover:border-t-grey-480
  ${(p: AccordionProps) => (p.open ? 'gap-[24px]' : 'gap-0')}
  py-[24px]
  ${(p: AccordionProps) => (p.open ? 'lg:py-[40px] lg:!gap-[32px]' : 'lg:py-[30px] lg:gap-0')}
`;

export const AccordionHeaderComponent: React.FC<IAccordionHeaderComponent> = tw(AccordionHeader)`
  border-none
  !py-0
  [&_span_svg]:w-[24px]
  [&_span_svg]:h-[24px]
  group
  ${(p: IAccordionHeaderComponent) => (p.$active ? '[&_h5]:text-grey-500' : '[&_h5]:text-grey-400')}
`;

export const AccordionBodyComponent = tw(AccordionBody)`
  !py-0
`;
