'use client';

import Image from 'next/image';
import { BLOCKS } from '@contentful/rich-text-types';
import Breadcrumb from 'components/atoms/navigation/breadcrumb/breadcrumb';
import { Typography } from 'components/atoms/typography';
import ResponsiveImageOrVideo from 'components/molecules/image-or-video/responsive-image-or-video';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { HeroContentVariant, IContentfulImage, IRichTextData, TBreadCrumbItem } from 'helpers/types';
import { tablet } from 'helpers/utils/screensizes';
import {
  BannerBreadcumbContainer,
  BannerImageContainer,
  BannerTextContainer,
  HeroBanner,
  MainBannerImageContainer,
  OverlayBannerImageContainer,
} from './hero-content-styles';

interface IHeroBannerProps {
  title?: string;
  description?: IRichTextData;
  mainImage?: IContentfulImage;
  mobileImage?: IContentfulImage;
  overlayImage?: IContentfulImage;
  breadcrumb?: TBreadCrumbItem[];
  varaint?: HeroContentVariant;
}

const HeroBannerComponent = ({
  title,
  description,
  mainImage,
  mobileImage,
  overlayImage,
  breadcrumb,
}: IHeroBannerProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const titlePaddingTop = overlayImage ? 'pt-6 md:pt-0' : 'lg:pt-20';

  return (
    <HeroBanner>
      <BannerImageContainer>
        <MainBannerImageContainer>
          <ResponsiveImageOrVideo
            desktop={mainImage as IContentfulImage}
            mobile={mobileImage as IContentfulImage}
            imageProps={{
              className: '2xl:h-[408px]',
              alt: 'hero-banner',
            }}
          />
        </MainBannerImageContainer>
        {overlayImage && (
          <OverlayBannerImageContainer>
            <Image
              src={overlayImage.url || ''}
              width={isTablet ? 280 : 130}
              height={isTablet ? 280 : 130}
              title={overlayImage.title}
              loader={({ src }) => src}
              alt={overlayImage.alt || ''}
              layout="responsive"
              aria-label={overlayImage.alt}
              priority
            />
          </OverlayBannerImageContainer>
        )}
      </BannerImageContainer>
      <BannerTextContainer>
        {breadcrumb && (
          <BannerBreadcumbContainer role="navigation" aria-label="Breadcrumb">
            <Breadcrumb items={breadcrumb} />
          </BannerBreadcumbContainer>
        )}
        {title && (
          <Typography variant="h2" className={`${!breadcrumb && titlePaddingTop}`}>
            {title}
          </Typography>
        )}
        {description && (
          <ContentfulRichText
            data={description}
            renderNodeOptions={{
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <Typography variant="body-large" className="pb-10 pt-[24px] !text-grey-400 lg:w-[432px]">
                  {children}
                </Typography>
              ),
            }}
          />
        )}
      </BannerTextContainer>
    </HeroBanner>
  );
};

export default HeroBannerComponent;
