import tw from 'tailwind-styled-components';

export const FaqsContainer = tw.div`
    px-5
    py-16
    lg:p-0
    lg:flex
    max-w-[1920px]
    mx-auto
`;

export const FaqCategoriesContainer = tw.div`
    px-5
    py-16
    xl:px-0
    xl:py-0
    max-w-[1920px]
    mx-auto
`;

export const FaqImageContainer = tw.div`
    pb-8
    lg:pl-[60px]
    xl:pr-[304px]
    lg:pt-[152px]
    lg:pb-[216px]
`;

export const FaqBodyContainer = tw.div`
    lg:py-[60px]
    xl:pr-[120px]
    2xl:py-[120px]
    2xl:pr-[212px]
`;

type TFaqDataContainerProps = {
  $isGap?: boolean;
};

export const FaqDataContainer = tw.div<TFaqDataContainerProps>`
    mb-2
    scroll-mt-[200px]
    lg:max-w-[888px]
    2xl:flex
    2xl:flex-col
    ${(p) => (p.$isGap ? '' : '2xl:gap-10')}
`;

export const EmptyDiv = tw.div`
    hidden
    2xl:flex
    2xl:h-[929px]
    2xl:min-w-[113px]
    2xl:w-[328px]
    2xl:max-w-[328px]
    2xl:grow
    2xl:shrink-0
    2xl:basis-0
`;

// Note to developer - FlexList can can not have items-start as per Figma. This will break TOC sticky
export const FlexList = tw.div`
    md:flex
    2xl:space-x-[328px]
    xl:space-x-[50px]
    2xl:space-x-[0px]
    2xl:max-w-[2200px]
    2xl:grow
    2xl:shrink-0
    2xl:basis-0
`;
