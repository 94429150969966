'use client';

import { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import ArticleCardsVariantB from 'components/organisms/media/article-cards/variant-b-article-cards';
import { cardListClickedTrack } from 'helpers/analytics/card-list-clicked-track';
import { ReplaceUrlWithHost } from 'helpers/utils/common';
import { RootState } from 'redux/store';

interface TProps {
  data: {
    variant: string;
    sectionData: any;
    backgroundColor: string;
    showtitlepagination?: boolean;
    tasticPlacementNumber: any;
  };
}

const ArticleCardsTastic: FunctionComponent = ({ data }: TProps) => {
  const host = useSelector((state: RootState) => state.hostReducer.host);
  const background = data?.backgroundColor === 'Grey' ? 'bg-grey-100' : 'bg-light';
  const showTitlePagination = data?.showtitlepagination === false ? false : true;
  const sectionData = data?.sectionData?.dataSource?.data?.page?.items?.[0];
  const showMoreCta = Boolean(sectionData?.relatedArticlesCta);
  const ctaUrl = ReplaceUrlWithHost(sectionData?.relatedArticlesCta?.longUrl, host);

  const handleSegmentEvent = () => {
    const cardListData = {
      card_list_name: 'Article List - Search',
      card_list_title: sectionData.relatedArticlesHeading,
      card_list_type: '',
      card_list_facet_name: '',
      card_list_facet_value: '',
      card_list_url: ctaUrl,
      link_name: sectionData?.relatedArticlesCta?.title,
      placement: data?.tasticPlacementNumber + 1 || 1,
      link_type: 'hyperlink',
    };
    cardListClickedTrack(cardListData);
  };
  const commonData = {
    articles: sectionData?.relatedArticlesCollection?.items || [],
    showTitlePagination,
    bgColor: background || 'bg-grey-100',
    sectionTitle: sectionData?.relatedArticlesHeading || 'Related Articles',
    sectionDescription: sectionData?.relatedArticlesDescription,
    as: 'a',
    href: ctaUrl || '/',
    buttonText: sectionData?.relatedArticlesCta?.title || '',
    showMore: showMoreCta,
    onClick: () => handleSegmentEvent(),
  };
  return <ArticleCardsVariantB {...commonData} />;
};

export default ArticleCardsTastic;
