import React from 'react';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import QuantitySelector from 'components/atoms/buttons/quantity-selector';
import { WeightSelectorButton } from 'components/atoms/buttons/weight-selector';
import { Typography } from 'components/atoms/typography';
import { SegementAddToCartTriggerSource } from 'helpers/analytics/cart-product-added';
import useDispensary from 'helpers/hooks/use-dispensary';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TProductSize } from 'helpers/types/organisms/listing/product-details';
import { useCartState } from 'helpers/utils/cart-funationaltiy';
import { mobile } from 'helpers/utils/screensizes';
import ChevronLeftIcon from 'public/icons/figma/chevron-left.svg';
import ProductCardTypography from '../../product-card-typography';
import {
  CardConfirmHorizontalBox,
  CardConfirmHorizontalButtonBox,
  CardConfirmHorizontalWeightsBox,
  CardHorizontalBodyContainers,
  CardHorizontalContainers,
  CardHorizontalFooterBox,
} from '../styles';

type TProps = {
  showFavorite?: boolean;
  containersStyle?: string;
  horizontalImagePosition?: 'left' | 'right';
  horizontalShowBorder?: boolean;
  horizontalFooterSection?: boolean;
  name: string;
  brandName: string;
  price: string;
  onClose: () => void;
  handleSelectedWeight?: (item: any) => void;
  weights?: TProductSize[];
  order?: number;
  kind?: string;
  isShowWeightSelector?: boolean;
  defaultWeightSelect: string;
  position?: number;
  maxCartLimit?: number;
  productId?: number;
  thc: string;
  offer: string;
  category: string;
  discountPrice?: string;
};
const ProductCardConfirmHorizontal = ({
  containersStyle,
  name,
  brandName,
  horizontalShowBorder = true,
  horizontalFooterSection = true,
  price,
  onClose,
  isShowWeightSelector,
  offer,
  kind,
  order,
  thc,
  weights,
  defaultWeightSelect,
  position,
  handleSelectedWeight,
  maxCartLimit,
  productId,
  discountPrice,
}: TProps) => {
  const [isMobile] = useMediaQuery(mobile);
  const tags = thc.split(',').map((tag) => tag.trim());
  const { currentDispensary } = useDispensary();
  const storeId = currentDispensary?.dispensaries?.storeId;
  const store_id = parseInt(storeId as string);
  const price_id = parseFloat(price);
  const discount_price = parseFloat(discountPrice as string);
  const cartProps = {
    tags: tags,
    position: position,
    defaultWeightSelect: defaultWeightSelect,
    productId: productId,
    placement: order,
    storeId: store_id,
    brand: brandName,
    strain: name,
    originalPrice: price_id,
    price: discount_price,
    offerText: offer,
    kind: kind,
  };
  const triggeredFrom = SegementAddToCartTriggerSource.NON_PDP;
  const {
    selectedQuantity,
    selectedWeight,
    setSelectedWeight,
    isSelectedWeightInCart,
    setSelectedQuantity,
    handleCartClick,
    existingItem,
  } = useCartState(cartProps, triggeredFrom, onClose);
  const totalPrice = price_id * selectedQuantity;

  const handleWeightChange = async (item: TProductSize) => {
    handleSelectedWeight && handleSelectedWeight(item);

    if (item?.name !== selectedWeight) {
      setSelectedWeight(item?.name);
      setSelectedQuantity(1);
    }
  };

  return (
    <CardHorizontalContainers className={containersStyle}>
      <CardConfirmHorizontalBox>
        <CardHorizontalBodyContainers $showBorder={horizontalShowBorder}>
          <CardConfirmHorizontalButtonBox>
            <IconButton button-type="primary" className="!bg-grey-200 p-2" color="white" onClick={onClose}>
              <ChevronLeftIcon />
            </IconButton>
            <div>
              <ProductCardTypography variant="brand" text={brandName} />
              <ProductCardTypography variant="name" text={name} className={'pb-0'} />
            </div>
          </CardConfirmHorizontalButtonBox>
        </CardHorizontalBodyContainers>
        {horizontalFooterSection && (
          <React.Fragment>
            {isShowWeightSelector && (
              <React.Fragment>
                {weights && weights?.length > 1 && (
                  <Typography variant="body-small-bold" className="!text-grey-480">
                    Select weight
                  </Typography>
                )}
                <CardConfirmHorizontalWeightsBox>
                  {weights &&
                    weights?.map((item: TProductSize, index: number) => {
                      return (
                        <WeightSelectorButton
                          key={index}
                          label={item?.label || ''}
                          size={!isMobile ? 'lg' : 'sm'}
                          className="flex py-2 lg:py-3.5"
                          onClick={() => handleWeightChange(item)}
                          defaultSelected={item?.name === selectedWeight}
                          isMultiSelect={false}
                        />
                      );
                    })}
                </CardConfirmHorizontalWeightsBox>
              </React.Fragment>
            )}
            <CardHorizontalFooterBox className="gap-6">
              <QuantitySelector
                maxLimit={maxCartLimit}
                onChange={(value: number) => {
                  setSelectedQuantity(value);
                }}
                initialCount={selectedQuantity}
                quantityContainerStyle={'w-full h-12'}
                existingItemQuantity={existingItem.quantity}
              />
              <CommonButton
                size="lg"
                button-type="secondary"
                color="green"
                disabled={isSelectedWeightInCart ? existingItem.quantity === selectedQuantity : false}
                className="h-12 w-full"
                onClick={handleCartClick}
              >
                <Typography variant="body-small-bold">
                  {!existingItem.quantity || (isSelectedWeightInCart && existingItem.quantity === selectedQuantity)
                    ? `Confirm $${Number(totalPrice)?.toFixed(2)}`
                    : selectedQuantity === 0
                    ? 'Remove Item'
                    : `Update Cart $${Number(totalPrice)?.toFixed(2)}`}
                </Typography>
              </CommonButton>
            </CardHorizontalFooterBox>
          </React.Fragment>
        )}
      </CardConfirmHorizontalBox>
    </CardHorizontalContainers>
  );
};

export default ProductCardConfirmHorizontal;
