import { useState } from 'react';
import { Typography } from 'components/atoms/typography';
import { DayMap } from 'helpers/constants/misc';
import { IDispensaryTiming } from 'helpers/types';
import { getStoreHoursForToday } from 'helpers/utils/get-dispensary-time';
import TimeIcon from 'public/icons/figma/time.svg';
import {
  CaretDownIcon,
  CaretUpIcon,
  MetaContent,
  StoreTimingForToday,
  StoreTimingRow,
  TimingDay,
  TimingHourContainer,
  TimingItem,
} from './styles';

interface StoreHoursProps {
  storeHours: IDispensaryTiming[];
  isHomeDispensary?: boolean;
}

const StoreHoursDropdown = ({ storeHours, isHomeDispensary }: StoreHoursProps) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const storeHourstoDisplay = storeHours?.map((day: IDispensaryTiming) => {
    const hours = day?.hours;
    return {
      day: day?.day,
      dayToDisplay: DayMap[day?.day.toLowerCase()],
      hours,
    };
  });
  const storeHoursForToday = getStoreHoursForToday(storeHourstoDisplay);
  const MEDICAL = 'medical';
  const hasMedicalTiming = (timing) => timing?.hours?.toLowerCase()?.includes(MEDICAL);

  return (
    <div
      data-testid="Store timing"
      id="storeHoursDropdownPopup"
      onClick={() => setDropdownOpen(!dropdownOpen)}
      role="button"
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.code === '32') {
          setDropdownOpen(!dropdownOpen);
        }
      }}
    >
      <StoreTimingRow $isHomeDispensary={Boolean(isHomeDispensary)}>
        <TimeIcon />
        <>
          <StoreTimingForToday $isHomeDispensary={Boolean(isHomeDispensary)}>
            <Typography variant="body-small" className="ml-3 h-6 pt-1 lg:ml-6 ">
              {dropdownOpen
                ? 'Opening hours'
                : `${storeHoursForToday?.dayToDisplay} ${storeHoursForToday?.hours?.replace('Hours: \n', 'Hours: ')}`}
            </Typography>
          </StoreTimingForToday>
          {dropdownOpen ? (
            <CaretUpIcon $verticalAlignBottom={hasMedicalTiming(storeHoursForToday)} />
          ) : (
            <CaretDownIcon $verticalAlignBottom={hasMedicalTiming(storeHoursForToday)} />
          )}
        </>
      </StoreTimingRow>
      {dropdownOpen && (
        <MetaContent data-testid="timing-list">
          {storeHourstoDisplay?.map((timing: IDispensaryTiming, i: number) => {
            const typographyVariant = timing?.day === storeHoursForToday?.day ? 'body-small-bold' : 'body-small';
            const typographyClasses = timing?.day === storeHoursForToday?.day ? '!text-grey-500' : '!text-grey-400';
            return (
              <TimingItem
                key={timing.day}
                className={i === storeHourstoDisplay.length - 1 ? '!mb-3 lg:!mb-2' : i === 0 ? 'mt-4 lg:mt-5' : ''}
              >
                <TimingDay>
                  <Typography className={typographyClasses} variant={typographyVariant}>
                    {timing?.dayToDisplay}
                  </Typography>
                </TimingDay>
                <TimingHourContainer>
                  <Typography className={typographyClasses} variant={typographyVariant}>
                    {hasMedicalTiming(timing) ? timing?.hours : timing?.hours?.toUpperCase()}
                  </Typography>
                </TimingHourContainer>
              </TimingItem>
            );
          })}
        </MetaContent>
      )}
    </div>
  );
};

export default StoreHoursDropdown;
