import tw from 'tailwind-styled-components';

export const HomeDispensaryWrapper = tw.div`
  md:w-auto
  md:mt-12 
  md:mx-[60px]
  md:mb-[120px]  
  mx-5 
  mt-6 
  mb-[64px]
  md:min-w-[513px]
  xl:min-w-[860px]
  md:flex-1
`;

export const InfoContainer = tw.div`
 w-full
 rounded-md
 relative
 h-full
 md:min-w-[305px]
 xl:min-w-[400px]
`;

export const DispensaryContainer = tw.div`
 md:p-10 
 md:box-border
 md:border-2
 md:flex
 justify-between
 space-x-auto
 xl:w-full
`;

// TODO: Add this in ImageContainer on responsive ticket
//[&_img]:md:w-[300px]
// [&_img]:md-h-[231px]
// [&_img]:md:!max-w-[300px]

export const ImageContainer = tw.div`
 [&_img]:!rounded-md
 [&_img]:w-full
 [&_img]:xl:w-full
 [&_img]:h-[196px]
 [&_img]:xl:!h-[360px]
 [&_img]:xl:!max-w-[584px]
 [&_img]:right-0
 my-5
 md:my-0
`;

export const ContactContainer = tw.div`
 flex
 items-center
 h-4
 mt-4
 md:h-auto
`;

export const AddressContainer = tw.div`
 flex
 my-4
 md:items-center
 md:h-auto
 min-h-4
`;

export const contactClasses = `
 ml-3
 !text-grey-400
`;

export const addressClasses = `
 ml-3
 !text-grey-400
`;

export const aboutDispensaryButtonClasses = `
 ml-3
 p-0
 md:p-0
 flex
`;

export const NewDispensaryContainer = tw.div`
 bg-grey-100 
 md:mt-8 
 md:py-8 
 md:px-10
 mt-6 
 p-4 
`;

export const ContactUsContainer = tw.div`
 flex
 items-center
 h-4
 md:h-auto
`;

export const IconsStyle = `
 ml-2
 mt-0.5
 float-right
 text-leaf
`;
