import { IDispensaryTiming } from 'helpers/types';

const extractTime = (time: string) => {
  return time.replaceAll(':00 ', '').toUpperCase();
};

export const getOpeningTime = (data): IDispensaryTiming[] => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const result = [];
  data?.forEach((item) => {
    item.appliesTo?.forEach((day) => {
      const dayWiseTime = {};
      (dayWiseTime['day'] = day),
        (dayWiseTime['hours'] = item.closedLabel
          ? item.closedLabel
          : `${extractTime(item.openingTime)} - ${extractTime(item.closingTime)}`);
      const position = days.findIndex((d) => d.toLowerCase().includes(day.toLowerCase()));

      // @ts-ignore
      result[position] = dayWiseTime;
    });
  });
  return result;
};

export const getStoreHoursForToday = (storeHours: IDispensaryTiming[]) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  const dateObj = new Date();
  return Array.isArray(storeHours)
    ? storeHours.find((timing: IDispensaryTiming) =>
        timing?.day?.toLowerCase()?.includes(days[dateObj?.getDay()]?.toLowerCase()),
      )
    : undefined;
};
