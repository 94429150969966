import tw from 'tailwind-styled-components';

export const BannerBox = tw.div`
  relative
  bg-center
  bg-cover
  bg-no-repeat
  object-cover
  p-4
  bg-grey-100
  md:p-[60px]
  sm:px-5
  sm:py-6
`;

export const BreadCrumbContainer = tw.div`
    h-[24px]
    md:mb-[66px]
    sm:mb-6
`;

export const ContentContainer = tw.div`
    sm:block
    md:inline-flex
    w-full
    min1440:gap-x-[176px]
    md:gap-x-[30px]
`;

export const CarouselContainer = tw.div`
    md:w-[50%]
    sm:w-full
    mt-[32px]
    md:mt-0
    relative
`;
interface ImageContainerProps {
  $style?: string;
}
export const ImageContainer = tw.div<ImageContainerProps>`
    [&_img]:!rounded-md
    relative
    ${(p) => p.$style}
`;
export const TextContainer = tw.div`
    md:w-[50%]
    sm:w-full
`;
export const ButtonContainer = tw.div`
    flex
    mt-[32px]
    sm:justify-center
    md:justify-normal
`;
