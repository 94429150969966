import tw from 'tailwind-styled-components';

export const LineElement = tw.hr`
    mb-4 
    h-[2px] 
    w-14 
    bg-resin
`;

export const AnchorLinkTextEffects = `
    !font-normal 
    !text-grey-400 
    hover:!font-bold 
    hover:!text-leaf
`;
