import { useEffect, useRef, useState } from 'react';
import { SortingButton } from 'components/atoms/buttons/sorting-button';
import { Typography } from 'components/atoms/typography';
import Loading from 'components/organisms/global/loader';
import useOnScreen from 'helpers/hooks/use-onscreen';
import janeApi from 'helpers/jane-api';
import janeStoreApi from 'helpers/jane-store-operations-api';
import { ORDER_STATUS_MAPPING } from 'helpers/types';
import { useAppSelector } from 'redux/hooks';
import AccountEmptyState from '../empty-state';
import { TransactionHistoryBox, TransactionHistoryTitleBox } from './styles';
import TransactionHistoryAccordion from './transactions-history-accordion';

type TSortType = 'NEWEST' | 'OLD';

type TProps = {
  emptyStateData: any;
  tasticPlacementNumber?: number;
};

const TransactionHistory = ({ emptyStateData, tasticPlacementNumber }: TProps) => {
  const [open, setOpen] = useState<number | null>(null);
  const user = useAppSelector((state) => state.accountReducer.user);
  const [data, setData] = useState<[]>([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [orderApiState, setOrderApiState] = useState({
    loading: false,
    err: '',
  });
  const paginationId = useRef(null);
  const itemPerPage = 20;
  const lastAccordionRef = useRef<HTMLDivElement>(null);
  const sortRef = useRef<TSortType>();
  const isOnScreen = useOnScreen(lastAccordionRef, isUpdated);

  const handleOpen = (index: number) => {
    setOpen(open === index ? null : index);
  };

  const sortData = (type: TSortType | undefined, data) => {
    const dataCopy = [...data];
    dataCopy.sort((a, b) => {
      const firstItemData = new Date(a.checked_out_time).getTime();
      const secondItemData = new Date(b.checked_out_time).getTime();
      if (type === 'OLD') return firstItemData - secondItemData;
      else return secondItemData - firstItemData;
    });
    return dataCopy;
  };

  const updateData = async () => {
    try {
      if (data?.length === 0) setOrderApiState({ loading: true, err: '' });
      if (paginationId.current || data?.length === 0) {
        // @ts-ignore
        const res: any = await janeApi.getTransactionHistory(itemPerPage, paginationId.current);
        const filteredResponse = res?.data?.data?.reservations
          ?.filter((item) => ORDER_STATUS_MAPPING[item.status] !== ORDER_STATUS_MAPPING['pending'])
          ?.map((item) => {
            return {
              ...item,
              products: item.products.slice(0, 4),
              productsCount: item?.products?.length,
            };
          });
        const copyFilteredResponse = [...filteredResponse];
        // Call Jane to get products data.....
        const updatedFilteredResponse = await Promise.all(
          copyFilteredResponse.map(async (item) => {
            const storeId = item?.store_id;
            const products = await Promise.all(
              item?.products.map(async (product) => {
                const productData = await janeStoreApi.getJaneProduct(storeId, product?.product_id);
                return productData?.data?.data?.menu_product;
              }),
            );

            return {
              ...item,
              products,
            };
          }),
        );

        paginationId.current = res?.data?.metadata?.pagination_id;

        if (data?.length) {
          const updateData = [...data, ...updatedFilteredResponse];
          const sortedData = sortData(sortRef.current, updateData);
          setData(sortedData as []);
        } else {
          const sortedData = sortData('NEWEST', updatedFilteredResponse);
          setData(sortedData as []);
        }
        setOrderApiState({ loading: false, err: '' });
        setIsUpdated(!isUpdated);
      }
    } catch (err) {
      setOrderApiState({ loading: false, err });
      if (err?.response?.status === 404) {
        // Orer history not found
        setData([]);
      }
    }
  };

  useEffect(() => {
    if (isOnScreen) {
      if (user) updateData();
    }
  }, [isOnScreen, user]);

  useEffect(() => {
    if (user) updateData();
  }, [user]);

  const handleSortSelect = (item) => {
    if (item === 'Oldest to Newest') {
      const sortedData = sortData('OLD', data);
      setData(sortedData as []);
      sortRef.current = 'OLD';
    } else {
      const sortedData = sortData('NEWEST', data);
      setData(sortedData as []);
      sortRef.current = 'NEWEST';
    }
  };

  return (
    <>
      {orderApiState.loading && <Loading showbackdrop />}
      {data && (
        <>
          {data?.length === 0 && emptyStateData?.items ? (
            <AccountEmptyState
              firstName={user?.attributes?.given_name}
              message={emptyStateData?.items[0]?.title}
              subMessage={emptyStateData?.items[0]?.description}
              buttonLabel={emptyStateData?.items[0]?.ctaCollection?.items[0]?.title}
              ctaLink={''}
              src={emptyStateData?.items[0]?.image?.url}
              alt={emptyStateData?.items[0]?.image?.title}
              ariaLabel={emptyStateData?.items[0]?.image?.title}
            />
          ) : (
            <TransactionHistoryBox>
              <TransactionHistoryTitleBox>
                <Typography variant="h2">{'your purchase history'}</Typography>
                <SortingButton
                  placement={tasticPlacementNumber}
                  card_list_name="Your Purchase History"
                  card_list_title=""
                  card_list_type="List"
                  options={['Newest to Oldest', 'Oldest to Newest']}
                  onSelect={handleSortSelect}
                  label="Sort by"
                />
              </TransactionHistoryTitleBox>
              <div>
                {data?.map((item, index) => (
                  <TransactionHistoryAccordion
                    key={index}
                    open={open === index}
                    data={item}
                    index={index}
                    handleOpen={handleOpen}
                    ctaName="View order"
                    ctaLink="/"
                    ref={index === data.length - 5 ? lastAccordionRef : null}
                  />
                ))}
              </div>
            </TransactionHistoryBox>
          )}
        </>
      )}
    </>
  );
};

export default TransactionHistory;
