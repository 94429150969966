import Image from 'next/image';
import { Typography } from 'components/atoms/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getMenuLink } from 'helpers/utils/common';
import { getDispensaryAddress } from 'helpers/utils/convert-to-dispensary-page-type';
import { getOpeningTime } from 'helpers/utils/convert-to-dispensary-type';
import { tablet } from 'helpers/utils/screensizes';
import { useAppSelector } from 'redux/hooks';
import DispensaryInfoSection from './dispensary-inner-info-section';
import SetNewHomeDispensary from './set-new-home-dispensary';
import { DispensaryContainer, HomeDispensaryWrapper, ImageContainer, InfoContainer } from './styles';

const HomeDispensary = () => {
  const [isTablet] = useMediaQuery(tablet);
  const homeDispensaryData = useAppSelector((store) => store.accountReducer.homeDispensary.data);
  if (!homeDispensaryData) return null;
  const {
    dispensaryInformation,
    heroImage: image,
    mobileHeroImage: mobileImage,
    state,
    menusCollection,
    slug,
    dispensaryTags,
  } = homeDispensaryData || {};
  const {
    title: dispensaryName,
    contactNumber: contact,
    hoursInformationCollection,
    mapCtaText,
    mapUrl,
  } = dispensaryInformation || {};
  const callCtaText = 'Call now';
  const contactCtaText = 'Contact us';
  const shopDispensaryCtaText = 'Shop this dispensary';
  const dispensaryTitle = 'HOME DISPENSARY';

  const DispensaryInfoProps = {
    contact,
    timing: getOpeningTime(hoursInformationCollection?.items),
    address: getDispensaryAddress(dispensaryInformation, state),
    mapCtaText,
    mapURL: mapUrl,
    callCtaText,
    contactCtaText,
    shopDispensaryCtaText,
    shopDispensaryCtaUrl: getMenuLink({
      dispensaries: {
        menusCollection,
        slug,
        dispensaryTags,
        storeId: menusCollection?.items?.[0]?.menuSourceId || 'null',
      },
    }),
  };

  return (
    <>
      {isTablet ? (
        <HomeDispensaryWrapper>
          <Typography variant="h2" className="mb-12" data-testid="dispensary-title">
            {dispensaryTitle}
          </Typography>
          <DispensaryContainer>
            <InfoContainer>
              <Typography variant="h3" className="md:!pb-5" data-testid="dispensary-name">
                {dispensaryName}
              </Typography>
              <DispensaryInfoSection {...DispensaryInfoProps} />
            </InfoContainer>
            {
              <ImageContainer>
                <Image
                  loader={(options) => options.src}
                  src={image?.url}
                  alt={image?.title}
                  objectFit="fill"
                  width={image?.width}
                  height={image?.height}
                  className="dispensaryImage"
                  aria-hidden="true"
                />
              </ImageContainer>
            }
          </DispensaryContainer>
          <SetNewHomeDispensary />
        </HomeDispensaryWrapper>
      ) : (
        <HomeDispensaryWrapper>
          <Typography variant="h2" className="mb-5">
            {dispensaryTitle}
          </Typography>
          <hr className="mb-5 h-[2px]" />
          <DispensaryContainer>
            <InfoContainer>
              <Typography variant="h3" className="!mb-5">
                {dispensaryName}
              </Typography>
            </InfoContainer>
            {
              <ImageContainer>
                <Image
                  loader={(options) => options.src}
                  src={mobileImage?.url}
                  alt={mobileImage?.title}
                  objectFit="fill"
                  width={mobileImage?.width}
                  height={mobileImage?.height}
                  className="dispensaryImage"
                  priority
                />
              </ImageContainer>
            }
            <DispensaryInfoSection {...DispensaryInfoProps} />
          </DispensaryContainer>
          <SetNewHomeDispensary />
        </HomeDispensaryWrapper>
      )}
    </>
  );
};

export default HomeDispensary;
