/* eslint-disable react/jsx-no-target-blank */
'use client';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import DealCard from 'components/molecules/cards/deal-card';
import { dealClicked } from 'helpers/analytics/deal-clicked';
import { SegmentEvents } from 'helpers/constants/forms';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getPathMenuFiltersLink } from 'helpers/utils/common';
import { hugeDesktop } from 'helpers/utils/screensizes';
import { DealCardItemsProps } from '.';
import {
  DealCardsListShowMoreButtonSection,
  DealCardsListShowMoreCardList,
  DealCardsListShowMoreContainer,
} from './styles';

interface DealCardListShowMoreProps {
  host: string;
  tabletItemsLimit?: number;
  desktopItemsLimit?: number;
  items: DealCardItemsProps[];
  size?: 'sm' | 'md';
  variant?: 'vertical' | 'horizontal';
}

const DealCardListShowMore = ({
  host = '',
  tabletItemsLimit = 2,
  desktopItemsLimit = 4,
  items = [],
  size,
  variant,
}: DealCardListShowMoreProps) => {
  const [showNumberOfItems, setShowNumberOfItems] = useState(tabletItemsLimit);
  const [hasReachedLimit, setHasReachLimit] = useState(false);
  const [isDesktop] = useMediaQuery(hugeDesktop);
  const myRef = useRef(null);

  useEffect(() => {
    setShowNumberOfItems(isDesktop ? desktopItemsLimit : tabletItemsLimit);
  }, [isDesktop]);

  const limit = isDesktop ? desktopItemsLimit : tabletItemsLimit;
  const handleDealCard = (item, i) => {
    dealClicked(SegmentEvents.DEAL_CLICKED, {
      deal_position: i + 1,
      deal_name: item?.internalName || '',
      deal_title: item?.discountText || '',
      deal_image: item.image?.url,
      deal_type: 'special deal',
      link_type: 'Hyperlink',
      placement: item?.tasticPlacement,
    });
  };
  const list: any = useMemo(
    () =>
      items.slice(0, showNumberOfItems).map((item: DealCardItemsProps, i: number) => {
        const { image, specialText, discountText, extraText, percentText, link } = item;
        const newUrl = getPathMenuFiltersLink(link as string);

        return (
          <a
            href={newUrl || ''}
            target={!newUrl?.includes?.(host) ? '_blank' : '_self'}
            rel={!newUrl?.includes?.(host) ? 'nofollow noopener' : ''}
            key={i}
          >
            <DealCard
              className="xl:w-full xl:max-w-[568px] 2xl:w-[432px]  2xl:max-w-[496px]"
              image={image}
              specialText={specialText}
              discountText={discountText}
              percentText={percentText}
              extraText={extraText}
              size={size}
              variant={variant}
              handleAnalytics={() => handleDealCard(item, i)}
            />
          </a>
        );
      }),
    [items, size, variant, showNumberOfItems],
  );

  const onClick = () => {
    if (hasReachedLimit) {
      setShowNumberOfItems((state) => {
        const isMinLimit = state - limit <= limit;
        if (isMinLimit) {
          setHasReachLimit((state) => !state);
        }
        if (state - limit) {
          // @ts-ignore
          myRef.current?.scrollIntoView();
        }
        return isMinLimit ? limit : state - limit;
      });
    } else {
      setShowNumberOfItems((state) => {
        const isMaxLimit = state + limit >= items.length;
        if (isMaxLimit) {
          setHasReachLimit((state) => !state);
        }
        return isMaxLimit ? items.length : state + limit;
      });
    }
  };
  const showLessLabel = `Show less (${showNumberOfItems - limit <= limit ? showNumberOfItems - limit : limit})`;
  const showMoreLabel = `Show more (${
    showNumberOfItems + limit >= items?.length ? items?.length - list?.length : limit
  })`;
  const buttonLabel = hasReachedLimit ? showLessLabel : showMoreLabel;
  return (
    <DealCardsListShowMoreContainer data-testid="deal-cards-list-show-more">
      <DealCardsListShowMoreCardList ref={myRef}>{list}</DealCardsListShowMoreCardList>
      {items?.length > 4 && (
        <DealCardsListShowMoreButtonSection>
          <Typography variant="body-small" className="!text-[15px] !tracking-[0.15px] !text-grey-400">
            Showing {showNumberOfItems} of {items?.length}
          </Typography>
          <CommonButton onClick={onClick} button-type="secondary" color="green" size="sm">
            {buttonLabel}
          </CommonButton>
        </DealCardsListShowMoreButtonSection>
      )}
    </DealCardsListShowMoreContainer>
  );
};

export default DealCardListShowMore;
