import React from 'react';
import { WeightSelectorButton } from 'components/atoms/buttons/weight-selector';
import { Typography } from 'components/atoms/typography';
import { TProductSize } from 'helpers/types/organisms/listing/product-details';
import { useAppDispatch } from 'redux/hooks';
import { updateProduct } from 'redux/reducers/product';
import { ProductDetailsSizesContainer } from './styles';

type TProps = {
  weights?: TProductSize[];
  selectedWeight?: string;
  onChange?: () => void;
  setSelectedWeight: (weight: string) => void;
};

export const SingleSelectWeightSelectorGroup = ({ weights, setSelectedWeight, selectedWeight, onChange }: TProps) => {
  const dispatch = useAppDispatch();

  const handleWeightChange = async (item: TProductSize) => {
    if (item?.name !== selectedWeight) {
      await dispatch(updateProduct({ product_id: item?.value, code: item?.name }));
      setSelectedWeight(item?.name);
      if (onChange) onChange();
    }
  };

  return (
    <React.Fragment>
      {weights && weights?.length > 1 && (
        <Typography variant="body-small-bold" className="!text-grey-480">
          Select weight
        </Typography>
      )}
      <ProductDetailsSizesContainer>
        {weights &&
          weights?.map((item: TProductSize, index) => {
            return (
              <WeightSelectorButton
                key={index}
                label={item?.label || ''}
                size={'lg'}
                className="!h-[40px] !w-[96px] p-[12px_0px] md:!h-[42px] md:!w-[144px] md:p-[14px_0px] lg:!h-[48px] lg:!w-[136px]"
                onClick={() => handleWeightChange(item)}
                defaultSelected={item?.name === selectedWeight}
                isMultiSelect={false}
              />
            );
          })}
      </ProductDetailsSizesContainer>
    </React.Fragment>
  );
};
