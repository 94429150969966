import React from 'react';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { RewardMessage, RewardsAboutCountSection, RewardsGrennContainer } from './styles';

interface IRewards {
  donatedToNonProfit: string;
  donatedToNonProfitDesc: any;
  donatedThroughGreenThumb: string;
  donatedThroughGreenThumbDesc: any;
}

const RewardsAboutCard = ({
  donatedToNonProfit,
  donatedToNonProfitDesc,
  donatedThroughGreenThumb,
  donatedThroughGreenThumbDesc,
}: IRewards) => {
  return (
    <React.Fragment>
      <div>
        <RewardsAboutCountSection>
          <Typography variant="h2">$</Typography>
          <Typography variant="h1">{donatedToNonProfit}</Typography>
        </RewardsAboutCountSection>
        <RewardMessage data-testid="non-profit">
          <ContentfulRichText data={donatedToNonProfitDesc} />
        </RewardMessage>
      </div>
      <RewardsGrennContainer>
        <RewardsAboutCountSection>
          <Typography variant="h2">$</Typography>
          <Typography variant="h1">{donatedThroughGreenThumb}</Typography>
        </RewardsAboutCountSection>
        <RewardMessage data-testid="green-thumb">
          <ContentfulRichText data={donatedThroughGreenThumbDesc} />
        </RewardMessage>
      </RewardsGrennContainer>
    </React.Fragment>
  );
};

export default RewardsAboutCard;
