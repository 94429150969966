import tw from 'tailwind-styled-components';

export const MemberBenefitsSection = tw.div`
    w-full
    h-auto
    overflow-hidden
`;

export const MemberBenefitsInnerContainer = tw.div`
    px-[20px]
    pt-[56px]
    pb-[64px]
    md:px-[60px]
    md:pt-[120px]
    md:pb-[272px]
`;

export const MemberBenefitsTextRow = tw.div`
    pb-[32px]
    flex
    justify-between
`;

export const MemberBenefitsTextContainer = tw.div`
    w-full
    h-auto
    md:w-[432px]
    md:min-h-[144px]
`;

export const MemberBenefitsPaginationContainer = tw.div`
    jusitfy-end
`;

export const MemberBenefitsListContainer = tw.div`
    grid
    grid-cols-2
    gap-x-8
    gap-y-2
    md:hidden
`;

export const MemberBenefitsListDesktopContainer = tw.div`
    flex
    max-w-[1800px]
    hidden
    md:flex
`;

export const MemberBenefitsMobilePaginationLabelContainer = tw.div`
    flex
    justify-center
`;

export const MemberBenefitsMobilePaginationContainer = tw.div<{ $showPagination: boolean }>`
    ${(props) => (props.$showPagination ? 'flex' : 'hidden')}
    flex-col
    pt-[32px]
    md:hidden
`;

export const MemberBenefitsMobilePaginationButtonContainer = tw.div`
    flex
    justify-center
    pt-[16px]
`;
