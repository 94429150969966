'use client';

import { useState } from 'react';
import { Configure, InstantSearch, usePagination } from 'react-instantsearch';
import GridArticles from 'components/organisms/listings/grid-articles';
import SearchFilters from 'components/organisms/search/filters/search-filters';
import { gtiSearchClient } from 'helpers/algolia/clients';
import { getGTIIndexName } from 'helpers/algolia/get-index-name';
import useBlogRecipeFilter from 'helpers/hooks/use-blog-recipe-filter';
import { usePageType } from 'helpers/hooks/usePageType';

type TSearchFiltersProps = {
  title?: string;
  subTitle?: string;
  tasticsPlacementNumber?: number;
  data: any;
  optionsData: any;
  onClickFilterPill?: (data?: any) => void;
  filters?: any;
  showFilters?: boolean;
  defaultSelected?: string;
  eventTitle?: string;
  sortValue?: string;
  setSortValue?: (data?: string) => void;
};
const SearchFiltersSection = ({
  tasticsPlacementNumber,
  eventTitle,
  optionsData,
  data,
  onClickFilterPill,
  filters,
  showFilters,
  setSortValue,
  sortValue,
}: TSearchFiltersProps) => {
  const page = usePagination();
  const handleOnClickFilterPill = (data) => {
    if (onClickFilterPill && data) {
      onClickFilterPill(data);
    }
    page?.refine(0);
  };
  return (
    <SearchFilters
      tasticsPlacementNumber={tasticsPlacementNumber}
      eventTitle={eventTitle}
      optionsData={optionsData}
      data={data}
      onClickFilterPill={handleOnClickFilterPill}
      filters={filters}
      showFilters={showFilters}
      setSortValue={setSortValue}
      sortValue={sortValue}
    />
  );
};
const BlogRecepieArticleResultTastics = ({ data }: any) => {
  const optionsData = ['Newest', 'Oldest'];
  const [sortValue, setSortValue] = useState(optionsData[0]);
  const { searchFilterData, handleSearchFilterPill, searchFilterPill, generateCategoryFilter } = useBlogRecipeFilter({
    showFilter: data?.showFilters,
  });
  const filters = generateCategoryFilter(searchFilterPill);
  const recommendedResourcesData = data?.data?.dataSource?.data?.pageListingPageCollection?.items[0];
  const page = usePageType();
  const blogPage = page === 'blogIndexPage';
  return (
    <InstantSearch indexName={getGTIIndexName('site-pages')} searchClient={gtiSearchClient}>
      <Configure
        analytics={false}
        filters={
          blogPage
            ? `(page_type: Blog OR page_type: Recipe) AND  showOnBlogListing:true ${filters ? ' AND ' + filters : ''}`
            : '(page_type: Recipe) AND showOnBlogListing:true'
        }
        hitsPerPage={6}
      />
      <SearchFiltersSection
        tasticsPlacementNumber={data?.tasticPlacementNumber}
        eventTitle={recommendedResourcesData?.bodyHeading}
        optionsData={optionsData}
        data={searchFilterData}
        onClickFilterPill={handleSearchFilterPill}
        filters={searchFilterPill}
        showFilters={data?.showFilters}
        // @ts-ignore
        setSortValue={setSortValue}
        sortValue={sortValue}
      />
      <GridArticles
        title={recommendedResourcesData?.bodyHeading}
        recommendedResourcesData={recommendedResourcesData}
        tasticPlacementNumber={data.tasticPlacementNumber}
        sortValue={sortValue}
      />
    </InstantSearch>
  );
};

export default BlogRecepieArticleResultTastics;
