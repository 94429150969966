import tw from 'tailwind-styled-components';

export const FormPersonalInformationContainer = tw.div`
    flex
    flex-col
    py-6
    px-5
    items-start
    gap-5
    w-full

    md:px-10
    md:pt-10
    md:pb-[60px]
    md:min-w-[513px]
    md:max-w-[958px]
    md:gap-12

    2xl:px-[60px]
    2xl:pt-12
    2xl:pb-20
    2xl:min-w-[860px]
    2xl:max-w-[1464px]
`;

export const FormPersonalInformationFormGroupSection = tw.div`
    flex
    flex-col
    items-center
    self-stretch
    py-8
    gap-8

    md:py-0
    md:gap-8
    md:min-w-[340px]
    md:max-w-[419px]
    md:item-start
    md:grow
    md:shrink-0
    md:basis-0

    2xl:max-w-[736px]
    2xl:gap-6
    2xl:self-stretch
    2xl:justify-between
`;

export const FormPersonalInformationFlexDirection = tw.div`
    flex
    flex-col
    items-start
    w-full
    gap-5

    md:content-start
    md:gap-10
    md:self-stretch
    md:flex-wrap
    md:flex-row

    2xl:gap-0
    2xl:justify-between
    2xl:items-start
`;

export const FormPersonalInformationFormGroup = tw.div`
    flex
    flex-col
    items-center
    self-stretch
    gap-8

    md:items-start
`;

export const FormPersonalInformationTitleSection = tw.div`
    flex
    flex-col
    items-start
    w-full
`;

export const FormPersonalInformationFormGroupInput = tw.div`
    md:flex
    md:justify-between
    md:items-center
    md:content-center
    md:gap-y-2.5
    md:self-stretch
    md:flex-wrap

    2xl:gap-y-3
    2xl:w-full
`;

export const FormPersonalInformationInputLabel = tw.label`
    hidden

    md:flex
    md:w-[116px]
    md:font-arial
    md:text-[15px]
    md:not-italic
    md:font-bold
    md:leading-[18px]
    md:tracking-[0.15px]
    md:text-grey-400

    2xl:text-base
    2xl:leading-5
    2xl:tracking-[0.16px]

`;

export const FormPersonalInformationChangeEmailSection = tw.div`
    flex
    flex-col
    items-start
    gap-5
    self-stretch
`;

export const FormPersonalInformationEmptyDiv = tw.div`
    hidden
    2xl:flex
    2xl:min-w-[60px]
    2xl:self-stretch
    2xl:w-[60px]
`;

export const FormPersonalInformationChangeEmailWidth = tw.div`
    md:w-[419px]
    2xl:w-[548px]
`;
