/* eslint-disable react/jsx-no-target-blank */
'use client';

import { useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import Pagination from 'components/atoms/pagination';
import DealCard from 'components/molecules/cards/deal-card';
import { dealClicked } from 'helpers/analytics/deal-clicked';
import { SegmentEvents } from 'helpers/constants/forms';
import { getPathMenuFiltersLink } from 'helpers/utils/common';
import { DealCardItemsProps } from '.';
import { DealCardsListListSection, DealCardsListSliderContainer, DealCardsListSliderPadding } from './styles';

interface DealCardListListProps {
  host: string;
  items: DealCardItemsProps[];
  size?: 'sm' | 'md';
  variant?: 'vertical' | 'horizontal';
}

const DealCardListSlider = ({ items = [], size, variant, host = '' }: DealCardListListProps) => {
  const slickRef = useRef<any>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 100,
    variableWidth: true,
    afterChange: (x: number) => setCurrentIndex(Math.ceil(x / 1)),
  };
  const handleDealCard = (data: DealCardItemsProps) => {
    dealClicked(SegmentEvents.DEAL_CLICKED, data);
  };
  const list: any = useMemo(
    () =>
      items.map((item: DealCardItemsProps, i: number) => {
        const { image, specialText, discountText, extraText, percentText, link } = item;
        const newLink = getPathMenuFiltersLink(link as string);
        return (
          <a
            href={newLink || ''}
            target={newLink?.includes?.(host) ? '_blank' : '_self'}
            rel={!newLink?.includes?.(host) ? 'noreferrer' : ''}
            key={i}
          >
            <DealCard
              image={image}
              specialText={specialText}
              discountText={discountText}
              percentText={percentText}
              extraText={extraText}
              size={size}
              variant={variant}
              handleAnalytics={() => handleDealCard(item)}
            />
          </a>
        );
      }),
    [items, size, variant],
  );

  const onChange = (pageNumber) => slickRef?.current?.slickGoTo?.(pageNumber.page - 1);

  return (
    <DealCardsListSliderContainer data-testid="deal-cards-list-slider">
      <DealCardsListListSection>
        <Slider {...settings} ref={slickRef}>
          {list}
        </Slider>
      </DealCardsListListSection>
      <DealCardsListSliderPadding>
        <Pagination
          variant="large"
          color="resin"
          itemsPerPage={1}
          count={items.length}
          onPageChange={onChange}
          slickCurrentIndex={currentIndex}
        />
      </DealCardsListSliderPadding>
    </DealCardsListSliderContainer>
  );
};

export default DealCardListSlider;
