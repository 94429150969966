import { Typography } from 'components/atoms/typography';
import FormPersonalInformationForm from './personal-information-form';
import {
  FormPersonalInformationChangeEmailSection,
  FormPersonalInformationChangeEmailWidth,
  FormPersonalInformationContainer,
  FormPersonalInformationEmptyDiv,
  FormPersonalInformationFlexDirection,
  FormPersonalInformationFormGroupSection,
  FormPersonalInformationTitleSection,
} from './styles';

interface FormPersonalInformationProps {
  title?: string;
  link?: string;
  firstName: string;
  lastName: string;
  dob: string;
  phone: string;
  email: string;
  state: string;
  address: string;
}

const FormPersonalInformation = ({
  title = 'Personal Information',
  link = 'mailto:rewardssupport@risecannabis.com',
  firstName = '',
  lastName = '',
  dob = '',
  phone = '',
  email = '',
  state = '',
  address = '',
}: FormPersonalInformationProps) => {
  return (
    <FormPersonalInformationContainer>
      <Typography variant="h2">
        {firstName} {lastName}
      </Typography>
      <FormPersonalInformationFlexDirection>
        <FormPersonalInformationFormGroupSection>
          <FormPersonalInformationTitleSection>
            <Typography variant="h5" as="h3">
              {title}
            </Typography>
          </FormPersonalInformationTitleSection>
          <FormPersonalInformationForm
            firstName={firstName}
            lastName={lastName}
            dob={dob}
            phone={phone}
            email={email}
            state={state}
            address={address}
          />
        </FormPersonalInformationFormGroupSection>
        <FormPersonalInformationEmptyDiv />
        <FormPersonalInformationChangeEmailWidth>
          <FormPersonalInformationChangeEmailSection>
            <Typography variant="h5" as="h3">
              Change Personal Preferences
            </Typography>
            <Typography variant="body-small" className="!self-stretch !text-grey-400">
              If you need to make changes to any information in your profile, please reach out to customer support at{' '}
              <a href={link} className="cursor-pointer underline">
                rewardssupport@risecannabis.com
              </a>
              {'.'}
            </Typography>
          </FormPersonalInformationChangeEmailSection>
        </FormPersonalInformationChangeEmailWidth>
      </FormPersonalInformationFlexDirection>
    </FormPersonalInformationContainer>
  );
};

export default FormPersonalInformation;
