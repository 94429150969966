import Image from 'next/image';
import { Typography } from 'components/atoms/typography';
import { TBenefitsCardProps } from 'helpers/types/organisms/rewards/benefits-card';
import {
  BenefitsCardContainer,
  BenefitsCardIconContainer,
  BenefitsCardInnerContainer,
  BenefitsCardTextContainer,
} from './styles';

export const BenefitsCardComponent = ({ imageSource, text }: TBenefitsCardProps) => {
  return (
    <BenefitsCardContainer>
      <BenefitsCardInnerContainer>
        <BenefitsCardIconContainer>
          <Image
            src={imageSource}
            width={40}
            height={40}
            loader={(options) => options.src}
            alt={text}
            data-testid="benefits_card_icon"
          />
        </BenefitsCardIconContainer>
        <BenefitsCardTextContainer>
          <Typography variant={'h4'} as="h3" data-testid="benefits_card_text">
            {text}
          </Typography>
        </BenefitsCardTextContainer>
      </BenefitsCardInnerContainer>
    </BenefitsCardContainer>
  );
};
