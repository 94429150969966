'use client';

import React, { useEffect, useState } from 'react';
import Autocomplete from 'react-google-autocomplete';
import tw from 'tailwind-styled-components';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IGeoLoc } from 'helpers/types';
import { getSortedDispensaries } from 'helpers/utils/get-sorted-dispensaries';
import LocationIcon from 'public/icons/figma/location.svg';

interface TProps {
  list: Record<string, any>[];
  geoLoc?: IGeoLoc;
  setgeoLoc?: (geoLoc: IGeoLoc) => void;
  setOrderedDispensaries?: (detail) => void;
}

const AutocompleteContainer = tw.div`
  relative
  my-6
  [&_input]:md:h-16
  [&_input]:h-12
  [&_input]:md:w-[420px]
  [&_input]:w-full
  [&_input]:md:p-5
  [&_input]:p-4
  [&_input]:md:pl-14
  [&_input]:pl-12
  [&_input]:rounded-md
  [&_input]:border-[1px]
  [&_input]:border-solid
  [&_input]:border-grey-400
  [&_input]:md:mr-[10px]
  [&_input]:mr-0
  [&_input]:mb-4
  [&_input]:md:mb-0
`;

export const AutocompleteComponent = ({ list, geoLoc, setgeoLoc, setOrderedDispensaries }: TProps) => {
  const submitSearch = async (e) => {
    e.preventDefault();

    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: value }, (res, status) => {
      if (status == google.maps.GeocoderStatus.OK && res) {
        setgeoLoc &&
          setgeoLoc({
            lat: parseInt(JSON.stringify(res[0].geometry.location.lat())),
            lng: parseInt(JSON.stringify(res[0].geometry.location.lng())),
          });
      }
    });
  };
  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const [value, setValue] = useState('');

  useEffect(() => {
    if (geoLoc) {
      getSortedDispensaries({
        origin: geoLoc,
        locationList: list,
      }).then((ordered) => {
        setOrderedDispensaries && setOrderedDispensaries(ordered);
      });
    }
  }, [geoLoc]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AutocompleteContainer>
      <form onSubmit={submitSearch} role="search">
        <LocationIcon className="absolute left-3 top-3 h-6 w-6 text-grey-400 md:left-5 md:top-5" />
        <Autocomplete
          apiKey={process.env.NEXT_PUBLIC_MAP_APIKEY}
          data-testid="autocompleteInput"
          placeholder="Enter a location"
          onChange={handleChange}
          aria-label="Search Location"
          onPlaceSelected={(place) => {
            if (place.geometry?.location?.lat() && place.geometry?.location?.lng()) {
              setgeoLoc &&
                setgeoLoc({
                  lat: place.geometry.location.lat(),
                  lng: place.geometry.location.lng(),
                });
            }
          }}
          options={{
            types: ['establishment', 'geocode'],
          }}
        />
        <CommonButton
          data-testid="searchButton"
          button-type="primary"
          size="lg"
          color="green"
          className="w-full md:w-auto"
        >
          Search
        </CommonButton>
      </form>
    </AutocompleteContainer>
  );
};
