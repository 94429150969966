import { SegmentEvents } from '../constants/forms';
import { IMenuChangeToggle, SegementTrackEvent } from './segment-helper';

export const menuChangeToggle = (values) => {
  const { menu, page_url } = values;
  try {
    const data: IMenuChangeToggle = {
      menu_label: menu,
      page_location: page_url,
    };
    return SegementTrackEvent(SegmentEvents.MENU_CHANGED, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
