'use client';

import { Configure, InstantSearch, useHits } from 'react-instantsearch';
import { DispensaryCards } from 'components/organisms/listings/dispensary-cards';
import { gtiSearchClient } from 'helpers/algolia/clients';
import { getGTIIndexName } from 'helpers/algolia/get-index-name';
import { mapDispensaryItems } from 'helpers/utils/dispenary-nearby';

const DispensaryNearByTastics = ({ pageFolder }: any) => {
  const dataSourceConfig = pageFolder?.dataSourceConfigurations;
  const data = dataSourceConfig[3]?.preloadedValue?.data?.page.items[0];
  const latitude = data?.dispensaryInformation?.latitude;
  const longitude = data?.dispensaryInformation?.longitude;
  const stateName = data?.state?.state?.stateName;
  const geoStateString = `(address.state:"${stateName}")`;
  const geoFilterString = `${latitude || 42.2379}, ${longitude || -87.9837}`;
  const host = pageFolder.host;

  const DispensaryNearByHits = (props) => {
    const res = useHits<any>(props);
    const mappedDispensaries = mapDispensaryItems(res);
    return (
      mappedDispensaries && (
        <DispensaryCards dispensaries={mappedDispensaries || []} title="Dispensaries Nearby" host={host} />
      )
    );
  };

  return (
    <div>
      <InstantSearch indexName={getGTIIndexName('dispensary')} searchClient={gtiSearchClient}>
        <Configure analytics={false} hitsPerPage={3} aroundLatLng={geoFilterString} filters={geoStateString} />
        <DispensaryNearByHits />
      </InstantSearch>
    </div>
  );
};

export default DispensaryNearByTastics;
