'use client';

import { useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import Pagination from 'components/atoms/pagination';
import { Typography } from 'components/atoms/typography';
import { BrandCardProps } from 'components/molecules/cards/brand-card';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { smallMobile, tablet } from 'helpers/utils/screensizes';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
  CarouselBrandsCard,
  CarouselBrandsContainer,
  CarouselBrandsContent,
  CarouselBrandsContentContainer,
  CarouselBrandsDesktopFlexContainer,
  CarouselBrandsDesktopFlexContainerSpacer,
  CarouselBrandsDesktopeHidden,
  CarouselBrandsFlexDirection,
  CarouselBrandsInnerContainer,
  CarouselBrandsMobileHidden,
  CarouselBrandsSliderContainer,
} from './styles';
interface CarouselBrandsProps {
  items: BrandCardProps[];
  title: string;
  content?: string;
  host?: string;
}

const cardInfo: any = {
  mobile: { breakpoint: smallMobile, slide: 2 },
  tablet: { breakpoint: tablet, slide: 4 },
  desktop: { breakpoint: 1918, slide: 5 },
};

const CarouselBrands = ({ title = '', content, items = [], host }: CarouselBrandsProps) => {
  const [, width] = useMediaQuery(smallMobile);
  const [currentIndex, setCurrentIndex] = useState(0);

  const slickRef = useRef<any>(null);
  const cards = useMemo(
    () =>
      items?.map((item: BrandCardProps, i: number) => {
        return <CarouselBrandsCard key={i} title={item?.title} image={item?.image} url={item?.url} host={host} />;
      }),
    [items],
  );

  const onChange = (p: any) => {
    slickRef.current.slickGoTo(p?.startIndex);
  };

  const itemsPerPage =
    width < cardInfo?.tablet?.breakpoint
      ? cardInfo?.mobile?.slide
      : width < cardInfo?.desktop?.breakpoint
      ? cardInfo?.tablet?.slide
      : cardInfo?.desktop?.slide;
  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    infinite: false,
    slidesToShow: itemsPerPage,
    slidesToScroll: itemsPerPage,
    arrows: false,
    speed: 100,
    initialSlide: 0,
    variableWidth: true,
    afterChange: (x: number) => setCurrentIndex(Math.ceil(x / itemsPerPage)),
  };

  return (
    <CarouselBrandsContainer key={itemsPerPage}>
      <CarouselBrandsInnerContainer>
        <CarouselBrandsFlexDirection>
          <CarouselBrandsContentContainer>
            <CarouselBrandsDesktopFlexContainerSpacer />
            <CarouselBrandsDesktopFlexContainer>
              <CarouselBrandsContent>
                <Typography variant="h3" className="shrink-0 grow basis-0">
                  {title}
                </Typography>
                {content && (
                  <Typography variant="body-small" className="!text-grey-400 2xl:w-[736px]">
                    {content}
                  </Typography>
                )}
              </CarouselBrandsContent>
              {items?.length > 1 && (
                <CarouselBrandsMobileHidden>
                  <Pagination
                    variant="large"
                    itemsPerPage={itemsPerPage}
                    count={items?.length}
                    color="leaf"
                    onPageChange={onChange}
                    slickCurrentIndex={currentIndex}
                  />
                </CarouselBrandsMobileHidden>
              )}
            </CarouselBrandsDesktopFlexContainer>
            <CarouselBrandsDesktopFlexContainerSpacer />
          </CarouselBrandsContentContainer>
          <CarouselBrandsSliderContainer>
            <Slider {...settings} ref={slickRef}>
              {cards}
            </Slider>
          </CarouselBrandsSliderContainer>
          {items?.length > 1 && (
            <CarouselBrandsDesktopeHidden>
              <Pagination
                variant="large"
                itemsPerPage={itemsPerPage}
                count={items?.length}
                color="leaf"
                onPageChange={onChange}
                slickCurrentIndex={currentIndex}
              />
            </CarouselBrandsDesktopeHidden>
          )}
        </CarouselBrandsFlexDirection>
      </CarouselBrandsInnerContainer>
    </CarouselBrandsContainer>
  );
};

export default CarouselBrands;
