'use client';

import DealCardsList from 'components/organisms/listings/deal-cards';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { getPathMenuFiltersLink } from 'helpers/utils/common';
import { mobile } from 'helpers/utils/screensizes';

interface TProps {
  pageFolder: Record<string, any>;
  data: any;
}

const DealCardsTastic = ({ data, pageFolder }: TProps) => {
  const { preloadedValue } = pageFolder?.dataSourceConfigurations?.find((item) => item.streamId === '__master') || {};
  const [isMobile] = useMediaQuery(mobile);
  const dealCardsData = preloadedValue?.data?.pageStateDealsCollection?.items?.[0]?.dealsCollection?.items;

  const dealText = preloadedValue?.data?.pageStateDealsCollection?.items?.[0]?.dealsHeading;
  const items =
    dealCardsData &&
    dealCardsData
      ?.filter((item) => Boolean(item))
      .map((item) => ({
        image: isMobile || item?.mobileImage ? item?.image : item?.mobileImage,
        discountText: item?.title || '',
        extraText: item?.dealDescription,
        link: getPathMenuFiltersLink(item?.cta?.longUrl || ''),
        tasticPlacement: data?.tasticPlacementNumber + 1,
        internalName: item?.internalName || '',
        specialText: item?.specialText,
      }));
  return <DealCardsList dealText={dealText} host={pageFolder?.host} items={items} />;
};

export default DealCardsTastic;
