import tw from 'tailwind-styled-components';

export const MyRewardsContainer = tw.div`
  p-0
  w-full
  flex-1
  relative
`;

export const MyRewardsBox = tw.div`
  md:pt-[56px]
  md:px-[60px]
`;

export const MyRewardsInnerContainer = tw.div`
  flex
  flex-col
  py-[24px]
  px-[20px]
  md:hidden
`;

export const MyRewardsInnerContainerDesktop = tw.div`
  hidden
  flex-col
  py-[24px]
  px-[20px]
  md:flex
  md:relative
  md:p-[60px]
`;

export const MyRewardsTitleRow = tw.div`
  pb-[8px]
  md:pb-[16px]
`;

export const MyRewardsPointsRow = tw.div`
  pb-[8px]
  md:pb-[32px]
`;

export const RiseRewardsLogoContainer = tw.div`
  absolute
  mr-[20px]
  mb-[184px]
  ml-[314px]
  md:top-[105px]
  md:right-[125px]
`;

export const RiseRewardsLogoMobile = tw.div`
  md:hidden
`;

export const RiseRewardsLogoDesktop = tw.div`
  hidden
  md:contents
`;

export const RewardsIllustrationContainer = tw.div`
  absolute
  ml-[227px]
  mt-[60px]
  md:bottom-[24px]
  md:right-[130px]
`;

export const RewardsIllustrationMobile = tw.div`
  md:hidden
`;

export const RewardsIllustrationDesktop = tw.div`
  hidden
  md:contents
`;
