import tw from 'tailwind-styled-components';

export const HeroProductListingWapper = tw.div`
    p-6 
    md:p-10 
    xl:p-[60px] 
    bg-grey-100
`;

export const HeroProductListingBox = tw.div`
    flex 
    flex-col 
    md:flex-row 
    md:items-center 
    md:justify-between
`;

export const HeroProductListingTitleBox = tw.div`
    flex 
    flex-col 
    gap-6
`;
