'use client';

import { useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import {
  ButtonClasses,
  DataContainer,
  DispensaryContainer,
  DispensaryLocationInformationContainer,
  HeadingContainer,
  ImageContainer,
  InfoContainer,
  InnerDataContainer,
  addressClasses,
} from 'components/organisms/listings/dispensary-location-information/styles';
import { communicationInitiated } from 'helpers/analytics/communication-initiated-track';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IDispensaryTiming } from 'helpers/types';
import { tablet } from 'helpers/utils/screensizes';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import ExternalLinkIcon from 'public/icons/figma/external-link.svg';
import LocationIcon from 'public/icons/figma/location.svg';
import PhoneIcon from 'public/icons/figma/phone.svg';
import TimeIcon from 'public/icons/figma/time.svg';

interface DispensaryLocationInformationProps {
  heading: string;
  description: string;
  CTALabel: string;
  dispensaryName: string;
  contact: string;
  timing?: IDispensaryTiming;
  address: string;
  mapCtaText: string;
  mapURL: string;
  image: Record<string, any>;
  mobileImage: Record<string, any>;
  slug: string;
  host: string;
}

export const DispensaryLocationInformation = ({
  heading,
  description,
  CTALabel,
  dispensaryName,
  contact,
  timing,
  address,
  mapCtaText,
  mapURL,
  image,
  mobileImage,
  slug,
  host,
}: DispensaryLocationInformationProps) => {
  const DispensaryDrawer = dynamic(() => import('components/organisms/drawers/dispensary-drawer'));
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openDispensaryList = () => {
    setIsOpen(true);
  };
  const [isTablet] = useMediaQuery(tablet);

  const selectedImage = isTablet ? image : mobileImage;

  const handleCallNow = () => {
    const data = {
      method: 'call',
      link_name: `tel:${contact}`,
      link_type: 'hyperlink',
      communication_trigger: 'dispensary-loc-info',
    };
    communicationInitiated(data);
  };

  return (
    <>
      <DispensaryLocationInformationContainer>
        <HeadingContainer>
          <Typography variant="h3" className="mb-4" as={'h2'}>
            {heading}
          </Typography>
          <Typography variant="body-small" className="mb-8 !text-grey-400" data-testid="dispensaryInfoDescription">
            {description}
          </Typography>
          {isTablet && (
            <CommonButton
              onClick={openDispensaryList}
              button-type="secondary"
              color="green"
              size="sm"
              className="findDispensaryButton flex"
            >
              <LocationIcon className="mr-2 mt-[2px]" />
              {CTALabel}
            </CommonButton>
          )}
        </HeadingContainer>
        <DispensaryContainer>
          <InfoContainer>
            <Typography variant="h4" className="mb-8" as={'h3'}>
              {dispensaryName}
            </Typography>
            {!isTablet && (
              <ImageContainer>
                <Image
                  loader={(options) => options.src}
                  src={selectedImage?.url}
                  alt={selectedImage?.title}
                  objectFit="contain"
                  width={selectedImage?.width}
                  height={selectedImage?.height}
                  className="dispensaryImage"
                />
              </ImageContainer>
            )}
            {timing && (
              <DataContainer className="mb-4">
                <TimeIcon className="mr-4" />
                <Typography
                  variant="body-small"
                  className="openingTime !text-grey-400"
                >{`${timing.dayToDisplay} ${timing.hours}`}</Typography>
              </DataContainer>
            )}
            {contact && (
              <DataContainer className="mb-4">
                <PhoneIcon className="mr-4" />
                <InnerDataContainer>
                  <Typography variant="body-small" className="contact !text-grey-400 ">
                    {contact}
                  </Typography>
                  <CommonButton
                    button-type="tertiary"
                    color="green"
                    size="sm"
                    className={ButtonClasses + 'contactLink !my-0'}
                    as="a"
                    href={`tel:${contact}`}
                    onClick={handleCallNow}
                  >
                    Call now
                    <ChevronRightIcon className={'ml-2 mt-1'} />
                  </CommonButton>
                </InnerDataContainer>
              </DataContainer>
            )}
            {address && (
              <DataContainer>
                <LocationIcon />
                <InnerDataContainer className="sm:!block">
                  <Typography variant="body-small" className={addressClasses + 'address'}>
                    {address}
                  </Typography>
                  <CommonButton
                    button-type="tertiary"
                    color="green"
                    size="sm"
                    className={ButtonClasses + 'mapURL flex sm:mt-[6px] md:!my-0 md:!mt-[-2px]'}
                    as="a"
                    href={mapURL}
                    target="_blank"
                  >
                    {mapCtaText}
                    <ExternalLinkIcon className={'ml-2 mt-1'} />
                  </CommonButton>
                </InnerDataContainer>
              </DataContainer>
            )}
            <CommonButton
              button-type="secondary"
              color="green"
              size="sm"
              className="viewDispensary inline-flex whitespace-nowrap sm:mt-5 md:mt-8"
              as="a"
              href={slug}
            >
              View Dispensary
              <ChevronRightIcon className={'ml-2 mt-1'} />
            </CommonButton>
          </InfoContainer>
          {isTablet && (
            <ImageContainer>
              <Image
                loader={(options) => options.src}
                src={selectedImage?.url}
                alt={selectedImage?.title}
                objectFit="contain"
                width={selectedImage?.width}
                height={selectedImage?.height}
                className="dispensaryImage"
              />
            </ImageContainer>
          )}
        </DispensaryContainer>
        {!isTablet && (
          // TODO: use the IconWithTextButton
          <CommonButton
            onClick={openDispensaryList}
            button-type="secondary"
            color="green"
            size="sm"
            className="findDispensaryButton mt-6 flex md:mt-0"
          >
            <LocationIcon className="mr-2 mt-[2px]" />
            {CTALabel}
          </CommonButton>
        )}
      </DispensaryLocationInformationContainer>
      {isOpen && <DispensaryDrawer isOpen={isOpen} setDrawerOpen={setIsOpen} host={host} />}
    </>
  );
};
