import tw from 'tailwind-styled-components';

export const ArticleBodyWrapper = tw.div`
    mx-auto 
    max-w-[1920px] 
    lg:flex
`;

export const ShowTocContainer = tw.div`
    z-10
`;

export const HideTocContainer = tw.div`
    ml-[340px]
    mx-auto 
    max-w-[1920px]
`;
