import tw from 'tailwind-styled-components';

export const BenefitsCardContainer = tw.div`
    w-auto
    h-auto
    border-[1px]
    border-grey-250
    border-solid
    rounded-[6px]
    md:mr-[24px]
`;

export const BenefitsCardInnerContainer = tw.div`
    p-[16px]
    md:p-[32px]
    w-[170px]
    md:w-auto
`;

export const BenefitsCardIconContainer = tw.div`
    pb-[24px]
    md:pb-[48px]
`;

export const BenefitsCardTextContainer = tw.div`
    w-[138px]
    h-[72px]
    md:w-[368px]
    md:h-[80px]
`;
