import { useState } from 'react';
import dynamic from 'next/dynamic';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import LocationIcon from 'public/icons/figma/location.svg';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { setDrawerOpenerState } from 'redux/reducers/dispensary-list-drawer';
import { ContactContainer, IconsStyle, NewDispensaryContainer, aboutDispensaryButtonClasses } from './styles';
const DispensaryDrawer = dynamic(() => import('components/organisms/drawers/dispensary-drawer'));

const SetNewHomeDispensary = () => {
  const host = useAppSelector((store) => store.hostReducer.host);
  const newDispensaryCtaText = 'Set new home dispensary';
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const openDrawer = () => {
    setIsOpen(true);
    dispatch(setDrawerOpenerState('HOME_DISPENSARY'));
  };

  return (
    <>
      <NewDispensaryContainer>
        <ContactContainer>
          <LocationIcon className="self-end text-xl text-resin xl:text-2xl" />
          <IconWithTextButton
            className={aboutDispensaryButtonClasses + '!my-0 !ml-3 !p-0 xl:!ml-4'}
            button-type="tertiary"
            color="green"
            icon={<ChevronRightIcon className={IconsStyle + '!mr-0 ml-3 shrink-0 xl:ml-4'} />}
            iconPosition="after"
            onClick={openDrawer}
          >
            {newDispensaryCtaText}
          </IconWithTextButton>
        </ContactContainer>
      </NewDispensaryContainer>
      {isOpen && <DispensaryDrawer isOpen={isOpen} setDrawerOpen={setIsOpen} host={host} />}
    </>
  );
};

export default SetNewHomeDispensary;
