import { BLOCKS } from '@contentful/rich-text-types';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import Pagination from 'components/atoms/pagination';
import { Typography } from 'components/atoms/typography';
import SimpleImageOrVideo from 'components/molecules/image-or-video/simple-image-or-video';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage, IRichTextData } from 'helpers/types/common.type';
import { getPathMenuFiltersLink } from 'helpers/utils/common';
import { desktop, mobile, smallMobile } from 'helpers/utils/screensizes';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import {
  DiscountEventButtonSection,
  DiscountEventContentSection,
  DiscountEventFlexDirectionContainer,
  DiscountEventInnerContent,
} from './styles';

export interface itemsProps {
  placement?: number;
  internalName?: string;
  title: string;
  description: IRichTextData;
  image: IContentfulImage;
  link: string;
  currentIndex?: number;
  count?: number;
  onPageChange?: (p: any) => void;
  onClick?: () => void;
  ctaCollection?: {
    items: [
      {
        title: string;
        longUrl: string;
        openInNewTab: boolean;
      },
    ];
  };
}

const setImageHeight = (width: number) => {
  if (width > desktop) return 529;
  else if (width > mobile) return 400;
  else return 249;
};

const DiscountEventHeroBanner = ({
  buttonLabel = 'Read more',
  title = '',
  description,
  link = '/',
  host = '',
  image,
  onClick,
  count,
  currentIndex,
  onPageChange,
}: itemsProps & { buttonLabel?: string; host: string }) => {
  const [, width] = useMediaQuery(smallMobile);

  return (
    <DiscountEventFlexDirectionContainer>
      <SimpleImageOrVideo
        dataTestTd="banner-backgroung-image"
        imageProps={{
          height: setImageHeight(width),
          width: width,
          layout: 'fixed',
        }}
        imageOrVideo={image}
      />
      <DiscountEventContentSection>
        <DiscountEventInnerContent>
          <Typography
            variant="h3"
            className="!self-stretch lg:!text-[54px] lg:leading-[51px] lg:!tracking-[0.648px] 2xl:!text-[68px] 2xl:!leading-[64px] 2xl:!tracking-[0.816px]"
          >
            {title}
          </Typography>
          <ContentfulRichText
            data={description}
            renderNodeOptions={{
              [BLOCKS.PARAGRAPH]: (node, children) => (
                <Typography variant="body-small" className="!self-stretch !text-grey-400">
                  {children}
                </Typography>
              ),
            }}
          />
        </DiscountEventInnerContent>
        <DiscountEventButtonSection>
          <IconWithTextButton
            host={host}
            button-type="primary"
            icon={<ChevronRightIcon />}
            color="green"
            iconPosition="after"
            href={getPathMenuFiltersLink(link)}
            target={getPathMenuFiltersLink(link).includes(host) ? '_self' : '_blank'}
            as="a"
            size="sm"
            onClick={onClick}
          >
            {buttonLabel}
          </IconWithTextButton>
          {count && count > 1 && (
            <Pagination
              variant="large"
              count={count}
              color="leaf"
              slickCurrentIndex={currentIndex}
              onPageChange={(p) => onPageChange && onPageChange(p)}
            />
          )}
        </DiscountEventButtonSection>
      </DiscountEventContentSection>
    </DiscountEventFlexDirectionContainer>
  );
};

export default DiscountEventHeroBanner;
