'use client';

import CarouselProduct from 'components/organisms/carousel/carousel-product';
import { cardListClickedTrack } from 'helpers/analytics/card-list-clicked-track';
import { getMenuFiltersLink, getPathMenuFiltersLink } from 'helpers/utils/common';

type TProps = {
  data: {
    tasticPlacementNumber: any;
    orderNumber: number;
    productCarouselListData: {
      dataSource: {
        data: {
          page: {
            items: Array<any>;
          };
        };
      };
    };
  };
};

const CarouselProductTastic = ({ data }: TProps) => {
  const carouselProduct = data?.productCarouselListData?.dataSource?.data?.page?.items[0].component.filter(
    (d) => d?.cms?.__typename === 'SectionProductCarousel',
  )[data.orderNumber || 0];
  const { cms, algolia, algolia_total } = carouselProduct || {};
  const algoliaId = cms?.facetTypeCollection?.items?.[0]?.algoliaId;
  const algoliaValue = cms?.facetTypeCollection?.items?.[0]?.algoliaValue;

  const button = {
    title: `${cms?.cta?.title ? cms?.cta?.title : 'See All'} ${algolia_total ? `(${algolia_total})` : ''}`,
    url: cms?.cta?.longUrl
      ? getPathMenuFiltersLink(cms?.cta?.longUrl, algoliaId, algoliaValue)
      : getMenuFiltersLink(algoliaId, algoliaValue),
  };

  const handleSegmentEvent = () => {
    const cardListData = {
      card_list_name: cms?.internalName,
      card_list_title: cms?.heading,
      card_list_type: cms?.carouselVariant,
      card_list_facet_name: algoliaId,
      card_list_facet_value: algoliaValue,
      card_list_url: button?.url,
      link_name: button.title,
      placement: data?.tasticPlacementNumber + 1 || 1,
      link_type: 'hyperlink',
    };
    cardListClickedTrack(cardListData);
  };

  return (
    algolia?.length > 0 && (
      <CarouselProduct
        order={carouselProduct.orderNumber}
        data={algolia || []}
        type={cms?.carouselVariant}
        title={cms?.heading || ''}
        description={cms?.descriptions || ''}
        buttonLink={button?.url || ''}
        buttonTitle={button?.title || ''}
        onClick={handleSegmentEvent}
        algoliaValue={algoliaValue}
      />
    )
  );
};

export default CarouselProductTastic;
