import { Form, Formik } from 'formik';
import {
  EmailInput,
  FirstNameInput,
  FormikTextFieldInput,
  LastNameInput,
} from 'components/molecules/form-fields/text-field';
import {
  FormPersonalInformationFormGroup,
  FormPersonalInformationFormGroupInput,
  FormPersonalInformationInputLabel,
} from './styles';

interface FormDataProps {
  firstName: string;
  lastName: string;
  dob: string;
  phone: string;
  email: string;
  state: string;
  address: string;
}

const initialValues: FormDataProps = {
  firstName: '',
  lastName: '',
  dob: '',
  phone: '',
  email: '',
  state: '',
  address: '',
};
// At this point of time. Form is not interactive to the user. Form is use to diplay the information only.
// It'll be in Formik just in case this form is update to be interactive in the future. ~ jerry.lee 12.26.2024
const FormPersonalInformationForm = ({
  firstName = 'First name',
  lastName = 'Last name',
  dob = 'Date of birth',
  phone = 'Mobile number',
  email = 'Email',
  state = 'State',
  address = 'Address',
}: FormDataProps) => {
  const onFormSubmit = (data) => {
    // data parameter need to be use so it return the data parameter. This will prevent lint error for formik form fields.
    return data;
  };
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={onFormSubmit}
      enableReinitialize={false}
    >
      {(formikProps) => {
        return (
          <Form onReset={formikProps.handleReset} onSubmit={formikProps.handleSubmit} className="lg:w-full">
            <FormPersonalInformationFormGroup>
              <FormPersonalInformationFormGroupInput>
                <FormPersonalInformationInputLabel>First name</FormPersonalInformationInputLabel>
                <FirstNameInput disabled {...formikProps} label={firstName} />
              </FormPersonalInformationFormGroupInput>
              <FormPersonalInformationFormGroupInput>
                <FormPersonalInformationInputLabel>Last name</FormPersonalInformationInputLabel>
                <LastNameInput disabled {...formikProps} label={lastName} />
              </FormPersonalInformationFormGroupInput>
              <FormPersonalInformationFormGroupInput>
                <FormPersonalInformationInputLabel>Date of birth</FormPersonalInformationInputLabel>
                <FormikTextFieldInput {...formikProps} disabled fieldName="dob" label={dob} />
              </FormPersonalInformationFormGroupInput>
              <FormPersonalInformationFormGroupInput>
                <FormPersonalInformationInputLabel>Mobile number</FormPersonalInformationInputLabel>
                <FormikTextFieldInput {...formikProps} disabled fieldName="phone" label={phone} />
              </FormPersonalInformationFormGroupInput>
              <FormPersonalInformationFormGroupInput>
                <FormPersonalInformationInputLabel>Email</FormPersonalInformationInputLabel>
                <EmailInput disabled {...formikProps} label={email} />
              </FormPersonalInformationFormGroupInput>
              <FormPersonalInformationFormGroupInput>
                <FormPersonalInformationInputLabel>State</FormPersonalInformationInputLabel>
                <FormikTextFieldInput {...formikProps} disabled fieldName="state" label={state} />
              </FormPersonalInformationFormGroupInput>
              <FormPersonalInformationFormGroupInput>
                <FormPersonalInformationInputLabel>Address</FormPersonalInformationInputLabel>
                <FormikTextFieldInput {...formikProps} disabled fieldName="address" label={address} />
              </FormPersonalInformationFormGroupInput>
            </FormPersonalInformationFormGroup>
          </Form>
        );
      }}
    </Formik>
  );
};

export default FormPersonalInformationForm;
