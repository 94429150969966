'use client';

import { Typography } from 'components/atoms/typography';
import LinkCard from 'components/molecules/cards/link-cards/with-image';
import {
  LinkCardContainer,
  StateDispensaryListContainer,
  StateDispensaryListWrapper,
  TitleContainer,
} from 'components/organisms/listings/state-dispensary-list/styles';
import { ReplaceUrlWithHost, getLinkTarget } from 'helpers/utils/common';
import { useAppSelector } from 'redux/hooks';

interface StateDispensaryListProps {
  data: Record<string, any>;
  stateName: string;
  stateLink: string;
}
const StateDispensaryList = ({ data, stateName, stateLink }: StateDispensaryListProps) => {
  const host = useAppSelector((state) => state?.hostReducer.host);
  const generateStateLink = '{{url}}' + stateLink;
  const stateUrl = ReplaceUrlWithHost(generateStateLink, host);

  return (
    <StateDispensaryListWrapper>
      <StateDispensaryListContainer>
        <TitleContainer>
          {stateName && stateLink && (
            <a href={stateUrl} target={getLinkTarget(stateUrl)} aria-labelledby="state-heading">
              <Typography
                data-testid="stateName"
                variant="h4"
                as="h2"
                className="mb-12 cursor-pointer hover:underline md:mb-0 xl:w-[342px] min1440:w-[456px]"
                id="state-heading"
              >
                {stateName}
              </Typography>
            </a>
          )}
        </TitleContainer>
        {data && (
          <LinkCardContainer data-testid="linkCardContainer">
            {Array.isArray(data) &&
              data.map((state, index) => {
                const linkProps = {
                  image: state?.heroImage || null,
                  title: state?.dispensaryInformation?.title || '',
                  longUrl: `{{url}}${state?.slug}` || '',
                };
                return <LinkCard key={index} index={index} link={linkProps} titleClickable={true} />;
              })}
          </LinkCardContainer>
        )}
      </StateDispensaryListContainer>
    </StateDispensaryListWrapper>
  );
};

export default StateDispensaryList;
