'use client';

import React from 'react';
import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage, ICta } from 'helpers/types';
import { ReplaceUrlWithHost } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import { ButtonContainer, LeftImageContainer, MainContainer, RightImageContainer } from './styles';

export interface IAboutCta {
  title: string;
  desktopImage: IContentfulImage[];
  mobileImage: IContentfulImage[];
  cta: ICta;
  tasticPlacementNumber: number;
  host: string;
}

const AboutCta = ({ title, desktopImage, mobileImage, cta, tasticPlacementNumber, host }: IAboutCta) => {
  const [isTablet] = useMediaQuery(tablet);
  const handleSegmentEvent = (type?: string) => {
    const bannerClickedData = {
      placement: tasticPlacementNumber + 1,
      banner_position: 1,
      banner_internal_name: title,
      banner_title: title,
      banner_image_main: '',
      banner_type: 'Other',
      link_name: cta?.title,
      link_type: 'Hyperlink',
    };
    bannerTrack(type, bannerClickedData);
  };
  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });
  return (
    <MainContainer ref={targetRef}>
      <ButtonContainer>
        {title && (
          <Typography variant="h1" className="mb-10 !text-kief">
            {title}
          </Typography>
        )}
        {cta && (
          <CommonButton
            color="green"
            button-type="primary"
            as={'a'}
            href={ReplaceUrlWithHost(cta?.longUrl as string, host)}
            onClick={() => handleSegmentEvent(SegmentEvents.BANNER_CLICKED)}
          >
            {cta?.title}
          </CommonButton>
        )}
      </ButtonContainer>
      {isTablet ? (
        <>
          <LeftImageContainer>
            {Array.isArray(desktopImage) &&
              desktopImage?.slice(0, 3).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`absolute ${
                      index === 0 ? 'left-0 top-0' : index === 1 || index === 2 ? 'bottom-0 left-0' : ''
                    }`}
                  >
                    <Image
                      src={item?.url || ''}
                      alt={item?.title || ''}
                      width={item?.width}
                      height={item?.height}
                      loader={(options) => options.src}
                    />
                  </div>
                );
              })}
          </LeftImageContainer>
          <RightImageContainer>
            {Array.isArray(desktopImage) &&
              desktopImage?.slice(3).map((item, index) => {
                return (
                  <div
                    key={index}
                    className={`absolute ${index === 0 ? 'right-[2%] top-0' : index === 1 ? 'bottom-0 right-0' : ''}`}
                  >
                    <Image
                      src={item?.url || ''}
                      alt={item?.title || ''}
                      height={item?.height}
                      width={item?.width}
                      key={index}
                      loader={(options) => options.src}
                    />
                  </div>
                );
              })}
          </RightImageContainer>
        </>
      ) : (
        <>
          <LeftImageContainer>
            {Array.isArray(mobileImage) &&
              mobileImage?.slice(0, 1).map((item, index) => {
                return (
                  <div key={index} className="absolute bottom-0 left-0">
                    <Image
                      src={item?.url || ''}
                      alt={item?.title || ''}
                      width={item?.width}
                      height={item?.height}
                      loader={(options) => options.src}
                    />
                  </div>
                );
              })}
          </LeftImageContainer>
          <RightImageContainer>
            {Array.isArray(mobileImage) &&
              mobileImage?.slice(1).map((item, index) => {
                return (
                  <div key={index} className="absolute right-0 top-0">
                    <Image
                      src={item?.url || ''}
                      alt={item?.title || ''}
                      height={item?.height}
                      width={item?.width}
                      key={index}
                      loader={(options) => options.src}
                    />
                  </div>
                );
              })}
          </RightImageContainer>
        </>
      )}
    </MainContainer>
  );
};

export default AboutCta;
