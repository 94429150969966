import { formatPhoneNumber } from 'helpers/utils/format-phonenumber';
import { IFormTrackEvent, SegementTrackEvent } from '../analytics/segment-helper';
import { BlueshiftEvents, SegmentEvents } from '../constants/forms';

export const contactUsFormSubmit = async (values) => {
  const { email, phoneNumber, state, dispensary, firstName, lastName, help_you_with, description, user_attachments } =
    values;
  try {
    const data: IFormTrackEvent = {
      lead_email: email,
      phone_number: formatPhoneNumber(phoneNumber),
      form_state_name: state,
      form_dispensary_name: dispensary,
      first_name: firstName,
      last_name: lastName,
      form_type: BlueshiftEvents.CONTACT_US,
      form_details: {
        user_query: help_you_with,
        user_message: description,
        user_attachments,
      },
    };
    return await SegementTrackEvent(SegmentEvents.FORM_SUBMITTED, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
