'use client';

import React from 'react';
import { useSelector } from 'react-redux';
import ContactUsForm from 'components/organisms/forms/contact-us/contact-us-form';
import { RootState } from 'redux/store';

const ContactUsPageTastic = (props: any) => {
  const data = props?.data?.contactUsDataSource?.dataSource?.data?.pageContactUsCollection?.items[0];
  const dispensariesStateData: any = useSelector((state: RootState) => state?.dispensaryList?.dispensaryList) || [];
  return <ContactUsForm data={data} stateList={dispensariesStateData} host={props?.pageFolder?.host} />;
};

export default ContactUsPageTastic;
