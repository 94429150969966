import tw from 'tailwind-styled-components';

export const RewardsHeroContainer = tw.div`
    bg-grey-100
    flex
    flex-col
    columns-1
    flex-start
    justify-start
    gap-y-6
    md:flex-row
    md:justify-between
    md:gap-y-0
    md:gap-x-[32px]
    md:py-[80px]
    md:px-[60px]
`;

export const RewardsHeroTextContainer = tw.div`
    bg-grey-100
    md:w-[736px]
`;

export const RewardsHeroInnerTextContainer = tw.div`
    px-[20px]
    pt-[40px]
    text-center
    md:text-left
    md:px-0
    md:py-[80px]
`;

export const RewardsHeroImageContainer = tw.img`
    w-full
    h-full
    bg-center
    bg-cover
    bg-no-repeat
    grow
    shrink-0
    basis-0
    md:hidden
`;

export const RewardsHeroDesktopBannerContainer = tw.div`
    hidden
    md:flex
    md:w-[736px]
`;

export const RewardsHeroDesktopImageContainer = tw.div`
    bg-no-repeat
    flex
    flex-col
    justify-start
    items-start
    md:w-[736px]
    md:h-[720px]
    md:bg-right
`;

export const RewardsHeroDescriptionContainer = tw.div`
    py-[32px]
    w-[350px]
    md:max-w-[432px]
`;

export const RewardsHeroRewardsLogoContainer = tw.div`
    bg-white
    w-[130px]
    h-[130px]
    rounded-md
    md:w-[280px]
    md:h-[280px]
    md:my-[220px]
    md:ml-0
    md:mr-0
    md:absolute
    md:z-[2]
`;

export const RewardsHeroRewardsLogoImageContainer = tw.div`
    pl-[32px]
    pr-[34px]
    pt-[36px]
    pb-[38px]
    md:px-[85px]
    md:pt-[90px]
    md:pb-[94px]
`;

export const RewardsHeroRewardsLogoImage = tw.img`
    w-[64px]
    h-[56px]
    md:w-[110px]
    md:h-[96px]
`;

export const RewardsHeroImageMobileContainer = tw.div`
    flex
    flex-col
    h-full
    items-start
    -gap-[370px]
    shrink-0
    self-stretch
    md:hidden
`;

export const RewardHeroBgImageMobileContainer = tw.div`
    flex
    flex-col
    items-start
    -gap-[10]
    shrink-0
    self-stretch
`;

export const RewardHeroLogoMobileContainer = tw.div`
    flex
    h-full
    p-[20px]
    items-start
    gap-2.5
    shrink-0
    absolute
    md:hidden
`;
