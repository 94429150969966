export const mapDispensaryItems = (items) => {
  const dispensaries = items?.hits?.length > 1 ? items?.hits?.slice(1, items?.results?.hitsPerPage) : items?.hits;
  return dispensaries?.map((item) => ({
    title: item?.name || '',
    image: item?.image || {},
    mobile_image: item?.mobile_image || {},
    phoneNumber: item?.phone_number || '',
    address: { text: Object.values(item?.address).join(' ') },
    _geoloc: item?._geoloc || '',
    slug: item?.slug || '',
    timing: item?.hours || [],
  }));
};
