import tw from 'tailwind-styled-components';

export const DispensaryNationMapListingItemStart = tw.div`
    flex
    flex-col
    w-full
    items-start
`;

export const DispensaryNationMapListingContainer = tw(DispensaryNationMapListingItemStart)`
    md:flex
    md:flex-col
    md:w-full
    md:min-w-[768px]
    md:max-w-[1200px]
    md:py-[60px]
    md:justify-center
    md:items-center
    md:bg-white

    lg:min-w-[1280px]
    lg:max-w-[1920px]
`;

export const DispensaryNationMapListingInnerContainer = tw(DispensaryNationMapListingItemStart)`
    shrink-0
    bg-white

    md:px-10
    md:gap-10
    md:grow
    md:shrink-0
    md:basis-0

    2xl:px-[60px]
    2xl:gap-[48px]
    2xl:max-[2200px]
`;

export const DispensaryNationMapListingHeader = tw(DispensaryNationMapListingItemStart)`
    px-5
    py-6
    gap-6
`;

export const DispensaryNationMapListingMapContainer = tw.div`
    w-full
    shrink-0
    self-stretch

    [&_div[role="dialog"]]:!max-h-fit
    [&_div.gm-style-iw-d]:!max-h-fit

    sm:[&_div#google-map]:!min-h-[620px]
    sm:[&_div#google-map]:!h-[620px]

    md:[&_div#google-map]:!min-h-[600px]
    md:[&_div#google-map]:!h-[600px]

    lg:[&_div#google-map]:!min-h-[600px]
    lg:[&_div#google-map]:!h-[600px]

    2xl:[&_div#google-map]:!min-h-[821px]
    2xl:[&_div#google-map]:!h-[821px]
`;
