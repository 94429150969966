'use client';

import PageSEOComponent from 'components/organisms/global/page-seo';
import ProductDetailsComponent from 'components/organisms/listings/product-details';
import useProductDetails from 'helpers/hooks/use-product-details';

const ProductDetailsTastics = ({ pageFolder }: any) => {
  const { productDetailsData, seoMetaData } = useProductDetails({ pageFolder });
  return (
    <>
      <PageSEOComponent data={seoMetaData} />
      {/* @ts-ignore */}
      <ProductDetailsComponent {...productDetailsData} />
    </>
  );
};

export default ProductDetailsTastics;
