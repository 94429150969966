import { RefObject, useEffect, useState } from 'react';

const useOnScreen = (ref: RefObject<HTMLElement>, isUpdated: boolean) => {
  const [isOnScreen, setIsOnScreen] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIsOnScreen(entry.isIntersecting));
    if (ref.current) observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, [isUpdated]);

  return isOnScreen;
};

export default useOnScreen;
