'use client';

import { useRef, useState } from 'react';
import Slider from 'react-slick';
import Pagination from 'components/atoms/pagination';
import { Typography } from 'components/atoms/typography';
import { UserReview } from 'components/molecules/cards/user-review';
import {
  CarouselContainer,
  DispensaryReviewsContainer,
  TitlePaginationContainer,
} from 'components/organisms/listings/dispensary-reviews/styles';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { tablet } from 'helpers/utils/screensizes';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
interface DispensaryReviewsProps {
  title: string;
  reviews: {
    author: string;
    comment: string;
    rating: string;
  }[];
}
export const DispensaryReviews = ({ title, reviews }: DispensaryReviewsProps) => {
  const pageChange = (pageNumber) => {
    // @ts-ignore
    slickRef.current.slickGoTo(pageNumber.page - 1);
  };
  const [disabledPaginatuion, setDisabledPagination] = useState(false);
  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    speed: 500,
    variableWidth: true,
    beforeChange: () => setDisabledPagination(true),
    afterChange: () => setDisabledPagination(false),
  };
  const slickRef = useRef(null);
  const [isTablet] = useMediaQuery(tablet);
  return (
    <DispensaryReviewsContainer>
      <TitlePaginationContainer>
        <Typography variant="h3" as={'h2'} className="mb-[42px] md:mb-[172px]">
          {title}
        </Typography>
        {isTablet && (
          <Pagination
            isVisible={true}
            count={reviews.length}
            color="resin"
            variant="large"
            itemsPerPage={1}
            onPageChange={(x) => pageChange(x)}
            disableButton={disabledPaginatuion}
          />
        )}
      </TitlePaginationContainer>
      <CarouselContainer className="dispensaryReviews">
        <Slider {...settings} ref={slickRef}>
          {reviews.map((review, index) => {
            return (
              <div className="mr-6" key={index}>
                <UserReview {...review} />
              </div>
            );
          })}
        </Slider>
      </CarouselContainer>
      {!isTablet && (
        <Pagination
          isVisible={true}
          count={reviews.length}
          color="resin"
          variant="large"
          itemsPerPage={1}
          onPageChange={(x) => pageChange(x)}
        />
      )}
    </DispensaryReviewsContainer>
  );
};
