'use client';

import React, { useEffect, useState } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import AnchorLink from 'components/atoms/navigation/anchor-link/anchor-link';
import { Typography } from 'components/atoms/typography';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { generateHashId } from 'helpers/utils/common';
import { desktop } from 'helpers/utils/screensizes';
import { TopicsContainerList } from './styles';

type TopicsContainerProps = {
  titles: string[];
  tocHeading: string;
  closeDrawer?: () => void;
  isDrawer?: boolean;
};

const TopicsListContainer = ({ tocHeading, titles, closeDrawer, isDrawer }: TopicsContainerProps) => {
  const path = usePathname();
  const searchParams = useSearchParams();

  const [hash, setHash] = useState<string>('');
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isDesktop] = useMediaQuery(desktop);

  const handleTitleClick = () => {
    closeDrawer && closeDrawer();
  };

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);

    let timer = null;
    if (timer !== null) {
      clearTimeout(timer);
    }

    // @ts-ignore
    timer = setTimeout(() => {
      const scrollables = document.getElementsByClassName('scroll-headings');
      if (scrollables) {
        const recArr = Array.from(scrollables).map((scrollable) => {
          return { rect: scrollable.getBoundingClientRect(), id: scrollable.id };
        });
        const filteredRect = recArr.filter((rect) => rect.rect.top - 1 <= 400 && rect.rect.top - 1 >= 100);
        if (filteredRect.length > 0) {
          setHash(filteredRect[0].id);
        }
      }
    }, 500);
  };

  useEffect(() => {
    if (isDesktop) {
      window.addEventListener('scroll', handleScroll, { passive: true });
    } else {
      window.removeEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isDesktop]);

  // TODO: test if changing pathname or searchParams will trigger useEffect
  // useEffect(() => {
  //   setHash(path.split('#')[1]);
  //   const onHashChangeStart = (url) => {
  //     setHash(url.split('#')[1]);
  //   };

  //   router.events.on('hashChangeStart', onHashChangeStart);

  //   return () => {
  //     router.events.off('hashChangeStart', onHashChangeStart);
  //   };
  // }, [path, searchParams]);

  useEffect(() => {
    if (path) {
      setHash(path.split('#')[1]);
    }
  }, [path, searchParams]);

  return (
    <TopicsContainerList $isSticky={scrollPosition > 450}>
      <Typography variant="h5" className={!isDrawer ? 'mb-6 lg:mb-[56px] 2xl:mb-0' : 'mb-6 2xl:mb-0'}>
        {tocHeading}
      </Typography>
      <div>
        {titles.map((title, index) => (
          <AnchorLink
            key={index}
            isActive={hash === generateHashId(title)}
            href={`#${generateHashId(title)}`}
            onClick={handleTitleClick}
          >
            {title}
          </AnchorLink>
        ))}
      </div>
    </TopicsContainerList>
  );
};

export default TopicsListContainer;
