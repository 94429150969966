import tw from 'tailwind-styled-components';

export const DealCardsListContainer = tw.div`
    flex
    flex-col
    items-start
    w-full
    min-w-[320px]
    max-w-[767px]
    py-10
    gap-10
    bg-white

    md:px-10
    md:min-w-[698px]
    md:max-w-[1280px]

    2xl:py-[120px]
    2xl:px-[60px]
    2xl:max-w-[1920px]
    2xl:gap-16
    2xl:justify-center
`;

export const DealCardsListHeaderSection = tw.div`
    w-full
    px-5
`;

export const DealCardsListListSection = tw.div`
    w-full
    pl-[20px]
    [&_div.slick-track]:flex
    [&_div.slick-track]:pl-5
    [&_div.slick-track]:items-start
    [&_div.slick-track]:gap-4
    [&_div.slick-track]:self-stretch
`;

export const DealCardsListSliderContainer = tw.div`
    flex
    flex-col
    items-start
    gap-6
    self-stretch
    w-full
    md:hidden
`;

export const DealCardsListSliderPadding = tw.div`
    px-5
`;

export const DealCardsListShowMoreContainer = tw.div`
    hidden
    md:flex
    md:flex-col
    md:gap-10

    2xl:gap-16
    w-full
`;
export const DealCardsListShowMoreCardList = tw.div`
    flex
    items-center
    gap-x-6
    self-stretch
    flex-wrap
    max-w-[2200px]

    md:gap-y-10
    2xl:gap-y-16

`;

export const DealCardsListShowMoreButtonSection = tw.div`
    flex
    flex-row
    justify-center
    items-center
    gap-6
`;
