'use client';

import { useEffect, useState } from 'react';
import RichTextItems from 'components/organisms/media/rich-text-component';
import TableOfContent from 'components/organisms/media/summary-anchor-links';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { COMMON_COOKIES } from 'helpers/types/cookies';
import { getCookie } from 'helpers/utils/cookies';
import { tablet } from 'helpers/utils/screensizes';
import { ArticleBodyWrapper, HideTocContainer, ShowTocContainer } from './styles';

type TProps = {
  pageFolder: Record<string, any>;
};

const ArticleBodyTastic = ({ pageFolder }: TProps) => {
  const dataObject = pageFolder?.dataSourceConfigurations.filter((config) => config.streamId === '__master');
  const dataCollection = dataObject[0].preloadedValue.data.pageBlogResourceCollection.items[0];
  const sectionData = dataCollection.blogSectionsCollection;
  const [isDrawer, setIsDrawer] = useState(false);
  const [isTablet] = useMediaQuery(tablet);
  const [scrollPosition, setScrollPosition] = useState(0);
  const cookies = getCookie(COMMON_COOKIES.STICKY_BAR);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (isTablet) {
      return;
    }
    let timer = null;

    const handleScroll = () => {
      // @ts-ignore
      clearTimeout(timer);

      // @ts-ignore
      timer = setTimeout(() => {
        const tocEndElement = document.getElementById('tocEnd');
        if (tocEndElement) {
          const rect = tocEndElement.getBoundingClientRect();
          if (rect.top >= 0) {
            setIsDrawer(false);
          } else {
            setIsDrawer(true);
          }
        } else {
          setIsDrawer(true);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isTablet]);

  return (
    <>
      {dataCollection.showToC === true ? (
        <ArticleBodyWrapper>
          <div
            className={`${
              scrollPosition < 600 ? `${cookies ? 'xl:top-[600px]' : ' xl:top-[650px]'} xl:fixed ` : `xl:top-0`
            }`}
          >
            <div
              className={
                scrollPosition < 100
                  ? 'xl:fixed'
                  : `${
                      cookies ? 'md:top-[170px] xl:bottom-40 2xl:top-40' : '2xl:top-43 md:top-[230px] xl:bottom-40'
                    } md:sticky`
              }
            >
              <TableOfContent
                tocMargin={true}
                bottomScroll={true}
                isDrawer={isDrawer}
                titles={sectionData.items
                  .filter((item) => item.useInToC && item.title !== null)
                  .map((item) => item.title)}
                tocHeading={'Content'}
              />
            </div>
          </div>
          <ShowTocContainer id="tocEnd" className={scrollPosition < 600 ? ' xl:ml-[169px] 2xl:ml-[340px]' : ''}>
            <RichTextItems data={sectionData} />
          </ShowTocContainer>
        </ArticleBodyWrapper>
      ) : (
        <HideTocContainer>
          <RichTextItems data={sectionData} />
        </HideTocContainer>
      )}
    </>
  );
};
export default ArticleBodyTastic;
