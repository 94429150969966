import tw from 'tailwind-styled-components';

export const AccountEmptyStateContainer = tw.div<{ $styles: string }>`
    inline-flex
    flex-col
    py-16
    px-[35px]

    items-center
    gap-20
    bg-white

    lg:shrink-0
    lg:h-[968px]
    lg:w-full
    lg:p-0
    lg:items-start
    lg:gap-24
    lg:flex-row
    md:flex-1
    ${(p) => p.$styles || ''}
`;

export const AccountEmptyStateMessageSection = tw.div<{ $styles: string }>`
    flex
    flex-col
    items-center
    w-full
    gap-6

    lg:w-full
    lg:my-12
    lg:ml-[60px]
    lg:items-start
    lg:gap-10
    ${(p) => p.$styles || ''}
`;

export const AccountEmptyStateMessageWrapper = tw.div`
    flex
    flex-col
    gap-4
    lg:gap-6
    lg:self-stretch
    max-w-full
    xl:max-w-[690px]
`;

export const AccountEmptyStateMessageSubSection = tw.div`
    lg:w-[432px]
    lg:pr-[85px]
`;

export const AccountEmptyStateImageSection = tw.div<{ $styles: string }>`
    flex
    w-full
    lg:flex-row-reverse
    lg:items-start
    lg:pr-[60px]
    lg:w-auto
    lg:mt-[560px]
    ${(p) => p.$styles || ''}
`;
