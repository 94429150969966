'use client';

import { useMemo, useState } from 'react';
import { Typography } from 'components/atoms/typography';
import MapComponent from 'components/organisms/listings/map-listing/google-map';
import { MapType } from 'helpers/types';
import { convertToDispensaryType } from 'helpers/utils/convert-to-dispensary-type';
import { getMainDeliveryZone } from 'helpers/utils/delivery-zone';
import { sortListByTitle } from 'helpers/utils/sort-list-by-title';
import {
  DispensaryNationMapListingContainer,
  DispensaryNationMapListingHeader,
  DispensaryNationMapListingInnerContainer,
  DispensaryNationMapListingMapContainer,
} from './styles';

interface DispensaryNationMapListingProps {
  host: string;
  data: any;
}

const mapStyle = {
  width: '100%',
  height: '100%',
};

const DispensaryNationMapListing = ({ host, data = [] }: DispensaryNationMapListingProps) => {
  const [open, setOpen] = useState(false);
  const geojson = useMemo(
    () =>
      Object?.values(
        data?.reduce?.((obj, { deliveryZones }) => {
          getMainDeliveryZone(deliveryZones?.items, obj);
          return obj;
        }, {}) || {},
      ),
    [data],
  );

  const mapData =
    useMemo(
      () =>
        convertToDispensaryType(
          sortListByTitle(data?.map?.((item) => ({ ...item, title: item?.dispensaryInformation?.title }))),
        ),
      [data],
    ) || [];

  return (
    <DispensaryNationMapListingContainer>
      <DispensaryNationMapListingInnerContainer>
        <DispensaryNationMapListingHeader>
          <Typography variant="h3">RISE Dispensaries Near You</Typography>
        </DispensaryNationMapListingHeader>
        <DispensaryNationMapListingMapContainer>
          {data?.length !== 0 && (
            <MapComponent
              geoJsons={geojson}
              data={mapData}
              host={host}
              mapStyle={mapStyle}
              open={open}
              setOpen={setOpen}
              showAllDispensaries
              page={MapType.dispensary}
            />
          )}
        </DispensaryNationMapListingMapContainer>
      </DispensaryNationMapListingInnerContainer>
    </DispensaryNationMapListingContainer>
  );
};

export default DispensaryNationMapListing;
