import tw from 'tailwind-styled-components';

export const HeroContainer: any = tw.div`
  w-full
  h-[390px]
  md:h-[848px]
  relative
`;

export const MainContainer: any = tw.div`
  absolute
  w-full
  sm:py-10
  sm:px-8
  lg:py-14
  lg:px-[60px]
  bottom-0
  flex 
  flex-col 
  gap-6
  items-center
  md:items-start
  lg:items-start
`;

export const TitleWrapper: any = tw.div`
 lg:grid
 lg:grid-cols-12
 xl:grid
 xl:grid-cols-6
 
 `;

export const OverlayContainer = tw.div<{ $chooseDarkTemplate: boolean }>`
  absolute
  bottom-0
  h-full
  w-full
  bg-gradient-to-t
  ${(props) =>
    props.$chooseDarkTemplate ? 'from-dark to-transparent opacity-80' : 'from-light to-transparent opacity-60'}
`;
export const BannerBreadcumbContainer = tw.div`
   pt-6
   xl:py-0 
   md:py-0
`;
