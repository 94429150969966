'use client';

import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import Pagination from 'components/atoms/pagination';
import { Typography } from 'components/atoms/typography';
import { BenefitsCardComponent } from 'components/molecules/cards/benefits-card';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render/contentful-rich-text';
import { bannerTrack } from 'helpers/analytics/banner-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useIntersectionObserver from 'helpers/hooks/use-intersection-observer';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { TBenefitsCardProps } from 'helpers/types/organisms/rewards/benefits-card';
import { TMemberBenefitsProps } from 'helpers/types/organisms/rewards/member-benefits';
import { desktop, smallMobile } from 'helpers/utils/screensizes';
import {
  MemberBenefitsInnerContainer,
  MemberBenefitsListContainer,
  MemberBenefitsListDesktopContainer,
  MemberBenefitsMobilePaginationButtonContainer,
  MemberBenefitsMobilePaginationContainer,
  MemberBenefitsMobilePaginationLabelContainer,
  MemberBenefitsPaginationContainer,
  MemberBenefitsSection,
  MemberBenefitsTextContainer,
  MemberBenefitsTextRow,
} from './styles';

export const MemberBenefitsComponent = ({
  title,
  copyText,
  benefits,
  tasticPlacementNumber,
  internalName,
}: TMemberBenefitsProps) => {
  const DEFAULT_CARD_DISPLAY = 4;
  const [isMobile] = useMediaQuery(smallMobile);
  const [isDesktop] = useMediaQuery(desktop);
  const [isMobileTrayOpen, setMobileTray] = useState(false);
  const [benefitsToRender, setBenefitsList] = useState<TBenefitsCardProps[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const showPagination = benefits?.length > DEFAULT_CARD_DISPLAY;
  const showDesktopPagination = showPagination && isDesktop;

  useEffect(() => {
    if (isMobileTrayOpen) {
      // @ts-ignore
      setBenefitsList(benefits);
    } else {
      // @ts-ignore
      setBenefitsList(benefits.slice(0, DEFAULT_CARD_DISPLAY));
    }
  }, [benefits, isMobileTrayOpen]);

  const toggleMobileTray = () => {
    setMobileTray(!isMobileTrayOpen);
  };

  const handleSegmentEvent = (type: string, options?: object) => {
    const bannerClickedData = {
      placement: tasticPlacementNumber + 1,
      banner_position: 1,
      banner_internal_name: internalName,
      banner_title: title,
      banner_image_main: '',
      banner_type: 'Other',
      link_name: '',
      link_type: '',
      ...options,
    };
    bannerTrack(type, bannerClickedData);
  };

  const targetRef = useIntersectionObserver(() => {
    handleSegmentEvent(SegmentEvents.BANNER_VIEWED);
  });

  const settings = {
    lazyLoading: 'ondemand',
    dots: false,
    infinite: false,
    slidesToShow: DEFAULT_CARD_DISPLAY,
    slidesToScroll: DEFAULT_CARD_DISPLAY,
    arrows: false,
    speed: 500,
    variableWidth: true,
  };
  const slickRef = useRef(null);

  const animatePageChange = (startIndex, endIndex, pageNumber) => {
    const direction = endIndex - startIndex;
    handleSegmentEvent(SegmentEvents.BANNER_VIEWED, {
      banner_scroll_direction: currentIndex > pageNumber ? 'left' : 'right',
    });

    //@ts-ignore
    slickRef?.current?.slickGoTo(
      direction >= DEFAULT_CARD_DISPLAY ? pageNumber - DEFAULT_CARD_DISPLAY : pageNumber + DEFAULT_CARD_DISPLAY,
    );
  };

  const renderMobileTrayToggleButtonLabel = () => {
    const showLabel = isMobileTrayOpen ? 'less' : 'more';
    const showCount = isMobileTrayOpen
      ? benefits?.length - DEFAULT_CARD_DISPLAY
      : benefits?.length - benefitsToRender?.length;
    return `Show ${showLabel} (${showCount})`;
  };

  const renderBenefitsList = (benefitsList) => {
    return benefitsList?.map((benefit, index) => {
      return <BenefitsCardComponent key={index} imageSource={benefit.logo.url} text={benefit.title} />;
    });
  };

  return (
    <MemberBenefitsSection ref={targetRef}>
      <MemberBenefitsInnerContainer>
        <MemberBenefitsTextRow>
          <MemberBenefitsTextContainer>
            <Typography
              variant="h3"
              className={isMobile ? 'pb-[16px]' : 'pb-[20px]'}
              data-testid="member_benefits_title"
              as={'h2'}
            >
              {title}
            </Typography>
            <Typography
              variant="body-small"
              data-testid="member_benefits_copy"
              className="!text-grey-400 last:[&_div_p]:pb-0"
            >
              <ContentfulRichText data={copyText} />
            </Typography>
          </MemberBenefitsTextContainer>
          <MemberBenefitsPaginationContainer data-testid="member_benefits_pagination">
            <Pagination
              variant="large"
              count={benefits?.length}
              onPageChange={({ startIndex, endIndex, page }) => {
                animatePageChange(startIndex, endIndex, page);
                // @ts-ignore
                setBenefitsList(benefits?.slice(startIndex, endIndex));
              }}
              color="resin"
              itemsPerPage={DEFAULT_CARD_DISPLAY}
              isVisible={showDesktopPagination}
            />
          </MemberBenefitsPaginationContainer>
        </MemberBenefitsTextRow>
        <MemberBenefitsListContainer>{renderBenefitsList(benefitsToRender)}</MemberBenefitsListContainer>
        <MemberBenefitsListDesktopContainer>
          {benefits?.length > DEFAULT_CARD_DISPLAY ? (
            <Slider
              {...settings}
              afterChange={(newIndex) => {
                setCurrentIndex(newIndex);
              }}
              ref={slickRef}
            >
              {renderBenefitsList(benefits)}
            </Slider>
          ) : (
            renderBenefitsList(benefits)
          )}
        </MemberBenefitsListDesktopContainer>
        <MemberBenefitsMobilePaginationContainer $showPagination={showPagination}>
          <MemberBenefitsMobilePaginationLabelContainer>
            <Typography variant="body-small" className="!text-grey-400">
              Showing {benefitsToRender?.length} of {benefits?.length}
            </Typography>
          </MemberBenefitsMobilePaginationLabelContainer>
          <MemberBenefitsMobilePaginationButtonContainer>
            <CommonButton
              color="green"
              button-type="secondary"
              size="sm"
              onClick={toggleMobileTray}
              data-testid="member_benefits_tray_button"
            >
              {renderMobileTrayToggleButtonLabel()}
            </CommonButton>
          </MemberBenefitsMobilePaginationButtonContainer>
        </MemberBenefitsMobilePaginationContainer>
      </MemberBenefitsInnerContainer>
    </MemberBenefitsSection>
  );
};
