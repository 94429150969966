import tw from 'tailwind-styled-components';
import { ImageBg, ImageBgProps } from 'components/atoms/image';

export const BrandCardContainer = tw.div`
    flex
    flex-col
    rounded-md
    bg-white
    items-center

    w-[130px]
    pt-7
    px-4
    gap-4

    md:w-[280px]
    md:pt-5
    md:px-5
    md:gap-5
    md:justify-end

    lg:w-[280px]
    lg:h-[352px]
    lg:pt-10
    lg:gap-10
    lg:shrink-0
`;

export const BrandCardImg = tw(ImageBg)<ImageBgProps>`
    !h-20
    !w-20
    !bg-white
    !bg-contain
    !bg-center

    md:!w-[200px]
    md:!h-[200px]

    lg:!w-[200px]
    lg:!h-[200px]
`;

export const BrandCardLabel = tw.div`
    flex
    flex-col
    jsutify-end
    items-center
    self-stretch
    border-t
    border-grey-200
    py-4
    px-2.5
    gap-2.5

    md:gap-5
    md:px-0
    md:py-6
`;
