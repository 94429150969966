import { useMemo } from 'react';
import Image from 'next/image';
import { IContentfulImage } from 'helpers/types';
import { ThumbnailImageOverlay, ThumbnailImageOverlayContainer, ThumbnailRowContainer } from './styles';

type TGalleryThumbnailImages = {
  images: IContentfulImage[];
  activeImageIndex: number;
  pageChange: (pageNumber: number) => void;
};

export const GalleryThumbnailImages = ({ images, activeImageIndex = 0, pageChange }: TGalleryThumbnailImages) => {
  const memoizedImages = useMemo(() => {
    return images?.map((image, index) => {
      return (
        <ThumbnailImageOverlayContainer key={index}>
          <Image
            key={index}
            src={image?.url || ''}
            alt={image?.alt || ''}
            title={image?.title}
            width={160}
            height={80}
            layout="intrinsic"
            className="rounded-md"
            aria-description={image?.description}
            loader={(options) => options.src}
            onClick={() => {
              pageChange(index + 1);
            }}
          />
          {activeImageIndex === index && <ThumbnailImageOverlay />}
        </ThumbnailImageOverlayContainer>
      );
    });
  }, [images, activeImageIndex]);

  return <ThumbnailRowContainer>{memoizedImages}</ThumbnailRowContainer>;
};
