import tw from 'tailwind-styled-components';

export const SideBarContainer = tw.div`
    relative
`;

export const SideBarDesktop = tw.div`
    md:pt-10 
    md:pr-0
    md:pb-[30px]
    md:pl-10
    xl:pt-14
    xl:pr-0
    xl:pb-12
    xl:pl-[60px]
    md:min-w-[255px]
    xl:min-w-[340px]
    md:max-w-[322px]
    xl:max-w-[456px]
    md:h-[768px]
    xl:h-auto
    md:flex
    md:items-start
    md:flex-1
`;

export const SideBarDesktopContainer = tw.div`
    flex
    flex-col
    items-start
    justify-between
    flex-1
    self-stretch
    xl:h-[864px]
`;

export const SideBarDesktopWrapper = tw.div`
    flex
    flex-col
    items-start
    md:gap-1
    xl:gap-2
    self-stretch	
`;

export const ChatAndLogoutContainer = tw.div`
    flex
    flex-col
    md:gap-5
    xl:gap-6
    items-start
    self-stretch
`;

export const LogoutButton = tw.div`
    flex
    py-4
    xl:py-4
    md:px-6 
    xl:pl-6 
    xl:pr-[60px]
    gap-4
    items-center
    md:self-stretch
    xl:self-auto
    [&_svg]:w-6
    [&_svg]:h-6
    [&_svg]:flex
    [&_svg]:shrink-0	
    [&_svg]:text-grey-400
`;

export const MobileSideLine = tw.hr`
    my-4 
    -ml-10 
    h-[5px] 
    w-10 
    !rotate-90 
    border-l-2 
    !bg-resin
`;

export const MobileBottomLine = tw.hr`
    -mx-5 
    mb-[-30px] 
    mt-[25px] 
    h-[3px] 
    border-b 
    !bg-resin
`;

export const MobileTopMenu = tw.div`
    flex 
    items-center
`;

export const ChevronButton = `
    absolute 
    right-0 
    ml-[25px] 
    mr-5 
    h-6 
    w-6
`;

export const FlexClass = tw.div<{ $active?: boolean }>`
    flex
    md:gap-4
    xl:gap-6
    md:py-4
    [&_svg]:w-6
    [&_svg]:h-6
    [&_svg]:flex
    [&_svg]:shrink-0
    w-full
    ${(p) => (p.$active ? 'border border-solid border-l-0 border-y-0 border-r-[3px] border-r-resin' : '')}
`;

export const DeskButtonClass = `
   absolute 
   md:relative
   bottom-0  
   !py-[22px] 
   !pl-8 
   !pr-[38px]
`;

export const MobButtonClass = `
    absolute 
    bottom-5 
    ml-[100px] 
    w-[203px] 
    [&_span]:!mr-0 
    !w-[165px] 
    -translate-x-1/2 
    !py-4 
    !pl-[22px] 
    !pr-[26px]
`;

export const OpenDrawerClass = `
     !max-h-[540px] 
     overflow-y-auto 
     p-5 
     pb-6
`;
