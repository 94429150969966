'use client';

import React, { useRef, useState } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { Typography } from 'components/atoms/typography';
import { categoriesTrack } from 'helpers/analytics/categories-track';
import { SegmentEvents } from 'helpers/constants/forms';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { IContentfulImage } from 'helpers/types';
import { getLinkTarget, getMenuFiltersLink } from 'helpers/utils/common';
import { mediumDesktop, tablet } from 'helpers/utils/screensizes';
import ChevronRightIcon from 'public/icons/figma/chevron-right.svg';
import ImageContainer from './image-component';
import {
  ButtonContainer,
  OcpContainer,
  OcpItems,
  OcpItemsContainer,
  OcpLeftSideImageContainer,
  OcpListItemsContainer,
  OcpMainContainer,
} from './styles';

interface IOcpProps {
  title: string;
  itemsCollection: Array<{
    title: string;
    algoliaId?: string;
    algoliaValue?: string;
  }>;
  leftSideImageData: IContentfulImage[];
  rightSideImageData: IContentfulImage[];
  mobileImageData: IContentfulImage[];
}

const OcpSection = ({ itemsCollection, leftSideImageData, rightSideImageData, mobileImageData, title }: IOcpProps) => {
  const totalItems = itemsCollection?.length;
  const [newItemsList, setNewItemsList] = useState(itemsCollection && itemsCollection?.slice(0, 4));
  const scrollRef = useRef(null);
  const [isTablet] = useMediaQuery(tablet);
  const [isDesktop] = useMediaQuery(mediumDesktop);
  const showButtonText =
    totalItems > 8
      ? newItemsList.length === totalItems
        ? `Show Less (4)`
        : newItemsList.length + 4 >= totalItems
        ? `Show More (${totalItems - newItemsList.length})`
        : `Show More (4)`
      : '';

  const handleClick = (data: { category: string | Array<string>; type: string }) => {
    categoriesTrack(data);
  };

  return (
    <OcpMainContainer>
      <OcpContainer>
        <OcpListItemsContainer ref={scrollRef}>
          <Typography
            variant="h4"
            as="p"
            className="lg:mb-15 mb-12 flex !text-grey-400 md:items-center md:justify-center"
          >
            {title}
          </Typography>
          <OcpItemsContainer>
            {totalItems > 0 &&
              newItemsList.map((item, index) => {
                return (
                  <OcpItems key={index}>
                    <Typography
                      variant="h1"
                      as="a"
                      target={getLinkTarget(getMenuFiltersLink(item?.algoliaId, item?.algoliaValue))}
                      className=" relative z-[2] mb-4 flex cursor-pointer items-center text-[45px] !text-grey-400 hover:!text-leaf md:mb-6"
                      onClick={() => handleClick({ category: item?.title, type: SegmentEvents.CATEGORIES_CLICKED })}
                      id={`category_${index}`}
                      href={getMenuFiltersLink(item?.algoliaId, item?.algoliaValue)}
                    >
                      {item?.title}
                      <IconButton className="ml-3 hidden !bg-kief !p-2 !text-leaf" color="green" button-type="primary">
                        <ChevronRightIcon />
                      </IconButton>
                    </Typography>
                  </OcpItems>
                );
              })}
          </OcpItemsContainer>
          {totalItems > 4 && (
            <ButtonContainer>
              <Typography
                variant="small"
                className="pb-4"
              >{`Showing ${newItemsList.length} of ${totalItems}`}</Typography>
              <CommonButton
                color="green"
                button-type="secondary"
                className="w-fit"
                onClick={() => {
                  newItemsList?.length <= 4 &&
                    handleClick({
                      category: itemsCollection?.slice(0, 4).map((item) => item?.title || ''),
                      type: SegmentEvents.CATEGORIES_EXPANDED,
                    });
                  newItemsList?.length === totalItems
                    ? setNewItemsList(itemsCollection.slice(0, totalItems - 4))
                    : setNewItemsList(
                        newItemsList?.length + 4 > itemsCollection?.length
                          ? itemsCollection
                          : itemsCollection?.slice(0, newItemsList?.length + 4),
                      );
                }}
              >
                {showButtonText}
              </CommonButton>
            </ButtonContainer>
          )}
          {isTablet && (
            <>
              <OcpLeftSideImageContainer>
                {(newItemsList.length === 4
                  ? leftSideImageData?.filter((item) => item).slice(0, 4)
                  : newItemsList.length < 9
                  ? leftSideImageData?.slice(0, 5)
                  : leftSideImageData?.filter((item) => item)
                )?.map((item, index) => {
                  if (!item) return <></>;
                  return (
                    <div
                      key={index}
                      style={{
                        height: `${isDesktop ? item.height : item?.height ?? 0}px`,
                        width: `${isDesktop ? item.width : item.width ?? 0}px`,
                      }}
                      className={`  absolute z-[1] ${
                        index === 0
                          ? 'left-[8%] top-[150px] xl:left-[10%] xl:top-[150px]'
                          : index === 1
                          ? 'left-[5%] top-[300px] xl:left-[5%] xl:top-[350px]'
                          : index === 2
                          ? 'left-0 top-[450px] xl:left-0 xl:top-[550px]'
                          : index === 3
                          ? 'left-0 top-[650px] xl:left-0 xl:top-[780px]'
                          : index === 4
                          ? 'left-[-12%] top-[780px] xl:left-[-8%] xl:top-[980px]'
                          : index === 5
                          ? 'left-[5%] top-[980px] xl:left-[3%] xl:top-[1180px]'
                          : index === 6
                          ? 'left-[-2%] top-[1180px] xl:left-0 xl:top-[1380px]'
                          : ''
                      }`}
                    >
                      <Parallax translateY={[5, -160]}>
                        {/* ratio of 1920 vs 1280 images are about 86/100 */}
                        <ImageContainer
                          src={item.url || ''}
                          alt={item.alt || ''}
                          height={isDesktop && item.height ? item.height * 0.86 : 0}
                          width={isDesktop && item.width ? item.width * 0.86 : 0}
                        />
                      </Parallax>
                    </div>
                  );
                })}
              </OcpLeftSideImageContainer>
              <div className=" flex flex-col items-end lg:col-span-2">
                {(newItemsList.length === 4
                  ? rightSideImageData?.slice(0, 3)
                  : newItemsList.length < 9
                  ? rightSideImageData?.slice(0, 4)
                  : rightSideImageData
                )?.map((item, index) => {
                  if (!item) return <></>;
                  return (
                    <div
                      key={index}
                      style={{
                        height: `${isDesktop && item?.height ? item?.height * 0.86 : 0}px`,
                        width: `${isDesktop && item.width ? item.width * 0.86 : 0}px`,
                      }}
                      className={`   absolute ${
                        index === 0
                          ? 'right-[-5%] top-[150px] xl:right-[2%] xl:top-[150px]'
                          : index === 1
                          ? 'right-[5%] top-[380px] xl:right-[10%] xl:top-[380px]'
                          : index === 2
                          ? 'right-[-7%] top-[530px] xl:right-[7%] xl:top-[500px]'
                          : index === 3
                          ? 'right-[-13%] top-[800px] xl:right-[-5%] xl:top-[700px]'
                          : index === 4
                          ? 'right-0 top-[1100px] xl:right-[10%] xl:top-[1000px]'
                          : index === 5
                          ? 'right-0 top-[1300px] xl:right-0 xl:top-[1344px]'
                          : ''
                      }`}
                    >
                      <Parallax translateY={[10, -160]}>
                        <ImageContainer
                          src={item.url || ''}
                          alt={item.alt || ''}
                          height={isDesktop && item.height ? item.height * 0.86 : 0}
                          width={isDesktop && item.width ? item.width * 0.86 : 0}
                          key={index}
                        />
                      </Parallax>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          {!isTablet && (
            <div className=" flex flex-col items-end lg:col-span-2">
              {(newItemsList.length === 4
                ? mobileImageData?.slice(0, 8)
                : newItemsList.length < 9
                ? mobileImageData?.slice(0, 10)
                : mobileImageData
              )?.map((item, index) => {
                if (!item) return <></>;
                return (
                  <div
                    key={index}
                    style={{ height: `${item.height}px`, width: `${item.width}px` }}
                    className={`   absolute ${
                      index === 0
                        ? 'right-[2%] top-[80px] lg:right-0 lg:top-[150px]'
                        : index === 1
                        ? 'right-[20%] top-[140px] lg:right-[20%] lg:top-[350px]'
                        : index === 2
                        ? 'right-[15%]  top-[190px] lg:right-0 lg:top-[550px]'
                        : index === 3
                        ? 'right-[12%] top-[240px] lg:right-0'
                        : index === 4
                        ? 'right-[10%] top-[330px] lg:right-0'
                        : index === 5
                        ? 'right-[10%] top-[400px] lg:right-0'
                        : index === 6
                        ? 'right-[3%] top-[440px] lg:right-0'
                        : index === 7
                        ? 'right-[10%] top-[480px] lg:right-0'
                        : index === 8
                        ? 'right-[3%] top-[530px] lg:right-0'
                        : index === 9
                        ? 'right-[20%] top-[550px] lg:right-0'
                        : index === 10
                        ? 'right-0 top-[630px] lg:right-0'
                        : index === 11
                        ? 'right-[5%] top-[730px] lg:right-0'
                        : index === 12
                        ? 'right-[5%] top-[830px] lg:right-0'
                        : ''
                    }`}
                  >
                    <Parallax translateY={[10, -160]}>
                      <ImageContainer
                        src={item.url || ''}
                        alt={item.alt || ''}
                        height={item.height || 0}
                        width={item.width || 0}
                        key={index}
                      />
                    </Parallax>
                  </div>
                );
              })}
            </div>
          )}
        </OcpListItemsContainer>
      </OcpContainer>
    </OcpMainContainer>
  );
};

export default OcpSection;
