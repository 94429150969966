import tw from 'tailwind-styled-components';

export const ThumbnailRowContainer = tw.div`
  flex
  w-full
  justify-center
  gap-2.5
  relative
  cursor-pointer
  md:flex-col
  xl:gap-[20px]
`;

export const ThumbnailImageOverlayContainer = tw.div`
  flex
  w-20
  h-20
  xl:h-[92px]
  xl:w-[92px]
  2xl:h-[128px]
  2xl:w-[128px]
`;

export const ThumbnailImageOverlay = tw.div`
  fixed
  w-20
  h-20
  xl:h-[92px]
  xl:w-[92px]
  2xl:h-[128px]
  2xl:w-[128px]
  bg-overlay
  rounded-md
`;
