import tw from 'tailwind-styled-components';

export const StickyBarContainer = tw.div<{ $isSticky: boolean }>`
    ${(p) => (p.$isSticky ? 'fixed right-[20px] bottom-[24px] md:bg-white md:right-auto md:bottom-0 md:w-full' : '')}
    z-10
`;

export const StickyBarMobileContainer = tw.div<{ $isSticky: boolean }>`
    flex
    ${(p) => (p.$isSticky ? 'right-0 bottom-0' : 'justify-center')}
    md:hidden
`;

export const StickyBarDesktopContainer = tw.div`
    hidden
    md:flex
`;

export const StickyBarInnerDesktopContainer = tw.div`
    flex
    w-full
    items-center
    md:py-[21px]
    xl:py-4
    md:px-[40px]
    xl:px-[60px]
`;
