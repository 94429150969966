'use client';

import React, { useState } from 'react';
import { useRouter } from 'next/navigation';
import { Drawer } from '@material-tailwind/react/components/Drawer';
import { IconButton } from 'components/atoms/buttons/icon-button/icon-button';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import { Typography } from 'components/atoms/typography';
import Loading from 'components/organisms/global/loader';
import ModalCentered from 'components/organisms/modal/centered';
import { communicationInitiated } from 'helpers/analytics/communication-initiated-track';
import { SegementResetEvent } from 'helpers/analytics/segment-helper';
import { trackSignedOut } from 'helpers/analytics/signup-form';
import { useChatEventTracking } from 'helpers/hooks/use-chat-event-tracking';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { AccountUrl, TSelectedSubItem } from 'helpers/types';
import Auth from 'helpers/utils/cognito';
import { tablet } from 'helpers/utils/screensizes';
import BagMediumIcon from 'public/icons/figma/bag-medium.svg';
import ChevronDownIcon from 'public/icons/figma/chevron-down.svg';
import ChevronUpIcon from 'public/icons/figma/chevron-up.svg';
import CLoseIcon from 'public/icons/figma/close-small.svg';
import DashboardIcon from 'public/icons/figma/dashboard.svg';
import LocationIcon from 'public/icons/figma/location.svg';
import LogOutIcon from 'public/icons/figma/logout.svg';
import RewardGiltIcon from 'public/icons/figma/reward-gift.svg';
import UserIcon from 'public/icons/figma/user-sidebar.svg';
import ChatIcon from 'public/icons/rise-icons/Chat.svg';
import { useAppSelector } from 'redux/hooks';
import {
  ChatAndLogoutContainer,
  ChevronButton,
  DeskButtonClass,
  FlexClass,
  LogoutButton,
  MobButtonClass,
  MobileBottomLine,
  MobileSideLine,
  MobileTopMenu,
  OpenDrawerClass,
  SideBarContainer,
  SideBarDesktop,
  SideBarDesktopContainer,
  SideBarDesktopWrapper,
} from './styles';

const items = [
  { label: 'Overview', icon: <DashboardIcon width={24} />, url: AccountUrl.OVERVIEW, type: TSelectedSubItem.OVERVIEW },
  { label: 'My Profile', icon: <UserIcon />, url: AccountUrl.PROFILE, type: TSelectedSubItem.PROFILE },
  { label: 'My Orders', icon: <BagMediumIcon />, url: AccountUrl.ORDERS, type: TSelectedSubItem.ORDERS },
  {
    label: 'Home Dispensary',
    icon: <LocationIcon />,
    url: AccountUrl.HOME_DISPENSARY,
    type: TSelectedSubItem.HOME_DISPENSARY,
  },
  { label: 'Rewards', icon: <RewardGiltIcon />, url: AccountUrl.REWARDS, type: TSelectedSubItem.REWARDS },
];

type TProps = {
  selectedItem: TSelectedSubItem;
};

const SideBar = ({ selectedItem }: TProps) => {
  const [isTablet] = useMediaQuery(tablet);
  const { openChatEvent } = useChatEventTracking();
  const router = useRouter();
  const host = useAppSelector((store) => store.hostReducer.host);
  const email = useAppSelector((store) => store?.accountReducer?.user?.attributes?.email);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [loading, setLoading] = useState(false);
  const selectedItemIndex = items.findIndex((item) => item.type === selectedItem);
  const closeDrawer = () => {
    setOpenDrawer(false);
  };

  const logout = async () => {
    setLoading(true);
    await Auth.signOut();
    trackSignedOut({ email });
    SegementResetEvent();
    router.push('/');
    setLoading(false);
  };

  const LogoutBtn = () => (
    <ModalCentered
      title={'Are you sure you want to log out?'}
      buttonCloseLabel={'Cancel'}
      buttonCtaLabel={'Yes'}
      customButton={({ onClick }) => {
        return (
          <LogoutButton>
            <LogOutIcon />
            <Typography
              data-testid="sidebar-titles"
              role="button"
              tabIndex={0}
              as={'div'}
              variant="body-small-bold"
              onClick={onClick}
              className="!text-grey-400"
            >
              Log out
            </Typography>
          </LogoutButton>
        );
      }}
      onClick={logout}
      closeModalOnExitClick
      reverseButtonOrder
      cancelButtonType="primary"
      ctaButtonType="secondary"
    />
  );

  return (
    <SideBarContainer>
      {loading && <Loading />}
      {isTablet ? (
        <SideBarDesktop>
          <SideBarDesktopContainer>
            <SideBarDesktopWrapper>
              {items.map((item, index) => (
                <FlexClass key={index} $active={selectedItemIndex === index}>
                  {item.icon}
                  <a href={`${host}${item?.url}`}>
                    <Typography
                      data-testid="sidebar-titles"
                      key={index}
                      role="button"
                      tabIndex={0}
                      as={'div'}
                      variant="body-small-bold"
                      className={`${index === selectedItemIndex ? '' : '!text-grey-400'}`}
                    >
                      {item.label}
                    </Typography>
                  </a>
                </FlexClass>
              ))}
            </SideBarDesktopWrapper>
            <ChatAndLogoutContainer>
              <LogoutBtn />
              {!host.includes('oh') && (
                <IconWithTextButton
                  color="green"
                  data-testid="chat-button"
                  button-type="primary"
                  iconPosition="before"
                  size="lg"
                  className={DeskButtonClass}
                  icon={<ChatIcon />}
                  onClick={() => {
                    openChatEvent();
                    communicationInitiated({
                      method: 'chat',
                      link_name: '',
                      link_type: 'button',
                      communication_trigger: 'left-sidebar',
                    });
                  }}
                >
                  Chat with us
                </IconWithTextButton>
              )}
            </ChatAndLogoutContainer>
          </SideBarDesktopContainer>
        </SideBarDesktop>
      ) : (
        <SideBarContainer>
          <MobileTopMenu role="button" tabIndex={0} onClick={() => setOpenDrawer(true)}>
            {items.map((item, index) => (
              <Typography
                key={item?.label}
                role="button"
                tabIndex={0}
                data-testid="topics-menu"
                as={'div'}
                variant="body-small-bold"
                className={`!p-5 ${
                  selectedItemIndex < 3
                    ? index < 3
                      ? 'block'
                      : 'hidden'
                    : index >= selectedItemIndex - 1 && index <= selectedItemIndex
                    ? 'block'
                    : 'hidden'
                } ${index === selectedItemIndex ? '' : '!text-grey-400'}`}
              >
                {item.label}

                {index === selectedItemIndex && <MobileBottomLine />}
              </Typography>
            ))}
            {openDrawer ? (
              <ChevronUpIcon className={ChevronButton} />
            ) : (
              <ChevronDownIcon
                data-testid="sidebar-down-icon"
                onClick={() => {
                  setOpenDrawer(true);
                }}
                className={ChevronButton}
              />
            )}
          </MobileTopMenu>
          <Drawer
            open={openDrawer}
            onClose={closeDrawer}
            className={openDrawer ? OpenDrawerClass : '!max-h-[300px]'}
            placement="bottom"
            data-testid="sidebar-drawer"
            overlayProps={{ className: 'bg-overlay !backdrop-blur-none h-[2000px]' }}
          >
            <div className="float-right mb-6">
              <IconButton data-testid="sidebar-close-icon" button-type="tertiary" color="white" onClick={closeDrawer}>
                <CLoseIcon className="h-6 w-6 text-leaf" />
              </IconButton>
            </div>
            <div className="pt-6">
              {items.map((item, index) => (
                <React.Fragment key={index}>
                  <FlexClass>
                    {index === selectedItemIndex && index != items.length - 1 && <MobileSideLine />}
                    <a href={`${host}${item?.url}`}>
                      <Typography
                        as={'div'}
                        role="button"
                        tabIndex={0}
                        data-testid="drawer-titles"
                        variant="body-small-bold"
                        className={`p-4 pl-0 ${index === selectedItemIndex ? '' : '!text-grey-400'}`}
                      >
                        <FlexClass>
                          {item.icon}
                          <div className="flex items-center pl-4">{item.label}</div>
                        </FlexClass>
                      </Typography>
                    </a>
                  </FlexClass>
                </React.Fragment>
              ))}
            </div>
            <hr className="my-2 border-t-2" />

            <LogoutBtn />
            {!host.includes('oh') && (
              <IconWithTextButton
                color="green"
                button-type="primary"
                iconPosition="before"
                data-testid="chat-button"
                size="lg"
                className={MobButtonClass}
                icon={<ChatIcon />}
                onClick={() => {
                  setOpenDrawer(false);
                  openChatEvent();
                  communicationInitiated({
                    method: 'chat',
                    link_name: '',
                    link_type: 'button',
                    communication_trigger: 'account-drawer',
                  });
                }}
              >
                Chat with us
              </IconWithTextButton>
            )}
          </Drawer>
        </SideBarContainer>
      )}
    </SideBarContainer>
  );
};

export default SideBar;
