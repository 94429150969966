import tw from 'tailwind-styled-components';
import { TProps, Typography } from 'components/atoms/typography';

export const RichTextComponentWrapper = tw.div`
    mx-5
    2xl:pl-[176px]
    xl:pl-[177px]
    lg:pr-3
    xl:pr-0
    lg:mx-0
`;

export const RichTextTitle: React.FC<TProps> = tw(Typography)`
    pt-6
    pb-8
    lg:pt-10
    lg:pb-16
    relative
    text-leaf
    before:absolute
    before:content-[""]
    before:border
    before:border-solid
    before:block
    before:top-0
    lg:before:w-[128px]
    before:w-20
    before:h-[2px]
    before:border-t-resin
    scroll-headings
    scroll-mt-[200px]
`;

type ContentfulRichTextWrapperProps = {
  $PaddingClass: boolean;
};

export const ContentfulRichTextWrapper = tw.div<ContentfulRichTextWrapperProps>`
  ${(props) => (props.$PaddingClass ? 'pb-12 xl:pb-20 2xl:pb-[136px]' : 'pb-0')}
`;
