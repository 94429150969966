import React from 'react';
import { TProductSize } from 'helpers/types/organisms/listing/product-details';
import ProductCardHorizontal from './product-card-horizontal';
import ProductCardVertical from './product-card-vertical';
enum variantType {
  vertical = 'vertical',
  verticalHorizontal = 'vertical-horizontal',
  horizontal = 'horizontal',
}
type TProps = {
  showFavorite?: boolean;
  variant: 'vertical' | 'vertical-horizontal' | 'horizontal';
  image: string;
  horizontalImagePosition?: 'left' | 'right';
  horizontalShowBorder?: boolean;
  horizontalFooterSection?: boolean;
  showSalePriceFooter?: boolean;
  name: string;
  brandName: string;
  kind?: string;
  discountPrice?: string;
  price?: string;
  type?: string;
  thc?: string;
  gram?: string;
  saleLabel?: string;
  newLabel?: string;
  strainsLabel?: string;
  containersStyle?: string;
  horizontalImageStyle?: string;
  weights?: TProductSize[];
  rewardPoints?: string | number;
  salePrice?: string;
  hideGram?: boolean;
  showOutOfStock?: boolean;
  hidePrice?: boolean;
  hideAddToCart?: boolean;
  disableAddToCartBtn?: boolean;
  averageRating?: number;
  reviews?: number;
  productId?: number;
  defaultWeightSelect?: string;
  handleSelectedWeight?: (data: any) => void;
  isShowWeightSelector?: boolean;
  order?: number;
  url?: string;
  position?: number;
  maxCartLimit?: number;
};

const ProductCard = (props: TProps) => {
  switch (props.variant) {
    case variantType.vertical:
      return <ProductCardVertical {...props} />;
    case variantType.verticalHorizontal:
      return (
        // FIXME: Why are we showing both variants
        <React.Fragment>
          <ProductCardVertical {...props} containersStyle={`hidden md:flex ${props?.containersStyle}`} />
          <ProductCardHorizontal {...props} containersStyle={`md:hidden block ${props?.containersStyle}`} />
        </React.Fragment>
      );
    case variantType.horizontal:
      return <ProductCardHorizontal {...props} />;
  }
};

export default ProductCard;
