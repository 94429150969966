'use client';

import { BannerHelpComponent } from 'components/organisms/banners/banner-help';
import BannerImage from 'components/organisms/banners/banner-image';
import BannerInformation from 'components/organisms/banners/banner-information';
import { BannerProduct } from 'components/organisms/banners/banner-product';
import BannerStatement from 'components/organisms/banners/banner-statement';
import { bannerTrack } from 'helpers/analytics/banner-track';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { CaseVariants, VariantType } from 'helpers/types/common.type';
import { getPathMenuFiltersLink } from 'helpers/utils/common';
import { tablet } from 'helpers/utils/screensizes';
import { PageFolder } from 'frontastic/lib/types';

type TTasticsProps = {
  data: any;
  pageFolder: PageFolder;
};

const PromotionalBannerTastics = ({ data, pageFolder }: TTasticsProps) => {
  let variantContent;
  const [isTablet] = useMediaQuery(tablet);
  const sectionData = data?.sectionData?.dataSource?.data?.page?.items[0]?.component?.items;

  const filterSectionData = sectionData?.filter((d) => Boolean(d))?.filter((d) => d?.__typename === 'CommonHeroBanner');
  const bannerData = filterSectionData ? filterSectionData[data.orderNumber || 0] : {};

  if (!bannerData) return <></>;
  const ctaData = bannerData?.ctaCollection?.items?.[0];

  const handleSegmentEvent = (type?: string) => {
    const bannerClickedData = {
      placement: data?.tasticPlacementNumber + 1,
      banner_position: 1,
      banner_internal_name: bannerData?.internalName,
      banner_title: bannerData?.title,
      banner_image_main: isTablet ? bannerData?.image?.url : bannerData?.mobileImage?.url || bannerData?.image?.url,
      banner_image_additional: isTablet
        ? bannerData?.additionalImage?.desktopImage?.url
        : bannerData?.additionalImage?.mobileImage?.url || bannerData?.additionalImage?.desktopImage?.url,
      banner_image_overlay: isTablet
        ? bannerData?.additionalImage?.desktopImage?.url
        : bannerData?.additionalImage?.mobileImage?.url || bannerData?.additionalImage?.desktopImage?.url,
      banner_type: bannerData?.bannerType,
      link_name: bannerData?.ctaCollection?.items[0]?.title || '',
      link_type: 'hyperlink',
    };
    bannerTrack(type, bannerClickedData);
  };

  const commonBannerData = {
    title: bannerData?.title,
    description: bannerData?.description,
    subTitle: bannerData?.subTitle,
    image: {
      desktopImage: {
        ...bannerData?.image,
      },
      mobileImage: bannerData?.mobileImage,
    },
    additionalImage: bannerData?.additionalImage,
    overlayImage: bannerData?.overlayImage,
    ctaCollection: bannerData?.ctaCollection?.items?.[0],
    ctaRewards: bannerData?.ctaCollection?.items?.[1],
    host: pageFolder?.host,
    handleSegmentEvent: (type?: string) => handleSegmentEvent(type),
    bannerType: bannerData?.bannerType,
    video: bannerData?.videoEmbed,
  };

  switch (bannerData?.bannerType) {
    case CaseVariants.BANNER_C09_A_GENERAL || CaseVariants.BANNER_C09_RIGHT_IMAGE:
      variantContent = (
        <BannerImage {...commonBannerData} imagePlacement="right" variant={VariantType.SPACED_SECONDARY} />
      );
      break;
    case CaseVariants.BANNER_C09_LEFT_IMAGE:
      variantContent = (
        <BannerImage {...commonBannerData} imagePlacement="left" variant={VariantType.SPACED_SECONDARY} />
      );
      break;
    case CaseVariants.BANNER_C09_A_TEXT_BUTTON:
      variantContent = <BannerImage {...commonBannerData} variant={VariantType.SPACED_PRIMARY} />;
      break;
    case CaseVariants.BANNER_C09_B_EXTENDED_IMAGE:
      variantContent = <BannerImage {...commonBannerData} variant={VariantType.FULL} />;
      break;
    case CaseVariants.BANNER_C10_STATEMENT:
      variantContent = <BannerStatement {...commonBannerData} />;
      break;
    case CaseVariants.BANNER_C11_A_DEALS:
      variantContent = <BannerInformation variant={VariantType.DEALS} {...commonBannerData} />;
      break;
    case CaseVariants.BANNER_C11_B_SPECIAL_OFFER:
      variantContent = <BannerInformation variant={VariantType.SPECIAL_OFFER} {...commonBannerData} />;
      break;
    case CaseVariants.BANNER_C11_C_REWARDS:
      variantContent = <BannerInformation variant={VariantType.REWARDS} {...commonBannerData} />;
      break;
    case CaseVariants.BANNER_C11_D_TEXT_AREA:
      variantContent = <BannerInformation variant={VariantType.TEXT_AREA} {...commonBannerData} />;
      break;
    case CaseVariants.BANNER_C11_E_VIDEO:
      variantContent = <BannerInformation variant={VariantType.VIDEO} {...commonBannerData} />;
      break;
    case CaseVariants.BANNER_C08_PRODUCT_IMAGE:
      variantContent = (
        <BannerProduct
          variant="image"
          bgColor="kief"
          {...commonBannerData}
          image={isTablet ? bannerData?.image?.url : bannerData?.mobileImage?.url}
          productImageProps={{
            additionalImage: isTablet
              ? commonBannerData?.additionalImage?.desktopImage?.url
              : commonBannerData?.additionalImage?.mobileImage?.url,
            overlayImage: isTablet
              ? commonBannerData?.overlayImage?.desktopImage
              : commonBannerData?.overlayImage?.mobileImage,
            ctaText: commonBannerData?.ctaCollection?.title,
            ctaLink: getPathMenuFiltersLink(commonBannerData?.ctaCollection?.longUrl),
          }}
        />
      );
      break;
    case CaseVariants.BANNER_C12_HELP:
      variantContent = (
        <div className="mx-auto flex max-w-[1920px] justify-end lg:pr-[212px]">
          <div className="md:w-[888px]">
            <BannerHelpComponent
              title={bannerData?.title}
              subtitle={bannerData?.description}
              cta={ctaData}
              imageUrl={isTablet ? bannerData?.image?.url : bannerData?.mobileImage?.url || bannerData?.image?.url}
              host={pageFolder?.host as string}
              handleSegmentEvent={handleSegmentEvent}
            />
          </div>
        </div>
      );
      break;
    case 'Other':
      variantContent = <></>;
  }

  return <>{variantContent}</>;
};

export default PromotionalBannerTastics;
